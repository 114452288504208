<template>
  <div v-if="page_loading">
    <loading :page_loading="page_loading" />
  </div>
  <div v-else>
    <loading :loading="loading" />
    <!-- แสดงภาพ -->
    <Photo v-if="ShowPhoto" :showphoto="ShowPhoto" :showphotolink="ShowPhotoLink" @emitshowphoto="ClosePhoto" />
    <div>
      <v-row>
        <v-col cols="4"></v-col>
        <v-col cols="4"></v-col>
        <v-col cols="4" class="pa-0">
          <v-select class="white" v-model="warehouse_select" :items="warehouse_list" item-value="id" item-text="name"
            persistent-hint return-object dense hide-details outlined @input="btn_warehouse()">
          </v-select>
        </v-col>
      </v-row>

      <v-tabs v-model="set_TabListHeader">
        <v-tabs-slider color="red"></v-tabs-slider>
        <v-tab v-for="item in tabListHeaders" class="box-shadow" :key="item.id" @click="ChangeTab(item.id)"
          :href="'#' + item.id">
          {{ use_language[item.keyName] }}
          <span>
            ({{ item.packageCount }})
          </span>
        </v-tab>

      </v-tabs>
      <v-card>
        <v-card v-if="set_TabListHeader == '0'" class="v-card-bottom-30 mx-0">
          <v-card-text>
            <v-row>
              <v-col cols="12" md="6" lg="2" class="pb-0">
                <v-select autocomplete="off" item-key="text" item-value="value" hide-details dense outlined>
                  <template v-slot:label> <span>{{ use_language.order_status }}</span> </template> </v-select>
              </v-col>
              <v-col cols="12" md="6" lg="3" class="pb-0">
                <v-menu ref="search_menu_date_range" transition="scale-transition" offset-y min-width="290px">
                  <template v-slot:activator="{ on }">
                    <v-text-field autocomplete="off" prepend-icon="" prepend-inner-icon="mdi-calendar"
                      ref="dateRangeText" outlined readonly hide-details dense v-on="on">
                      <template v-slot:label> <span>{{ use_language.open_order_date }}</span> </template>
                    </v-text-field>
                  </template>
                  <v-date-picker>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary">{{ use_language.reset }}</v-btn>
                    <v-btn text color="primary">{{ use_language.cancel_1 }}</v-btn>
                    <v-btn text color="primary">{{ use_language.ok }}</v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" md="6" lg="2" class="pb-0">
                <v-text-field autocomplete="off" label="ชื่อผู้รับ" hide-details dense outlined>
                  <template v-slot:label> <span>{{ use_language.recipient_name }}</span> </template>
                </v-text-field>
              </v-col>
              <v-col cols="12" md="6" lg="2" class="pb-0">
                <v-text-field autocomplete="off" label="Facebook" hide-details dense outlined>
                  <template v-slot:label> <span>{{ use_language.facebook }}</span> </template>
                </v-text-field>
              </v-col>
              <v-col cols="12" md="6" lg="2" class="pb-0">
                <v-text-field autocomplete="off" label="TrackingNo" hide-details dense outlined>
                  <template v-slot:label> <span>{{ use_language.tracking_no }}</span> </template>
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6" lg="2" class="pb-0">
                <v-select autocomplete="off" item-text="text" item-value="value" outlined hide-details dense
                  label="สถานะการส่งพัสดุ">
                  <template v-slot:label> <span>{{ use_language.parcel_delivery_status }}</span> </template>
                </v-select>
              </v-col>
              <v-col cols="12" md="6" lg="3" class="pb-0">
                <v-menu transition="scale-transition" offset-y max-width="290px" min-width="290px">
                  <template v-slot:activator="{ on }">
                    <v-text-field autocomplete="off" label="วันที่ส่งของ" outlined prepend-icon=""
                      prepend-inner-icon="mdi-calendar" readonly hide-details dense v-on="on">
                      <template v-slot:label> <span>{{ use_language.delivery_date }}</span> </template>
                    </v-text-field>
                  </template>
                  <v-date-picker class="my-0">
                    <v-spacer></v-spacer>
                    <v-btn text color="primary">{{ use_language.reset }}</v-btn>
                    <v-btn text color="primary">{{ use_language.cancel_1 }}</v-btn>
                    <v-btn text color="primary">{{ use_language.ok }}</v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" md="6" lg="2" class="pb-0">
                <v-text-field autocomplete="off" label="เลขออเดอร์" outlined hide-details dense>
                  <template v-slot:label> <span>{{ use_language.order_number }}</span> </template>
                </v-text-field>
              </v-col>
              <v-col cols="12" md="6" lg="2" class="pb-0">
                <v-combobox label="สร้างโดย" item-key="text" item-value="value" outlined hide-details dense>
                  <template v-slot:label> <span>{{ use_language.create_by }}</span> </template>
                </v-combobox>
              </v-col>
              <v-col cols="12" md="6" lg="2" class="pb-0">
                <v-text-field autocomplete="off" label="เบอร์โทรศัพท์" outlined hide-details dense>
                  <template v-slot:label> <span>{{ use_language.tel }}</span> </template>
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6" lg="2" class="pb-0">
                <v-select autocomplete="off" item-text="text" item-value="value" outlined hide-details dense
                  label="สถานะจ่าย COD">
                  <template v-slot:label> <span>{{ use_language.cod_pay_status }}</span> </template>
                </v-select>
              </v-col>
              <v-col cols="12" md="6" lg="3" class="pb-0">
                <v-menu transition="scale-transition" offset-y max-width="290px" min-width="290px">
                  <template v-slot:activator="{ on }">
                    <v-text-field autocomplete="off" label="วันที่แพ็คสินค้า" outlined prepend-icon=""
                      prepend-inner-icon="mdi-calendar" readonly hide-details dense v-on="on">
                      <template v-slot:label> <span>{{ use_language.pack_date }}</span> </template>
                    </v-text-field>
                  </template>
                  <v-date-picker class="my-0">
                    <v-spacer></v-spacer>
                    <v-btn text color="primary">{{ use_language.reset }}</v-btn>
                    <v-btn text color="primary">{{ use_language.cancel_1 }}</v-btn>
                    <v-btn text color="primary">{{ use_language.ok }}</v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" md="6" lg="2" class="pb-0">
                <v-text-field autocomplete="off" label="เลขอ้างอิง" outlined hide-details dense>
                  <template v-slot:label> <span>{{ use_language.reference_number }}</span> </template>
                </v-text-field>
              </v-col>

              <v-col cols="12" md="6" lg="2" class="pb-0">
                <v-text-field autocomplete="off" label="Line" hide-details dense outlined>
                  <template v-slot:label> <span>{{ use_language.line }}</span> </template>
                </v-text-field>
              </v-col>
              <v-col cols="12" md="6" lg="2" class="pb-0">
                <v-text-field autocomplete="off" label="Email" hide-details dense outlined>
                  <template v-slot:label> <span>{{ use_language.email }}</span> </template>
                </v-text-field>
              </v-col>

              <v-col md="6" lg="4" class="hidden-md-and-down">
                <v-btn @click="get_order_list()" color="primary" class="mr-3">{{ use_language.search }}</v-btn>
                <v-btn outlined color="primary">{{ use_language.reset }}</v-btn>
              </v-col>
              <v-col cols="12" align="center" class="hidden-lg-and-up">
                <v-btn @click="get_order_list()" color="primary" class="mr-3">{{ use_language.search }}</v-btn>
                <v-btn outlined color="primary">{{ use_language.reset }}</v-btn>
              </v-col>
            </v-row>
            <v-data-table class="elevation-0 packhai-border-table" item-key="no" show-select hide-default-footer
              :items-per-page="10000">




            </v-data-table>
          </v-card-text>
        </v-card>
        <v-card v-if="set_TabListHeader == '1-5'" class="v-card-bottom-30 mx-0">
          <v-card>
            <v-card-text>


              <template v-if="showSubheader.length > 0">
                <v-tabs v-model="set_TabListSubHeader_wait_payment">
                  <v-tabs-slider color="red"></v-tabs-slider>
                  <v-tab v-for="subItem of showSubheader" class="box-shadow" :key="subItem.id"
                    @click="ChangeTabSub(subItem.id)" :href="'#' + subItem.id">
                    {{ use_language[subItem.keyName] }}
                    <span>
                      ({{ subItem.packageCount }})

                    </span>
                  </v-tab>
                </v-tabs>
              </template>

              <template v-if="set_TabListSubHeader_wait_payment == '1'">
                <v-card class="v-card-bottom-30 mx-0">
                  <v-card-text>
                    <v-row class="item-center">
                      <v-col class="py-1" cols="5">
                        <v-row class="box-shadow-picker">
                          <v-col class="py-0" cols="6">
                            <v-select v-model="dataset_wait_payment_wait_payment.search.select_topic"
                              :items="dataset_wait_payment_wait_payment.search.select_header" persistent-hint
                              return-object dense hide-details flat solo item-text="name" item-value="value"></v-select>
                          </v-col>
                          <v-col class="py-0" cols="6">
                            <v-text-field v-model="dataset_wait_payment_wait_payment.search.text_topic"
                              placeholder="ค้นหา" flat solo hide-details dense />
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col md="6" lg="3" class="hidden-md-and-down py-1">
                        <v-btn color="primary" class="mr-3"
                          @click="btnSearchOrder(set_TabListSubHeader_wait_payment)">{{ use_language.search }}</v-btn>
                      </v-col>
                      <v-col md="6" lg="4" class="hidden-md-and-down py-1">
                        <v-select v-model="dataset_wait_payment_wait_payment.search.select_channel"
                          :items="dataset_wait_payment_wait_payment.search.list_channel" item-text="channelName"
                          item-value="channelID" persistent-hint return-object dense outlined label="ช่องทาง"
                          @input="btnChannelSource(set_TabListSubHeader_wait_payment, dataset_wait_payment_wait_payment.search.select_channel)"
                          hide-details>
                          <template slot="selection" slot-scope="data">
                            <img v-if="data.item.channelLogo != null" :src="data.item.channelLogo" class="mr-2"
                              style="width: 25px" />
                            {{ length60(data.item.channelName) }}
                            <v-badge v-if="data.item.channelCount" inline color="error"
                              :content="data.item.channelCount" />
                          </template>
                          <template slot="item" slot-scope="data">
                            <img v-if="data.item.channelLogo != null" :src="data.item.channelLogo" class="mr-2"
                              style="width: 25px" />
                            {{ length60(data.item.channelName) }}
                            <v-badge v-if="data.item.channelCount" inline color="error"
                              :content="data.item.channelCount" />
                          </template>
                        </v-select>
                      </v-col>

                    </v-row>
                    <v-row class="item-center">
                      <v-col class="py-1" cols="12" md="6" lg="1">วันที่ :</v-col>
                      <v-col class="py-1" md="6" lg="7">
                      </v-col>
                      <v-col class="py-1" md="6" lg="4">

                        <v-select v-model="dataset_wait_payment_wait_payment.search.select_express"
                          :items="dataset_wait_payment_wait_payment.search.list_express" persistent-hint return-object
                          item-text="expressCompanyName" item-value="expressCompanyID" dense outlined label="ขนส่ง"
                          @input="btnExprees(set_TabListSubHeader_wait_payment, dataset_wait_payment_wait_payment.search.select_express)"
                          hide-details>
                          <template slot="selection" slot-scope="data">
                            <img v-if="data.item.expressCompanyLogo != null" :src="data.item.expressCompanyLogo"
                              class="mr-2" style="width: 45px" />
                            {{ length60(data.item.expressCompanyName) }}
                            <v-badge v-if="data.item.expressCount" inline color="error"
                              :content="data.item.expressCount" />
                          </template>
                          <template slot="item" slot-scope="data">
                            <img v-if="data.item.expressCompanyLogo != null" :src="data.item.expressCompanyLogo"
                              class="mr-2" style="width: 45px" />
                            {{ length60(data.item.expressCompanyName) }}
                            <v-badge v-if="data.item.expressCount" inline color="error"
                              :content="data.item.expressCount" />
                          </template>
                        </v-select>

                      </v-col>
                    </v-row>
                    <v-row class="item-center">
                      <v-col class="py-1" cols="8">



                      </v-col>
                      <v-col class="py-1" cols="4">
                        <v-select v-model="dataset_wait_payment_wait_payment.search.select_print"
                          :items="dataset_wait_payment_wait_payment.search.list_print" item-text="name" item-value="id"
                          label="ประเภทการปริ้น" dense return-object hide-details outlined
                          @input="btnSearchPrintType(set_TabListSubHeader_wait_payment, dataset_wait_payment_wait_payment.search.select_print)">
                          <template slot="selection" slot-scope="data">
                            {{ length60(data.item.name) }}
                            <v-badge v-if="data.item.count" inline color="error" :content="data.item.count" />
                          </template>
                          <template slot="item" slot-scope="data">
                            {{ length60(data.item.name) }}
                            <v-badge v-if="data.item.count" inline color="error" :content="data.item.count" />
                          </template>
                        </v-select>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
                <v-row class="item-center px-0 py-1">
                  <v-col class="px-0" cols="8">

                    <v-menu offset-y>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn :elevation="1" outlined v-bind="attrs" v-on="on">
                          <span class="pl-1">ดำเนินการ</span>
                          <v-icon right>mdi-chevron-down</v-icon>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item v-for="item in dataset_wait_payment_wait_payment.manageMenuListType"
                          :key="item.name" @click="handleClickOutside(item, set_TabListSubHeader_wait_payment)">
                          <v-list-item-title>{{ item.name }}</v-list-item-title>
                        </v-list-item>


                      </v-list>
                    </v-menu>

                  </v-col>
                  <v-col class="py-1" cols="4">

                  </v-col>
                </v-row>
                <v-row class="text-center" align="center" v-if="dataset_wait_payment_wait_payment.selected.length > 1">
                  <v-col cols="12">
                    <div style="background: rgb(235 235 235)">
                      <b>คุณเลือกออเดอร์ {{ dataset_wait_payment_wait_payment.selected.length }} รายการในหน้านี้
                      </b>
                      <v-btn text @click="btnSelectedTarget(set_TabListSubHeader_wait_payment)"
                        v-if="dataset_wait_payment_wait_payment.selected.length != dataset_wait_payment_wait_payment.total && dataset_wait_payment_wait_payment.limits != 999999">
                        <a>เลือกออเดอร์ทั้ง {{ dataset_wait_payment_wait_payment.total }} ในรายการหลัก</a>
                      </v-btn>
                      <v-btn text @click="dataset_wait_payment_wait_payment.selected = []" v-else>
                        <a>ล้างการเลือก</a>
                      </v-btn>
                    </div>
                  </v-col>
                </v-row>
                <v-data-table v-model="dataset_wait_payment_wait_payment.selected"
                  :headers="dataset_wait_payment_wait_payment.headers"
                  :items="dataset_wait_payment_wait_payment.dataTable" class="elevation-0 packhai-border-table"
                  item-key="packageID" show-select hide-default-footer :items-per-page="10000">
                  <template v-slot:item.startPackingDate="{ item }">
                    {{ formatDatetime(item.startPackingDate) }}
                  </template>
                  <template v-slot:item.packedDate="{ item }">
                    {{ formatDatetime(item.packedDate) }}
                  </template>
                  <template v-slot:item.createdDatetime="{ item }">
                    {{ formatDatetime(item.createdDatetime) }}
                  </template>
                  <template v-slot:item.readyToPackDate="{ item }">
                    {{ formatDatetime(item.readyToPackDate) }}
                  </template>
                  <template v-slot:item.endPackingDate="{ item }">
                    {{ formatDatetime(item.endPackingDate) }}
                  </template>
                  <template v-slot:item.receiverName="{ item }">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <div v-bind="attrs" v-on="on" style="width: 250px" class="text-truncate">
                          <span>{{ item.receiverName }}</span>
                        </div>
                      </template>
                      <span>
                        <span>{{ item.receiverName }}</span>
                      </span>
                    </v-tooltip>
                  </template>
                  <template v-slot:item.channelName="{ item }">
                    <v-avatar size="25px" tile>
                      <v-img v-if="item.channelLogo != null && item.channelLogo != ''" :src="item.channelLogo" />
                    </v-avatar>
                  </template>
                  <template v-slot:item.expressCompanyName="{ item }">
                    <img v-if="item.expressLogo != null && item.expressLogo != ''" width="60px"
                      :src="item.expressLogo" />
                  </template>
                  <template v-slot:item.isInit="{ item }">
                    <v-icon v-if="item.isInit != null && item.isInit" size="10"
                      color="success">mdi-checkbox-blank-circle</v-icon>
                    <v-icon v-else-if="item.isInit != null && !item.isInit" size="10"
                      color="danger">mdi-checkbox-blank-circle</v-icon>
                  </template>
                  <template v-slot:item.isPrintPackingList="{ item }">
                    <v-icon v-if="item.isPrintPackingList" size="10" color="success">mdi-checkbox-blank-circle</v-icon>
                    <v-icon v-else size="10" color="danger">mdi-checkbox-blank-circle</v-icon>
                  </template>
                  <template v-slot:item.isPrintAWB="{ item }">
                    <v-icon v-if="item.isPrintAWB" size="10" color="success">mdi-checkbox-blank-circle</v-icon>
                    <v-icon v-else size="10" color="danger">mdi-checkbox-blank-circle</v-icon>
                  </template>
                  <template v-slot:item.id="{ item }">
                    <a @click="ShowDetailOrder(item.token)">{{
                      FormatOrder(item.id)
                    }}</a>
                  </template>
                  <template v-for="(header, index) in dataTable.header" v-slot:[`header.${header.value}`]="{ header }">
                    <v-tooltip :key="index" v-slot:activator="{ on }">
                      <span>{{ header.text }}</span>
                    </v-tooltip>
                  </template>
                </v-data-table>

                <v-row class="mt-2">
                  <v-col cols="2">
                    <v-select v-model="dataset_wait_payment_wait_payment.limits" hide-details outlined
                      :items="dataset_wait_payment_wait_payment.search.select_per" item-text="name" item-value="value"
                      @change="btnChangeLimits(set_TabListSubHeader_wait_payment)">
                    </v-select>
                  </v-col>
                  <v-col cols="8" class="pa-0" center @click="btnChangePage(set_TabListSubHeader_wait_payment)">
                    <v-pagination v-model="dataset_wait_payment_wait_payment.page"
                      :length="dataset_wait_payment_wait_payment.pageLength" :total-visible="9"
                      color="info"></v-pagination>
                  </v-col>
                </v-row>

              </template>
              <template v-if="set_TabListSubHeader_wait_payment == '5'">
                <v-card class="v-card-bottom-30 mx-0">
                  <v-card-text>
                    <v-row class="item-center">
                      <v-col class="py-1" cols="5">
                        <v-row class="box-shadow-picker">
                          <v-col class="py-0" cols="6">
                            <v-select v-model="dataset_wait_payment_check_payment.search.select_topic"
                              :items="dataset_wait_payment_check_payment.search.select_header" persistent-hint
                              return-object dense hide-details flat solo item-text="name" item-value="value"></v-select>
                          </v-col>
                          <v-col class="py-0" cols="6">
                            <v-text-field v-model="dataset_wait_payment_check_payment.search.text_topic"
                              placeholder="ค้นหา" flat solo hide-details dense />
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col md="6" lg="3" class="hidden-md-and-down py-1">
                        <v-btn color="primary" class="mr-3"
                          @click="btnSearchOrder(set_TabListSubHeader_wait_payment)">{{ use_language.search }}</v-btn>
                      </v-col>
                      <v-col md="6" lg="4" class="hidden-md-and-down py-1">
                        <v-select v-model="dataset_wait_payment_check_payment.search.select_channel"
                          :items="dataset_wait_payment_check_payment.search.list_channel" item-text="channelName"
                          item-value="channelID" persistent-hint return-object dense outlined label="ช่องทาง"
                          @input="btnChannelSource(set_TabListSubHeader_wait_payment, dataset_wait_payment_check_payment.search.select_channel)"
                          hide-details>
                          <template slot="selection" slot-scope="data">
                            <img v-if="data.item.channelLogo != null" :src="data.item.channelLogo" class="mr-2"
                              style="width: 25px" />
                            {{ length60(data.item.channelName) }}
                            <v-badge v-if="data.item.channelCount" inline color="error"
                              :content="data.item.channelCount" />
                          </template>
                          <template slot="item" slot-scope="data">
                            <img v-if="data.item.channelLogo != null" :src="data.item.channelLogo" class="mr-2"
                              style="width: 25px" />
                            {{ length60(data.item.channelName) }}
                            <v-badge v-if="data.item.channelCount" inline color="error"
                              :content="data.item.channelCount" />
                          </template>
                        </v-select>
                      </v-col>

                    </v-row>
                    <v-row class="item-center">
                      <v-col class="py-1" cols="12" md="6" lg="1">วันที่ :</v-col>
                      <v-col class="py-1" md="6" lg="7">
                      </v-col>
                      <v-col class="py-1" md="6" lg="4">

                        <v-select v-model="dataset_wait_payment_check_payment.search.select_express"
                          :items="dataset_wait_payment_check_payment.search.list_express" persistent-hint return-object
                          item-text="expressCompanyName" item-value="expressCompanyID" dense outlined label="ขนส่ง"
                          @input="btnExprees(set_TabListSubHeader_wait_payment, dataset_wait_payment_check_payment.search.select_express)"
                          hide-details>
                          <template slot="selection" slot-scope="data">
                            <img v-if="data.item.expressCompanyLogo != null" :src="data.item.expressCompanyLogo"
                              class="mr-2" style="width: 45px" />
                            {{ length60(data.item.expressCompanyName) }}
                            <v-badge v-if="data.item.expressCount" inline color="error"
                              :content="data.item.expressCount" />
                          </template>
                          <template slot="item" slot-scope="data">
                            <img v-if="data.item.expressCompanyLogo != null" :src="data.item.expressCompanyLogo"
                              class="mr-2" style="width: 45px" />
                            {{ length60(data.item.expressCompanyName) }}
                            <v-badge v-if="data.item.expressCount" inline color="error"
                              :content="data.item.expressCount" />
                          </template>
                        </v-select>

                      </v-col>
                    </v-row>
                    <v-row class="item-center">
                      <v-col class="py-1" cols="8">



                      </v-col>
                      <v-col class="py-1" cols="4">
                        <v-select v-model="dataset_wait_payment_check_payment.search.select_print"
                          :items="dataset_wait_payment_check_payment.search.list_print" item-text="name" item-value="id"
                          label="ประเภทการปริ้น" dense return-object hide-details outlined
                          @input="btnSearchPrintType(set_TabListSubHeader_wait_payment, dataset_wait_payment_check_payment.search.select_print)">
                          <template slot="selection" slot-scope="data">
                            {{ length60(data.item.name) }}
                            <v-badge v-if="data.item.count" inline color="error" :content="data.item.count" />
                          </template>
                          <template slot="item" slot-scope="data">
                            {{ length60(data.item.name) }}
                            <v-badge v-if="data.item.count" inline color="error" :content="data.item.count" />
                          </template>
                        </v-select>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
                <v-row class="item-center px-0 py-1">
                  <v-col class="px-0" cols="8">

                    <v-menu offset-y>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn :elevation="1" outlined v-bind="attrs" v-on="on">
                          <span class="pl-1">ดำเนินการ</span>
                          <v-icon right>mdi-chevron-down</v-icon>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item v-for="item in dataset_wait_payment_check_payment.manageMenuListType"
                          :key="item.name" @click="handleClickOutside(item, set_TabListSubHeader_wait_payment)">
                          <v-list-item-title>{{ item.name }}</v-list-item-title>
                        </v-list-item>


                      </v-list>
                    </v-menu>

                  </v-col>
                  <v-col class="py-1" cols="4">

                  </v-col>
                </v-row>
                <v-row class="text-center" align="center" v-if="dataset_wait_payment_check_payment.selected.length > 1">
                  <v-col cols="12">
                    <div style="background: rgb(235 235 235)">
                      <b>คุณเลือกออเดอร์ {{ dataset_wait_payment_check_payment.selected.length }} รายการในหน้านี้
                      </b>
                      <v-btn text @click="btnSelectedTarget(set_TabListSubHeader_wait_payment)"
                        v-if="dataset_wait_payment_check_payment.selected.length != dataset_wait_payment_check_payment.total && dataset_wait_payment_check_payment.limits != 999999">
                        <a>เลือกออเดอร์ทั้ง {{ dataset_wait_payment_check_payment.total }} ในรายการหลัก</a>
                      </v-btn>
                      <v-btn text @click="dataset_wait_payment_check_payment.selected = []" v-else>
                        <a>ล้างการเลือก</a>
                      </v-btn>
                    </div>
                  </v-col>
                </v-row>
                <v-data-table v-model="dataset_wait_payment_check_payment.selected"
                  :headers="dataset_wait_payment_check_payment.headers"
                  :items="dataset_wait_payment_check_payment.dataTable" class="elevation-0 packhai-border-table"
                  item-key="packageID" show-select hide-default-footer :items-per-page="10000">
                  <template v-slot:item.startPackingDate="{ item }">
                    {{ formatDatetime(item.startPackingDate) }}
                  </template>
                  <template v-slot:item.packedDate="{ item }">
                    {{ formatDatetime(item.packedDate) }}
                  </template>
                  <template v-slot:item.createdDatetime="{ item }">
                    {{ formatDatetime(item.createdDatetime) }}
                  </template>
                  <template v-slot:item.readyToPackDate="{ item }">
                    {{ formatDatetime(item.readyToPackDate) }}
                  </template>
                  <template v-slot:item.endPackingDate="{ item }">
                    {{ formatDatetime(item.endPackingDate) }}
                  </template>
                  <template v-slot:item.receiverName="{ item }">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <div v-bind="attrs" v-on="on" style="width: 250px" class="text-truncate">
                          <span>{{ item.receiverName }}</span>
                        </div>
                      </template>
                      <span>
                        <span>{{ item.receiverName }}</span>
                      </span>
                    </v-tooltip>
                  </template>
                  <template v-slot:item.channelName="{ item }">
                    <v-avatar size="25px" tile>
                      <v-img v-if="item.channelLogo != null && item.channelLogo != ''" :src="item.channelLogo" />
                    </v-avatar>
                  </template>
                  <template v-slot:item.expressCompanyName="{ item }">
                    <img v-if="item.expressLogo != null && item.expressLogo != ''" width="60px"
                      :src="item.expressLogo" />
                  </template>
                  <template v-slot:item.isInit="{ item }">
                    <v-icon v-if="item.isInit != null && item.isInit" size="10"
                      color="success">mdi-checkbox-blank-circle</v-icon>
                    <v-icon v-else-if="item.isInit != null && !item.isInit" size="10"
                      color="danger">mdi-checkbox-blank-circle</v-icon>
                  </template>
                  <template v-slot:item.isPrintPackingList="{ item }">
                    <v-icon v-if="item.isPrintPackingList" size="10" color="success">mdi-checkbox-blank-circle</v-icon>
                    <v-icon v-else size="10" color="danger">mdi-checkbox-blank-circle</v-icon>
                  </template>
                  <template v-slot:item.isPrintAWB="{ item }">
                    <v-icon v-if="item.isPrintAWB" size="10" color="success">mdi-checkbox-blank-circle</v-icon>
                    <v-icon v-else size="10" color="danger">mdi-checkbox-blank-circle</v-icon>
                  </template>
                  <template v-slot:item.id="{ item }">
                    <a @click="ShowDetailOrder(item.token)">{{
                      FormatOrder(item.id)
                    }}</a>
                  </template>
                  <template v-for="(header, index) in dataTable.header" v-slot:[`header.${header.value}`]="{ header }">
                    <v-tooltip :key="index" v-slot:activator="{ on }">
                      <span>{{ header.text }}</span>
                    </v-tooltip>
                  </template>
                </v-data-table>
                <v-row class="mt-2">
                  <v-col cols="2">
                    <v-select v-model="dataset_wait_payment_check_payment.limits" hide-details outlined
                      :items="dataset_wait_payment_check_payment.search.select_per" item-text="name" item-value="value"
                      @change="btnChangeLimits(set_TabListSubHeader_wait_payment)">
                    </v-select>
                  </v-col>
                  <v-col cols="8" class="pa-0" center @click="btnChangePage(set_TabListSubHeader_wait_payment)">
                    <v-pagination v-model="dataset_wait_payment_check_payment.page"
                      :length="dataset_wait_payment_check_payment.pageLength" :total-visible="9"
                      color="info"></v-pagination>
                  </v-col>
                </v-row>
              </template>

            </v-card-text>

          </v-card>
        </v-card>
        <v-card v-if="set_TabListHeader == '10-20-30-40-60-80'" class="v-card-bottom-30 mx-0">

          <v-card>
            <v-card-text>
              <template v-if="showSubheader.length > 0">
                <v-tabs v-model="set_TabListSubHeader_Process">
                  <v-tabs-slider color="red"></v-tabs-slider>
                  <v-tab v-for="subItem of showSubheader" class="box-shadow" :key="subItem.id"
                    @click="ChangeTabSub(subItem.id)" :href="'#' + subItem.id">
                    {{ use_language[subItem.keyName] }}
                    <span>
                      ({{ subItem.packageCount }})
                    </span>
                  </v-tab>
                </v-tabs>
              </template>

              <template v-if="set_TabListSubHeader_Process == '10'">
                <v-card class="v-card-bottom-30 mx-0">
                  <v-card-text>
                    <v-row class="item-center">
                      <v-col class="py-1" cols="5">
                        <v-row class="box-shadow-picker">
                          <v-col class="py-0" cols="6">
                            <v-select v-model="dataset_process_map_product.search.select_topic"
                              :items="dataset_process_map_product.search.select_header" persistent-hint return-object
                              dense hide-details flat solo item-text="name" item-value="value"></v-select>
                          </v-col>
                          <v-col class="py-0" cols="6">
                            <v-text-field v-model="dataset_process_map_product.search.text_topic" placeholder="ค้นหา"
                              flat solo hide-details dense />
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col md="6" lg="3" class="hidden-md-and-down py-1">
                        <v-btn color="primary" class="mr-3" @click="btnSearchOrder(set_TabListSubHeader_Process)">{{
                          use_language.search }}</v-btn>
                      </v-col>
                      <v-col md="6" lg="4" class="hidden-md-and-down py-1">
                        <v-select v-model="dataset_process_map_product.search.select_channel"
                          :items="dataset_process_map_product.search.list_channel" item-text="channelName"
                          item-value="channelID" persistent-hint return-object dense outlined label="ช่องทาง"
                          @input="btnChannelSource(set_TabListSubHeader_Process, dataset_process_map_product.search.select_channel)"
                          hide-details>
                          <template slot="selection" slot-scope="data">
                            <img v-if="data.item.channelLogo != null" :src="data.item.channelLogo" class="mr-2"
                              style="width: 25px" />
                            {{ length60(data.item.channelName) }}
                            <v-badge v-if="data.item.channelCount" inline color="error"
                              :content="data.item.channelCount" />
                          </template>
                          <template slot="item" slot-scope="data">
                            <img v-if="data.item.channelLogo != null" :src="data.item.channelLogo" class="mr-2"
                              style="width: 25px" />
                            {{ length60(data.item.channelName) }}
                            <v-badge v-if="data.item.channelCount" inline color="error"
                              :content="data.item.channelCount" />
                          </template>
                        </v-select>
                      </v-col>

                    </v-row>
                    <v-row class="item-center">
                      <v-col class="py-1" cols="12" md="6" lg="1">วันที่ :</v-col>
                      <v-col class="py-1" md="6" lg="7">
                      </v-col>
                      <v-col class="py-1" md="6" lg="4">

                        <v-select v-model="dataset_process_map_product.search.select_express"
                          :items="dataset_process_map_product.search.list_express" persistent-hint return-object
                          item-text="expressCompanyName" item-value="expressCompanyID" dense outlined label="ขนส่ง"
                          @input="btnExprees(set_TabListSubHeader_Process, dataset_process_map_product.search.select_express)"
                          hide-details>
                          <template slot="selection" slot-scope="data">
                            <img v-if="data.item.expressCompanyLogo != null" :src="data.item.expressCompanyLogo"
                              class="mr-2" style="width: 45px" />
                            {{ length60(data.item.expressCompanyName) }}
                            <v-badge v-if="data.item.expressCount" inline color="error"
                              :content="data.item.expressCount" />
                          </template>
                          <template slot="item" slot-scope="data">
                            <img v-if="data.item.expressCompanyLogo != null" :src="data.item.expressCompanyLogo"
                              class="mr-2" style="width: 45px" />
                            {{ length60(data.item.expressCompanyName) }}
                            <v-badge v-if="data.item.expressCount" inline color="error"
                              :content="data.item.expressCount" />
                          </template>
                        </v-select>

                      </v-col>
                    </v-row>
                    <v-row class="item-center">
                      <v-col class="py-1" cols="8">



                      </v-col>
                      <v-col class="py-1" cols="4">
                        <v-select v-model="dataset_process_map_product.search.select_print"
                          :items="dataset_process_map_product.search.list_print" item-text="name" item-value="id"
                          label="ประเภทการปริ้น" dense return-object hide-details outlined
                          @input="btnSearchPrintType(set_TabListSubHeader_Process, dataset_process_map_product.search.select_print)">
                          <template slot="selection" slot-scope="data">
                            {{ length60(data.item.name) }}
                            <v-badge v-if="data.item.count" inline color="error" :content="data.item.count" />
                          </template>
                          <template slot="item" slot-scope="data">
                            {{ length60(data.item.name) }}
                            <v-badge v-if="data.item.count" inline color="error" :content="data.item.count" />
                          </template>
                        </v-select>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
                <v-row class="item-center px-0 py-1">
                  <v-col class="px-0" cols="8">

                    <v-menu offset-y>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn :elevation="1" outlined v-bind="attrs" v-on="on">
                          <span class="pl-1">ดำเนินการ</span>
                          <v-icon right>mdi-chevron-down</v-icon>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item v-for="item in dataset_process_map_product.manageMenuListType" :key="item.name"
                          @click="handleClickOutside(item, set_TabListSubHeader_Process)">
                          <v-list-item-title>{{ item.name }}</v-list-item-title>
                        </v-list-item>

                      </v-list>
                    </v-menu>

                  </v-col>
                  <v-col class="py-1" cols="4">

                  </v-col>
                </v-row>
                <v-row class="text-center" align="center" v-if="dataset_process_map_product.selected.length > 1">
                  <v-col cols="12">
                    <div style="background: rgb(235 235 235)">
                      <b>คุณเลือกออเดอร์ {{ dataset_process_map_product.selected.length }} รายการในหน้านี้
                      </b>
                      <v-btn text @click="btnSelectedTarget(set_TabListSubHeader_Process)"
                        v-if="dataset_process_map_product.selected.length != dataset_process_map_product.total && dataset_process_map_product.limits != 999999">
                        <a>เลือกออเดอร์ทั้ง {{ dataset_process_map_product.total }} ในรายการหลัก</a>
                      </v-btn>
                      <v-btn text @click="dataset_process_map_product.selected = []" v-else>
                        <a>ล้างการเลือก</a>
                      </v-btn>
                    </div>
                  </v-col>
                </v-row>
                <v-data-table v-model="dataset_process_map_product.selected"
                  :headers="dataset_process_map_product.headers" :items="dataset_process_map_product.dataTable"
                  class="elevation-0 packhai-border-table" item-key="packageID" show-select hide-default-footer
                  :items-per-page="10000">
                  <template v-slot:item.startPackingDate="{ item }">
                    {{ formatDatetime(item.startPackingDate) }}
                  </template>
                  <template v-slot:item.packedDate="{ item }">
                    {{ formatDatetime(item.packedDate) }}
                  </template>
                  <template v-slot:item.createdDatetime="{ item }">
                    {{ formatDatetime(item.createdDatetime) }}
                  </template>
                  <template v-slot:item.readyToPackDate="{ item }">
                    {{ formatDatetime(item.readyToPackDate) }}
                  </template>
                  <template v-slot:item.endPackingDate="{ item }">
                    {{ formatDatetime(item.endPackingDate) }}
                  </template>
                  <template v-slot:item.receiverName="{ item }">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <div v-bind="attrs" v-on="on" style="width: 250px" class="text-truncate">
                          <span>{{ item.receiverName }}</span>
                        </div>
                      </template>
                      <span>
                        <span>{{ item.receiverName }}</span>
                      </span>
                    </v-tooltip>
                  </template>
                  <template v-slot:item.channelName="{ item }">
                    <v-avatar size="25px" tile>
                      <v-img v-if="item.channelLogo != null && item.channelLogo != ''" :src="item.channelLogo" />
                    </v-avatar>
                  </template>
                  <template v-slot:item.expressCompanyName="{ item }">
                    <img v-if="item.expressLogo != null && item.expressLogo != ''" width="60px"
                      :src="item.expressLogo" />
                  </template>
                  <template v-slot:item.isInit="{ item }">
                    <v-icon v-if="item.isInit != null && item.isInit" size="10"
                      color="success">mdi-checkbox-blank-circle</v-icon>
                    <v-icon v-else-if="item.isInit != null && !item.isInit" size="10"
                      color="danger">mdi-checkbox-blank-circle</v-icon>
                  </template>
                  <template v-slot:item.isPrintPackingList="{ item }">
                    <v-icon v-if="item.isPrintPackingList" size="10" color="success">mdi-checkbox-blank-circle</v-icon>
                    <v-icon v-else size="10" color="danger">mdi-checkbox-blank-circle</v-icon>
                  </template>
                  <template v-slot:item.isPrintAWB="{ item }">
                    <v-icon v-if="item.isPrintAWB" size="10" color="success">mdi-checkbox-blank-circle</v-icon>
                    <v-icon v-else size="10" color="danger">mdi-checkbox-blank-circle</v-icon>
                  </template>
                  <template v-slot:item.id="{ item }">
                    <a @click="ShowDetailOrder(item.token)">{{
                      FormatOrder(item.id)
                    }}</a>
                  </template>
                  <template v-for="(header, index) in dataTable.header" v-slot:[`header.${header.value}`]="{ header }">
                    <v-tooltip :key="index" v-slot:activator="{ on }">
                      <span>{{ header.text }}</span>
                    </v-tooltip>
                  </template>
                </v-data-table>
                <v-row class="mt-2">
                  <v-col cols="2">
                    <v-select v-model="dataset_process_map_product.limits" hide-details outlined
                      :items="dataset_process_map_product.search.select_per" item-text="name" item-value="value"
                      @change="btnChangeLimits(set_TabListSubHeader_Process)">
                    </v-select>
                  </v-col>
                  <v-col cols="8" class="pa-0" center @click="btnChangePage(set_TabListSubHeader_Process)">
                    <v-pagination v-model="dataset_process_map_product.page"
                      :length="dataset_process_map_product.pageLength" :total-visible="9" color="info"></v-pagination>
                  </v-col>
                </v-row>
              </template>
              <template v-if="set_TabListSubHeader_Process == '20'">
                <v-card class="v-card-bottom-30 mx-0">
                  <v-card-text>
                    <v-row class="item-center">
                      <v-col class="py-1" cols="5">
                        <v-row class="box-shadow-picker">
                          <v-col class="py-0" cols="6">
                            <v-select v-model="dataset_process_allocate_stock.search.select_topic"
                              :items="dataset_process_allocate_stock.search.select_header" persistent-hint return-object
                              dense hide-details flat solo item-text="name" item-value="value"></v-select>
                          </v-col>
                          <v-col class="py-0" cols="6">
                            <v-text-field v-model="dataset_process_allocate_stock.search.text_topic" placeholder="ค้นหา"
                              flat solo hide-details dense />
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col md="6" lg="3" class="hidden-md-and-down py-1">
                        <v-btn color="primary" class="mr-3" @click="btnSearchOrder(set_TabListSubHeader_Process)">{{
                          use_language.search }}</v-btn>
                      </v-col>
                      <v-col md="6" lg="4" class="hidden-md-and-down py-1">
                        <v-select v-model="dataset_process_allocate_stock.search.select_channel"
                          :items="dataset_process_allocate_stock.search.list_channel" item-text="channelName"
                          item-value="channelID" persistent-hint return-object dense outlined label="ช่องทาง"
                          @input="btnChannelSource(set_TabListSubHeader_Process, dataset_process_allocate_stock.search.select_channel)"
                          hide-details>
                          <template slot="selection" slot-scope="data">
                            <img v-if="data.item.channelLogo != null" :src="data.item.channelLogo" class="mr-2"
                              style="width: 25px" />
                            {{ length60(data.item.channelName) }}
                            <v-badge v-if="data.item.channelCount" inline color="error"
                              :content="data.item.channelCount" />
                          </template>
                          <template slot="item" slot-scope="data">
                            <img v-if="data.item.channelLogo != null" :src="data.item.channelLogo" class="mr-2"
                              style="width: 25px" />
                            {{ length60(data.item.channelName) }}
                            <v-badge v-if="data.item.channelCount" inline color="error"
                              :content="data.item.channelCount" />
                          </template>
                        </v-select>
                      </v-col>

                    </v-row>
                    <v-row class="item-center">
                      <v-col class="py-1" cols="12" md="6" lg="1">วันที่ :</v-col>
                      <v-col class="py-1" md="6" lg="7">
                      </v-col>
                      <v-col class="py-1" md="6" lg="4">

                        <v-select v-model="dataset_process_allocate_stock.search.select_express"
                          :items="dataset_process_allocate_stock.search.list_express" persistent-hint return-object
                          item-text="expressCompanyName" item-value="expressCompanyID" dense outlined label="ขนส่ง"
                          @input="btnExprees(set_TabListSubHeader_Process, dataset_process_allocate_stock.search.select_express)"
                          hide-details>
                          <template slot="selection" slot-scope="data">
                            <img v-if="data.item.expressCompanyLogo != null" :src="data.item.expressCompanyLogo"
                              class="mr-2" style="width: 45px" />
                            {{ length60(data.item.expressCompanyName) }}
                            <v-badge v-if="data.item.expressCount" inline color="error"
                              :content="data.item.expressCount" />
                          </template>
                          <template slot="item" slot-scope="data">
                            <img v-if="data.item.expressCompanyLogo != null" :src="data.item.expressCompanyLogo"
                              class="mr-2" style="width: 45px" />
                            {{ length60(data.item.expressCompanyName) }}
                            <v-badge v-if="data.item.expressCount" inline color="error"
                              :content="data.item.expressCount" />
                          </template>
                        </v-select>

                      </v-col>
                    </v-row>
                    <v-row class="item-center">
                      <v-col class="py-1" cols="8">



                      </v-col>
                      <v-col class="py-1" cols="4">
                        <v-select v-model="dataset_process_allocate_stock.search.select_print"
                          :items="dataset_process_allocate_stock.search.list_print" item-text="name" item-value="id"
                          label="ประเภทการปริ้น" dense return-object hide-details outlined
                          @input="btnSearchPrintType(set_TabListSubHeader_Process, dataset_process_allocate_stock.search.select_print)">
                          <template slot="selection" slot-scope="data">
                            {{ length60(data.item.name) }}
                            <v-badge v-if="data.item.count" inline color="error" :content="data.item.count" />
                          </template>
                          <template slot="item" slot-scope="data">
                            {{ length60(data.item.name) }}
                            <v-badge v-if="data.item.count" inline color="error" :content="data.item.count" />
                          </template>
                        </v-select>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
                <v-row class="item-center px-0 py-1">
                  <v-col class="px-0" cols="8">

                    <v-menu offset-y>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn :elevation="1" outlined v-bind="attrs" v-on="on">
                          <span class="pl-1">ดำเนินการ</span>
                          <v-icon right>mdi-chevron-down</v-icon>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item v-for="item in dataset_process_allocate_stock.manageMenuListType" :key="item.name"
                          @click="handleClickOutside(item, set_TabListSubHeader_Process)">
                          <v-list-item-title>{{ item.name }}</v-list-item-title>
                        </v-list-item>



                      </v-list>
                    </v-menu>

                  </v-col>
                  <v-col class="py-1" cols="4">

                  </v-col>
                </v-row>
                <v-row class="text-center" align="center" v-if="dataset_process_allocate_stock.selected.length > 1">
                  <v-col cols="12">
                    <div style="background: rgb(235 235 235)">
                      <b>คุณเลือกออเดอร์ {{ dataset_process_allocate_stock.selected.length }} รายการในหน้านี้
                      </b>
                      <v-btn text @click="btnSelectedTarget(set_TabListSubHeader_Process)"
                        v-if="dataset_process_allocate_stock.selected.length != dataset_process_allocate_stock.total && dataset_process_allocate_stock.limits != 999999">
                        <a>เลือกออเดอร์ทั้ง {{ dataset_process_allocate_stock.total }} ในรายการหลัก</a>
                      </v-btn>
                      <v-btn text @click="dataset_process_allocate_stock.selected = []" v-else>
                        <a>ล้างการเลือก</a>
                      </v-btn>
                    </div>
                  </v-col>
                </v-row>
                <v-data-table v-model="dataset_process_allocate_stock.selected"
                  :headers="dataset_process_allocate_stock.headers" :items="dataset_process_allocate_stock.dataTable"
                  class="elevation-0 packhai-border-table" item-key="packageID" show-select hide-default-footer
                  :items-per-page="10000">
                </v-data-table>
                <v-row class="mt-2">
                  <v-col cols="2">
                    <v-select v-model="dataset_process_allocate_stock.limits" hide-details outlined
                      :items="dataset_process_allocate_stock.search.select_per" item-text="name" item-value="value"
                      @change="btnChangeLimits(set_TabListSubHeader_Process)">
                    </v-select>
                  </v-col>
                  <v-col cols="8" class="pa-0" center @click="btnChangePage(set_TabListSubHeader_Process)">
                    <v-pagination v-model="dataset_process_allocate_stock.page"
                      :length="dataset_process_allocate_stock.pageLength" :total-visible="9"
                      color="info"></v-pagination>
                  </v-col>
                </v-row>
              </template>
              <template v-if="set_TabListSubHeader_Process == '30'">
                <v-card class="v-card-bottom-30 mx-0">
                  <v-card-text>
                    <v-row class="item-center">
                      <v-col class="py-1" cols="5">
                        <v-row class="box-shadow-picker">
                          <v-col class="py-0" cols="6">
                            <v-select v-model="dataset_process_ready_to_ship.search.select_topic"
                              :items="dataset_process_ready_to_ship.search.select_header" persistent-hint return-object
                              dense hide-details flat solo item-text="name" item-value="value"></v-select>
                          </v-col>
                          <v-col class="py-0" cols="6">
                            <v-text-field v-model="dataset_process_ready_to_ship.search.text_topic" placeholder="ค้นหา"
                              flat solo hide-details dense />
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col md="6" lg="3" class="hidden-md-and-down py-1">
                        <v-btn color="primary" class="mr-3" @click="btnSearchOrder(set_TabListSubHeader_Process)">{{
                          use_language.search }}</v-btn>
                      </v-col>
                      <v-col md="6" lg="4" class="hidden-md-and-down py-1">
                        <v-select v-model="dataset_process_ready_to_ship.search.select_channel"
                          :items="dataset_process_ready_to_ship.search.list_channel" item-text="channelName"
                          item-value="channelID" persistent-hint return-object dense outlined label="ช่องทาง"
                          @input="btnChannelSource(set_TabListSubHeader_Process, dataset_process_ready_to_ship.search.select_channel)"
                          hide-details>
                          <template slot="selection" slot-scope="data">
                            <img v-if="data.item.channelLogo != null" :src="data.item.channelLogo" class="mr-2"
                              style="width: 25px" />
                            {{ length60(data.item.channelName) }}
                            <v-badge v-if="data.item.channelCount" inline color="error"
                              :content="data.item.channelCount" />
                          </template>
                          <template slot="item" slot-scope="data">
                            <img v-if="data.item.channelLogo != null" :src="data.item.channelLogo" class="mr-2"
                              style="width: 25px" />
                            {{ length60(data.item.channelName) }}
                            <v-badge v-if="data.item.channelCount" inline color="error"
                              :content="data.item.channelCount" />
                          </template>
                        </v-select>
                      </v-col>

                    </v-row>
                    <v-row class="item-center">
                      <v-col class="py-1" cols="12" md="6" lg="1">วันที่ :</v-col>
                      <v-col class="py-1" md="6" lg="7">
                      </v-col>
                      <v-col class="py-1" md="6" lg="4">

                        <v-select v-model="dataset_process_ready_to_ship.search.select_express"
                          :items="dataset_process_ready_to_ship.search.list_express" persistent-hint return-object
                          item-text="expressCompanyName" item-value="expressCompanyID" dense outlined label="ขนส่ง"
                          @input="btnExprees(set_TabListSubHeader_Process, dataset_process_ready_to_ship.search.select_express)"
                          hide-details>
                          <template slot="selection" slot-scope="data">
                            <img v-if="data.item.expressCompanyLogo != null" :src="data.item.expressCompanyLogo"
                              class="mr-2" style="width: 45px" />
                            {{ length60(data.item.expressCompanyName) }}
                            <v-badge v-if="data.item.expressCount" inline color="error"
                              :content="data.item.expressCount" />
                          </template>
                          <template slot="item" slot-scope="data">
                            <img v-if="data.item.expressCompanyLogo != null" :src="data.item.expressCompanyLogo"
                              class="mr-2" style="width: 45px" />
                            {{ length60(data.item.expressCompanyName) }}
                            <v-badge v-if="data.item.expressCount" inline color="error"
                              :content="data.item.expressCount" />
                          </template>
                        </v-select>

                      </v-col>
                    </v-row>
                    <v-row class="item-center">
                      <v-col class="py-1" cols="8">



                      </v-col>
                      <v-col class="py-1" cols="4">
                        <v-select v-model="dataset_process_ready_to_ship.search.select_print"
                          :items="dataset_process_ready_to_ship.search.list_print" item-text="name" item-value="id"
                          label="ประเภทการปริ้น" dense return-object hide-details outlined
                          @input="btnSearchPrintType(set_TabListSubHeader_Process, dataset_process_ready_to_ship.search.select_print)">
                          <template slot="selection" slot-scope="data">
                            {{ length60(data.item.name) }}
                            <v-badge v-if="data.item.count" inline color="error" :content="data.item.count" />
                          </template>
                          <template slot="item" slot-scope="data">
                            {{ length60(data.item.name) }}
                            <v-badge v-if="data.item.count" inline color="error" :content="data.item.count" />
                          </template>
                        </v-select>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
                <v-row class="item-center px-0 py-1">
                  <v-col class="px-0" cols="8">

                    <v-menu offset-y>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn :elevation="1" outlined v-bind="attrs" v-on="on">
                          <span class="pl-1">ดำเนินการ</span>
                          <v-icon right>mdi-chevron-down</v-icon>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item v-for="item in dataset_process_ready_to_ship.manageMenuListType" :key="item.name"
                          @click="handleClickOutside(item, set_TabListSubHeader_Process)">
                          <v-list-item-title>{{ item.name }}</v-list-item-title>
                        </v-list-item>


                      </v-list>
                    </v-menu>

                  </v-col>
                  <v-col class="py-1" cols="4">

                  </v-col>
                </v-row>
                <v-row class="text-center" align="center" v-if="dataset_process_ready_to_ship.selected.length > 1">
                  <v-col cols="12">
                    <div style="background: rgb(235 235 235)">
                      <b>คุณเลือกออเดอร์ {{ dataset_process_ready_to_ship.selected.length }} รายการในหน้านี้
                      </b>
                      <v-btn text @click="btnSelectedTarget(set_TabListSubHeader_Process)"
                        v-if="dataset_process_ready_to_ship.selected.length != dataset_process_ready_to_ship.total && dataset_process_ready_to_ship.limits != 999999">
                        <a>เลือกออเดอร์ทั้ง {{ dataset_process_ready_to_ship.total }} ในรายการหลัก</a>
                      </v-btn>
                      <v-btn text @click="dataset_process_ready_to_ship.selected = []" v-else>
                        <a>ล้างการเลือก</a>
                      </v-btn>
                    </div>
                  </v-col>
                </v-row>
                <v-data-table v-model="dataset_process_ready_to_ship.selected"
                  :headers="dataset_process_ready_to_ship.headers" :items="dataset_process_ready_to_ship.dataTable"
                  class="elevation-0 packhai-border-table" item-key="packageID" show-select hide-default-footer
                  :items-per-page="10000">
                  <template v-slot:item.startPackingDate="{ item }">
                    {{ formatDatetime(item.startPackingDate) }}
                  </template>
                  <template v-slot:item.packedDate="{ item }">
                    {{ formatDatetime(item.packedDate) }}
                  </template>
                  <template v-slot:item.createdDatetime="{ item }">
                    {{ formatDatetime(item.createdDatetime) }}
                  </template>
                  <template v-slot:item.readyToPackDate="{ item }">
                    {{ formatDatetime(item.readyToPackDate) }}
                  </template>
                  <template v-slot:item.endPackingDate="{ item }">
                    {{ formatDatetime(item.endPackingDate) }}
                  </template>
                  <template v-slot:item.receiverName="{ item }">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <div v-bind="attrs" v-on="on" style="width: 250px" class="text-truncate">
                          <span>{{ item.receiverName }}</span>
                        </div>
                      </template>
                      <span>
                        <span>{{ item.receiverName }}</span>
                      </span>
                    </v-tooltip>
                  </template>
                  <template v-slot:item.channelName="{ item }">
                    <v-avatar size="25px" tile>
                      <v-img v-if="item.channelLogo != null && item.channelLogo != ''" :src="item.channelLogo" />
                    </v-avatar>
                  </template>
                  <template v-slot:item.expressCompanyName="{ item }">
                    <img v-if="item.expressLogo != null && item.expressLogo != ''" width="60px"
                      :src="item.expressLogo" />
                  </template>
                  <template v-slot:item.isInit="{ item }">
                    <v-icon v-if="item.isInit != null && item.isInit" size="10"
                      color="success">mdi-checkbox-blank-circle</v-icon>
                    <v-icon v-else-if="item.isInit != null && !item.isInit" size="10"
                      color="danger">mdi-checkbox-blank-circle</v-icon>
                  </template>
                  <template v-slot:item.isPrintPackingList="{ item }">
                    <v-icon v-if="item.isPrintPackingList" size="10" color="success">mdi-checkbox-blank-circle</v-icon>
                    <v-icon v-else size="10" color="danger">mdi-checkbox-blank-circle</v-icon>
                  </template>
                  <template v-slot:item.isPrintAWB="{ item }">
                    <v-icon v-if="item.isPrintAWB" size="10" color="success">mdi-checkbox-blank-circle</v-icon>
                    <v-icon v-else size="10" color="danger">mdi-checkbox-blank-circle</v-icon>
                  </template>
                  <template v-slot:item.id="{ item }">
                    <a @click="ShowDetailOrder(item.token)">{{
                      FormatOrder(item.id)
                    }}</a>
                  </template>
                  <template v-for="(header, index) in dataTable.header" v-slot:[`header.${header.value}`]="{ header }">
                    <v-tooltip :key="index" v-slot:activator="{ on }">
                      <span>{{ header.text }}</span>
                    </v-tooltip>
                  </template>
                </v-data-table>
                <v-row class="mt-2">
                  <v-col cols="2">
                    <v-select v-model="dataset_process_ready_to_ship.limits" hide-details outlined
                      :items="dataset_process_ready_to_ship.search.select_per" item-text="name" item-value="value"
                      @change="btnChangeLimits(set_TabListSubHeader_Process)">
                    </v-select>
                  </v-col>
                  <v-col cols="8" class="pa-0" center @click="btnChangePage(set_TabListSubHeader_Process)">
                    <v-pagination v-model="dataset_process_ready_to_ship.page"
                      :length="dataset_process_ready_to_ship.pageLength" :total-visible="9" color="info"></v-pagination>
                  </v-col>
                </v-row>
              </template>
              <template v-if="set_TabListSubHeader_Process == '40'">
                <v-card class="v-card-bottom-30 mx-0">
                  <v-card-text>
                    <v-row class="item-center">
                      <v-col class="py-1" cols="5">
                        <v-row class="box-shadow-picker">
                          <v-col class="py-0" cols="6">
                            <v-select v-model="dataset_process_to_pack.search.select_topic"
                              :items="dataset_process_to_pack.search.select_header" persistent-hint return-object dense
                              hide-details flat solo item-text="name" item-value="value"></v-select>
                          </v-col>
                          <v-col class="py-0" cols="6">
                            <v-text-field v-model="dataset_process_to_pack.search.text_topic" placeholder="ค้นหา" flat
                              solo hide-details dense />
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col md="6" lg="3" class="hidden-md-and-down py-1">
                        <v-btn color="primary" class="mr-3" @click="btnSearchOrder(set_TabListSubHeader_Process)">{{
                          use_language.search }}</v-btn>
                      </v-col>
                      <v-col md="6" lg="4" class="hidden-md-and-down py-1">
                        <v-select v-model="dataset_process_to_pack.search.select_channel"
                          :items="dataset_process_to_pack.search.list_channel" item-text="channelName"
                          item-value="channelID" persistent-hint return-object dense outlined label="ช่องทาง"
                          @input="btnChannelSource(set_TabListSubHeader_Process, dataset_process_to_pack.search.select_channel)"
                          hide-details>
                          <template slot="selection" slot-scope="data">
                            <img v-if="data.item.channelLogo != null" :src="data.item.channelLogo" class="mr-2"
                              style="width: 25px" />
                            {{ length60(data.item.channelName) }}
                            <v-badge v-if="data.item.channelCount" inline color="error"
                              :content="data.item.channelCount" />
                          </template>
                          <template slot="item" slot-scope="data">
                            <img v-if="data.item.channelLogo != null" :src="data.item.channelLogo" class="mr-2"
                              style="width: 25px" />
                            {{ length60(data.item.channelName) }}
                            <v-badge v-if="data.item.channelCount" inline color="error"
                              :content="data.item.channelCount" />
                          </template>
                        </v-select>
                      </v-col>

                    </v-row>
                    <v-row class="item-center">
                      <v-col class="py-1" cols="12" md="6" lg="1">วันที่ :</v-col>
                      <v-col class="py-1" md="6" lg="7">
                      </v-col>
                      <v-col class="py-1" md="6" lg="4">

                        <v-select v-model="dataset_process_to_pack.search.select_express"
                          :items="dataset_process_to_pack.search.list_express" persistent-hint return-object
                          item-text="expressCompanyName" item-value="expressCompanyID" dense outlined label="ขนส่ง"
                          @input="btnExprees(set_TabListSubHeader_Process, dataset_process_to_pack.search.select_express)"
                          hide-details>
                          <template slot="selection" slot-scope="data">
                            <img v-if="data.item.expressCompanyLogo != null" :src="data.item.expressCompanyLogo"
                              class="mr-2" style="width: 45px" />
                            {{ length60(data.item.expressCompanyName) }}
                            <v-badge v-if="data.item.expressCount" inline color="error"
                              :content="data.item.expressCount" />
                          </template>
                          <template slot="item" slot-scope="data">
                            <img v-if="data.item.expressCompanyLogo != null" :src="data.item.expressCompanyLogo"
                              class="mr-2" style="width: 45px" />
                            {{ length60(data.item.expressCompanyName) }}
                            <v-badge v-if="data.item.expressCount" inline color="error"
                              :content="data.item.expressCount" />
                          </template>
                        </v-select>

                      </v-col>
                    </v-row>
                    <v-row class="item-center">
                      <v-col class="py-1" cols="8">

                      </v-col>
                      <v-col class="py-1" cols="4">
                        <v-select v-model="dataset_process_to_pack.search.select_print"
                          :items="dataset_process_to_pack.search.list_print" item-text="name" item-value="id"
                          label="ประเภทการปริ้น" dense return-object hide-details outlined
                          @input="btnSearchPrintType(set_TabListSubHeader_Process, dataset_process_to_pack.search.select_print)">
                          <template slot="selection" slot-scope="data">
                            {{ length60(data.item.name) }}
                            <v-badge v-if="data.item.count" inline color="error" :content="data.item.count" />
                          </template>
                          <template slot="item" slot-scope="data">
                            {{ length60(data.item.name) }}
                            <v-badge v-if="data.item.count" inline color="error" :content="data.item.count" />
                          </template>
                        </v-select>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
                <v-row class="item-center px-0 py-1">
                  <v-col class="px-0" cols="8">

                    <v-menu offset-y>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn :elevation="1" outlined v-bind="attrs" v-on="on">
                          <span class="pl-1">ดำเนินการ</span>
                          <v-icon right>mdi-chevron-down</v-icon>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item v-for="item in dataset_process_to_pack.manageMenuListType" :key="item.name"
                          @click="handleClickOutside(item, set_TabListSubHeader_Process)">
                          <v-list-item-title>{{ item.name }}</v-list-item-title>
                        </v-list-item>



                      </v-list>
                    </v-menu>

                  </v-col>
                  <v-col class="py-1" cols="4">

                  </v-col>
                </v-row>
                <v-row class="text-center" align="center" v-if="dataset_process_to_pack.selected.length > 1">
                  <v-col cols="12">
                    <div style="background: rgb(235 235 235)">
                      <b>คุณเลือกออเดอร์ {{ dataset_process_to_pack.selected.length }} รายการในหน้านี้
                      </b>
                      <v-btn text @click="btnSelectedTarget(set_TabListSubHeader_Process)"
                        v-if="dataset_process_to_pack.selected.length != dataset_process_to_pack.total && dataset_process_to_pack.limits != 999999">
                        <a>เลือกออเดอร์ทั้ง {{ dataset_process_to_pack.total }} ในรายการหลัก</a>
                      </v-btn>
                      <v-btn text @click="dataset_process_to_pack.selected = []" v-else>
                        <a>ล้างการเลือก</a>
                      </v-btn>
                    </div>
                  </v-col>
                </v-row>
                <v-data-table v-model="dataset_process_to_pack.selected" :headers="dataset_process_to_pack.headers"
                  :items="dataset_process_to_pack.dataTable" class="elevation-0 packhai-border-table"
                  item-key="packageID" show-select hide-default-footer :items-per-page="10000">
                  <template v-slot:item.startPackingDate="{ item }">
                    {{ formatDatetime(item.startPackingDate) }}
                  </template>
                  <template v-slot:item.packedDate="{ item }">
                    {{ formatDatetime(item.packedDate) }}
                  </template>
                  <template v-slot:item.createdDatetime="{ item }">
                    {{ formatDatetime(item.createdDatetime) }}
                  </template>
                  <template v-slot:item.readyToPackDate="{ item }">
                    {{ formatDatetime(item.readyToPackDate) }}
                  </template>
                  <template v-slot:item.endPackingDate="{ item }">
                    {{ formatDatetime(item.endPackingDate) }}
                  </template>
                  <template v-slot:item.receiverName="{ item }">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <div v-bind="attrs" v-on="on" style="width: 250px" class="text-truncate">
                          <span>{{ item.receiverName }}</span>
                        </div>
                      </template>
                      <span>
                        <span>{{ item.receiverName }}</span>
                      </span>
                    </v-tooltip>
                  </template>
                  <template v-slot:item.channelName="{ item }">
                    <v-avatar size="25px" tile>
                      <v-img v-if="item.channelLogo != null && item.channelLogo != ''" :src="item.channelLogo" />
                    </v-avatar>
                  </template>
                  <template v-slot:item.expressCompanyName="{ item }">
                    <img v-if="item.expressLogo != null && item.expressLogo != ''" width="60px"
                      :src="item.expressLogo" />
                  </template>
                  <template v-slot:item.isInit="{ item }">
                    <v-icon v-if="item.isInit != null && item.isInit" size="10"
                      color="success">mdi-checkbox-blank-circle</v-icon>
                    <v-icon v-else-if="item.isInit != null && !item.isInit" size="10"
                      color="danger">mdi-checkbox-blank-circle</v-icon>
                  </template>
                  <template v-slot:item.isPrintPackingList="{ item }">
                    <v-icon v-if="item.isPrintPackingList" size="10" color="success">mdi-checkbox-blank-circle</v-icon>
                    <v-icon v-else size="10" color="danger">mdi-checkbox-blank-circle</v-icon>
                  </template>
                  <template v-slot:item.isPrintAWB="{ item }">
                    <v-icon v-if="item.isPrintAWB" size="10" color="success">mdi-checkbox-blank-circle</v-icon>
                    <v-icon v-else size="10" color="danger">mdi-checkbox-blank-circle</v-icon>
                  </template>
                  <template v-slot:item.id="{ item }">
                    <a @click="ShowDetailOrder(item.token)">{{
                      FormatOrder(item.id)
                    }}</a>
                  </template>
                  <template v-for="(header, index) in dataTable.header" v-slot:[`header.${header.value}`]="{ header }">
                    <v-tooltip :key="index" v-slot:activator="{ on }">
                      <span>{{ header.text }}</span>
                    </v-tooltip>
                  </template>
                </v-data-table>
                <v-row class="mt-2">
                  <v-col cols="2">
                    <v-select v-model="dataset_process_to_pack.limits" hide-details outlined
                      :items="dataset_process_to_pack.search.select_per" item-text="name" item-value="value"
                      @change="btnChangeLimits(set_TabListSubHeader_Process)">
                    </v-select>
                  </v-col>
                  <v-col cols="8" class="pa-0" center @click="btnChangePage(set_TabListSubHeader_Process)">
                    <v-pagination v-model="dataset_process_to_pack.page" :length="dataset_process_to_pack.pageLength"
                      :total-visible="9" color="info"></v-pagination>
                  </v-col>
                </v-row>
              </template>
              <template v-if="set_TabListSubHeader_Process == '60'">
                <v-card class="v-card-bottom-30 mx-0">
                  <v-card-text>
                    <v-row class="item-center">
                      <v-col class="py-1" cols="5">
                        <v-row class="box-shadow-picker">
                          <v-col class="py-0" cols="6">
                            <v-select v-model="dataset_process_packing.search.select_topic"
                              :items="dataset_process_packing.search.select_header" persistent-hint return-object dense
                              hide-details flat solo item-text="name" item-value="value"></v-select>
                          </v-col>
                          <v-col class="py-0" cols="6">
                            <v-text-field v-model="dataset_process_packing.search.text_topic" placeholder="ค้นหา" flat
                              solo hide-details dense />
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col md="6" lg="3" class="hidden-md-and-down py-1">
                        <v-btn color="primary" class="mr-3" @click="btnSearchOrder(set_TabListSubHeader_Process)">{{
                          use_language.search }}</v-btn>
                      </v-col>
                      <v-col md="6" lg="4" class="hidden-md-and-down py-1">
                        <v-select v-model="dataset_process_packing.search.select_channel"
                          :items="dataset_process_packing.search.list_channel" item-text="channelName"
                          item-value="channelID" persistent-hint return-object dense outlined label="ช่องทาง"
                          @input="btnChannelSource(set_TabListSubHeader_Process, dataset_process_packing.search.select_channel)"
                          hide-details>
                          <template slot="selection" slot-scope="data">
                            <img v-if="data.item.channelLogo != null" :src="data.item.channelLogo" class="mr-2"
                              style="width: 25px" />
                            {{ length60(data.item.channelName) }}
                            <v-badge v-if="data.item.channelCount" inline color="error"
                              :content="data.item.channelCount" />
                          </template>
                          <template slot="item" slot-scope="data">
                            <img v-if="data.item.channelLogo != null" :src="data.item.channelLogo" class="mr-2"
                              style="width: 25px" />
                            {{ length60(data.item.channelName) }}
                            <v-badge v-if="data.item.channelCount" inline color="error"
                              :content="data.item.channelCount" />
                          </template>
                        </v-select>
                      </v-col>

                    </v-row>
                    <v-row class="item-center">
                      <v-col class="py-1" cols="12" md="6" lg="1">วันที่ :</v-col>
                      <v-col class="py-1" md="6" lg="7">
                      </v-col>
                      <v-col class="py-1" md="6" lg="4">

                        <v-select v-model="dataset_process_packing.search.select_express"
                          :items="dataset_process_packing.search.list_express" persistent-hint return-object
                          item-text="expressCompanyName" item-value="expressCompanyID" dense outlined label="ขนส่ง"
                          @input="btnExprees(set_TabListSubHeader_Process, dataset_process_packing.search.list_express)"
                          hide-details>
                          <template slot="selection" slot-scope="data">
                            <img v-if="data.item.expressCompanyLogo != null" :src="data.item.expressCompanyLogo"
                              class="mr-2" style="width: 45px" />
                            {{ length60(data.item.expressCompanyName) }}
                            <v-badge v-if="data.item.expressCount" inline color="error"
                              :content="data.item.expressCount" />
                          </template>
                          <template slot="item" slot-scope="data">
                            <img v-if="data.item.expressCompanyLogo != null" :src="data.item.expressCompanyLogo"
                              class="mr-2" style="width: 45px" />
                            {{ length60(data.item.expressCompanyName) }}
                            <v-badge v-if="data.item.expressCount" inline color="error"
                              :content="data.item.expressCount" />
                          </template>
                        </v-select>

                      </v-col>
                    </v-row>
                    <v-row class="item-center">
                      <v-col class="py-1" cols="8">



                      </v-col>
                      <v-col class="py-1" cols="4">
                        <v-select v-model="dataset_process_packing.search.select_print"
                          :items="dataset_process_packing.search.list_print" item-text="name" item-value="id"
                          label="ประเภทการปริ้น" dense return-object hide-details outlined
                          @input="btnSearchPrintType(set_TabListSubHeader_Process, dataset_process_packing.search.select_print)">
                          <template slot="selection" slot-scope="data">
                            {{ length60(data.item.name) }}
                            <v-badge v-if="data.item.count" inline color="error" :content="data.item.count" />
                          </template>
                          <template slot="item" slot-scope="data">
                            {{ length60(data.item.name) }}
                            <v-badge v-if="data.item.count" inline color="error" :content="data.item.count" />
                          </template>
                        </v-select>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
                <v-row class="item-center px-0 py-1">
                  <v-col class="px-0" cols="8">

                    <v-menu offset-y>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn :elevation="1" outlined v-bind="attrs" v-on="on">
                          <span class="pl-1">ดำเนินการ</span>
                          <v-icon right>mdi-chevron-down</v-icon>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item v-for="item in dataset_process_packing.manageMenuListType" :key="item.name"
                          @click="handleClickOutside(item, set_TabListSubHeader_Process)">
                          <v-list-item-title>{{ item.name }}</v-list-item-title>
                        </v-list-item>

                      </v-list>
                    </v-menu>

                  </v-col>
                  <v-col class="py-1" cols="4">

                  </v-col>
                </v-row>
                <v-row class="text-center" align="center" v-if="dataset_process_packing.selected.length > 1">
                  <v-col cols="12">
                    <div style="background: rgb(235 235 235)">
                      <b>คุณเลือกออเดอร์ {{ dataset_process_packing.selected.length }} รายการในหน้านี้
                      </b>
                      <v-btn text @click="btnSelectedTarget(set_TabListSubHeader_Process)"
                        v-if="dataset_process_packing.selected.length != dataset_process_packing.total && dataset_process_packing.limits != 999999">
                        <a>เลือกออเดอร์ทั้ง {{ dataset_process_packing.total }} ในรายการหลัก</a>
                      </v-btn>
                      <v-btn text @click="dataset_process_packing.selected = []" v-else>
                        <a>ล้างการเลือก</a>
                      </v-btn>
                    </div>
                  </v-col>
                </v-row>
                <v-data-table v-model="dataset_process_packing.selected" :headers="dataset_process_packing.headers"
                  :items="dataset_process_packing.dataTable" class="elevation-0 packhai-border-table"
                  item-key="packageID" show-select hide-default-footer :items-per-page="10000">
                  <template v-slot:item.startPackingDate="{ item }">
                    {{ formatDatetime(item.startPackingDate) }}
                  </template>
                  <template v-slot:item.packedDate="{ item }">
                    {{ formatDatetime(item.packedDate) }}
                  </template>
                  <template v-slot:item.createdDatetime="{ item }">
                    {{ formatDatetime(item.createdDatetime) }}
                  </template>
                  <template v-slot:item.readyToPackDate="{ item }">
                    {{ formatDatetime(item.readyToPackDate) }}
                  </template>
                  <template v-slot:item.endPackingDate="{ item }">
                    {{ formatDatetime(item.endPackingDate) }}
                  </template>
                  <template v-slot:item.receiverName="{ item }">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <div v-bind="attrs" v-on="on" style="width: 250px" class="text-truncate">
                          <span>{{ item.receiverName }}</span>
                        </div>
                      </template>
                      <span>
                        <span>{{ item.receiverName }}</span>
                      </span>
                    </v-tooltip>
                  </template>
                  <template v-slot:item.channelName="{ item }">
                    <v-avatar size="25px" tile>
                      <v-img v-if="item.channelLogo != null && item.channelLogo != ''" :src="item.channelLogo" />
                    </v-avatar>
                  </template>
                  <template v-slot:item.expressCompanyName="{ item }">
                    <img v-if="item.expressLogo != null && item.expressLogo != ''" width="60px"
                      :src="item.expressLogo" />
                  </template>
                  <template v-slot:item.isInit="{ item }">
                    <v-icon v-if="item.isInit != null && item.isInit" size="10"
                      color="success">mdi-checkbox-blank-circle</v-icon>
                    <v-icon v-else-if="item.isInit != null && !item.isInit" size="10"
                      color="danger">mdi-checkbox-blank-circle</v-icon>
                  </template>
                  <template v-slot:item.isPrintPackingList="{ item }">
                    <v-icon v-if="item.isPrintPackingList" size="10" color="success">mdi-checkbox-blank-circle</v-icon>
                    <v-icon v-else size="10" color="danger">mdi-checkbox-blank-circle</v-icon>
                  </template>
                  <template v-slot:item.isPrintAWB="{ item }">
                    <v-icon v-if="item.isPrintAWB" size="10" color="success">mdi-checkbox-blank-circle</v-icon>
                    <v-icon v-else size="10" color="danger">mdi-checkbox-blank-circle</v-icon>
                  </template>
                  <template v-slot:item.id="{ item }">
                    <a @click="ShowDetailOrder(item.token)">{{
                      FormatOrder(item.id)
                    }}</a>
                  </template>
                  <template v-for="(header, index) in dataTable.header" v-slot:[`header.${header.value}`]="{ header }">
                    <v-tooltip :key="index" v-slot:activator="{ on }">
                      <span>{{ header.text }}</span>
                    </v-tooltip>
                  </template>
                </v-data-table>
                <v-row class="mt-2">
                  <v-col cols="2">
                    <v-select v-model="dataset_process_packing.limits" hide-details outlined
                      :items="dataset_process_packing.search.select_per" item-text="name" item-value="value"
                      @change="btnChangeLimits(set_TabListSubHeader_Process)">
                    </v-select>
                  </v-col>
                  <v-col cols="8" class="pa-0" center @click="btnChangePage(set_TabListSubHeader_Process)">
                    <v-pagination v-model="dataset_process_packing.page" :length="dataset_process_packing.pageLength"
                      :total-visible="9" color="info"></v-pagination>
                  </v-col>
                </v-row>
              </template>
              <template v-if="set_TabListSubHeader_Process == '80'">
                <v-card class="v-card-bottom-30 mx-0">
                  <v-card-text>
                    <v-row class="item-center">
                      <v-col class="py-1" cols="5">
                        <v-row class="box-shadow-picker">
                          <v-col class="py-0" cols="6">
                            <v-select v-model="dataset_process_wait_pickup.search.select_topic"
                              :items="dataset_process_wait_pickup.search.select_header" persistent-hint return-object
                              dense hide-details flat solo item-text="name" item-value="value"></v-select>
                          </v-col>
                          <v-col class="py-0" cols="6">
                            <v-text-field v-model="dataset_process_wait_pickup.search.text_topic" placeholder="ค้นหา"
                              flat solo hide-details dense />
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col md="6" lg="3" class="hidden-md-and-down py-1">
                        <v-btn color="primary" class="mr-3" @click="btnSearchOrder(set_TabListSubHeader_Process)">{{
                          use_language.search }}</v-btn>
                      </v-col>
                      <v-col md="6" lg="4" class="hidden-md-and-down py-1">
                        <v-select v-model="dataset_process_wait_pickup.search.select_channel"
                          :items="dataset_process_wait_pickup.search.list_channel" item-text="channelName"
                          item-value="channelID" persistent-hint return-object dense outlined label="ช่องทาง"
                          @input="btnChannelSource(set_TabListSubHeader_Process, dataset_process_wait_pickup.search.select_channel)"
                          hide-details>
                          <template slot="selection" slot-scope="data">
                            <img v-if="data.item.channelLogo != null" :src="data.item.channelLogo" class="mr-2"
                              style="width: 25px" />
                            {{ length60(data.item.channelName) }}
                            <v-badge v-if="data.item.channelCount" inline color="error"
                              :content="data.item.channelCount" />
                          </template>
                          <template slot="item" slot-scope="data">
                            <img v-if="data.item.channelLogo != null" :src="data.item.channelLogo" class="mr-2"
                              style="width: 25px" />
                            {{ length60(data.item.channelName) }}
                            <v-badge v-if="data.item.channelCount" inline color="error"
                              :content="data.item.channelCount" />
                          </template>
                        </v-select>
                      </v-col>

                    </v-row>
                    <v-row class="item-center">
                      <v-col class="py-1" cols="12" md="6" lg="1">วันที่ :</v-col>
                      <v-col class="py-1" md="6" lg="7">
                      </v-col>
                      <v-col class="py-1" md="6" lg="4">

                        <v-select v-model="dataset_process_wait_pickup.search.select_express"
                          :items="dataset_process_wait_pickup.search.list_express" persistent-hint return-object
                          item-text="expressCompanyName" item-value="expressCompanyID" dense outlined label="ขนส่ง"
                          @input="btnExprees(set_TabListSubHeader_Process, dataset_process_wait_pickup.search.select_express)"
                          hide-details>
                          <template slot="selection" slot-scope="data">
                            <img v-if="data.item.expressCompanyLogo != null" :src="data.item.expressCompanyLogo"
                              class="mr-2" style="width: 45px" />
                            {{ length60(data.item.expressCompanyName) }}
                            <v-badge v-if="data.item.expressCount" inline color="error"
                              :content="data.item.expressCount" />
                          </template>
                          <template slot="item" slot-scope="data">
                            <img v-if="data.item.expressCompanyLogo != null" :src="data.item.expressCompanyLogo"
                              class="mr-2" style="width: 45px" />
                            {{ length60(data.item.expressCompanyName) }}
                            <v-badge v-if="data.item.expressCount" inline color="error"
                              :content="data.item.expressCount" />
                          </template>
                        </v-select>

                      </v-col>
                    </v-row>
                    <v-row class="item-center">
                      <v-col class="py-1" cols="8">

                      </v-col>
                      <v-col class="py-1" cols="4">
                        <v-select v-model="dataset_process_wait_pickup.search.select_print"
                          :items="dataset_process_wait_pickup.search.list_print" item-text="name" item-value="id"
                          label="ประเภทการปริ้น" dense return-object hide-details outlined
                          @input="btnSearchPrintType(set_TabListSubHeader_Process, dataset_process_wait_pickup.search.select_print)">
                          <template slot="selection" slot-scope="data">
                            {{ length60(data.item.name) }}
                            <v-badge v-if="data.item.count" inline color="error" :content="data.item.count" />
                          </template>
                          <template slot="item" slot-scope="data">
                            {{ length60(data.item.name) }}
                            <v-badge v-if="data.item.count" inline color="error" :content="data.item.count" />
                          </template>
                        </v-select>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
                <v-row class="item-center px-0 py-1">
                  <v-col class="px-0" cols="8">

                    <v-menu offset-y>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn :elevation="1" outlined v-bind="attrs" v-on="on">
                          <span class="pl-1">ดำเนินการ</span>
                          <v-icon right>mdi-chevron-down</v-icon>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item v-for="item in dataset_process_wait_pickup.manageMenuListType" :key="item.name"
                          @click="handleClickOutside(item, set_TabListSubHeader_Process)">
                          <v-list-item-title>{{ item.name }}</v-list-item-title>
                        </v-list-item>


                      </v-list>
                    </v-menu>

                  </v-col>
                  <v-col class="py-1" cols="4">

                  </v-col>
                </v-row>
                <v-row class="text-center" align="center" v-if="dataset_process_wait_pickup.selected.length > 1">
                  <v-col cols="12">
                    <div style="background: rgb(235 235 235)">
                      <b>คุณเลือกออเดอร์ {{ dataset_process_wait_pickup.selected.length }} รายการในหน้านี้
                      </b>
                      <v-btn text @click="btnSelectedTarget(set_TabListSubHeader_Process)"
                        v-if="dataset_process_wait_pickup.selected.length != dataset_process_wait_pickup.total && dataset_process_wait_pickup.limits != 999999">
                        <a>เลือกออเดอร์ทั้ง {{ dataset_process_wait_pickup.total }} ในรายการหลัก</a>
                      </v-btn>
                      <v-btn text @click="dataset_process_wait_pickup.selected = []" v-else>
                        <a>ล้างการเลือก</a>
                      </v-btn>
                    </div>
                  </v-col>
                </v-row>
                <v-data-table v-model="dataset_process_wait_pickup.selected"
                  :headers="dataset_process_wait_pickup.headers" :items="dataset_process_wait_pickup.dataTable"
                  class="elevation-0 packhai-border-table" item-key="packageID" show-select hide-default-footer
                  :items-per-page="10000">
                  <template v-slot:item.startPackingDate="{ item }">
                    {{ formatDatetime(item.startPackingDate) }}
                  </template>
                  <template v-slot:item.packedDate="{ item }">
                    {{ formatDatetime(item.packedDate) }}
                  </template>
                  <template v-slot:item.createdDatetime="{ item }">
                    {{ formatDatetime(item.createdDatetime) }}
                  </template>
                  <template v-slot:item.readyToPackDate="{ item }">
                    {{ formatDatetime(item.readyToPackDate) }}
                  </template>
                  <template v-slot:item.endPackingDate="{ item }">
                    {{ formatDatetime(item.endPackingDate) }}
                  </template>
                  <template v-slot:item.receiverName="{ item }">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <div v-bind="attrs" v-on="on" style="width: 250px" class="text-truncate">
                          <span>{{ item.receiverName }}</span>
                        </div>
                      </template>
                      <span>
                        <span>{{ item.receiverName }}</span>
                      </span>
                    </v-tooltip>
                  </template>
                  <template v-slot:item.channelName="{ item }">
                    <v-avatar size="25px" tile>
                      <v-img v-if="item.channelLogo != null && item.channelLogo != ''" :src="item.channelLogo" />
                    </v-avatar>
                  </template>
                  <template v-slot:item.expressCompanyName="{ item }">
                    <img v-if="item.expressLogo != null && item.expressLogo != ''" width="60px"
                      :src="item.expressLogo" />
                  </template>
                  <template v-slot:item.isInit="{ item }">
                    <v-icon v-if="item.isInit != null && item.isInit" size="10"
                      color="success">mdi-checkbox-blank-circle</v-icon>
                    <v-icon v-else-if="item.isInit != null && !item.isInit" size="10"
                      color="danger">mdi-checkbox-blank-circle</v-icon>
                  </template>
                  <template v-slot:item.isPrintPackingList="{ item }">
                    <v-icon v-if="item.isPrintPackingList" size="10" color="success">mdi-checkbox-blank-circle</v-icon>
                    <v-icon v-else size="10" color="danger">mdi-checkbox-blank-circle</v-icon>
                  </template>
                  <template v-slot:item.isPrintAWB="{ item }">
                    <v-icon v-if="item.isPrintAWB" size="10" color="success">mdi-checkbox-blank-circle</v-icon>
                    <v-icon v-else size="10" color="danger">mdi-checkbox-blank-circle</v-icon>
                  </template>
                  <template v-slot:item.id="{ item }">
                    <a @click="ShowDetailOrder(item.token)">{{
                      FormatOrder(item.id)
                    }}</a>
                  </template>
                  <template v-for="(header, index) in dataTable.header" v-slot:[`header.${header.value}`]="{ header }">
                    <v-tooltip :key="index" v-slot:activator="{ on }">
                      <span>{{ header.text }}</span>
                    </v-tooltip>
                  </template>
                </v-data-table>
                <v-row class="mt-2">
                  <v-col cols="2">
                    <v-select v-model="dataset_process_wait_pickup.limits" hide-details outlined
                      :items="dataset_process_wait_pickup.search.select_per" item-text="name" item-value="value"
                      @change="btnChangeLimits(set_TabListSubHeader_Process)">
                    </v-select>
                  </v-col>
                  <v-col cols="8" class="pa-0" center @click="btnChangePage(set_TabListSubHeader_Process)">
                    <v-pagination v-model="dataset_process_wait_pickup.page"
                      :length="dataset_process_wait_pickup.pageLength" :total-visible="9" color="info"></v-pagination>
                  </v-col>
                </v-row>
              </template>

            </v-card-text>

          </v-card>
        </v-card>
        <v-card v-if="set_TabListHeader == '90'" class="v-card-bottom-30 mx-0">
          <v-card>
            <v-card-text>

              <template>
                <v-card class="v-card-bottom-30 mx-0">
                  <v-card-text>
                    <v-row class="item-center">
                      <v-col class="py-1" cols="5">
                        <v-row class="box-shadow-picker">
                          <v-col class="py-0" cols="6">
                            <v-select v-model="dataset_delivering.search.select_topic"
                              :items="dataset_delivering.search.select_header" persistent-hint return-object dense
                              hide-details flat solo item-text="name" item-value="value"></v-select>
                          </v-col>
                          <v-col class="py-0" cols="6">
                            <v-text-field v-model="dataset_delivering.search.text_topic" placeholder="ค้นหา" flat solo
                              hide-details dense />
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col md="6" lg="3" class="hidden-md-and-down py-1">
                        <v-btn color="primary" class="mr-3" @click="btnSearchOrder(set_TabListHeader)">{{
                          use_language.search }}</v-btn>
                      </v-col>
                      <v-col md="6" lg="4" class="hidden-md-and-down py-1">
                        <v-select v-model="dataset_delivering.search.select_channel"
                          :items="dataset_delivering.search.list_channel" item-text="channelName" item-value="channelID"
                          persistent-hint return-object dense outlined label="ช่องทาง"
                          @input="btnChannelSource(set_TabListHeader, dataset_delivering.search.select_channel)"
                          hide-details>
                          <template slot="selection" slot-scope="data">
                            <img v-if="data.item.channelLogo != null" :src="data.item.channelLogo" class="mr-2"
                              style="width: 25px" />
                            {{ length60(data.item.channelName) }}
                            <v-badge v-if="data.item.channelCount" inline color="error"
                              :content="data.item.channelCount" />
                          </template>
                          <template slot="item" slot-scope="data">
                            <img v-if="data.item.channelLogo != null" :src="data.item.channelLogo" class="mr-2"
                              style="width: 25px" />
                            {{ length60(data.item.channelName) }}
                            <v-badge v-if="data.item.channelCount" inline color="error"
                              :content="data.item.channelCount" />
                          </template>
                        </v-select>
                      </v-col>

                    </v-row>
                    <v-row class="item-center">
                      <v-col class="py-1" cols="12" md="6" lg="1">วันที่ :</v-col>
                      <v-col class="py-1" md="6" lg="7">
                      </v-col>
                      <v-col class="py-1" md="6" lg="4">

                        <v-select v-model="dataset_delivering.search.select_express"
                          :items="dataset_delivering.search.list_express" persistent-hint return-object
                          item-text="expressCompanyName" item-value="expressCompanyID" dense outlined label="ขนส่ง"
                          @input="btnExprees(set_TabListHeader, dataset_delivering.search.select_express)" hide-details>
                          <template slot="selection" slot-scope="data">
                            <img v-if="data.item.expressCompanyLogo != null" :src="data.item.expressCompanyLogo"
                              class="mr-2" style="width: 45px" />
                            {{ length60(data.item.expressCompanyName) }}
                            <v-badge v-if="data.item.expressCount" inline color="error"
                              :content="data.item.expressCount" />
                          </template>
                          <template slot="item" slot-scope="data">
                            <img v-if="data.item.expressCompanyLogo != null" :src="data.item.expressCompanyLogo"
                              class="mr-2" style="width: 45px" />
                            {{ length60(data.item.expressCompanyName) }}
                            <v-badge v-if="data.item.expressCount" inline color="error"
                              :content="data.item.expressCount" />
                          </template>
                        </v-select>

                      </v-col>
                    </v-row>
                    <v-row class="item-center">
                      <v-col class="py-1" cols="8">

                      </v-col>
                      <v-col class="py-1" cols="4">
                        <v-select v-model="dataset_delivering.search.select_print"
                          :items="dataset_delivering.search.list_print" item-text="name" item-value="id"
                          label="ประเภทการปริ้น" dense return-object hide-details outlined
                          @input="btnSearchPrintType(set_TabListSubHeader_Process, dataset_delivering.search.select_print)">
                          <template slot="selection" slot-scope="data">
                            {{ length60(data.item.name) }}
                            <v-badge v-if="data.item.count" inline color="error" :content="data.item.count" />
                          </template>
                          <template slot="item" slot-scope="data">
                            {{ length60(data.item.name) }}
                            <v-badge v-if="data.item.count" inline color="error" :content="data.item.count" />
                          </template>
                        </v-select>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
                <v-row class="item-center px-0 py-1">
                  <v-col class="px-0" cols="8">

                    <v-menu offset-y>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn :elevation="1" outlined v-bind="attrs" v-on="on">
                          <span class="pl-1">ดำเนินการ</span>
                          <v-icon right>mdi-chevron-down</v-icon>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item v-for="item in dataset_delivering.manageMenuListType" :key="item.name"
                          @click="handleClickOutside(item, set_TabListSubHeader_Process)">
                          <v-list-item-title>{{ item.name }}</v-list-item-title>
                        </v-list-item>


                      </v-list>
                    </v-menu>

                  </v-col>
                  <v-col class="py-1" cols="4">

                  </v-col>
                </v-row>
                <v-row class="text-center" align="center" v-if="dataset_delivering.selected.length > 1">
                  <v-col cols="12">
                    <div style="background: rgb(235 235 235)">
                      <b>คุณเลือกออเดอร์ {{ dataset_delivering.selected.length }} รายการในหน้านี้
                      </b>
                      <v-btn text @click="btnSelectedTarget(set_TabListSubHeader_Process)"
                        v-if="dataset_delivering.selected.length != dataset_delivering.total && dataset_delivering.limits != 999999">
                        <a>เลือกออเดอร์ทั้ง {{ dataset_delivering.total }} ในรายการหลัก</a>
                      </v-btn>
                      <v-btn text @click="dataset_delivering.selected = []" v-else>
                        <a>ล้างการเลือก</a>
                      </v-btn>
                    </div>
                  </v-col>
                </v-row>
                <v-data-table v-model="dataset_delivering.selected" :headers="dataset_delivering.headers"
                  :items="dataset_delivering.dataTable" class="elevation-0 packhai-border-table" item-key="packageID"
                  show-select hide-default-footer :items-per-page="10000">
                  <template v-slot:item.startPackingDate="{ item }">
                    {{ formatDatetime(item.startPackingDate) }}
                  </template>
                  <template v-slot:item.packedDate="{ item }">
                    {{ formatDatetime(item.packedDate) }}
                  </template>
                  <template v-slot:item.createdDatetime="{ item }">
                    {{ formatDatetime(item.createdDatetime) }}
                  </template>
                  <template v-slot:item.readyToPackDate="{ item }">
                    {{ formatDatetime(item.readyToPackDate) }}
                  </template>
                  <template v-slot:item.endPackingDate="{ item }">
                    {{ formatDatetime(item.endPackingDate) }}
                  </template>
                  <template v-slot:item.receiverName="{ item }">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <div v-bind="attrs" v-on="on" style="width: 250px" class="text-truncate">
                          <span>{{ item.receiverName }}</span>
                        </div>
                      </template>
                      <span>
                        <span>{{ item.receiverName }}</span>
                      </span>
                    </v-tooltip>
                  </template>
                  <template v-slot:item.channelName="{ item }">
                    <v-avatar size="25px" tile>
                      <v-img v-if="item.channelLogo != null && item.channelLogo != ''" :src="item.channelLogo" />
                    </v-avatar>
                  </template>
                  <template v-slot:item.expressCompanyName="{ item }">
                    <img v-if="item.expressLogo != null && item.expressLogo != ''" width="60px"
                      :src="item.expressLogo" />
                  </template>
                  <template v-slot:item.isInit="{ item }">
                    <v-icon v-if="item.isInit != null && item.isInit" size="10"
                      color="success">mdi-checkbox-blank-circle</v-icon>
                    <v-icon v-else-if="item.isInit != null && !item.isInit" size="10"
                      color="danger">mdi-checkbox-blank-circle</v-icon>
                  </template>
                  <template v-slot:item.isPrintPackingList="{ item }">
                    <v-icon v-if="item.isPrintPackingList" size="10" color="success">mdi-checkbox-blank-circle</v-icon>
                    <v-icon v-else size="10" color="danger">mdi-checkbox-blank-circle</v-icon>
                  </template>
                  <template v-slot:item.isPrintAWB="{ item }">
                    <v-icon v-if="item.isPrintAWB" size="10" color="success">mdi-checkbox-blank-circle</v-icon>
                    <v-icon v-else size="10" color="danger">mdi-checkbox-blank-circle</v-icon>
                  </template>
                  <template v-slot:item.id="{ item }">
                    <a @click="ShowDetailOrder(item.token)">{{
                      FormatOrder(item.id)
                    }}</a>
                  </template>
                  <template v-for="(header, index) in dataTable.header" v-slot:[`header.${header.value}`]="{ header }">
                    <v-tooltip :key="index" v-slot:activator="{ on }">
                      <span>{{ header.text }}</span>
                    </v-tooltip>
                  </template>
                </v-data-table>
                <v-row class="mt-2">
                  <v-col cols="2">
                    <v-select v-model="dataset_delivering.limits" hide-details outlined
                      :items="dataset_delivering.search.select_per" item-text="name" item-value="value"
                      @change="btnChangeLimits(set_TabListHeader)">
                    </v-select>
                  </v-col>
                  <v-col cols="8" class="pa-0" center @click="btnChangePage(set_TabListHeader)">
                    <v-pagination v-model="dataset_delivering.page" :length="dataset_delivering.pageLength"
                      :total-visible="9" color="info"></v-pagination>
                  </v-col>
                </v-row>
              </template>

            </v-card-text>

          </v-card>
        </v-card>
        <v-card v-if="set_TabListHeader == '130'" class="v-card-bottom-30 mx-0">
          <v-card>
            <v-card-text>

              <template>
                <v-card class="v-card-bottom-30 mx-0">
                  <v-card-text>
                    <v-row class="item-center">
                      <v-col class="py-1" cols="5">
                        <v-row class="box-shadow-picker">
                          <v-col class="py-0" cols="6">
                            <v-select v-model="dataset_success.search.select_topic"
                              :items="dataset_success.search.select_header" persistent-hint return-object dense
                              hide-details flat solo item-text="name" item-value="value"></v-select>
                          </v-col>
                          <v-col class="py-0" cols="6">
                            <v-text-field v-model="dataset_success.search.text_topic" placeholder="ค้นหา" flat solo
                              hide-details dense />
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col md="6" lg="3" class="hidden-md-and-down py-1">
                        <v-btn color="primary" class="mr-3" @click="btnSearchOrder(set_TabListHeader)">{{
                          use_language.search }}</v-btn>
                      </v-col>
                      <v-col md="6" lg="4" class="hidden-md-and-down py-1">
                        <v-select v-model="dataset_success.search.select_channel"
                          :items="dataset_success.search.list_channel" item-text="channelName" item-value="channelID"
                          persistent-hint return-object dense outlined label="ช่องทาง"
                          @input="btnChannelSource(set_TabListHeader, dataset_success.search.select_channel)"
                          hide-details>
                          <template slot="selection" slot-scope="data">
                            <img v-if="data.item.channelLogo != null" :src="data.item.channelLogo" class="mr-2"
                              style="width: 25px" />
                            {{ length60(data.item.channelName) }}
                            <v-badge v-if="data.item.channelCount" inline color="error"
                              :content="data.item.channelCount" />
                          </template>
                          <template slot="item" slot-scope="data">
                            <img v-if="data.item.channelLogo != null" :src="data.item.channelLogo" class="mr-2"
                              style="width: 25px" />
                            {{ length60(data.item.channelName) }}
                            <v-badge v-if="data.item.channelCount" inline color="error"
                              :content="data.item.channelCount" />
                          </template>
                        </v-select>
                      </v-col>

                    </v-row>
                    <v-row class="item-center">
                      <v-col class="py-1" cols="12" md="6" lg="1">วันที่ :</v-col>
                      <v-col class="py-1" md="6" lg="7">
                      </v-col>
                      <v-col class="py-1" md="6" lg="4">

                        <v-select v-model="dataset_success.search.select_express"
                          :items="dataset_success.search.list_express" persistent-hint return-object
                          item-text="expressCompanyName" item-value="expressCompanyID" dense outlined label="ขนส่ง"
                          @input="btnExprees(set_TabListHeader, dataset_success.search.select_express)" hide-details>
                          <template slot="selection" slot-scope="data">
                            <img v-if="data.item.expressCompanyLogo != null" :src="data.item.expressCompanyLogo"
                              class="mr-2" style="width: 45px" />
                            {{ length60(data.item.expressCompanyName) }}
                            <v-badge v-if="data.item.expressCount" inline color="error"
                              :content="data.item.expressCount" />
                          </template>
                          <template slot="item" slot-scope="data">
                            <img v-if="data.item.expressCompanyLogo != null" :src="data.item.expressCompanyLogo"
                              class="mr-2" style="width: 45px" />
                            {{ length60(data.item.expressCompanyName) }}
                            <v-badge v-if="data.item.expressCount" inline color="error"
                              :content="data.item.expressCount" />
                          </template>
                        </v-select>

                      </v-col>
                    </v-row>
                    <v-row class="item-center">
                      <v-col class="py-1" cols="8">



                      </v-col>
                      <v-col class="py-1" cols="4">
                        <v-select v-model="dataset_success.search.select_print"
                          :items="dataset_success.search.list_print" item-text="name" item-value="id"
                          label="ประเภทการปริ้น" dense return-object hide-details outlined
                          @input="btnSearchPrintType(set_TabListHeader, dataset_success.search.select_print)">
                          <template slot="selection" slot-scope="data">
                            {{ length60(data.item.name) }}
                            <v-badge v-if="data.item.count" inline color="error" :content="data.item.count" />
                          </template>
                          <template slot="item" slot-scope="data">
                            {{ length60(data.item.name) }}
                            <v-badge v-if="data.item.count" inline color="error" :content="data.item.count" />
                          </template>
                        </v-select>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
                <v-row class="item-center px-0 py-1">
                  <v-col class="px-0" cols="8">

                    <v-menu offset-y>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn :elevation="1" outlined v-bind="attrs" v-on="on">
                          <span class="pl-1">ดำเนินการ</span>
                          <v-icon right>mdi-chevron-down</v-icon>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item v-for="item in dataset_success.manageMenuListType" :key="item.name"
                          @click="handleClickOutside(item, set_TabListHeader)">
                          <v-list-item-title>{{ item.name }}</v-list-item-title>
                        </v-list-item>


                      </v-list>
                    </v-menu>

                  </v-col>
                  <v-col class="py-1" cols="4">

                  </v-col>
                </v-row>
                <v-row class="text-center" align="center" v-if="dataset_success.selected.length > 1">
                  <v-col cols="12">
                    <div style="background: rgb(235 235 235)">
                      <b>คุณเลือกออเดอร์ {{ dataset_success.selected.length }} รายการในหน้านี้
                      </b>
                      <v-btn text @click="btnSelectedTarget(set_TabListHeader)"
                        v-if="dataset_success.selected.length != dataset_success.total && dataset_success.limits != 999999">
                        <a>เลือกออเดอร์ทั้ง {{ dataset_success.total }} ในรายการหลัก</a>
                      </v-btn>
                      <v-btn text @click="dataset_success.selected = []" v-else>
                        <a>ล้างการเลือก</a>
                      </v-btn>
                    </div>
                  </v-col>
                </v-row>
                <v-data-table v-model="dataset_success.selected" :headers="dataset_success.headers"
                  :items="dataset_success.dataTable" class="elevation-0 packhai-border-table" item-key="packageID"
                  show-select hide-default-footer :items-per-page="10000">
                  <template v-slot:item.startPackingDate="{ item }">
                    {{ formatDatetime(item.startPackingDate) }}
                  </template>
                  <template v-slot:item.packedDate="{ item }">
                    {{ formatDatetime(item.packedDate) }}
                  </template>
                  <template v-slot:item.createdDatetime="{ item }">
                    {{ formatDatetime(item.createdDatetime) }}
                  </template>
                  <template v-slot:item.readyToPackDate="{ item }">
                    {{ formatDatetime(item.readyToPackDate) }}
                  </template>
                  <template v-slot:item.endPackingDate="{ item }">
                    {{ formatDatetime(item.endPackingDate) }}
                  </template>
                  <template v-slot:item.receiverName="{ item }">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <div v-bind="attrs" v-on="on" style="width: 250px" class="text-truncate">
                          <span>{{ item.receiverName }}</span>
                        </div>
                      </template>
                      <span>
                        <span>{{ item.receiverName }}</span>
                      </span>
                    </v-tooltip>
                  </template>
                  <template v-slot:item.channelName="{ item }">
                    <v-avatar size="25px" tile>
                      <v-img v-if="item.channelLogo != null && item.channelLogo != ''" :src="item.channelLogo" />
                    </v-avatar>
                  </template>
                  <template v-slot:item.expressCompanyName="{ item }">
                    <img v-if="item.expressLogo != null && item.expressLogo != ''" width="60px"
                      :src="item.expressLogo" />
                  </template>
                  <template v-slot:item.isInit="{ item }">
                    <v-icon v-if="item.isInit != null && item.isInit" size="10"
                      color="success">mdi-checkbox-blank-circle</v-icon>
                    <v-icon v-else-if="item.isInit != null && !item.isInit" size="10"
                      color="danger">mdi-checkbox-blank-circle</v-icon>
                  </template>
                  <template v-slot:item.isPrintPackingList="{ item }">
                    <v-icon v-if="item.isPrintPackingList" size="10" color="success">mdi-checkbox-blank-circle</v-icon>
                    <v-icon v-else size="10" color="danger">mdi-checkbox-blank-circle</v-icon>
                  </template>
                  <template v-slot:item.isPrintAWB="{ item }">
                    <v-icon v-if="item.isPrintAWB" size="10" color="success">mdi-checkbox-blank-circle</v-icon>
                    <v-icon v-else size="10" color="danger">mdi-checkbox-blank-circle</v-icon>
                  </template>
                  <template v-slot:item.id="{ item }">
                    <a @click="ShowDetailOrder(item.token)">{{
                      FormatOrder(item.id)
                    }}</a>
                  </template>
                  <template v-for="(header, index) in dataTable.header" v-slot:[`header.${header.value}`]="{ header }">
                    <v-tooltip :key="index" v-slot:activator="{ on }">
                      <span>{{ header.text }}</span>
                    </v-tooltip>
                  </template>
                </v-data-table>
                <v-row class="mt-2">
                  <v-col cols="2">
                    <v-select v-model="dataset_success.limits" hide-details outlined
                      :items="dataset_success.search.select_per" item-text="name" item-value="value"
                      @change="btnChangeLimits(set_TabListHeader)">
                    </v-select>
                  </v-col>
                  <v-col cols="8" class="pa-0" center @click="btnChangePage(set_TabListHeader)">
                    <v-pagination v-model="dataset_success.page" :length="dataset_success.pageLength" :total-visible="9"
                      color="info"></v-pagination>
                  </v-col>
                </v-row>
              </template>

            </v-card-text>

          </v-card>
        </v-card>
        <v-card v-if="set_TabListHeader == '100-110-120'" class="v-card-bottom-30 mx-0">
          <v-card>

            <v-card-text>
              <template v-if="showSubheader.length > 0">
                <v-tabs v-model="set_TabListSubHeader_Return">
                  <v-tabs-slider color="red"></v-tabs-slider>
                  <v-tab v-for="subItem of showSubheader" class="box-shadow" :key="subItem.id"
                    @click="ChangeTabSub(subItem.id)" :href="'#' + subItem.id">
                    {{ use_language[subItem.keyName] }}
                    <span>
                      ({{ subItem.packageCount }})

                    </span>
                  </v-tab>
                </v-tabs>
              </template>

              <template v-if="set_TabListSubHeader_Return == '100'">
                <v-card class="v-card-bottom-30 mx-0">
                  <v-card-text>
                    <v-row class="item-center">
                      <v-col class="py-1" cols="5">
                        <v-row class="box-shadow-picker">
                          <v-col class="py-0" cols="6">
                            <v-select v-model="dataset_Return_Returnning.search.select_topic"
                              :items="dataset_Return_Returnning.search.select_header" persistent-hint return-object
                              dense hide-details flat solo item-text="name" item-value="value"></v-select>
                          </v-col>
                          <v-col class="py-0" cols="6">
                            <v-text-field v-model="dataset_Return_Returnning.search.text_topic" placeholder="ค้นหา" flat
                              solo hide-details dense />
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col md="6" lg="3" class="hidden-md-and-down py-1">
                        <v-btn color="primary" class="mr-3" @click="btnSearchOrder(set_TabListSubHeader_Return)">{{
                          use_language.search }}</v-btn>
                      </v-col>
                      <v-col md="6" lg="4" class="hidden-md-and-down py-1">
                        <v-select v-model="dataset_Return_Returnning.search.select_channel"
                          :items="dataset_Return_Returnning.search.list_channel" item-text="channelName"
                          item-value="channelID" persistent-hint return-object dense outlined label="ช่องทาง"
                          @input="btnChannelSource(set_TabListSubHeader_Return, dataset_Return_Returnning.search.select_channel)"
                          hide-details>
                          <template slot="selection" slot-scope="data">
                            <img v-if="data.item.channelLogo != null" :src="data.item.channelLogo" class="mr-2"
                              style="width: 25px" />
                            {{ length60(data.item.channelName) }}
                            <v-badge v-if="data.item.channelCount" inline color="error"
                              :content="data.item.channelCount" />
                          </template>
                          <template slot="item" slot-scope="data">
                            <img v-if="data.item.channelLogo != null" :src="data.item.channelLogo" class="mr-2"
                              style="width: 25px" />
                            {{ length60(data.item.channelName) }}
                            <v-badge v-if="data.item.channelCount" inline color="error"
                              :content="data.item.channelCount" />
                          </template>
                        </v-select>
                      </v-col>

                    </v-row>
                    <v-row class="item-center">
                      <v-col class="py-1" cols="12" md="6" lg="1">วันที่ :</v-col>
                      <v-col class="py-1" md="6" lg="7">
                      </v-col>
                      <v-col class="py-1" md="6" lg="4">

                        <v-select v-model="dataset_Return_Returnning.search.select_express"
                          :items="dataset_Return_Returnning.search.list_express" persistent-hint return-object
                          item-text="expressCompanyName" item-value="expressCompanyID" dense outlined label="ขนส่ง"
                          @input="btnExprees(set_TabListSubHeader_Return, dataset_Return_Returnning.search.select_express)"
                          hide-details>
                          <template slot="selection" slot-scope="data">
                            <img v-if="data.item.expressCompanyLogo != null" :src="data.item.expressCompanyLogo"
                              class="mr-2" style="width: 45px" />
                            {{ length60(data.item.expressCompanyName) }}
                            <v-badge v-if="data.item.expressCount" inline color="error"
                              :content="data.item.expressCount" />
                          </template>
                          <template slot="item" slot-scope="data">
                            <img v-if="data.item.expressCompanyLogo != null" :src="data.item.expressCompanyLogo"
                              class="mr-2" style="width: 45px" />
                            {{ length60(data.item.expressCompanyName) }}
                            <v-badge v-if="data.item.expressCount" inline color="error"
                              :content="data.item.expressCount" />
                          </template>
                        </v-select>

                      </v-col>
                    </v-row>
                    <v-row class="item-center">
                      <v-col class="py-1" cols="8">

                      </v-col>
                      <v-col class="py-1" cols="4">
                        <v-select v-model="dataset_Return_Returnning.search.select_print"
                          :items="dataset_Return_Returnning.search.list_print" item-text="name" item-value="id"
                          label="ประเภทการปริ้น" dense return-object hide-details outlined
                          @input="btnSearchPrintType(set_TabListSubHeader_Return, dataset_Return_Returnning.search.select_print)">
                          <template slot="selection" slot-scope="data">
                            {{ length60(data.item.name) }}
                            <v-badge v-if="data.item.count" inline color="error" :content="data.item.count" />
                          </template>
                          <template slot="item" slot-scope="data">
                            {{ length60(data.item.name) }}
                            <v-badge v-if="data.item.count" inline color="error" :content="data.item.count" />
                          </template>
                        </v-select>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
                <v-row class="item-center px-0 py-1">
                  <v-col class="px-0" cols="8">

                    <v-menu offset-y>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn :elevation="1" outlined v-bind="attrs" v-on="on">
                          <span class="pl-1">ดำเนินการ</span>
                          <v-icon right>mdi-chevron-down</v-icon>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item v-for="item in dataset_Return_Returnning.manageMenuListType" :key="item.name"
                          @click="handleClickOutside(item, set_TabListSubHeader_Return)">
                          <v-list-item-title>{{ item.name }}</v-list-item-title>
                        </v-list-item>


                      </v-list>
                    </v-menu>

                  </v-col>
                  <v-col class="py-1" cols="4">

                  </v-col>
                </v-row>
                <v-row class="text-center" align="center" v-if="dataset_Return_Returnning.selected.length > 1">
                  <v-col cols="12">
                    <div style="background: rgb(235 235 235)">
                      <b>คุณเลือกออเดอร์ {{ dataset_Return_Returnning.selected.length }} รายการในหน้านี้
                      </b>
                      <v-btn text @click="btnSelectedTarget(set_TabListSubHeader_Return)"
                        v-if="dataset_Return_Returnning.selected.length != dataset_Return_Returnning.total && dataset_Return_Returnning.limits != 999999">
                        <a>เลือกออเดอร์ทั้ง {{ dataset_Return_Returnning.total }} ในรายการหลัก</a>
                      </v-btn>
                      <v-btn text @click="dataset_Return_Returnning.selected = []" v-else>
                        <a>ล้างการเลือก</a>
                      </v-btn>
                    </div>
                  </v-col>
                </v-row>
                <v-data-table v-model="dataset_Return_Returnning.selected" :headers="dataset_Return_Returnning.headers"
                  :items="dataset_Return_Returnning.dataTable" class="elevation-0 packhai-border-table"
                  item-key="packageID" show-select hide-default-footer :items-per-page="10000">
                  <template v-slot:item.startPackingDate="{ item }">
                    {{ formatDatetime(item.startPackingDate) }}
                  </template>
                  <template v-slot:item.packedDate="{ item }">
                    {{ formatDatetime(item.packedDate) }}
                  </template>
                  <template v-slot:item.createdDatetime="{ item }">
                    {{ formatDatetime(item.createdDatetime) }}
                  </template>
                  <template v-slot:item.readyToPackDate="{ item }">
                    {{ formatDatetime(item.readyToPackDate) }}
                  </template>
                  <template v-slot:item.endPackingDate="{ item }">
                    {{ formatDatetime(item.endPackingDate) }}
                  </template>
                  <template v-slot:item.receiverName="{ item }">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <div v-bind="attrs" v-on="on" style="width: 250px" class="text-truncate">
                          <span>{{ item.receiverName }}</span>
                        </div>
                      </template>
                      <span>
                        <span>{{ item.receiverName }}</span>
                      </span>
                    </v-tooltip>
                  </template>
                  <template v-slot:item.channelName="{ item }">
                    <v-avatar size="25px" tile>
                      <v-img v-if="item.channelLogo != null && item.channelLogo != ''" :src="item.channelLogo" />
                    </v-avatar>
                  </template>
                  <template v-slot:item.expressCompanyName="{ item }">
                    <img v-if="item.expressLogo != null && item.expressLogo != ''" width="60px"
                      :src="item.expressLogo" />
                  </template>
                  <template v-slot:item.isInit="{ item }">
                    <v-icon v-if="item.isInit != null && item.isInit" size="10"
                      color="success">mdi-checkbox-blank-circle</v-icon>
                    <v-icon v-else-if="item.isInit != null && !item.isInit" size="10"
                      color="danger">mdi-checkbox-blank-circle</v-icon>
                  </template>
                  <template v-slot:item.isPrintPackingList="{ item }">
                    <v-icon v-if="item.isPrintPackingList" size="10" color="success">mdi-checkbox-blank-circle</v-icon>
                    <v-icon v-else size="10" color="danger">mdi-checkbox-blank-circle</v-icon>
                  </template>
                  <template v-slot:item.isPrintAWB="{ item }">
                    <v-icon v-if="item.isPrintAWB" size="10" color="success">mdi-checkbox-blank-circle</v-icon>
                    <v-icon v-else size="10" color="danger">mdi-checkbox-blank-circle</v-icon>
                  </template>
                  <template v-slot:item.id="{ item }">
                    <a @click="ShowDetailOrder(item.token)">{{
                      FormatOrder(item.id)
                    }}</a>
                  </template>
                  <template v-for="(header, index) in dataTable.header" v-slot:[`header.${header.value}`]="{ header }">
                    <v-tooltip :key="index" v-slot:activator="{ on }">
                      <span>{{ header.text }}</span>
                    </v-tooltip>
                  </template>
                </v-data-table>
                <v-row class="mt-2">
                  <v-col cols="2">
                    <v-select v-model="dataset_Return_Returnning.limits" hide-details outlined
                      :items="dataset_Return_Returnning.search.select_per" item-text="name" item-value="value"
                      @change="btnChangeLimits(set_TabListSubHeader_Return)">
                    </v-select>
                  </v-col>
                  <v-col cols="8" class="pa-0" center @click="btnChangePage(set_TabListSubHeader_Return)">
                    <v-pagination v-model="dataset_Return_Returnning.page"
                      :length="dataset_Return_Returnning.pageLength" :total-visible="9" color="info"></v-pagination>
                  </v-col>
                </v-row>
              </template>
              <template v-if="set_TabListSubHeader_Return == '110'">
                <v-card class="v-card-bottom-30 mx-0">
                  <v-card-text>
                    <v-row class="item-center">
                      <v-col class="py-1" cols="5">
                        <v-row class="box-shadow-picker">
                          <v-col class="py-0" cols="6">
                            <v-select v-model="dataset_Return_To_Return_Stock.search.select_topic"
                              :items="dataset_Return_To_Return_Stock.search.select_header" persistent-hint return-object
                              dense hide-details flat solo item-text="name" item-value="value"></v-select>
                          </v-col>
                          <v-col class="py-0" cols="6">
                            <v-text-field v-model="dataset_Return_To_Return_Stock.search.text_topic" placeholder="ค้นหา"
                              flat solo hide-details dense />
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col md="6" lg="3" class="hidden-md-and-down py-1">
                        <v-btn color="primary" class="mr-3" @click="btnSearchOrder(set_TabListSubHeader_Return)">{{
                          use_language.search }}</v-btn>
                      </v-col>
                      <v-col md="6" lg="4" class="hidden-md-and-down py-1">
                        <v-select v-model="dataset_Return_To_Return_Stock.search.select_channel"
                          :items="dataset_Return_To_Return_Stock.search.list_channel" item-text="channelName"
                          item-value="channelID" persistent-hint return-object dense outlined label="ช่องทาง"
                          @input="btnChannelSource(set_TabListSubHeader_Return, dataset_Return_To_Return_Stock.search.select_channel)"
                          hide-details>
                          <template slot="selection" slot-scope="data">
                            <img v-if="data.item.channelLogo != null" :src="data.item.channelLogo" class="mr-2"
                              style="width: 25px" />
                            {{ length60(data.item.channelName) }}
                            <v-badge v-if="data.item.channelCount" inline color="error"
                              :content="data.item.channelCount" />
                          </template>
                          <template slot="item" slot-scope="data">
                            <img v-if="data.item.channelLogo != null" :src="data.item.channelLogo" class="mr-2"
                              style="width: 25px" />
                            {{ length60(data.item.channelName) }}
                            <v-badge v-if="data.item.channelCount" inline color="error"
                              :content="data.item.channelCount" />
                          </template>
                        </v-select>
                      </v-col>

                    </v-row>
                    <v-row class="item-center">
                      <v-col class="py-1" cols="12" md="6" lg="1">วันที่ :</v-col>
                      <v-col class="py-1" md="6" lg="7">
                      </v-col>
                      <v-col class="py-1" md="6" lg="4">

                        <v-select v-model="dataset_Return_To_Return_Stock.search.select_express"
                          :items="dataset_Return_To_Return_Stock.search.list_express" persistent-hint return-object
                          item-text="expressCompanyName" item-value="expressCompanyID" dense outlined label="ขนส่ง"
                          @input="btnExprees(set_TabListSubHeader_Return, dataset_Return_To_Return_Stock.search.select_express)"
                          hide-details>
                          <template slot="selection" slot-scope="data">
                            <img v-if="data.item.expressCompanyLogo != null" :src="data.item.expressCompanyLogo"
                              class="mr-2" style="width: 45px" />
                            {{ length60(data.item.expressCompanyName) }}
                            <v-badge v-if="data.item.expressCount" inline color="error"
                              :content="data.item.expressCount" />
                          </template>
                          <template slot="item" slot-scope="data">
                            <img v-if="data.item.expressCompanyLogo != null" :src="data.item.expressCompanyLogo"
                              class="mr-2" style="width: 45px" />
                            {{ length60(data.item.expressCompanyName) }}
                            <v-badge v-if="data.item.expressCount" inline color="error"
                              :content="data.item.expressCount" />
                          </template>
                        </v-select>

                      </v-col>
                    </v-row>
                    <v-row class="item-center">
                      <v-col class="py-1" cols="8">


                      </v-col>
                      <v-col class="py-1" cols="4">
                        <v-select v-model="dataset_Return_To_Return_Stock.search.select_print"
                          :items="dataset_Return_To_Return_Stock.search.list_print" item-text="name" item-value="id"
                          label="ประเภทการปริ้น" dense return-object hide-details outlined
                          @input="btnSearchPrintType(set_TabListSubHeader_Return, dataset_Return_To_Return_Stock.search.select_print)">
                          <template slot="selection" slot-scope="data">
                            {{ length60(data.item.name) }}
                            <v-badge v-if="data.item.count" inline color="error" :content="data.item.count" />
                          </template>
                          <template slot="item" slot-scope="data">
                            {{ length60(data.item.name) }}
                            <v-badge v-if="data.item.count" inline color="error" :content="data.item.count" />
                          </template>
                        </v-select>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
                <v-row class="item-center px-0 py-1">
                  <v-col class="px-0" cols="8">

                    <v-menu offset-y>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn :elevation="1" outlined v-bind="attrs" v-on="on">
                          <span class="pl-1">ดำเนินการ</span>
                          <v-icon right>mdi-chevron-down</v-icon>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item v-for="item in dataset_Return_To_Return_Stock.manageMenuListType" :key="item.name"
                          @click="handleClickOutside(item, set_TabListSubHeader_Return)">
                          <v-list-item-title>{{ item.name }}</v-list-item-title>
                        </v-list-item>


                      </v-list>
                    </v-menu>

                  </v-col>
                  <v-col class="py-1" cols="4">

                  </v-col>
                </v-row>
                <v-row class="text-center" align="center" v-if="dataset_Return_To_Return_Stock.selected.length > 1">
                  <v-col cols="12">
                    <div style="background: rgb(235 235 235)">
                      <b>คุณเลือกออเดอร์ {{ dataset_Return_To_Return_Stock.selected.length }} รายการในหน้านี้
                      </b>
                      <v-btn text @click="btnSelectedTarget(set_TabListSubHeader_Return)"
                        v-if="dataset_Return_To_Return_Stock.selected.length != dataset_Return_To_Return_Stock.total && dataset_Return_To_Return_Stock.limits != 999999">
                        <a>เลือกออเดอร์ทั้ง {{ dataset_Return_To_Return_Stock.total }} ในรายการหลัก</a>
                      </v-btn>
                      <v-btn text @click="dataset_Return_To_Return_Stock.selected = []" v-else>
                        <a>ล้างการเลือก</a>
                      </v-btn>
                    </div>
                  </v-col>
                </v-row>
                <v-data-table v-model="dataset_Return_To_Return_Stock.selected"
                  :headers="dataset_Return_To_Return_Stock.headers" :items="dataset_Return_To_Return_Stock.dataTable"
                  class="elevation-0 packhai-border-table" item-key="packageID" show-select hide-default-footer
                  :items-per-page="10000">
                  <template v-slot:item.startPackingDate="{ item }">
                    {{ formatDatetime(item.startPackingDate) }}
                  </template>
                  <template v-slot:item.packedDate="{ item }">
                    {{ formatDatetime(item.packedDate) }}
                  </template>
                  <template v-slot:item.createdDatetime="{ item }">
                    {{ formatDatetime(item.createdDatetime) }}
                  </template>
                  <template v-slot:item.readyToPackDate="{ item }">
                    {{ formatDatetime(item.readyToPackDate) }}
                  </template>
                  <template v-slot:item.endPackingDate="{ item }">
                    {{ formatDatetime(item.endPackingDate) }}
                  </template>
                  <template v-slot:item.receiverName="{ item }">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <div v-bind="attrs" v-on="on" style="width: 250px" class="text-truncate">
                          <span>{{ item.receiverName }}</span>
                        </div>
                      </template>
                      <span>
                        <span>{{ item.receiverName }}</span>
                      </span>
                    </v-tooltip>
                  </template>
                  <template v-slot:item.channelName="{ item }">
                    <v-avatar size="25px" tile>
                      <v-img v-if="item.channelLogo != null && item.channelLogo != ''" :src="item.channelLogo" />
                    </v-avatar>
                  </template>
                  <template v-slot:item.expressCompanyName="{ item }">
                    <img v-if="item.expressLogo != null && item.expressLogo != ''" width="60px"
                      :src="item.expressLogo" />
                  </template>
                  <template v-slot:item.isInit="{ item }">
                    <v-icon v-if="item.isInit != null && item.isInit" size="10"
                      color="success">mdi-checkbox-blank-circle</v-icon>
                    <v-icon v-else-if="item.isInit != null && !item.isInit" size="10"
                      color="danger">mdi-checkbox-blank-circle</v-icon>
                  </template>
                  <template v-slot:item.isPrintPackingList="{ item }">
                    <v-icon v-if="item.isPrintPackingList" size="10" color="success">mdi-checkbox-blank-circle</v-icon>
                    <v-icon v-else size="10" color="danger">mdi-checkbox-blank-circle</v-icon>
                  </template>
                  <template v-slot:item.isPrintAWB="{ item }">
                    <v-icon v-if="item.isPrintAWB" size="10" color="success">mdi-checkbox-blank-circle</v-icon>
                    <v-icon v-else size="10" color="danger">mdi-checkbox-blank-circle</v-icon>
                  </template>
                  <template v-slot:item.id="{ item }">
                    <a @click="ShowDetailOrder(item.token)">{{
                      FormatOrder(item.id)
                    }}</a>
                  </template>
                  <template v-for="(header, index) in dataTable.header" v-slot:[`header.${header.value}`]="{ header }">
                    <v-tooltip :key="index" v-slot:activator="{ on }">
                      <span>{{ header.text }}</span>
                    </v-tooltip>
                  </template>
                </v-data-table>
                <v-row class="mt-2">
                  <v-col cols="2">
                    <v-select v-model="dataset_Return_To_Return_Stock.limits" hide-details outlined
                      :items="dataset_Return_To_Return_Stock.search.select_per" item-text="name" item-value="value"
                      @change="btnChangeLimits(set_TabListSubHeader_Return)">
                    </v-select>
                  </v-col>
                  <v-col cols="8" class="pa-0" center @click="btnChangePage(set_TabListSubHeader_Return)">
                    <v-pagination v-model="dataset_Return_To_Return_Stock.page"
                      :length="dataset_Return_To_Return_Stock.pageLength" :total-visible="9"
                      color="info"></v-pagination>
                  </v-col>
                </v-row>
              </template>
              <template v-if="set_TabListSubHeader_Return == '120'">
                <v-card class="v-card-bottom-30 mx-0">
                  <v-card-text>
                    <v-row class="item-center">
                      <v-col class="py-1" cols="5">
                        <v-row class="box-shadow-picker">
                          <v-col class="py-0" cols="6">
                            <v-select v-model="dataset_Return_Stock_Returned.search.select_topic"
                              :items="dataset_Return_Stock_Returned.search.select_header" persistent-hint return-object
                              dense hide-details flat solo item-text="name" item-value="value"></v-select>
                          </v-col>
                          <v-col class="py-0" cols="6">
                            <v-text-field v-model="dataset_Return_Stock_Returned.search.text_topic" placeholder="ค้นหา"
                              flat solo hide-details dense />
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col md="6" lg="3" class="hidden-md-and-down py-1">
                        <v-btn color="primary" class="mr-3" @click="btnSearchOrder(set_TabListSubHeader_Return)">{{
                          use_language.search }}</v-btn>
                      </v-col>
                      <v-col md="6" lg="4" class="hidden-md-and-down py-1">
                        <v-select v-model="dataset_Return_Stock_Returned.search.select_channel"
                          :items="dataset_Return_Stock_Returned.search.list_channel" item-text="channelName"
                          item-value="channelID" persistent-hint return-object dense outlined label="ช่องทาง"
                          @input="btnChannelSource(set_TabListSubHeader_Return, dataset_Return_Stock_Returned.search.select_channel)"
                          hide-details>
                          <template slot="selection" slot-scope="data">
                            <img v-if="data.item.channelLogo != null" :src="data.item.channelLogo" class="mr-2"
                              style="width: 25px" />
                            {{ length60(data.item.channelName) }}
                            <v-badge v-if="data.item.channelCount" inline color="error"
                              :content="data.item.channelCount" />
                          </template>
                          <template slot="item" slot-scope="data">
                            <img v-if="data.item.channelLogo != null" :src="data.item.channelLogo" class="mr-2"
                              style="width: 25px" />
                            {{ length60(data.item.channelName) }}
                            <v-badge v-if="data.item.channelCount" inline color="error"
                              :content="data.item.channelCount" />
                          </template>
                        </v-select>
                      </v-col>

                    </v-row>
                    <v-row class="item-center">
                      <v-col class="py-1" cols="12" md="6" lg="1">วันที่ :</v-col>
                      <v-col class="py-1" md="6" lg="7">
                      </v-col>
                      <v-col class="py-1" md="6" lg="4">

                        <v-select v-model="dataset_Return_Stock_Returned.search.select_express"
                          :items="dataset_Return_Stock_Returned.search.list_express" persistent-hint return-object
                          item-text="expressCompanyName" item-value="expressCompanyID" dense outlined label="ขนส่ง"
                          @input="btnExprees(set_TabListSubHeader_Return, dataset_Return_Stock_Returned.search.select_express)"
                          hide-details>
                          <template slot="selection" slot-scope="data">
                            <img v-if="data.item.expressCompanyLogo != null" :src="data.item.expressCompanyLogo"
                              class="mr-2" style="width: 45px" />
                            {{ length60(data.item.expressCompanyName) }}
                            <v-badge v-if="data.item.expressCount" inline color="error"
                              :content="data.item.expressCount" />
                          </template>
                          <template slot="item" slot-scope="data">
                            <img v-if="data.item.expressCompanyLogo != null" :src="data.item.expressCompanyLogo"
                              class="mr-2" style="width: 45px" />
                            {{ length60(data.item.expressCompanyName) }}
                            <v-badge v-if="data.item.expressCount" inline color="error"
                              :content="data.item.expressCount" />
                          </template>
                        </v-select>

                      </v-col>
                    </v-row>
                    <v-row class="item-center">
                      <v-col class="py-1" cols="8">



                      </v-col>
                      <v-col class="py-1" cols="4">
                        <v-select v-model="dataset_Return_Stock_Returned.search.select_print"
                          :items="dataset_Return_Stock_Returned.search.list_print" item-text="name" item-value="id"
                          label="ประเภทการปริ้น" dense return-object hide-details outlined
                          @input="btnSearchPrintType(set_TabListSubHeader_Return, dataset_Return_Stock_Returned.search.select_print)">
                          <template slot="selection" slot-scope="data">
                            {{ length60(data.item.name) }}
                            <v-badge v-if="data.item.count" inline color="error" :content="data.item.count" />
                          </template>
                          <template slot="item" slot-scope="data">
                            {{ length60(data.item.name) }}
                            <v-badge v-if="data.item.count" inline color="error" :content="data.item.count" />
                          </template>
                        </v-select>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
                <v-row class="item-center px-0 py-1">
                  <v-col class="px-0" cols="8">

                    <v-menu offset-y>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn :elevation="1" outlined v-bind="attrs" v-on="on">
                          <span class="pl-1">ดำเนินการ</span>
                          <v-icon right>mdi-chevron-down</v-icon>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item v-for="item in dataset_Return_Stock_Returned.manageMenuListType" :key="item.name"
                          @click="handleClickOutside(item, set_TabListSubHeader_Return)">
                          <v-list-item-title>{{ item.name }}</v-list-item-title>
                        </v-list-item>


                      </v-list>
                    </v-menu>

                  </v-col>
                  <v-col class="py-1" cols="4">

                  </v-col>
                </v-row>
                <v-row class="text-center" align="center" v-if="dataset_Return_Stock_Returned.selected.length > 1">
                  <v-col cols="12">
                    <div style="background: rgb(235 235 235)">
                      <b>คุณเลือกออเดอร์ {{ dataset_Return_Stock_Returned.selected.length }} รายการในหน้านี้
                      </b>
                      <v-btn text @click="btnSelectedTarget(set_TabListSubHeader_Return)"
                        v-if="dataset_Return_Stock_Returned.selected.length != dataset_Return_Stock_Returned.total && dataset_Return_Stock_Returned.limits != 999999">
                        <a>เลือกออเดอร์ทั้ง {{ dataset_Return_Stock_Returned.total }} ในรายการหลัก</a>
                      </v-btn>
                      <v-btn text @click="dataset_Return_Stock_Returned.selected = []" v-else>
                        <a>ล้างการเลือก</a>
                      </v-btn>
                    </div>
                  </v-col>
                </v-row>
                <v-data-table v-model="dataset_Return_Stock_Returned.selected"
                  :headers="dataset_Return_Stock_Returned.headers" :items="dataset_Return_Stock_Returned.dataTable"
                  class="elevation-0 packhai-border-table" item-key="packageID" show-select hide-default-footer
                  :items-per-page="10000">
                  <template v-slot:item.startPackingDate="{ item }">
                    {{ formatDatetime(item.startPackingDate) }}
                  </template>
                  <template v-slot:item.packedDate="{ item }">
                    {{ formatDatetime(item.packedDate) }}
                  </template>
                  <template v-slot:item.createdDatetime="{ item }">
                    {{ formatDatetime(item.createdDatetime) }}
                  </template>
                  <template v-slot:item.readyToPackDate="{ item }">
                    {{ formatDatetime(item.readyToPackDate) }}
                  </template>
                  <template v-slot:item.endPackingDate="{ item }">
                    {{ formatDatetime(item.endPackingDate) }}
                  </template>
                  <template v-slot:item.receiverName="{ item }">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <div v-bind="attrs" v-on="on" style="width: 250px" class="text-truncate">
                          <span>{{ item.receiverName }}</span>
                        </div>
                      </template>
                      <span>
                        <span>{{ item.receiverName }}</span>
                      </span>
                    </v-tooltip>
                  </template>
                  <template v-slot:item.channelName="{ item }">
                    <v-avatar size="25px" tile>
                      <v-img v-if="item.channelLogo != null && item.channelLogo != ''" :src="item.channelLogo" />
                    </v-avatar>
                  </template>
                  <template v-slot:item.expressCompanyName="{ item }">
                    <img v-if="item.expressLogo != null && item.expressLogo != ''" width="60px"
                      :src="item.expressLogo" />
                  </template>
                  <template v-slot:item.isInit="{ item }">
                    <v-icon v-if="item.isInit != null && item.isInit" size="10"
                      color="success">mdi-checkbox-blank-circle</v-icon>
                    <v-icon v-else-if="item.isInit != null && !item.isInit" size="10"
                      color="danger">mdi-checkbox-blank-circle</v-icon>
                  </template>
                  <template v-slot:item.isPrintPackingList="{ item }">
                    <v-icon v-if="item.isPrintPackingList" size="10" color="success">mdi-checkbox-blank-circle</v-icon>
                    <v-icon v-else size="10" color="danger">mdi-checkbox-blank-circle</v-icon>
                  </template>
                  <template v-slot:item.isPrintAWB="{ item }">
                    <v-icon v-if="item.isPrintAWB" size="10" color="success">mdi-checkbox-blank-circle</v-icon>
                    <v-icon v-else size="10" color="danger">mdi-checkbox-blank-circle</v-icon>
                  </template>
                  <template v-slot:item.id="{ item }">
                    <a @click="ShowDetailOrder(item.token)">{{
                      FormatOrder(item.id)
                    }}</a>
                  </template>
                  <template v-for="(header, index) in dataTable.header" v-slot:[`header.${header.value}`]="{ header }">
                    <v-tooltip :key="index" v-slot:activator="{ on }">
                      <span>{{ header.text }}</span>
                    </v-tooltip>
                  </template>
                </v-data-table>
                <v-row class="mt-2">
                  <v-col cols="2">
                    <v-select v-model="dataset_Return_Stock_Returned.limits" hide-details outlined
                      :items="dataset_Return_Stock_Returned.search.select_per" item-text="name" item-value="value"
                      @change="btnChangeLimits(set_TabListSubHeader_Return)">
                    </v-select>
                  </v-col>
                  <v-col cols="8" class="pa-0" center @click="btnChangePage(set_TabListSubHeader_Return)">
                    <v-pagination v-model="dataset_Return_Stock_Returned.page"
                      :length="dataset_Return_Stock_Returned.pageLength" :total-visible="9" color="info"></v-pagination>
                  </v-col>
                </v-row>
              </template>

            </v-card-text>
          </v-card>
        </v-card>
        <v-card v-if="set_TabListHeader == '140'" class="v-card-bottom-30 mx-0">
          <v-card>
            <v-card-text>
              <template>
                <v-card class="v-card-bottom-30 mx-0">
                  <v-card-text>
                    <v-row class="item-center">
                      <v-col class="py-1" cols="5">
                        <v-row class="box-shadow-picker">
                          <v-col class="py-0" cols="6">
                            <v-select v-model="dataset_cancel.search.select_topic"
                              :items="dataset_cancel.search.select_header" persistent-hint return-object dense
                              hide-details flat solo item-text="name" item-value="value"></v-select>
                          </v-col>
                          <v-col class="py-0" cols="6">
                            <v-text-field v-model="dataset_cancel.search.text_topic" placeholder="ค้นหา" flat solo
                              hide-details dense />
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col md="6" lg="3" class="hidden-md-and-down py-1">
                        <v-btn color="primary" class="mr-3" @click="btnSearchOrder(set_TabListHeader)">{{
                          use_language.search }}</v-btn>
                      </v-col>
                      <v-col md="6" lg="4" class="hidden-md-and-down py-1">
                        <v-select v-model="dataset_cancel.search.select_channel"
                          :items="dataset_cancel.search.list_channel" item-text="channelName" item-value="channelID"
                          persistent-hint return-object dense outlined label="ช่องทาง"
                          @input="btnChannelSource(set_TabListHeader, dataset_cancel.search.select_channel)"
                          hide-details>
                          <template slot="selection" slot-scope="data">
                            <img v-if="data.item.channelLogo != null" :src="data.item.channelLogo" class="mr-2"
                              style="width: 25px" />
                            {{ length60(data.item.channelName) }}
                            <v-badge v-if="data.item.channelCount" inline color="error"
                              :content="data.item.channelCount" />
                          </template>
                          <template slot="item" slot-scope="data">
                            <img v-if="data.item.channelLogo != null" :src="data.item.channelLogo" class="mr-2"
                              style="width: 25px" />
                            {{ length60(data.item.channelName) }}
                            <v-badge v-if="data.item.channelCount" inline color="error"
                              :content="data.item.channelCount" />
                          </template>
                        </v-select>
                      </v-col>

                    </v-row>
                    <v-row class="item-center">
                      <v-col class="py-1" cols="12" md="6" lg="1">วันที่ :</v-col>
                      <v-col class="py-1" md="6" lg="7">
                      </v-col>
                      <v-col class="py-1" md="6" lg="4">

                        <v-select v-model="dataset_cancel.search.select_express"
                          :items="dataset_cancel.search.list_express" persistent-hint return-object
                          item-text="expressCompanyName" item-value="expressCompanyID" dense outlined label="ขนส่ง"
                          @input="btnExprees(set_TabListHeader, dataset_cancel.search.select_express)" hide-details>
                          <template slot="selection" slot-scope="data">
                            <img v-if="data.item.expressCompanyLogo != null" :src="data.item.expressCompanyLogo"
                              class="mr-2" style="width: 45px" />
                            {{ length60(data.item.expressCompanyName) }}
                            <v-badge v-if="data.item.expressCount" inline color="error"
                              :content="data.item.expressCount" />
                          </template>
                          <template slot="item" slot-scope="data">
                            <img v-if="data.item.expressCompanyLogo != null" :src="data.item.expressCompanyLogo"
                              class="mr-2" style="width: 45px" />
                            {{ length60(data.item.expressCompanyName) }}
                            <v-badge v-if="data.item.expressCount" inline color="error"
                              :content="data.item.expressCount" />
                          </template>
                        </v-select>

                      </v-col>
                    </v-row>
                    <v-row class="item-center">
                      <v-col class="py-1" cols="8">



                      </v-col>
                      <v-col class="py-1" cols="4">
                        <v-select v-model="dataset_cancel.search.select_print" :items="dataset_cancel.search.list_print"
                          item-text="name" item-value="id" label="ประเภทการปริ้น" dense return-object hide-details
                          outlined @input="btnSearchPrintType(set_TabListHeader, dataset_cancel.search.select_print)">
                          <template slot="selection" slot-scope="data">
                            {{ length60(data.item.name) }}
                            <v-badge v-if="data.item.count" inline color="error" :content="data.item.count" />
                          </template>
                          <template slot="item" slot-scope="data">
                            {{ length60(data.item.name) }}
                            <v-badge v-if="data.item.count" inline color="error" :content="data.item.count" />
                          </template>
                        </v-select>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
                <v-row class="item-center px-0 py-1">
                  <v-col class="px-0" cols="8">

                    <v-menu offset-y>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn :elevation="1" outlined v-bind="attrs" v-on="on">
                          <span class="pl-1">ดำเนินการ</span>
                          <v-icon right>mdi-chevron-down</v-icon>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item v-for="item in dataset_cancel.manageMenuListType" :key="item.name"
                          @click="handleClickOutside(item, set_TabListHeader)">
                          <v-list-item-title>{{ item.name }}</v-list-item-title>
                        </v-list-item>


                      </v-list>
                    </v-menu>

                  </v-col>
                  <v-col class="py-1" cols="4">

                  </v-col>
                </v-row>
                <v-row class="text-center" align="center" v-if="dataset_cancel.selected.length > 1">
                  <v-col cols="12">
                    <div style="background: rgb(235 235 235)">
                      <b>คุณเลือกออเดอร์ {{ dataset_cancel.selected.length }} รายการในหน้านี้
                      </b>
                      <v-btn text @click="btnSelectedTarget(set_TabListHeader)"
                        v-if="dataset_cancel.selected.length != dataset_cancel.total && dataset_cancel.limits != 999999">
                        <a>เลือกออเดอร์ทั้ง {{ dataset_cancel.total }} ในรายการหลัก</a>
                      </v-btn>
                      <v-btn text @click="dataset_cancel.selected = []" v-else>
                        <a>ล้างการเลือก</a>
                      </v-btn>
                    </div>
                  </v-col>
                </v-row>
                <v-data-table v-model="dataset_cancel.selected" :headers="dataset_cancel.headers"
                  :items="dataset_cancel.dataTable" class="elevation-0 packhai-border-table" item-key="packageID"
                  show-select hide-default-footer :items-per-page="10000">
                  <template v-slot:item.startPackingDate="{ item }">
                    {{ formatDatetime(item.startPackingDate) }}
                  </template>
                  <template v-slot:item.packedDate="{ item }">
                    {{ formatDatetime(item.packedDate) }}
                  </template>
                  <template v-slot:item.createdDatetime="{ item }">
                    {{ formatDatetime(item.createdDatetime) }}
                  </template>
                  <template v-slot:item.readyToPackDate="{ item }">
                    {{ formatDatetime(item.readyToPackDate) }}
                  </template>
                  <template v-slot:item.endPackingDate="{ item }">
                    {{ formatDatetime(item.endPackingDate) }}
                  </template>
                  <template v-slot:item.receiverName="{ item }">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <div v-bind="attrs" v-on="on" style="width: 250px" class="text-truncate">
                          <span>{{ item.receiverName }}</span>
                        </div>
                      </template>
                      <span>
                        <span>{{ item.receiverName }}</span>
                      </span>
                    </v-tooltip>
                  </template>
                  <template v-slot:item.channelName="{ item }">
                    <v-avatar size="25px" tile>
                      <v-img v-if="item.channelLogo != null && item.channelLogo != ''" :src="item.channelLogo" />
                    </v-avatar>
                  </template>
                  <template v-slot:item.expressCompanyName="{ item }">
                    <img v-if="item.expressLogo != null && item.expressLogo != ''" width="60px"
                      :src="item.expressLogo" />
                  </template>
                  <template v-slot:item.isInit="{ item }">
                    <v-icon v-if="item.isInit != null && item.isInit" size="10"
                      color="success">mdi-checkbox-blank-circle</v-icon>
                    <v-icon v-else-if="item.isInit != null && !item.isInit" size="10"
                      color="danger">mdi-checkbox-blank-circle</v-icon>
                  </template>
                  <template v-slot:item.isPrintPackingList="{ item }">
                    <v-icon v-if="item.isPrintPackingList" size="10" color="success">mdi-checkbox-blank-circle</v-icon>
                    <v-icon v-else size="10" color="danger">mdi-checkbox-blank-circle</v-icon>
                  </template>
                  <template v-slot:item.isPrintAWB="{ item }">
                    <v-icon v-if="item.isPrintAWB" size="10" color="success">mdi-checkbox-blank-circle</v-icon>
                    <v-icon v-else size="10" color="danger">mdi-checkbox-blank-circle</v-icon>
                  </template>
                  <template v-slot:item.id="{ item }">
                    <a @click="ShowDetailOrder(item.token)">{{
                      FormatOrder(item.id)
                    }}</a>
                  </template>
                  <template v-for="(header, index) in dataTable.header" v-slot:[`header.${header.value}`]="{ header }">
                    <v-tooltip :key="index" v-slot:activator="{ on }">
                      <span>{{ header.text }}</span>
                    </v-tooltip>
                  </template>
                </v-data-table>
                <v-row class="mt-2">
                  <v-col cols="2">
                    <v-select v-model="dataset_cancel.limits" hide-details outlined
                      :items="dataset_cancel.search.select_per" item-text="name" item-value="value"
                      @change="btnChangeLimits(set_TabListHeader)">
                    </v-select>
                  </v-col>
                  <v-col cols="8" class="pa-0" center @click="btnChangePage(set_TabListHeader)">
                    <v-pagination v-model="dataset_cancel.page" :length="dataset_cancel.pageLength" :total-visible="9"
                      color="info"></v-pagination>
                  </v-col>
                </v-row>
              </template>
            </v-card-text>

          </v-card>
        </v-card>

      </v-card>


      <!-- กรองสินค้า -->
      <v-dialog v-model="filterOrderPopup.dialog" scrollable :max-width="filterOrderPopup.MaxWidth" persistent
        :fullscreen="$vuetify.breakpoint.xsOnly">
        <v-card elevation="1" class="ma-0">
          <v-card-title class="height-57 pa-0">
            <div class="v-list-item">

              <v-row class="v-list-item__content ma-0">

                <v-col cols="6">
                  <span>
                    <h5>ตัวกรองสินค้า</h5>
                  </span>
                </v-col>
                <v-col cols="6" class="text-right">

                  <v-btn :elevation="1" outlined color="success" @click="btnExportExcelFilteOrder()">
                    <v-icon size="16" class="pr-1">fa-file-excel</v-icon>
                    <span class="fn-12"> Export Excel </span>
                  </v-btn>
                  <vue-excel-xlsx :data="filteOrderDataSet.dataset" :columns="filteOrderDataSet.headerExcel"
                    v-show="false" :file-name="GetDate() + '_รายงานกรองสินค้า'" :sheet-name="'filterOrderExcel'"
                    class="mr-2">
                  </vue-excel-xlsx>
                </v-col>

              </v-row>
              <div>
                <v-btn icon @click="filterOrderPopup.dialog = false"><v-icon color="danger">mdi-close</v-icon></v-btn>
              </div>
            </div>
          </v-card-title>

          <v-card class="overflow-x-auto hidden-xs-only">
            <v-card-text class="pa-0 ma-0">
              <v-tabs v-model="filteOrderDataSet.tab">
                <v-tabs-slider color="red"></v-tabs-slider>
                <v-tab v-for="i in filteOrderDataSet.tab_list" :key="i.id" @click="filteOrderDataSet.tab = i.id">
                  {{ i.name }}
                </v-tab>
              </v-tabs>

              <v-tabs-items v-model="filteOrderDataSet.tab" touchless>
                <v-tab-item v-for="i in filteOrderDataSet.tab_list" :key="i.id">
                  <v-card flat>
                    <v-card-text>
                      <div v-if="i.id == 0">
                        <table>
                          <tr>
                            <td class="pl-1">จำนวน SKU ในออเดอร์ :</td>
                            <td class="pl-1">
                              <v-radio-group v-model="filteOrderDataSet.radio_sku" row hide-details>
                                <v-radio :label="items.name" v-for="items in filteOrderDataSet.radio" :key="items.id"
                                  :value="items.id"></v-radio>
                              </v-radio-group>
                            </td>
                            <td class="pl-1">
                              <v-text-field class="text-center" style="width: 100px; text-align: center" type="number"
                                v-model="filteOrderDataSet.sku_start" placeholder="จาก" outlined hide-details dense
                                :disabled="!filteOrderDataSet.radio_sku" />
                            </td>
                            <td class="pl-1">
                              <span>ถึง</span>
                            </td>
                            <td class="pl-1">
                              <v-text-field class="text-center" style="width: 100px; text-align: center" type="number"
                                v-model="filteOrderDataSet.sku_end" placeholder="ถึง" outlined hide-details dense
                                :disabled="!filteOrderDataSet.radio_sku" />
                            </td>
                            <td class="pl-1">
                              <span>SKU</span>
                            </td>
                          </tr>
                          <tr>
                            <td class="pl-1">จำนวน ชิ้น ในออเดอร์ :</td>
                            <td class="pl-1">
                              <v-radio-group v-model="filteOrderDataSet.radio_item" row hide-details>
                                <v-radio :label="items.name" v-for="items in filteOrderDataSet.radio" :key="items.id"
                                  :value="items.id"></v-radio>
                              </v-radio-group>
                            </td>
                            <td class="pl-1">
                              <v-text-field class="text-center" style="width: 100px; text-align: center" type="number"
                                v-model="filteOrderDataSet.item_start" placeholder="จาก" outlined hide-details dense
                                :disabled="!filteOrderDataSet.radio_item" />
                            </td>
                            <td class="pl-1">
                              <span>ถึง</span>
                            </td>
                            <td class="pl-1">
                              <v-text-field class="text-center" type="number" style="width: 100px; text-align: center"
                                v-model="filteOrderDataSet.item_end" placeholder="จาก" outlined hide-details dense
                                :disabled="!filteOrderDataSet.radio_item" />
                            </td>
                            <td class="pl-1">
                              <span>ชิ้น</span>
                            </td>
                          </tr>
                        </table>

                        <v-data-table :headers="filteOrderDataSet.header" :items="filteOrderDataSet.dataset"
                          :items-per-page="9999999999" item-key="no" hide-default-footer
                          class="elevation-0 packhai-checkbox-select box-shadow" height="50vh"
                          v-model="filteOrderDataSet.selected" show-select fixed-header>
                          <template v-slot:item.outStock="{ item }">
                            <span class="red--text" v-if="item.outStock < 0">
                              {{ formatMoney(item.outStock) }}</span>
                          </template>
                        </v-data-table>
                      </div>
                      <div v-if="i.id == 1">
                        <v-data-table :headers="filteOrderDataSet.headerMapProductList" :key="0"
                          :items="filteOrderDataSet.mapProductList" hide-default-footer fixed-header
                          item-key="packageID" class="elevation-0 packhai-border-table">
                        </v-data-table>
                      </div>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
              </v-tabs-items>
            </v-card-text>
          </v-card>

          <v-divider />
          <v-card-actions>
            <v-row>
              <v-col cols="6">
                เลือกทั้งหมด {{ filteOrderDataSet.selected.length }} รายการ
              </v-col>
              <v-col cols="6" align="right">
                <v-btn color="primary" @click="btnSumitFilteOrder()"> ดำเนินการ </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- เปลี่ยนสถานะ -->
      <v-dialog v-model="changeStatusPopup.dialog" scrollable :max-width="changeStatusPopup.MaxWidth" persistent
        :fullscreen="$vuetify.breakpoint.xsOnly">
        <v-card elevation="1" class="ma-0">
          <v-card-title class="height-57 pa-0">
            <div class="v-list-item">

              <v-row class="v-list-item__content  ma-0">

                <v-col cols="6">
                  <v-row class="ma-0">
                    <v-col cols="3" class="px-0">
                      <h5>เปลี่ยนสถานะ</h5>
                    </v-col>
                    <v-col cols="9" class="pa-0">
                      <v-select v-model="changeStatusDataSet.changeStatus"
                        :items="changeStatusDataSet.showManageChangeStatusList" label="-- กรุณาเลือกสถานะ --"
                        persistent-hint return-object dense hide-details flat outlined item-text="Name"
                        item-value="ID" />

                    </v-col>

                  </v-row>

                </v-col>

                <v-col cols="6" class="text-right"> </v-col>
              </v-row>

              <div>
                <v-btn v-if="changeStatusDataSet.loading" icon @click="
                  (changeStatusPopup.dialog = false)
                  "><v-icon color="danger">mdi-close</v-icon></v-btn>
                <v-btn v-else icon @click="changeStatusPopup.dialog = false"><v-icon
                    color="danger">mdi-close</v-icon></v-btn>
              </div>
            </div>
          </v-card-title>

          <v-card class="overflow-x-auto hidden-xs-only">
            <v-card-text class="pa-0 ma-0">
              <v-card flat>
                <v-card-text>
                  <v-row>
                    <v-col cols="12" class="py-0">
                      <v-tabs v-model="changeStatusPopup.tab">
                        <v-tabs-slider color="red"></v-tabs-slider>

                        <v-tab v-for="i in changeStatusPopup.tab_list" class="box-shadow" :key="i.id"
                          @click="changeStatusPopup.tab = i.id">
                          {{ i.name }}
                          <v-badge v-if="i.id == 1" color="error" inline>
                            <template v-slot:badge>
                              <span>{{ changeStatusDataSet.error }} </span>
                            </template>
                          </v-badge>
                        </v-tab>
                      </v-tabs>
                    </v-col>
                  </v-row>

                  <v-tabs-items v-model="changeStatusPopup.tab" touchless>
                    <v-tab-item v-for="i in changeStatusPopup.tab_list" :key="i.id">
                      <div v-if="i.id == 0">
                        <v-row class="ma-0 pa-0">
                          <v-col cols="12">
                            <div>
                              <v-data-table :headers="changeStatusDataSet.header" :items="changeStatusDataSet.dataset"
                                :items-per-page="9999999999" item-key="packageID" hide-default-footer
                                class="elevation-0 packhai-checkbox-select box-shadow" height="50vh" fixed-header>
                                <template v-slot:item.referenceSourceLogo="{ item }">
                                  <v-avatar size="25px" tile>
                                    <v-img v-if="
                                      item.referenceSourceLogo != null &&
                                      item.referenceSourceLogo != ''
                                    " style="border-radius: 10px" :src="item.referenceSourceLogo" />
                                  </v-avatar>
                                </template>
                                <template v-slot:item.expressCompanyLogo="{ item }">
                                  <img v-if="
                                    item.expressCompanyLogo != null &&
                                    item.expressCompanyLogo != ''
                                  " width="60px" :src="item.expressCompanyLogo" />
                                </template>
                                <template v-slot:item.processing="{ item }">
                                  <div v-if="item.processing == 'success'">
                                    <v-icon size="20" color="success">mdi-check-circle
                                    </v-icon>
                                    <span>เปลี่ยนสถาะสำเร็จ </span>
                                  </div>

                                  <div v-else-if="item.processing == 'error'">
                                    <v-icon size="25" color="danger">mdi-close</v-icon>
                                    <span>{{ item.error_description }} </span>
                                    <!-- <span 
                                  >ไม่สำเร็จ
                                </span> -->
                                  </div>
                                </template>
                              </v-data-table>
                            </div>
                          </v-col>
                        </v-row>
                      </div>
                      <div v-if="i.id == 1">
                        <v-row class="ma-0 pa-0">
                          <v-col cols="12">
                            <div>
                              <v-data-table :headers="changeStatusDataSet.header"
                                :items="changeStatusDataSet.datasetError" :items-per-page="9999999999"
                                item-key="packageID" hide-default-footer
                                class="elevation-0 packhai-checkbox-select box-shadow" height="50vh" fixed-header>
                                <template v-slot:item.referenceSourceLogo="{ item }">
                                  <v-avatar size="25px" tile>
                                    <v-img v-if="
                                      item.referenceSourceLogo != null &&
                                      item.referenceSourceLogo != ''
                                    " style="border-radius: 10px" :src="item.referenceSourceLogo" />
                                  </v-avatar>
                                </template>
                                <template v-slot:item.expressCompanyLogo="{ item }">
                                  <img v-if="
                                    item.expressCompanyLogo != null &&
                                    item.expressCompanyLogo != ''
                                  " width="60px" :src="item.expressCompanyLogo" />
                                </template>
                                <template v-slot:item.processing="{ item }">
                                  <div v-if="item.processing == 'success'">
                                    <v-icon size="20" color="success">mdi-check-circle
                                    </v-icon>
                                    <span>เปลี่ยนสถาะสำเร็จ </span>
                                  </div>

                                  <div v-else-if="item.processing == 'error'">
                                    <v-icon size="25" color="danger">mdi-close</v-icon>
                                    <span>ไม่สำเร็จ </span>
                                    <span>{{ item.error_description }} </span>
                                  </div>
                                </template>
                              </v-data-table>
                            </div>
                          </v-col>
                        </v-row>
                      </div>
                    </v-tab-item>
                  </v-tabs-items>
                </v-card-text>
              </v-card>
            </v-card-text>
          </v-card>

          <v-divider />

          <v-card-actions>
            <v-row>
              <v-col cols="6">
                <template v-if="changeStatusDataSet.loadingPercent == true">
                  <strong>ดำเนินการแล้ว{{ percentLoading }}% </strong>
                  <span> สำเร็จ {{ this.changeStatusDataSet.success }} รายการ , </span>
                  <span> ไม่สำเร็จ {{ this.changeStatusDataSet.error }} รายการ </span>
                </template>
              </v-col>
              <v-col cols="6" align="right">
                <v-btn color="primary" :disabled="changeStatusDataSet.loading || changeStatusDataSet.changeStatus == null
                  " @click="btnSubmitChamgeStatus()">
                  ดำเนินการ
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- ย้อนสถานะ -->
      <v-dialog v-model="backStatusPopup.dialog" scrollable :max-width="backStatusPopup.MaxWidth" persistent
        :fullscreen="$vuetify.breakpoint.xsOnly">
        <v-card elevation="1" class="ma-0">
          <v-card-title class="height-57 pa-0">
            <div class="v-list-item">

              <v-row class="v-list-item__content  ma-0">

                <v-col cols="6">
                  <v-row class="ma-0">
                    <v-col cols="3" class="px-0">
                      <h5>ย้อนสถานะ</h5>
                    </v-col>
                    <v-col cols="9" class="pa-0">
                      <v-select v-model="backStatusDataSet.changeStatus"
                        :items="backStatusDataSet.showManageBackStatusList" label="-- กรุณาเลือกสถานะ --"
                        persistent-hint return-object dense hide-details flat outlined item-text="Name"
                        item-value="ID" />

                    </v-col>

                  </v-row>

                </v-col>

                <v-col cols="6" class="text-right"> </v-col>
              </v-row>

              <div>
                <v-btn v-if="backStatusDataSet.loading" icon @click="
                  (backStatusPopup.dialog = false)
                  "><v-icon color="danger">mdi-close</v-icon></v-btn>
                <v-btn v-else icon @click="backStatusPopup.dialog = false"><v-icon
                    color="danger">mdi-close</v-icon></v-btn>
              </div>
            </div>
          </v-card-title>

          <v-card class="overflow-x-auto hidden-xs-only">
            <v-card-text class="pa-0 ma-0">
              <v-card flat>
                <v-card-text>
                  <v-row>
                    <v-col cols="12" class="py-0">
                      <v-tabs v-model="backStatusPopup.tab">
                        <v-tabs-slider color="red"></v-tabs-slider>

                        <v-tab v-for="i in backStatusPopup.tab_list" class="box-shadow" :key="i.id"
                          @click="backStatusPopup.tab = i.id">
                          {{ i.name }}
                          <v-badge v-if="i.id == 1" color="error" inline>
                            <template v-slot:badge>
                              <span>{{ backStatusDataSet.error }} </span>
                            </template>
                          </v-badge>
                        </v-tab>
                      </v-tabs>
                    </v-col>
                  </v-row>
                  <v-tabs-items v-model="backStatusPopup.tab" touchless>
                    <v-tab-item v-for="i in backStatusPopup.tab_list" :key="i.id">
                      <div v-if="i.id == 0">
                        <v-row class="ma-0 pa-0">
                          <v-col cols="12">
                            <div>
                              <v-data-table :headers="backStatusDataSet.header" :items="backStatusDataSet.dataset"
                                :items-per-page="9999999999" item-key="packageID" hide-default-footer
                                class="elevation-0 packhai-checkbox-select box-shadow" height="50vh" fixed-header>
                                <template v-slot:item.referenceSourceLogo="{ item }">
                                  <v-avatar size="25px" tile>
                                    <v-img v-if="
                                      item.referenceSourceLogo != null &&
                                      item.referenceSourceLogo != ''
                                    " style="border-radius: 10px" :src="item.referenceSourceLogo" />
                                  </v-avatar>
                                </template>
                                <template v-slot:item.expressCompanyLogo="{ item }">
                                  <img v-if="
                                    item.expressCompanyLogo != null &&
                                    item.expressCompanyLogo != ''
                                  " width="60px" :src="item.expressCompanyLogo" />
                                </template>
                                <template v-slot:item.processing="{ item }">
                                  <div v-if="item.processing == 'success'">
                                    <v-icon size="20" color="success">mdi-check-circle
                                    </v-icon>
                                    <span>เปลี่ยนสถาะสำเร็จ </span>
                                  </div>

                                  <div v-else-if="item.processing == 'error'">
                                    <v-icon size="25" color="danger">mdi-close</v-icon>
                                    <!-- <span>{{ item.error_description }} </span> -->
                                    <span>ไม่สำเร็จ </span>
                                  </div>
                                </template>
                              </v-data-table>
                            </div>
                          </v-col>
                        </v-row>
                      </div>
                      <div v-if="i.id == 1">
                        <v-row class="ma-0 pa-0">
                          <v-col cols="12">
                            <div>
                              <v-data-table :headers="backStatusDataSet.header" :items="backStatusDataSet.datasetError"
                                :items-per-page="9999999999" item-key="packageID" hide-default-footer
                                class="elevation-0 packhai-checkbox-select box-shadow" height="50vh" fixed-header>
                                <template v-slot:item.referenceSourceLogo="{ item }">
                                  <v-avatar size="25px" tile>
                                    <v-img v-if="
                                      item.referenceSourceLogo != null &&
                                      item.referenceSourceLogo != ''
                                    " style="border-radius: 10px" :src="item.referenceSourceLogo" />
                                  </v-avatar>
                                </template>
                                <template v-slot:item.expressCompanyLogo="{ item }">
                                  <img v-if="
                                    item.expressCompanyLogo != null &&
                                    item.expressCompanyLogo != ''
                                  " width="60px" :src="item.expressCompanyLogo" />
                                </template>
                                <template v-slot:item.processing="{ item }">
                                  <div v-if="item.processing == 'success'">
                                    <v-icon size="20" color="success">mdi-check-circle
                                    </v-icon>
                                    <span>เปลี่ยนสถาะสำเร็จ </span>
                                  </div>

                                  <div v-else-if="item.processing == 'error'">
                                    <v-icon size="25" color="danger">mdi-close</v-icon>
                                    <!-- <span>{{ item.error_description }} </span> -->
                                    <span>ไม่สำเร็จ </span>
                                  </div>
                                </template>
                              </v-data-table>
                            </div>
                          </v-col>
                        </v-row>
                      </div>
                    </v-tab-item>
                  </v-tabs-items>
                </v-card-text>
              </v-card>
            </v-card-text>
          </v-card>

          <v-divider />

          <v-card-actions>
            <v-row>
              <v-col cols="6">
                <template v-if="backStatusDataSet.loadingPercent == true">
                  <strong>กำลังโหลด{{ percentLoading }}%</strong>
                  <span> สำเร็จ {{ this.backStatusDataSet.success }} รายการ , </span>
                  <span> ไม่สำเร็จ {{ this.backStatusDataSet.error }} รายการ </span>
                </template>
              </v-col>
              <v-col cols="6" align="right">
                <v-btn color="primary" :disabled="backStatusDataSet.loading || backStatusDataSet.changeStatus == null
                  " @click="btnSubmitBackStatus()">
                  ดำเนินการ
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- updatatoMarketplace-->
      <v-dialog v-model="UpdateToMarketplacePopup.dialog" scrollable :max-width="UpdateToMarketplacePopup.MaxWidth"
        persistent :fullscreen="$vuetify.breakpoint.xsOnly">
        <v-card elevation="1" class="ma-0">
          <v-card-title class="height-57 pa-0">
            <div class="v-list-item">
              <div class="pa-2">
                <span>
                  <h5 v-if="
                    UpdateToMarketplacePopup.marketplaceName == 'lazada' &&
                    UpdateToMarketplacePopup.marketplaceName != null
                  ">
                    ดำเนินการ
                  </h5>
                  <h5 v-if="
                    UpdateToMarketplaceDataSet.tiktokType == 0 &&
                    UpdateToMarketplacePopup.marketplaceName == 'tiktok'
                  ">
                    เลือกประเภท
                  </h5>
                  <h5 v-if="
                    UpdateToMarketplaceDataSet.tiktokType == 1 &&
                    UpdateToMarketplacePopup.marketplaceName == 'tiktok' &&
                    UpdateToMarketplacePopup.marketplaceName != null
                  ">
                    ดำเนินการ
                  </h5>
                  <h5 v-if="
                    UpdateToMarketplaceDataSet.shopeeType == 0 &&
                    UpdateToMarketplacePopup.marketplaceName == 'shopee'
                  ">
                    เลือกประเภท
                  </h5>
                  <h5 v-if="
                    UpdateToMarketplaceDataSet.shopeeType == 1 &&
                    UpdateToMarketplacePopup.marketplaceName == 'shopee'
                  ">
                    เลือกที่อยู่
                  </h5>
                  <h5 v-if="
                    UpdateToMarketplaceDataSet.shopeeType == 2 &&
                    UpdateToMarketplacePopup.marketplaceName == 'shopee' &&
                    UpdateToMarketplacePopup.marketplaceName != null
                  ">
                    ดำเนินการ
                  </h5>
                </span>
              </div>
              <div class="v-list-item__content pa-2">
                <v-row>
                  <v-col cols="3"> </v-col>
                  <v-col cols="9" class="text-right"> </v-col>
                </v-row>
              </div>
              <div>
                <v-btn v-if="UpdateToMarketplaceDataSet.loading" icon @click="
                  refreshWindow('UpdateToMarketplacePopup'),
                  (UpdateToMarketplacePopup.dialog = false)
                  "><v-icon color="danger">mdi-close</v-icon></v-btn>
                <v-btn v-else icon @click="UpdateToMarketplacePopup.dialog = false"><v-icon
                    color="danger">mdi-close</v-icon></v-btn>
              </div>
            </div>
          </v-card-title>

          <v-card class="overflow-x-auto hidden-xs-only">
            <v-card-text class="pa-0 ma-0">
              <v-card flat>
                <v-card-text>
                  <v-row class="pl-4" v-if="
                    UpdateToMarketplacePopup.marketplaceName == 'lazada' ||
                    (UpdateToMarketplacePopup.marketplaceName == 'tiktok' &&
                      UpdateToMarketplaceDataSet.tiktokType == 1) ||
                    (UpdateToMarketplacePopup.marketplaceName == 'shopee' &&
                      UpdateToMarketplaceDataSet.shopeeType == 2)
                  ">
                    <v-col cols="12">
                      <v-tabs v-model="UpdateToMarketplacePopup.tab" hide-slider>
                        <v-tab v-for="i in UpdateToMarketplacePopup.tab_list" class="box-shadow" :key="i.id"
                          @click="UpdateToMarketplacePopup.tab = i.id">
                          {{ i.name }}
                          <v-badge v-if="i.id == 1" color="error" inline>
                            <template v-slot:badge>
                              <span>{{ UpdateToMarketplaceDataSet.error }} </span>
                            </template>
                          </v-badge>
                        </v-tab>
                      </v-tabs>
                    </v-col>
                  </v-row>
                  <v-tabs-items v-model="UpdateToMarketplacePopup.tab" touchless>
                    <v-tab-item v-for="i in UpdateToMarketplacePopup.tab_list" :key="i.id">
                      <div v-if="i.id == 0">
                        <template v-if="
                          UpdateToMarketplacePopup.marketplaceName == 'lazada' &&
                          UpdateToMarketplacePopup.marketplaceName != null
                        ">
                          <v-row class="ma-0 pa-0">
                            <v-col cols="12">
                              <div>
                                <v-data-table :headers="UpdateToMarketplaceDataSet.header"
                                  :items="UpdateToMarketplaceDataSet.dataset" :items-per-page="9999999999"
                                  item-key="packageID" hide-default-footer
                                  class="elevation-0 packhai-checkbox-select box-shadow" height="50vh" fixed-header>
                                  <template v-slot:item.orderMasterID="{ item }">
                                    <a>{{ FormatOrder(item.orderMasterID) }} </a>
                                  </template>
                                  <template v-slot:item.expressCompanyLogo="{ item }">
                                    <img v-if="
                                      item.expressCompanyLogo != null &&
                                      item.expressCompanyLogo != ''
                                    " width="60px" :src="item.expressCompanyLogo" />
                                  </template>
                                  <template v-slot:item.referenceSourceLogo="{ item }">
                                    <v-avatar size="25px" tile>
                                      <v-img v-if="
                                        item.referenceSourceLogo != null &&
                                        item.referenceSourceLogo != ''
                                      " style="border-radius: 10px" :src="item.referenceSourceLogo" />
                                    </v-avatar>
                                  </template>
                                  <template v-slot:item.processing="{ item }">
                                    <div v-if="item.processing == 'success'">
                                      <v-icon size="20" color="success">mdi-check-circle
                                      </v-icon>
                                      <span>เปลี่ยนสถาะสำเร็จ {{ item.message }} </span>
                                    </div>

                                    <div v-else-if="item.processing == 'error'">
                                      <v-icon size="25" color="danger">mdi-close</v-icon>
                                      <span>{{ item.error_description }} </span>
                                      <span>ไม่สำเร็จ </span>
                                    </div>
                                  </template>
                                </v-data-table>
                              </div>
                            </v-col>
                          </v-row>
                        </template>
                        <template v-if="
                          UpdateToMarketplacePopup.marketplaceName == 'tiktok' &&
                          UpdateToMarketplacePopup.marketplaceName != null
                        ">
                          <v-row class="ma-0 pa-0">
                            <v-col cols="12">
                              <div v-if="UpdateToMarketplaceDataSet.tiktokType == 0">
                                <v-row class="text-center" justify="center">
                                  <!-- PICK_UP  -->
                                  <v-col cols="4" @click="
                                    {
                                    {
                                      (UpdateToMarketplaceDataSet.tiktokType = 1),
                                        (UpdateToMarketplaceDataSet.handover_method = `PICK_UP`);
                                    }
                                  }
                                    ">
                                    <v-card color="warning">
                                      <v-card-title class="textChip-center">
                                        <v-icon size="100" color="white">mdi-map-marker
                                        </v-icon>
                                      </v-card-title>
                                      <v-card-text class="color-white">
                                        <h3>นัดรับสินค้า</h3>
                                        <h3>เรียกให้ขนส่งมารับสินค้า</h3>
                                      </v-card-text>
                                    </v-card>
                                  </v-col>
                                  <!-- DROP_OFF  -->
                                  <v-col cols="4" @click="
                                    {
                                    {
                                      (UpdateToMarketplaceDataSet.tiktokType = 1),
                                        (UpdateToMarketplaceDataSet.handover_method = `DROP_OFF`);
                                    }
                                  }
                                    ">
                                    <v-card color="success">
                                      <v-card-title class="textChip-center">
                                        <v-icon size="100" color="white"> mdi-home </v-icon>
                                      </v-card-title>
                                      <v-card-text class="color-white">
                                        <h3>ส่งที่สาขา</h3>
                                        <h3>ไปส่งที่สาขาด้วยตนเอง</h3>
                                      </v-card-text>
                                    </v-card>
                                  </v-col>
                                </v-row>
                              </div>

                              <div v-if="UpdateToMarketplaceDataSet.tiktokType == 1">
                                <v-data-table :headers="UpdateToMarketplaceDataSet.header"
                                  :items="UpdateToMarketplaceDataSet.dataset" :items-per-page="9999999999"
                                  item-key="packageID" hide-default-footer
                                  class="elevation-0 packhai-checkbox-select box-shadow" height="50vh" fixed-header>
                                  <template v-slot:item.orderMasterID="{ item }">
                                    <a>{{ FormatOrder(item.orderMasterID) }} </a>
                                  </template>
                                  <template v-slot:item.expressCompanyLogo="{ item }">
                                    <img v-if="
                                      item.expressCompanyLogo != null &&
                                      item.expressCompanyLogo != ''
                                    " width="60px" :src="item.expressCompanyLogo" />
                                  </template>
                                  <template v-slot:item.referenceSourceLogo="{ item }">
                                    <v-avatar size="25px" tile>
                                      <v-img v-if="
                                        item.referenceSourceLogo != null &&
                                        item.referenceSourceLogo != ''
                                      " style="border-radius: 10px" :src="item.referenceSourceLogo" />
                                    </v-avatar>
                                  </template>
                                  <template v-slot:item.processing="{ item }">
                                    <div v-if="item.processing == 'success'">
                                      <v-icon size="20" color="success">mdi-check-circle
                                      </v-icon>
                                      <span>เปลี่ยนสถาะสำเร็จ {{ item.message }} </span>
                                    </div>

                                    <div v-else-if="item.processing == 'error'">
                                      <v-icon size="25" color="danger">mdi-close</v-icon>
                                      <span>{{ item.error_description }} </span>
                                      <span>ไม่สำเร็จ </span>
                                    </div>
                                  </template>
                                </v-data-table>
                              </div>
                            </v-col>
                          </v-row>
                        </template>
                        <template v-if="
                          UpdateToMarketplacePopup.marketplaceName == 'shopee' &&
                          UpdateToMarketplacePopup.marketplaceName != null
                        ">
                          <v-row class="ma-0 pa-0">
                            <v-col cols="12">
                              <div v-if="UpdateToMarketplaceDataSet.shopeeType == 0">
                                <v-row class="text-center" justify="center">
                                  <!-- PICK_UP  -->
                                  <v-col cols="4" @click="UpdateToMarketplaceDataSet.shopeeType = 1">
                                    <v-card color="warning">
                                      <v-card-title class="textChip-center">
                                        <v-icon size="100" color="white">mdi-map-marker
                                        </v-icon>
                                      </v-card-title>
                                      <v-card-text class="color-white">
                                        <h3>นัดรับสินค้า</h3>
                                        <h3>เรียกให้ขนส่งมารับสินค้า</h3>
                                      </v-card-text>
                                    </v-card>
                                  </v-col>
                                  <!-- DROP_OFF  -->
                                  <v-col cols="4" @click="UpdateToMarketplaceDataSet.shopeeType = 2">
                                    <v-card color="success">
                                      <v-card-title class="textChip-center">
                                        <v-icon size="100" color="white"> mdi-home </v-icon>
                                      </v-card-title>
                                      <v-card-text class="color-white">
                                        <h3>ส่งที่สาขา</h3>
                                        <h3>ไปส่งที่สาขาด้วยตนเอง</h3>
                                      </v-card-text>
                                    </v-card>
                                  </v-col>
                                </v-row>
                              </div>
                              <div v-if="UpdateToMarketplaceDataSet.shopeeType == 1">
                                <v-card class="mb-3">
                                  <v-card-text>
                                    <v-row class="text-center">
                                      <v-col cols="12">
                                        <v-radio-group label="เลือกสถานที่ขนส่ง" v-model="UpdateToMarketplaceDataSet.selectedAddress
                                          " @change="handleAddressChange()" style="max-height: 35vh; overflow-y: auto">
                                          <v-radio v-for="(
                                            items, index
                                          ) of UpdateToMarketplaceDataSet.pickup" :key="items.id" :value="index">
                                            <template v-slot:label>
                                              <v-col cols="12">
                                                <strong>{{
                                                  items.address +
                                                  " " +
                                                  items.city +
                                                  " " +
                                                  items.district +
                                                  " " +
                                                  items.state +
                                                  " " +
                                                  items.zipcode
                                                  }}</strong>
                                                <div v-if="items.address_flag.length > 0">
                                                  <h4>
                                                    <v-chip color="success">
                                                      ค่าเริ่มต้น</v-chip>
                                                    <v-chip v-if="
                                                      items.address_flag ==
                                                      'default_address'
                                                    " color="success">
                                                      ค่าเริ่มต้น</v-chip>
                                                    <v-chip v-if="
                                                      items.address_flag ==
                                                      'pickup_address'
                                                    " color="warning">ที่อยู่รับสินค้า</v-chip>
                                                    <v-chip v-if="
                                                      items.address_flag ==
                                                      'return_address'
                                                    " color="question">ที่อยู่สำหรับคืนสินค้า</v-chip>
                                                  </h4>
                                                </div>
                                              </v-col>
                                            </template>
                                          </v-radio>
                                        </v-radio-group>
                                      </v-col>
                                    </v-row>
                                  </v-card-text>
                                </v-card>

                                <v-card>
                                  <v-card-text>
                                    <v-row>
                                      <v-col cols="12">
                                        <v-icon size="25" class="pr-1">mdi-update</v-icon>
                                        <span>เวลานัดรับ</span>
                                      </v-col>
                                    </v-row>
                                    <v-row class="text-center mt-2">
                                      <v-col cols="12">
                                        <v-select v-model="UpdateToMarketplaceDataSet.pickupTimeID" :items="UpdateToMarketplaceDataSet.selectedpickupTime
                                            .time_slot_list
                                          " persistent-hint label="-- กรุณาเลือกเวลาขนส่ง --" dense hide-details flat
                                          outlined :item-text="(item) =>
                                              formatDatetime(
                                                new Date(item.date * 1000).toISOString()
                                              )
                                            " item-value="pickup_time_id">
                                        </v-select>
                                      </v-col>
                                    </v-row>
                                  </v-card-text>
                                </v-card>
                              </div>
                              <div v-if="UpdateToMarketplaceDataSet.shopeeType == 2">
                                <v-data-table :headers="UpdateToMarketplaceDataSet.header"
                                  :items="UpdateToMarketplaceDataSet.dataset" :items-per-page="9999999999"
                                  item-key="packageID" hide-default-footer
                                  class="elevation-0 packhai-checkbox-select box-shadow" height="50vh" fixed-header>
                                  <template v-slot:item.orderMasterID="{ item }">
                                    <a>{{ FormatOrder(item.orderMasterID) }} </a>
                                  </template>
                                  <template v-slot:item.expressCompanyLogo="{ item }">
                                    <img v-if="
                                      item.expressCompanyLogo != null &&
                                      item.expressCompanyLogo != ''
                                    " width="60px" :src="item.expressCompanyLogo" />
                                  </template>
                                  <template v-slot:item.referenceSourceLogo="{ item }">
                                    <v-avatar size="25px" tile>
                                      <v-img v-if="
                                        item.referenceSourceLogo != null &&
                                        item.referenceSourceLogo != ''
                                      " style="border-radius: 10px" :src="item.referenceSourceLogo" />
                                    </v-avatar>
                                  </template>
                                  <template v-slot:item.processing="{ item }">
                                    <div v-if="item.processing == 'success'">
                                      <v-icon size="20" color="success">mdi-check-circle
                                      </v-icon>
                                      <span>เปลี่ยนสถาะสำเร็จ {{ item.message }} </span>
                                    </div>

                                    <div v-else-if="item.processing == 'error'">
                                      <v-icon size="25" color="danger">mdi-close</v-icon>
                                      <span>{{ item.error_description }} </span>
                                      <span>ไม่สำเร็จ </span>
                                    </div>
                                  </template>
                                </v-data-table>
                              </div>
                            </v-col>
                          </v-row>
                        </template>
                      </div>
                      <div v-if="i.id == 1">
                        <v-row class="ma-0 pa-0">
                          <v-col cols="12">
                            <div>
                              <v-data-table :headers="UpdateToMarketplaceDataSet.header"
                                :items="UpdateToMarketplaceDataSet.datasetError" :items-per-page="9999999999"
                                item-key="packageID" hide-default-footer
                                class="elevation-0 packhai-checkbox-select box-shadow" height="50vh" fixed-header>
                                <template v-slot:item.orderMasterID="{ item }">
                                  <a>{{ FormatOrder(item.orderMasterID) }} </a>
                                </template>
                                <template v-slot:item.expressCompanyLogo="{ item }">
                                  <img v-if="
                                    item.expressCompanyLogo != null &&
                                    item.expressCompanyLogo != ''
                                  " width="60px" :src="item.expressCompanyLogo" />
                                </template>
                                <template v-slot:item.referenceSourceLogo="{ item }">
                                  <v-avatar size="25px" tile>
                                    <v-img v-if="
                                      item.referenceSourceLogo != null &&
                                      item.referenceSourceLogo != ''
                                    " style="border-radius: 10px" :src="item.referenceSourceLogo" />
                                  </v-avatar>
                                </template>
                                <template v-slot:item.processing="{ item }">
                                  <div v-if="item.processing == 'success'">
                                    <v-icon size="20" color="success">mdi-check-circle
                                    </v-icon>
                                    <span>เปลี่ยนสถาะสำเร็จ </span>
                                  </div>

                                  <div v-else-if="item.processing == 'error'">
                                    <v-icon size="25" color="danger">mdi-close</v-icon>
                                    <span>{{ item.error_description }} </span>
                                    <span>ไม่สำเร็จ </span>
                                  </div>
                                </template>
                              </v-data-table>
                            </div>
                          </v-col>
                        </v-row>
                      </div>
                    </v-tab-item>
                  </v-tabs-items>
                </v-card-text>
                <v-card-actions>
                  <v-row>
                    <v-col cols="6">
                      <template v-if="UpdateToMarketplaceDataSet.loadingPercent == true">
                        <strong>ดำเนินการแล้ว{{ percentLoading }}%</strong>
                        <span>
                          สำเร็จ {{ this.UpdateToMarketplaceDataSet.success }} รายการ ,
                        </span>
                        <span>
                          ไม่สำเร็จ {{ this.UpdateToMarketplaceDataSet.error }} รายการ
                        </span>
                      </template>
                    </v-col>
                    <v-col cols="6" align="right">
                      <div v-if="
                        UpdateToMarketplacePopup.marketplaceName == 'lazada' &&
                        UpdateToMarketplacePopup.marketplaceName != null
                      ">
                        <v-btn :disabled="UpdateToMarketplaceDataSet.loading" color="primary"
                          @click="btnSubmitToMarketPlace('lazada')">
                          ดำเนินการ
                        </v-btn>
                      </div>
                      <div v-if="
                        UpdateToMarketplacePopup.marketplaceName == 'tiktok' &&
                        UpdateToMarketplacePopup.marketplaceName != null
                      ">
                        <v-btn v-if="UpdateToMarketplaceDataSet.tiktokType == 1" color="success" class="mr-3" outlined
                          :disabled="UpdateToMarketplaceDataSet.loading" @click="
                          {
                            {
                              (UpdateToMarketplaceDataSet.tiktokType = null),
                                (UpdateToMarketplaceDataSet.handover_method = null),
                                (UpdateToMarketplaceDataSet.tiktokType = 0);
                            }
                          }
                            ">
                          ย้อนกลับ
                        </v-btn>
                        <v-btn v-if="UpdateToMarketplaceDataSet.tiktokType == 1" :disabled="UpdateToMarketplaceDataSet.loading ||
                          UpdateToMarketplaceDataSet.handover_method == null
                          " color="primary" @click="btnSubmitToMarketPlace('tiktok')">
                          ดำเนินการ
                        </v-btn>
                      </div>
                      <div v-if="
                        UpdateToMarketplacePopup.marketplaceName == 'shopee' &&
                        UpdateToMarketplacePopup.marketplaceName != null
                      ">
                        <v-btn v-if="
                          UpdateToMarketplaceDataSet.shopeeType == 2 ||
                          UpdateToMarketplaceDataSet.shopeeType == 1
                        " color="success" class="mr-3" outlined :disabled="UpdateToMarketplaceDataSet.loading" @click="
                          {
                          {
                            (UpdateToMarketplaceDataSet.pickupTimeID = null),
                              (UpdateToMarketplaceDataSet.selectedAddress = []),
                              (UpdateToMarketplaceDataSet.shopeeType = 0);
                          }
                        }
                          ">
                          ย้อนกลับ
                        </v-btn>
                        <v-btn v-if="UpdateToMarketplaceDataSet.shopeeType == 1" color="primary"
                          :disabled="UpdateToMarketplaceDataSet.pickupTimeID == null"
                          @click="UpdateToMarketplaceDataSet.shopeeType = 2">
                          ถัดไป
                        </v-btn>
                        <v-btn v-if="UpdateToMarketplaceDataSet.shopeeType == 2" color="primary"
                          :disabled="UpdateToMarketplaceDataSet.loading" @click="btnSubmitToMarketPlace('shopee')">
                          ดำเนินการ
                        </v-btn>
                      </div>
                    </v-col>
                  </v-row>
                </v-card-actions>
              </v-card>
            </v-card-text>
          </v-card>
        </v-card>
      </v-dialog>
      <!-- รายงาน -->
      <v-dialog v-model="reportOrderPopup.dialog" scrollable :max-width="reportOrderPopup.MaxWidth" persistent
        :fullscreen="$vuetify.breakpoint.xsOnly">
        <v-card elevation="1" class="ma-0">
          <v-card-title class="height-57 pa-0">
            <div class="v-list-item">
              <div class="pa-2">
                <span v-if="set_TabListSubHeader_Process == '10' && set_TabListHeader == '10-20-30-40-60-80'">
                  <h5>รายการที่ต้องผูกสินค้า</h5>
                </span>
                <span v-else>
                  <h5>รายการสินค้า</h5>
                </span>
              </div>
              <div class="v-list-item__content pa-2">
                <v-row>
                  <v-col cols="6" />
                  <v-col cols="6" class="text-right">
                    <v-btn :elevation="1" outlined color="success" class="pa-2">
                      <v-icon size="16" class="pr-1">fa-file-excel</v-icon>
                      <vue-excel-xlsx :data="reportOrderDataSet.dataset" :columns="reportOrderDataSet.header"
                        :file-name="GetDate() + '_รายงานสินค้า'" :sheet-name="'reportOrderItemExcel'" class="mr-2">
                        Export to Excel
                      </vue-excel-xlsx>
                    </v-btn>
                  </v-col>
                </v-row>
              </div>
              <div>
                <v-btn icon @click="reportOrderPopup.dialog = false"><v-icon color="danger">mdi-close</v-icon></v-btn>
              </div>
            </div>
          </v-card-title>

          <v-card class="overflow-x-auto hidden-xs-only">
            <v-card-text class="pa-0 ma-0">
              <v-card flat>
                <v-card-text>
                  <div>
                    <v-data-table :headers="reportOrderDataSet.header" :items="reportOrderDataSet.dataset"
                      :items-per-page="9999999999" item-key="no" hide-default-footer
                      class="elevation-0 packhai-checkbox-select box-shadow" height="50vh" fixed-header>
                      <template v-slot:item.photoLink="{ item }">
                        <a v-if="item.photoLink != null && item.photoLink != ''" icon
                          @click="OpenPhoto(item.photoLink)">
                          <img :src="item.photoLink" class="picsize-50 mt-1" />
                        </a>
                      </template>
                    </v-data-table>
                  </div>
                </v-card-text>
              </v-card>
            </v-card-text>
          </v-card>

          <v-divider />
          <v-card-actions>
            <v-row>
              <v-col cols="6">
                ทั้งหมด {{ reportOrderDataSet.dataset.length }} รายการ
                <span>, จำนวน {{ countreportOrderDataSet }} ชิ้น</span>
              </v-col>
              <v-col cols="6" align="right"> </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import Loading from '@/website/components/Loading'
import Photo from "@/website/components/photo";
import { AlertSuccess, AlertWarning, AlertError } from "@/website/global_alert";
import {
  get_languages,
  FormatOrder,
  DateNow,
  formatMoney,
  formatMoneyIsDecimal,
  Trim_value,
  Trim_rules,
  formatDatetime,
  length60,
  ChangeOrderId,
  GenPA,
  GetDate,
} from '@/website/global_function';
import axios from 'axios'
import Swal from 'sweetalert2'
import { header_token } from '@/website/backend/token'
import {
  shopService_dotnet,
  orderService_dotnet,
  branchService_dotnet,
  stockService_dotnet,
  ecomService_dotnet,
  logistic_Service,
} from "@/website/global"; export default {
  components: {
    Loading
  },
  data: () => ({
    header_token: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('authorization_token') },
    use_language: null,
    set_language: null,
    page_loading: true,
    loading: false,

    //ส่วนของ Tab
    tabListHeaders: [],
    tabListSubHeaders: [],
    set_TabListHeader: "0",
    set_TabListSubHeader: null,

    // แสดงภาพ
    ShowPhoto: false,
    ShowPhotoLink: "",

    //loadding Percent
    percentLoadingText: "",
    percentLoading: 0,

    datasetOrderALL: [],
    headerOrderALL: [
      { text: 'ลำดับ', align: 'center', sortable: false, value: 'num' },
      { text: 'OrderID', align: 'center', sortable: false, value: 'id', width: '120px' },
      { text: 'เวลาที่สร้าง', align: 'left', value: 'createdDatetime', sortable: false, width: '170px' },
      { text: 'ชื่อผู้รับ', align: 'left', value: 'receiverName', sortable: false, width: '250px' },
      { text: 'ช่องทาง', align: 'center', value: 'channelLogo', sortable: false },
      { text: 'ขนส่ง', align: 'center', value: 'expressCompanyLogo', sortable: false },
      { text: 'ยอดขาย', align: 'right', value: 'orderTotalAmount', sortable: false, width: '120px' },
      { text: 'ประเภทการส่ง', align: 'center', value: 'isCod', sortable: false },
      { text: 'ยอด COD', align: 'right', value: 'codamount', sortable: false, width: '120px' },
      { text: 'สถานะออเดอร์', align: 'center', value: 'shippingStatusName', sortable: false, width: '130px' },
      { text: 'สถานะพัสดุ', align: 'center', value: 'parcelStatusName', sortable: false, width: '110px' },
      { text: 'เลขอ้างอิง', align: 'left', value: 'referenceNumber', sortable: false, width: '130px' },
      { text: 'TrackingNo', align: 'left', value: 'trackingNo', sortable: false, width: '130px' },
      { text: 'สร้างโดย', align: 'left', value: 'createdByStaffName', sortable: false, width: '130px' },
      { text: 'พนักงานขาย', align: 'left', value: 'salemanName', sortable: false, width: '130px' },
    ],

    warehouse_list: null,
    warehouse_select: null,

    dataTable: {
      headers: [
        {
          text: "No",
          align: "center",
          sortable: false,
          value: "no",
          width: "70px",
        },
        {
          text: "ออเดอร์",
          align: "center",
          sortable: false,
          value: "id",
          width: "110px",
        },
        {
          text: "วันที่สร้าง",
          align: "center",
          sortable: false,
          value: "createdDatetime",
          width: "160px",
        },
        {
          text: "วันที่พร้อมแพ็ค",
          align: "center",
          sortable: false,
          value: "readyToPackDate",
          width: "160px",
        },
        {
          text: "ร้านค้า",
          align: "center",
          sortable: false,
          value: "shopName",
          width: "90px",
        },
        {
          text: "จำนวน SKU",
          align: "center",
          sortable: false,
          value: "skuCount",
          width: "90px",
        },
        {
          text: "จำนวนชิ้น",
          align: "center",
          sortable: false,
          value: "productCount",
          width: "90px",
        },
        {
          text: "ช่องทาง",
          align: "center",
          sortable: false,
          value: "channelName",
          width: "90px",
        },
        {
          text: "ขนส่ง",
          align: "center",
          sortable: false,
          value: "expressCompanyName",
          width: "90px",
        },
        {
          text: "เลขอ้างอิง",
          align: "left",
          sortable: false,
          value: "referenceNumber",
          width: "110px",
        },
        {
          text: "TrackingNo",
          align: "left",
          sortable: false,
          value: "trackingNo",
          width: "110px",
        },
        {
          text: "นัดรับ",
          align: "center",
          sortable: false,
          value: "isInit",
          width: "50px",
        },
        {
          text: "ใบแพ็ค",
          align: "center",
          sortable: false,
          value: "isPrintPackingList",
          width: "70px",
        },
        {
          text: "ใบปะหน้า",
          align: "center",
          sortable: false,
          value: "isPrintAWB",
          width: "70px",
        },
        {
          text: "น้ำหนัก",
          align: "center",
          sortable: false,
          value: "weight",
          width: "90px",
        },
        {
          text: "ขนาด",
          align: "center",
          sortable: false,
          value: "boxSize",
          width: "100px",
        },
        {
          text: "กล่อง",
          align: "center",
          sortable: false,
          value: "boxName",
          width: "100px",
        },
        {
          text: "กำลังแพ็ค",
          align: "center",
          sortable: false,
          value: "packedByStaffBranchName",
          width: "90px",
        },
        {
          text: "แพ็คเสร็จโดย",
          align: "center",
          sortable: false,
          value: "packedByStaffBranchName",
          width: "90px",
        },
        {
          text: "เริ่มแพ็ค",
          align: "center",
          sortable: false,
          value: "startPackingDate",
          width: "160px",
        },
        {
          text: "แพ็คเสร็จ",
          align: "center",
          sortable: false,
          value: "endPackingDate",
          width: "160px",
        },
        {
          text: "ลูกค้า",
          align: "left",
          sortable: false,
          value: "receiverName",
          width: "200px",
        },
      ],





    },

    set_TabListSubHeader_wait_payment: '1',
    dataset_wait_payment_wait_payment: {
      headers: [
        {
          text: "No",
          align: "center",
          sortable: false,
          value: "no",
          width: "70px",
        },
        {
          text: "ออเดอร์",
          align: "center",
          sortable: false,
          value: "id",
          width: "110px",
        },
        {
          text: "วันที่สร้าง",
          align: "center",
          sortable: false,
          value: "createdDatetime",
          width: "160px",
        },
        {
          text: "วันที่พร้อมแพ็ค",
          align: "center",
          sortable: false,
          value: "readyToPackDate",
          width: "160px",
        },
        {
          text: "ร้านค้า",
          align: "center",
          sortable: false,
          value: "shopName",
          width: "90px",
        },
        {
          text: "จำนวน SKU",
          align: "center",
          sortable: false,
          value: "skuCount",
          width: "90px",
        },
        {
          text: "จำนวนชิ้น",
          align: "center",
          sortable: false,
          value: "productCount",
          width: "90px",
        },
        {
          text: "ช่องทาง",
          align: "center",
          sortable: false,
          value: "channelName",
          width: "90px",
        },
        {
          text: "ขนส่ง",
          align: "center",
          sortable: false,
          value: "expressCompanyName",
          width: "90px",
        },
        {
          text: "เลขอ้างอิง",
          align: "left",
          sortable: false,
          value: "referenceNumber",
          width: "110px",
        },
        {
          text: "TrackingNo",
          align: "left",
          sortable: false,
          value: "trackingNo",
          width: "110px",
        },
        {
          text: "นัดรับ",
          align: "center",
          sortable: false,
          value: "isInit",
          width: "50px",
        },
        {
          text: "ใบแพ็ค",
          align: "center",
          sortable: false,
          value: "isPrintPackingList",
          width: "70px",
        },
        {
          text: "ใบปะหน้า",
          align: "center",
          sortable: false,
          value: "isPrintAWB",
          width: "70px",
        },
        {
          text: "น้ำหนัก",
          align: "center",
          sortable: false,
          value: "weight",
          width: "90px",
        },
        {
          text: "ขนาด",
          align: "center",
          sortable: false,
          value: "boxSize",
          width: "100px",
        },
        {
          text: "กล่อง",
          align: "center",
          sortable: false,
          value: "boxName",
          width: "100px",
        },
        {
          text: "กำลังแพ็ค",
          align: "center",
          sortable: false,
          value: "packedByStaffBranchName",
          width: "90px",
        },
        {
          text: "แพ็คเสร็จโดย",
          align: "center",
          sortable: false,
          value: "packedByStaffBranchName",
          width: "90px",
        },
        {
          text: "เริ่มแพ็ค",
          align: "center",
          sortable: false,
          value: "startPackingDate",
          width: "160px",
        },
        {
          text: "แพ็คเสร็จ",
          align: "center",
          sortable: false,
          value: "endPackingDate",
          width: "160px",
        },
        {
          text: "ลูกค้า",
          align: "left",
          sortable: false,
          value: "receiverName",
          width: "200px",
        },
      ],
      dataTable: [],
      page: 1,
      manageMenuListType: [
        { name: "อนุมัติ", btn: "btnApproveBalanceCheck" },
        { name: "รายงาน", btn: "btnViewReport" }],
      ManageChangeStatusList: [{ ID: "30", Name: "รอนัดรับ" },
      { ID: "40", Name: "รอแพ็ก" },
      { ID: "90", Name: "กำลังจัดส่ง" },
      { ID: "130", Name: "จัดส่งสำเร็จ" },
      { ID: "140", Name: "ยกเลิก" },
      ],
      ManageBackStatusList: [],
      offsets: 0,
      limits: 10,
      pageLength: 1,
      total: 0,
      selected: [],
      keyword: {},
      search: {
        select_per_page: 10,
        select_per: [
          { value: 5, name: "5 คำสั่งซื้อ" },
          { value: 10, name: "10 คำสั่งซื้อ" },
          { value: 25, name: "25 คำสั่งซื้อ" },
          { value: 50, name: "50 คำสั่งซื้อ" },
          { value: 100, name: "100 คำสั่งซื้อ" },
          { value: 999999, name: "ทั้งหมด" },
        ],
        search_by: '',
        select_channel: { channelID: 0, channelName: "--ทุกช่องทาง--" },
        list_channel: [{ channelID: 0, channelName: "--ทุกช่องทาง--" }],
        select_express: { expressCompanyID: null, expressCompanyName: " -- ทั้งหมด --" },
        list_express: [{ expressCompanyID: null, expressCompanyName: " -- ทั้งหมด --", },],
        select_print: { id: null, name: " -- ทั้งหมด --" },
        list_print: [{ id: null, name: " -- ทั้งหมด --" }],
        select_topic: { id: 1, name: "เลขออเดอร์", value: "orderId" },
        select_header: [
          { id: 0, name: "เลขออเดอร์", value: "orderId" },
          { id: 1, name: "เลขอ้างอิง", value: "referenceNumber" },
          { id: 2, name: "ชื่อผู้รับ", value: "receiverName" },
        ],
        text_topic: "",

      }
    },
    dataset_wait_payment_check_payment: {
      headers: [
        {
          text: "No",
          align: "center",
          sortable: false,
          value: "no",
          width: "70px",
        },
        {
          text: "ออเดอร์",
          align: "center",
          sortable: false,
          value: "id",
          width: "110px",
        },
        {
          text: "วันที่สร้าง",
          align: "center",
          sortable: false,
          value: "createdDatetime",
          width: "160px",
        },
        {
          text: "วันที่พร้อมแพ็ค",
          align: "center",
          sortable: false,
          value: "readyToPackDate",
          width: "160px",
        },
        {
          text: "ร้านค้า",
          align: "center",
          sortable: false,
          value: "shopName",
          width: "90px",
        },
        {
          text: "จำนวน SKU",
          align: "center",
          sortable: false,
          value: "skuCount",
          width: "90px",
        },
        {
          text: "จำนวนชิ้น",
          align: "center",
          sortable: false,
          value: "productCount",
          width: "90px",
        },
        {
          text: "ช่องทาง",
          align: "center",
          sortable: false,
          value: "channelName",
          width: "90px",
        },
        {
          text: "ขนส่ง",
          align: "center",
          sortable: false,
          value: "expressCompanyName",
          width: "90px",
        },
        {
          text: "เลขอ้างอิง",
          align: "left",
          sortable: false,
          value: "referenceNumber",
          width: "110px",
        },
        {
          text: "TrackingNo",
          align: "left",
          sortable: false,
          value: "trackingNo",
          width: "110px",
        },
        {
          text: "นัดรับ",
          align: "center",
          sortable: false,
          value: "isInit",
          width: "50px",
        },
        {
          text: "ใบแพ็ค",
          align: "center",
          sortable: false,
          value: "isPrintPackingList",
          width: "70px",
        },
        {
          text: "ใบปะหน้า",
          align: "center",
          sortable: false,
          value: "isPrintAWB",
          width: "70px",
        },
        {
          text: "น้ำหนัก",
          align: "center",
          sortable: false,
          value: "weight",
          width: "90px",
        },
        {
          text: "ขนาด",
          align: "center",
          sortable: false,
          value: "boxSize",
          width: "100px",
        },
        {
          text: "กล่อง",
          align: "center",
          sortable: false,
          value: "boxName",
          width: "100px",
        },
        {
          text: "กำลังแพ็ค",
          align: "center",
          sortable: false,
          value: "packedByStaffBranchName",
          width: "90px",
        },
        {
          text: "แพ็คเสร็จโดย",
          align: "center",
          sortable: false,
          value: "packedByStaffBranchName",
          width: "90px",
        },
        {
          text: "เริ่มแพ็ค",
          align: "center",
          sortable: false,
          value: "startPackingDate",
          width: "160px",
        },
        {
          text: "แพ็คเสร็จ",
          align: "center",
          sortable: false,
          value: "endPackingDate",
          width: "160px",
        },
        {
          text: "ลูกค้า",
          align: "left",
          sortable: false,
          value: "receiverName",
          width: "200px",
        },
      ],
      dataTable: [],
      page: 1,
      manageMenuListType: [{ name: "ผูกสินค้า", btn: "btnMappedProduct" },
      { name: "เปลี่ยนสถานะ", btn: "btnPackageChangeStatus" },
      { name: "รายงาน", btn: "btnViewReport" }],
      ManageChangeStatusList: [{ ID: "30", Name: "รอนัดรับ" },
      { ID: "40", Name: "รอแพ็ก" },
      { ID: "90", Name: "กำลังจัดส่ง" },
      { ID: "130", Name: "จัดส่งสำเร็จ" },
      { ID: "140", Name: "ยกเลิก" },
      ],
      ManageBackStatusList: [],
      offsets: 0,
      limits: 10,
      pageLength: 1,
      total: 0,
      selected: [],
      keyword: {},
      search: {
        select_per_page: 10,
        select_per: [
          { value: 5, name: "5 คำสั่งซื้อ" },
          { value: 10, name: "10 คำสั่งซื้อ" },
          { value: 25, name: "25 คำสั่งซื้อ" },
          { value: 50, name: "50 คำสั่งซื้อ" },
          { value: 100, name: "100 คำสั่งซื้อ" },
          { value: 999999, name: "ทั้งหมด" },
        ],
        search_by: '',
        select_channel: { channelID: 0, channelName: "--ทุกช่องทาง--" },
        list_channel: [{ channelID: 0, channelName: "--ทุกช่องทาง--" }],
        select_express: { expressCompanyID: null, expressCompanyName: " -- ทั้งหมด --" },
        list_express: [{ expressCompanyID: null, expressCompanyName: " -- ทั้งหมด --", },],
        select_print: { id: null, name: " -- ทั้งหมด --" },
        list_print: [{ id: null, name: " -- ทั้งหมด --" }],
        select_topic: { id: 1, name: "เลขออเดอร์", value: "orderId" },
        select_header: [
          { id: 0, name: "เลขออเดอร์", value: "orderId" },
          { id: 1, name: "เลขอ้างอิง", value: "referenceNumber" },
          { id: 2, name: "ชื่อผู้รับ", value: "receiverName" },
        ],
        text_topic: "",

      }
    },

    set_TabListSubHeader_Process: '10',
    dataset_process_map_product: {
      headers: [
        {
          text: "No",
          align: "center",
          sortable: false,
          value: "no",
          width: "70px",
        },
        {
          text: "ออเดอร์",
          align: "center",
          sortable: false,
          value: "id",
          width: "110px",
        },
        {
          text: "วันที่สร้าง",
          align: "center",
          sortable: false,
          value: "createdDatetime",
          width: "160px",
        },
        {
          text: "วันที่พร้อมแพ็ค",
          align: "center",
          sortable: false,
          value: "readyToPackDate",
          width: "160px",
        },
        {
          text: "ร้านค้า",
          align: "center",
          sortable: false,
          value: "shopName",
          width: "90px",
        },
        {
          text: "จำนวน SKU",
          align: "center",
          sortable: false,
          value: "skuCount",
          width: "90px",
        },
        {
          text: "จำนวนชิ้น",
          align: "center",
          sortable: false,
          value: "productCount",
          width: "90px",
        },
        {
          text: "ช่องทาง",
          align: "center",
          sortable: false,
          value: "channelName",
          width: "90px",
        },
        {
          text: "ขนส่ง",
          align: "center",
          sortable: false,
          value: "expressCompanyName",
          width: "90px",
        },
        {
          text: "เลขอ้างอิง",
          align: "left",
          sortable: false,
          value: "referenceNumber",
          width: "110px",
        },
        {
          text: "TrackingNo",
          align: "left",
          sortable: false,
          value: "trackingNo",
          width: "110px",
        },
        {
          text: "นัดรับ",
          align: "center",
          sortable: false,
          value: "isInit",
          width: "50px",
        },
        {
          text: "ใบแพ็ค",
          align: "center",
          sortable: false,
          value: "isPrintPackingList",
          width: "70px",
        },
        {
          text: "ใบปะหน้า",
          align: "center",
          sortable: false,
          value: "isPrintAWB",
          width: "70px",
        },
        {
          text: "น้ำหนัก",
          align: "center",
          sortable: false,
          value: "weight",
          width: "90px",
        },
        {
          text: "ขนาด",
          align: "center",
          sortable: false,
          value: "boxSize",
          width: "100px",
        },
        {
          text: "กล่อง",
          align: "center",
          sortable: false,
          value: "boxName",
          width: "100px",
        },
        {
          text: "กำลังแพ็ค",
          align: "center",
          sortable: false,
          value: "packedByStaffBranchName",
          width: "90px",
        },
        {
          text: "แพ็คเสร็จโดย",
          align: "center",
          sortable: false,
          value: "packedByStaffBranchName",
          width: "90px",
        },
        {
          text: "เริ่มแพ็ค",
          align: "center",
          sortable: false,
          value: "startPackingDate",
          width: "160px",
        },
        {
          text: "แพ็คเสร็จ",
          align: "center",
          sortable: false,
          value: "endPackingDate",
          width: "160px",
        },
        {
          text: "ลูกค้า",
          align: "left",
          sortable: false,
          value: "receiverName",
          width: "200px",
        },
      ],
      dataTable: [],
      page: 1,
      manageMenuListType: [{ name: "ผูกสินค้า", btn: "btnMappedProduct" },
      { name: "เปลี่ยนสถานะ", btn: "btnPackageChangeStatus" },
      { name: "รายงาน", btn: "btnViewReport" }],
      ManageChangeStatusList: [
        { ID: "40", Name: "รอแพ็ก" },
        { ID: "90", Name: "กำลังจัดส่ง" },
        { ID: "130", Name: "จัดส่งสำเร็จ" },
        { ID: "140", Name: "ยกเลิก" },
      ],
      ManageBackStatusList: [],
      offsets: 0,
      limits: 10,
      pageLength: 1,
      total: 0,
      selected: [],
      keyword: {},
      search: {
        select_per_page: 10,
        select_per: [
          { value: 5, name: "5 คำสั่งซื้อ" },
          { value: 10, name: "10 คำสั่งซื้อ" },
          { value: 25, name: "25 คำสั่งซื้อ" },
          { value: 50, name: "50 คำสั่งซื้อ" },
          { value: 100, name: "100 คำสั่งซื้อ" },
          { value: 999999, name: "ทั้งหมด" },
        ],
        search_by: '',
        select_channel: { channelID: 0, channelName: "--ทุกช่องทาง--" },
        list_channel: [{ channelID: 0, channelName: "--ทุกช่องทาง--" }],
        select_express: { expressCompanyID: null, expressCompanyName: " -- ทั้งหมด --" },
        list_express: [{ expressCompanyID: null, expressCompanyName: " -- ทั้งหมด --", },],
        select_print: { id: null, name: " -- ทั้งหมด --" },
        list_print: [{ id: null, name: " -- ทั้งหมด --" }],
        select_topic: { id: 1, name: "เลขออเดอร์", value: "orderId" },
        select_header: [
          { id: 0, name: "เลขออเดอร์", value: "orderId" },
          { id: 1, name: "เลขอ้างอิง", value: "referenceNumber" },
          { id: 2, name: "ชื่อผู้รับ", value: "receiverName" },
        ],
        text_topic: "",

      }
    },
    dataset_process_allocate_stock: {
      headers: [
        {
          text: "No",
          align: "center",
          sortable: false,
          value: "no",
          width: "70px",
        },
        {
          text: "ออเดอร์",
          align: "center",
          sortable: false,
          value: "id",
          width: "110px",
        },
        {
          text: "วันที่สร้าง",
          align: "center",
          sortable: false,
          value: "createdDatetime",
          width: "160px",
        },
        {
          text: "วันที่พร้อมแพ็ค",
          align: "center",
          sortable: false,
          value: "readyToPackDate",
          width: "160px",
        },
        {
          text: "ร้านค้า",
          align: "center",
          sortable: false,
          value: "shopName",
          width: "90px",
        },
        {
          text: "จำนวน SKU",
          align: "center",
          sortable: false,
          value: "skuCount",
          width: "90px",
        },
        {
          text: "จำนวนชิ้น",
          align: "center",
          sortable: false,
          value: "productCount",
          width: "90px",
        },
        {
          text: "ช่องทาง",
          align: "center",
          sortable: false,
          value: "channelName",
          width: "90px",
        },
        {
          text: "ขนส่ง",
          align: "center",
          sortable: false,
          value: "expressCompanyName",
          width: "90px",
        },
        {
          text: "เลขอ้างอิง",
          align: "left",
          sortable: false,
          value: "referenceNumber",
          width: "110px",
        },
        {
          text: "TrackingNo",
          align: "left",
          sortable: false,
          value: "trackingNo",
          width: "110px",
        },
        {
          text: "นัดรับ",
          align: "center",
          sortable: false,
          value: "isInit",
          width: "50px",
        },
        {
          text: "ใบแพ็ค",
          align: "center",
          sortable: false,
          value: "isPrintPackingList",
          width: "70px",
        },
        {
          text: "ใบปะหน้า",
          align: "center",
          sortable: false,
          value: "isPrintAWB",
          width: "70px",
        },
        {
          text: "น้ำหนัก",
          align: "center",
          sortable: false,
          value: "weight",
          width: "90px",
        },
        {
          text: "ขนาด",
          align: "center",
          sortable: false,
          value: "boxSize",
          width: "100px",
        },
        {
          text: "กล่อง",
          align: "center",
          sortable: false,
          value: "boxName",
          width: "100px",
        },
        {
          text: "กำลังแพ็ค",
          align: "center",
          sortable: false,
          value: "packedByStaffBranchName",
          width: "90px",
        },
        {
          text: "แพ็คเสร็จโดย",
          align: "center",
          sortable: false,
          value: "packedByStaffBranchName",
          width: "90px",
        },
        {
          text: "เริ่มแพ็ค",
          align: "center",
          sortable: false,
          value: "startPackingDate",
          width: "160px",
        },
        {
          text: "แพ็คเสร็จ",
          align: "center",
          sortable: false,
          value: "endPackingDate",
          width: "160px",
        },
        {
          text: "ลูกค้า",
          align: "left",
          sortable: false,
          value: "receiverName",
          width: "200px",
        },
      ],
      dataTable: [],
      page: 1,
      manageMenuListType: [{ name: "ผูกสินค้า", btn: "btnMappedProduct" },
      { name: "เปลี่ยนสถานะ", btn: "btnPackageChangeStatus" },
      { name: "รายงาน", btn: "btnViewReport" }],
      ManageChangeStatusList: [{ ID: "30", Name: "รอนัดรับ" },
      { ID: "40", Name: "รอแพ็ก" },
      { ID: "90", Name: "กำลังจัดส่ง" },
      { ID: "130", Name: "จัดส่งสำเร็จ" },
      { ID: "140", Name: "ยกเลิก" },
      ],
      ManageBackStatusList: [],
      offsets: 0,
      limits: 10,
      pageLength: 1,
      total: 0,
      selected: [],
      keyword: {},
      search: {
        select_per_page: 10,
        select_per: [
          { value: 5, name: "5 คำสั่งซื้อ" },
          { value: 10, name: "10 คำสั่งซื้อ" },
          { value: 25, name: "25 คำสั่งซื้อ" },
          { value: 50, name: "50 คำสั่งซื้อ" },
          { value: 100, name: "100 คำสั่งซื้อ" },
          { value: 999999, name: "ทั้งหมด" },
        ],
        search_by: '',
        select_channel: { channelID: 0, channelName: "--ทุกช่องทาง--" },
        list_channel: [{ channelID: 0, channelName: "--ทุกช่องทาง--" }],
        select_express: { expressCompanyID: null, expressCompanyName: " -- ทั้งหมด --" },
        list_express: [{ expressCompanyID: null, expressCompanyName: " -- ทั้งหมด --", },],
        select_print: { id: null, name: " -- ทั้งหมด --" },
        list_print: [{ id: null, name: " -- ทั้งหมด --" }],
        select_topic: { id: 1, name: "เลขออเดอร์", value: "orderId" },
        select_header: [
          { id: 0, name: "เลขออเดอร์", value: "orderId" },
          { id: 1, name: "เลขอ้างอิง", value: "referenceNumber" },
          { id: 2, name: "ชื่อผู้รับ", value: "receiverName" },
        ],
        text_topic: "",

      }
    },
    dataset_process_ready_to_ship: {
      headers: [
        {
          text: "No",
          align: "center",
          sortable: false,
          value: "no",
          width: "70px",
        },
        {
          text: "ออเดอร์",
          align: "center",
          sortable: false,
          value: "id",
          width: "110px",
        },
        {
          text: "วันที่สร้าง",
          align: "center",
          sortable: false,
          value: "createdDatetime",
          width: "160px",
        },
        {
          text: "วันที่พร้อมแพ็ค",
          align: "center",
          sortable: false,
          value: "readyToPackDate",
          width: "160px",
        },
        {
          text: "ร้านค้า",
          align: "center",
          sortable: false,
          value: "shopName",
          width: "90px",
        },
        {
          text: "จำนวน SKU",
          align: "center",
          sortable: false,
          value: "skuCount",
          width: "90px",
        },
        {
          text: "จำนวนชิ้น",
          align: "center",
          sortable: false,
          value: "productCount",
          width: "90px",
        },
        {
          text: "ช่องทาง",
          align: "center",
          sortable: false,
          value: "channelName",
          width: "90px",
        },
        {
          text: "ขนส่ง",
          align: "center",
          sortable: false,
          value: "expressCompanyName",
          width: "90px",
        },
        {
          text: "เลขอ้างอิง",
          align: "left",
          sortable: false,
          value: "referenceNumber",
          width: "110px",
        },
        {
          text: "TrackingNo",
          align: "left",
          sortable: false,
          value: "trackingNo",
          width: "110px",
        },
        {
          text: "นัดรับ",
          align: "center",
          sortable: false,
          value: "isInit",
          width: "50px",
        },
        {
          text: "ใบแพ็ค",
          align: "center",
          sortable: false,
          value: "isPrintPackingList",
          width: "70px",
        },
        {
          text: "ใบปะหน้า",
          align: "center",
          sortable: false,
          value: "isPrintAWB",
          width: "70px",
        },
        {
          text: "น้ำหนัก",
          align: "center",
          sortable: false,
          value: "weight",
          width: "90px",
        },
        {
          text: "ขนาด",
          align: "center",
          sortable: false,
          value: "boxSize",
          width: "100px",
        },
        {
          text: "กล่อง",
          align: "center",
          sortable: false,
          value: "boxName",
          width: "100px",
        },
        {
          text: "กำลังแพ็ค",
          align: "center",
          sortable: false,
          value: "packedByStaffBranchName",
          width: "90px",
        },
        {
          text: "แพ็คเสร็จโดย",
          align: "center",
          sortable: false,
          value: "packedByStaffBranchName",
          width: "90px",
        },
        {
          text: "เริ่มแพ็ค",
          align: "center",
          sortable: false,
          value: "startPackingDate",
          width: "160px",
        },
        {
          text: "แพ็คเสร็จ",
          align: "center",
          sortable: false,
          value: "endPackingDate",
          width: "160px",
        },
        {
          text: "ลูกค้า",
          align: "left",
          sortable: false,
          value: "receiverName",
          width: "200px",
        },
      ],
      dataTable: [],
      page: 1,
      manageMenuListType: [{ name: "พร้อมจัดส่งไปยัง Marketplace", btn: "btnUpdateToMarketplace" },
      { name: "เปลี่ยนสถานะ", btn: "btnPackageChangeStatus" },
      { name: "ย้อนสถานะ", btn: "btnPackageBackStatus" },
      { name: "รายงาน", btn: "btnViewReport" }],
      ManageChangeStatusList: [{ ID: "30", Name: "รอนัดรับ" },
      { ID: "40", Name: "รอแพ็ก" },
      { ID: "90", Name: "กำลังจัดส่ง" },
      { ID: "130", Name: "จัดส่งสำเร็จ" },
      { ID: "140", Name: "ยกเลิก" },
      ],
      ManageBackStatusList: [],
      offsets: 0,
      limits: 10,
      pageLength: 1,
      total: 0,
      selected: [],
      keyword: {},
      search: {
        select_per_page: 10,
        select_per: [
          { value: 5, name: "5 คำสั่งซื้อ" },
          { value: 10, name: "10 คำสั่งซื้อ" },
          { value: 25, name: "25 คำสั่งซื้อ" },
          { value: 50, name: "50 คำสั่งซื้อ" },
          { value: 100, name: "100 คำสั่งซื้อ" },
          { value: 999999, name: "ทั้งหมด" },
        ],
        search_by: '',
        select_channel: { channelID: 0, channelName: "--ทุกช่องทาง--" },
        list_channel: [{ channelID: 0, channelName: "--ทุกช่องทาง--" }],
        select_express: { expressCompanyID: null, expressCompanyName: " -- ทั้งหมด --" },
        list_express: [{ expressCompanyID: null, expressCompanyName: " -- ทั้งหมด --", },],
        select_print: { id: null, name: " -- ทั้งหมด --" },
        list_print: [{ id: null, name: " -- ทั้งหมด --" }],
        select_topic: { id: 1, name: "เลขออเดอร์", value: "orderId" },
        select_header: [
          { id: 0, name: "เลขออเดอร์", value: "orderId" },
          { id: 1, name: "เลขอ้างอิง", value: "referenceNumber" },
          { id: 2, name: "ชื่อผู้รับ", value: "receiverName" },
        ],
        text_topic: "",

      }
    },
    dataset_process_to_pack: {
      headers: [
        {
          text: "No",
          align: "center",
          sortable: false,
          value: "no",
          width: "70px",
        },
        {
          text: "ออเดอร์",
          align: "center",
          sortable: false,
          value: "id",
          width: "110px",
        },
        {
          text: "วันที่สร้าง",
          align: "center",
          sortable: false,
          value: "createdDatetime",
          width: "160px",
        },
        {
          text: "วันที่พร้อมแพ็ค",
          align: "center",
          sortable: false,
          value: "readyToPackDate",
          width: "160px",
        },
        {
          text: "ร้านค้า",
          align: "center",
          sortable: false,
          value: "shopName",
          width: "90px",
        },
        {
          text: "จำนวน SKU",
          align: "center",
          sortable: false,
          value: "skuCount",
          width: "90px",
        },
        {
          text: "จำนวนชิ้น",
          align: "center",
          sortable: false,
          value: "productCount",
          width: "90px",
        },
        {
          text: "ช่องทาง",
          align: "center",
          sortable: false,
          value: "channelName",
          width: "90px",
        },
        {
          text: "ขนส่ง",
          align: "center",
          sortable: false,
          value: "expressCompanyName",
          width: "90px",
        },
        {
          text: "เลขอ้างอิง",
          align: "left",
          sortable: false,
          value: "referenceNumber",
          width: "110px",
        },
        {
          text: "TrackingNo",
          align: "left",
          sortable: false,
          value: "trackingNo",
          width: "110px",
        },
        {
          text: "นัดรับ",
          align: "center",
          sortable: false,
          value: "isInit",
          width: "50px",
        },
        {
          text: "ใบแพ็ค",
          align: "center",
          sortable: false,
          value: "isPrintPackingList",
          width: "70px",
        },
        {
          text: "ใบปะหน้า",
          align: "center",
          sortable: false,
          value: "isPrintAWB",
          width: "70px",
        },
        {
          text: "น้ำหนัก",
          align: "center",
          sortable: false,
          value: "weight",
          width: "90px",
        },
        {
          text: "ขนาด",
          align: "center",
          sortable: false,
          value: "boxSize",
          width: "100px",
        },
        {
          text: "กล่อง",
          align: "center",
          sortable: false,
          value: "boxName",
          width: "100px",
        },
        {
          text: "กำลังแพ็ค",
          align: "center",
          sortable: false,
          value: "packedByStaffBranchName",
          width: "90px",
        },
        {
          text: "แพ็คเสร็จโดย",
          align: "center",
          sortable: false,
          value: "packedByStaffBranchName",
          width: "90px",
        },
        {
          text: "เริ่มแพ็ค",
          align: "center",
          sortable: false,
          value: "startPackingDate",
          width: "160px",
        },
        {
          text: "แพ็คเสร็จ",
          align: "center",
          sortable: false,
          value: "endPackingDate",
          width: "160px",
        },
        {
          text: "ลูกค้า",
          align: "left",
          sortable: false,
          value: "receiverName",
          width: "200px",
        },
      ],
      dataTable: [],
      page: 1,
      manageMenuListType: [{ name: "กรองสินค้า", btn: "btnFilterOrder" },
      { name: "ปริ้นใบแพ็ค", btn: "btnPrintBarcode" },
      { name: "ปริ้นใบปะหน้า", btn: "btnPrintAwb" },
      { name: "เปลี่ยนสถานะ", btn: "btnPackageChangeStatus" },
      { name: "รายงาน", btn: "btnViewReport" },],
      ManageChangeStatusList: [{ ID: "80", Name: "รอขนส่งเข้ารับ" },
      { ID: "90", Name: "กำลังจัดส่ง" },
      { ID: "130", Name: "จัดส่งสำเร็จ" },
      { ID: "140", Name: "ยกเลิก" },],
      ManageBackStatusList: [{ ID: "30", Name: "รอนัดรับ" }],
      offsets: 0,
      limits: 10,
      pageLength: 1,
      total: 0,
      selected: [],
      keyword: {},
      search: {
        select_per_page: 10,
        select_per: [
          { value: 5, name: "5 คำสั่งซื้อ" },
          { value: 10, name: "10 คำสั่งซื้อ" },
          { value: 25, name: "25 คำสั่งซื้อ" },
          { value: 50, name: "50 คำสั่งซื้อ" },
          { value: 100, name: "100 คำสั่งซื้อ" },
          { value: 999999, name: "ทั้งหมด" },
        ],
        search_by: '',
        select_channel: { channelID: 0, channelName: "--ทุกช่องทาง--" },
        list_channel: [{ channelID: 0, channelName: "--ทุกช่องทาง--" }],
        select_express: { expressCompanyID: null, expressCompanyName: " -- ทั้งหมด --" },
        list_express: [{ expressCompanyID: null, expressCompanyName: " -- ทั้งหมด --", },],
        select_print: { id: null, name: " -- ทั้งหมด --" },
        list_print: [{ id: null, name: " -- ทั้งหมด --" }],
        select_topic: { id: 1, name: "เลขออเดอร์", value: "orderId" },
        select_header: [
          { id: 0, name: "เลขออเดอร์", value: "orderId" },
          { id: 1, name: "เลขอ้างอิง", value: "referenceNumber" },
          { id: 2, name: "ชื่อผู้รับ", value: "receiverName" },
        ],
        text_topic: "",

      }
    },
    dataset_process_packing: {
      headers: [
        {
          text: "No",
          align: "center",
          sortable: false,
          value: "no",
          width: "70px",
        },
        {
          text: "ออเดอร์",
          align: "center",
          sortable: false,
          value: "id",
          width: "110px",
        },
        {
          text: "วันที่สร้าง",
          align: "center",
          sortable: false,
          value: "createdDatetime",
          width: "160px",
        },
        {
          text: "วันที่พร้อมแพ็ค",
          align: "center",
          sortable: false,
          value: "readyToPackDate",
          width: "160px",
        },
        {
          text: "ร้านค้า",
          align: "center",
          sortable: false,
          value: "shopName",
          width: "90px",
        },
        {
          text: "จำนวน SKU",
          align: "center",
          sortable: false,
          value: "skuCount",
          width: "90px",
        },
        {
          text: "จำนวนชิ้น",
          align: "center",
          sortable: false,
          value: "productCount",
          width: "90px",
        },
        {
          text: "ช่องทาง",
          align: "center",
          sortable: false,
          value: "channelName",
          width: "90px",
        },
        {
          text: "ขนส่ง",
          align: "center",
          sortable: false,
          value: "expressCompanyName",
          width: "90px",
        },
        {
          text: "เลขอ้างอิง",
          align: "left",
          sortable: false,
          value: "referenceNumber",
          width: "110px",
        },
        {
          text: "TrackingNo",
          align: "left",
          sortable: false,
          value: "trackingNo",
          width: "110px",
        },
        {
          text: "นัดรับ",
          align: "center",
          sortable: false,
          value: "isInit",
          width: "50px",
        },
        {
          text: "ใบแพ็ค",
          align: "center",
          sortable: false,
          value: "isPrintPackingList",
          width: "70px",
        },
        {
          text: "ใบปะหน้า",
          align: "center",
          sortable: false,
          value: "isPrintAWB",
          width: "70px",
        },
        {
          text: "น้ำหนัก",
          align: "center",
          sortable: false,
          value: "weight",
          width: "90px",
        },
        {
          text: "ขนาด",
          align: "center",
          sortable: false,
          value: "boxSize",
          width: "100px",
        },
        {
          text: "กล่อง",
          align: "center",
          sortable: false,
          value: "boxName",
          width: "100px",
        },
        {
          text: "กำลังแพ็ค",
          align: "center",
          sortable: false,
          value: "packedByStaffBranchName",
          width: "90px",
        },
        {
          text: "แพ็คเสร็จโดย",
          align: "center",
          sortable: false,
          value: "packedByStaffBranchName",
          width: "90px",
        },
        {
          text: "เริ่มแพ็ค",
          align: "center",
          sortable: false,
          value: "startPackingDate",
          width: "160px",
        },
        {
          text: "แพ็คเสร็จ",
          align: "center",
          sortable: false,
          value: "endPackingDate",
          width: "160px",
        },
        {
          text: "ลูกค้า",
          align: "left",
          sortable: false,
          value: "receiverName",
          width: "200px",
        },
      ],
      dataTable: [],
      page: 1,
      manageMenuListType: [{ name: "กรองสินค้า", btn: "btnFilterOrder" },
      { name: "ปริ้นใบแพ็ค", btn: "btnPrintBarcode" },
      { name: "ปริ้นใบปะหน้า", btn: "btnPrintAwb" },
      { name: "เปลี่ยนสถานะ", btn: "btnPackageChangeStatus" },
      { name: "รายงาน", btn: "btnViewReport" },],
      ManageChangeStatusList: [{ ID: "80", Name: "รอขนส่งเข้ารับ" },
      { ID: "90", Name: "กำลังจัดส่ง" },
      { ID: "130", Name: "จัดส่งสำเร็จ" },
      { ID: "140", Name: "ยกเลิก" },],
      ManageBackStatusList: [{ ID: "30", Name: "รอนัดรับ" }],
      offsets: 0,
      limits: 10,
      pageLength: 1,
      total: 0,
      selected: [],
      keyword: {},
      search: {
        select_per_page: 10,
        select_per: [
          { value: 5, name: "5 คำสั่งซื้อ" },
          { value: 10, name: "10 คำสั่งซื้อ" },
          { value: 25, name: "25 คำสั่งซื้อ" },
          { value: 50, name: "50 คำสั่งซื้อ" },
          { value: 100, name: "100 คำสั่งซื้อ" },
          { value: 999999, name: "ทั้งหมด" },
        ],
        search_by: '',
        select_channel: { channelID: 0, channelName: "--ทุกช่องทาง--" },
        list_channel: [{ channelID: 0, channelName: "--ทุกช่องทาง--" }],
        select_express: { expressCompanyID: null, expressCompanyName: " -- ทั้งหมด --" },
        list_express: [{ expressCompanyID: null, expressCompanyName: " -- ทั้งหมด --", },],
        select_print: { id: null, name: " -- ทั้งหมด --" },
        list_print: [{ id: null, name: " -- ทั้งหมด --" }],
        select_topic: { id: 1, name: "เลขออเดอร์", value: "orderId" },
        select_header: [
          { id: 0, name: "เลขออเดอร์", value: "orderId" },
          { id: 1, name: "เลขอ้างอิง", value: "referenceNumber" },
          { id: 2, name: "ชื่อผู้รับ", value: "receiverName" },
        ],
        text_topic: "",

      }
    },
    dataset_process_wait_pickup: {
      headers: [
        {
          text: "No",
          align: "center",
          sortable: false,
          value: "no",
          width: "70px",
        },
        {
          text: "ออเดอร์",
          align: "center",
          sortable: false,
          value: "id",
          width: "110px",
        },
        {
          text: "วันที่สร้าง",
          align: "center",
          sortable: false,
          value: "createdDatetime",
          width: "160px",
        },
        {
          text: "วันที่พร้อมแพ็ค",
          align: "center",
          sortable: false,
          value: "readyToPackDate",
          width: "160px",
        },
        {
          text: "ร้านค้า",
          align: "center",
          sortable: false,
          value: "shopName",
          width: "90px",
        },
        {
          text: "จำนวน SKU",
          align: "center",
          sortable: false,
          value: "skuCount",
          width: "90px",
        },
        {
          text: "จำนวนชิ้น",
          align: "center",
          sortable: false,
          value: "productCount",
          width: "90px",
        },
        {
          text: "ช่องทาง",
          align: "center",
          sortable: false,
          value: "channelName",
          width: "90px",
        },
        {
          text: "ขนส่ง",
          align: "center",
          sortable: false,
          value: "expressCompanyName",
          width: "90px",
        },
        {
          text: "เลขอ้างอิง",
          align: "left",
          sortable: false,
          value: "referenceNumber",
          width: "110px",
        },
        {
          text: "TrackingNo",
          align: "left",
          sortable: false,
          value: "trackingNo",
          width: "110px",
        },
        {
          text: "นัดรับ",
          align: "center",
          sortable: false,
          value: "isInit",
          width: "50px",
        },
        {
          text: "ใบแพ็ค",
          align: "center",
          sortable: false,
          value: "isPrintPackingList",
          width: "70px",
        },
        {
          text: "ใบปะหน้า",
          align: "center",
          sortable: false,
          value: "isPrintAWB",
          width: "70px",
        },
        {
          text: "น้ำหนัก",
          align: "center",
          sortable: false,
          value: "weight",
          width: "90px",
        },
        {
          text: "ขนาด",
          align: "center",
          sortable: false,
          value: "boxSize",
          width: "100px",
        },
        {
          text: "กล่อง",
          align: "center",
          sortable: false,
          value: "boxName",
          width: "100px",
        },
        {
          text: "กำลังแพ็ค",
          align: "center",
          sortable: false,
          value: "packedByStaffBranchName",
          width: "90px",
        },
        {
          text: "แพ็คเสร็จโดย",
          align: "center",
          sortable: false,
          value: "packedByStaffBranchName",
          width: "90px",
        },
        {
          text: "เริ่มแพ็ค",
          align: "center",
          sortable: false,
          value: "startPackingDate",
          width: "160px",
        },
        {
          text: "แพ็คเสร็จ",
          align: "center",
          sortable: false,
          value: "endPackingDate",
          width: "160px",
        },
        {
          text: "ลูกค้า",
          align: "left",
          sortable: false,
          value: "receiverName",
          width: "200px",
        },
      ],
      dataTable: [],
      page: 1,
      manageMenuListType: [{ name: "ปริ้นใบปะหน้า", btn: "btnPrintAwb" },
      { name: "เปลี่ยนสถานะ", btn: "btnPackageChangeStatus" },
      { name: "ย้อนสถานะ", btn: "btnPackageBackStatus" },
      { name: "รายงาน", btn: "btnViewReport" }],
      ManageChangeStatusList: [{ ID: "90", Name: "กำลังจัดส่ง" },
      { ID: "130", Name: "จัดส่งสำเร็จ" },
      { ID: "140", Name: "ยกเลิก" },],
      ManageBackStatusList: [{ ID: 50, Name: "รอแพ็ค" }],
      offsets: 0,
      limits: 10,
      pageLength: 1,
      total: 0,
      selected: [],
      keyword: {},
      search: {
        select_per_page: 10,
        select_per: [
          { value: 5, name: "5 คำสั่งซื้อ" },
          { value: 10, name: "10 คำสั่งซื้อ" },
          { value: 25, name: "25 คำสั่งซื้อ" },
          { value: 50, name: "50 คำสั่งซื้อ" },
          { value: 100, name: "100 คำสั่งซื้อ" },
          { value: 999999, name: "ทั้งหมด" },
        ],
        search_by: '',
        select_channel: { channelID: 0, channelName: "--ทุกช่องทาง--" },
        list_channel: [{ channelID: 0, channelName: "--ทุกช่องทาง--" }],
        select_express: { expressCompanyID: null, expressCompanyName: " -- ทั้งหมด --" },
        list_express: [{ expressCompanyID: null, expressCompanyName: " -- ทั้งหมด --", },],
        select_print: { id: null, name: " -- ทั้งหมด --" },
        list_print: [{ id: null, name: " -- ทั้งหมด --" }],
        select_topic: { id: 1, name: "เลขออเดอร์", value: "orderId" },
        select_header: [
          { id: 0, name: "เลขออเดอร์", value: "orderId" },
          { id: 1, name: "เลขอ้างอิง", value: "referenceNumber" },
          { id: 2, name: "ชื่อผู้รับ", value: "receiverName" },
        ],
        text_topic: "",

      }
    },

    dataset_delivering: {
      headers: [
        {
          text: "No",
          align: "center",
          sortable: false,
          value: "no",
          width: "70px",
        },
        {
          text: "ออเดอร์",
          align: "center",
          sortable: false,
          value: "id",
          width: "110px",
        },
        {
          text: "วันที่สร้าง",
          align: "center",
          sortable: false,
          value: "createdDatetime",
          width: "160px",
        },
        {
          text: "วันที่พร้อมแพ็ค",
          align: "center",
          sortable: false,
          value: "readyToPackDate",
          width: "160px",
        },
        {
          text: "ร้านค้า",
          align: "center",
          sortable: false,
          value: "shopName",
          width: "90px",
        },
        {
          text: "จำนวน SKU",
          align: "center",
          sortable: false,
          value: "skuCount",
          width: "90px",
        },
        {
          text: "จำนวนชิ้น",
          align: "center",
          sortable: false,
          value: "productCount",
          width: "90px",
        },
        {
          text: "ช่องทาง",
          align: "center",
          sortable: false,
          value: "channelName",
          width: "90px",
        },
        {
          text: "ขนส่ง",
          align: "center",
          sortable: false,
          value: "expressCompanyName",
          width: "90px",
        },
        {
          text: "เลขอ้างอิง",
          align: "left",
          sortable: false,
          value: "referenceNumber",
          width: "110px",
        },
        {
          text: "TrackingNo",
          align: "left",
          sortable: false,
          value: "trackingNo",
          width: "110px",
        },
        {
          text: "นัดรับ",
          align: "center",
          sortable: false,
          value: "isInit",
          width: "50px",
        },
        {
          text: "ใบแพ็ค",
          align: "center",
          sortable: false,
          value: "isPrintPackingList",
          width: "70px",
        },
        {
          text: "ใบปะหน้า",
          align: "center",
          sortable: false,
          value: "isPrintAWB",
          width: "70px",
        },
        {
          text: "น้ำหนัก",
          align: "center",
          sortable: false,
          value: "weight",
          width: "90px",
        },
        {
          text: "ขนาด",
          align: "center",
          sortable: false,
          value: "boxSize",
          width: "100px",
        },
        {
          text: "กล่อง",
          align: "center",
          sortable: false,
          value: "boxName",
          width: "100px",
        },
        {
          text: "กำลังแพ็ค",
          align: "center",
          sortable: false,
          value: "packedByStaffBranchName",
          width: "90px",
        },
        {
          text: "แพ็คเสร็จโดย",
          align: "center",
          sortable: false,
          value: "packedByStaffBranchName",
          width: "90px",
        },
        {
          text: "เริ่มแพ็ค",
          align: "center",
          sortable: false,
          value: "startPackingDate",
          width: "160px",
        },
        {
          text: "แพ็คเสร็จ",
          align: "center",
          sortable: false,
          value: "endPackingDate",
          width: "160px",
        },
        {
          text: "ลูกค้า",
          align: "left",
          sortable: false,
          value: "receiverName",
          width: "200px",
        },
      ],
      dataTable: [],
      page: 1,
      manageMenuListType: [{ name: "เปลี่ยนสถานะ", btn: "btnPackageChangeStatus" },
      { name: "ย้อนสถานะ", btn: "btnPackageBackStatus" },
      { name: "รายงาน", btn: "btnViewReport" },],
      ManageChangeStatusList: [{ ID: "100", Name: "กำลังตีกลับ" },
      { ID: "110", Name: "ตีกลับแล้วรอคืนสต๊อก" },
      { ID: "130", Name: "จัดส่งสำเร็จ" },
      { ID: "140", Name: "ยกเลิก" },],
      ManageBackStatusList: [{ ID: "80", Name: "รอขนส่งเข้ารับ" },],
      offsets: 0,
      limits: 10,
      pageLength: 1,
      total: 0,
      selected: [],
      keyword: {},
      search: {
        select_per_page: 10,
        select_per: [
          { value: 5, name: "5 คำสั่งซื้อ" },
          { value: 10, name: "10 คำสั่งซื้อ" },
          { value: 25, name: "25 คำสั่งซื้อ" },
          { value: 50, name: "50 คำสั่งซื้อ" },
          { value: 100, name: "100 คำสั่งซื้อ" },
          { value: 999999, name: "ทั้งหมด" },
        ],
        search_by: '',
        select_channel: { channelID: 0, channelName: "--ทุกช่องทาง--" },
        list_channel: [{ channelID: 0, channelName: "--ทุกช่องทาง--" }],
        select_express: { expressCompanyID: null, expressCompanyName: " -- ทั้งหมด --" },
        list_express: [{ expressCompanyID: null, expressCompanyName: " -- ทั้งหมด --", },],
        select_print: { id: null, name: " -- ทั้งหมด --" },
        list_print: [{ id: null, name: " -- ทั้งหมด --" }],
        select_topic: { id: 1, name: "เลขออเดอร์", value: "orderId" },
        select_header: [
          { id: 0, name: "เลขออเดอร์", value: "orderId" },
          { id: 1, name: "เลขอ้างอิง", value: "referenceNumber" },
          { id: 2, name: "ชื่อผู้รับ", value: "receiverName" },
        ],
        text_topic: "",

      }
    },
    dataset_success: {
      headers: [
        {
          text: "No",
          align: "center",
          sortable: false,
          value: "no",
          width: "70px",
        },
        {
          text: "ออเดอร์",
          align: "center",
          sortable: false,
          value: "id",
          width: "110px",
        },
        {
          text: "วันที่สร้าง",
          align: "center",
          sortable: false,
          value: "createdDatetime",
          width: "160px",
        },
        {
          text: "วันที่พร้อมแพ็ค",
          align: "center",
          sortable: false,
          value: "readyToPackDate",
          width: "160px",
        },
        {
          text: "ร้านค้า",
          align: "center",
          sortable: false,
          value: "shopName",
          width: "90px",
        },
        {
          text: "จำนวน SKU",
          align: "center",
          sortable: false,
          value: "skuCount",
          width: "90px",
        },
        {
          text: "จำนวนชิ้น",
          align: "center",
          sortable: false,
          value: "productCount",
          width: "90px",
        },
        {
          text: "ช่องทาง",
          align: "center",
          sortable: false,
          value: "channelName",
          width: "90px",
        },
        {
          text: "ขนส่ง",
          align: "center",
          sortable: false,
          value: "expressCompanyName",
          width: "90px",
        },
        {
          text: "เลขอ้างอิง",
          align: "left",
          sortable: false,
          value: "referenceNumber",
          width: "110px",
        },
        {
          text: "TrackingNo",
          align: "left",
          sortable: false,
          value: "trackingNo",
          width: "110px",
        },
        {
          text: "นัดรับ",
          align: "center",
          sortable: false,
          value: "isInit",
          width: "50px",
        },
        {
          text: "ใบแพ็ค",
          align: "center",
          sortable: false,
          value: "isPrintPackingList",
          width: "70px",
        },
        {
          text: "ใบปะหน้า",
          align: "center",
          sortable: false,
          value: "isPrintAWB",
          width: "70px",
        },
        {
          text: "น้ำหนัก",
          align: "center",
          sortable: false,
          value: "weight",
          width: "90px",
        },
        {
          text: "ขนาด",
          align: "center",
          sortable: false,
          value: "boxSize",
          width: "100px",
        },
        {
          text: "กล่อง",
          align: "center",
          sortable: false,
          value: "boxName",
          width: "100px",
        },
        {
          text: "กำลังแพ็ค",
          align: "center",
          sortable: false,
          value: "packedByStaffBranchName",
          width: "90px",
        },
        {
          text: "แพ็คเสร็จโดย",
          align: "center",
          sortable: false,
          value: "packedByStaffBranchName",
          width: "90px",
        },
        {
          text: "เริ่มแพ็ค",
          align: "center",
          sortable: false,
          value: "startPackingDate",
          width: "160px",
        },
        {
          text: "แพ็คเสร็จ",
          align: "center",
          sortable: false,
          value: "endPackingDate",
          width: "160px",
        },
        {
          text: "ลูกค้า",
          align: "left",
          sortable: false,
          value: "receiverName",
          width: "200px",
        },
      ],
      dataTable: [],
      page: 1,
      manageMenuListType: [{ name: "รายงาน", btn: "btnViewReport" },],
      ManageChangeStatusList: [],
      ManageBackStatusList: [],
      offsets: 0,
      limits: 10,
      pageLength: 1,
      total: 0,
      selected: [],
      keyword: {},
      search: {
        select_per_page: 10,
        select_per: [
          { value: 5, name: "5 คำสั่งซื้อ" },
          { value: 10, name: "10 คำสั่งซื้อ" },
          { value: 25, name: "25 คำสั่งซื้อ" },
          { value: 50, name: "50 คำสั่งซื้อ" },
          { value: 100, name: "100 คำสั่งซื้อ" },
          { value: 999999, name: "ทั้งหมด" },
        ],
        search_by: '',
        select_channel: { channelID: 0, channelName: "--ทุกช่องทาง--" },
        list_channel: [{ channelID: 0, channelName: "--ทุกช่องทาง--" }],
        select_express: { expressCompanyID: null, expressCompanyName: " -- ทั้งหมด --" },
        list_express: [{ expressCompanyID: null, expressCompanyName: " -- ทั้งหมด --", },],
        select_print: { id: null, name: " -- ทั้งหมด --" },
        list_print: [{ id: null, name: " -- ทั้งหมด --" }],
        select_topic: { id: 1, name: "เลขออเดอร์", value: "orderId" },
        select_header: [
          { id: 0, name: "เลขออเดอร์", value: "orderId" },
          { id: 1, name: "เลขอ้างอิง", value: "referenceNumber" },
          { id: 2, name: "ชื่อผู้รับ", value: "receiverName" },
        ],
        text_topic: "",

      }
    },


    set_TabListSubHeader_Return: '100',
    dataset_Return_Returnning: {
      headers: [
        {
          text: "No",
          align: "center",
          sortable: false,
          value: "no",
          width: "70px",
        },
        {
          text: "ออเดอร์",
          align: "center",
          sortable: false,
          value: "id",
          width: "110px",
        },
        {
          text: "วันที่สร้าง",
          align: "center",
          sortable: false,
          value: "createdDatetime",
          width: "160px",
        },
        {
          text: "วันที่พร้อมแพ็ค",
          align: "center",
          sortable: false,
          value: "readyToPackDate",
          width: "160px",
        },
        {
          text: "ร้านค้า",
          align: "center",
          sortable: false,
          value: "shopName",
          width: "90px",
        },
        {
          text: "จำนวน SKU",
          align: "center",
          sortable: false,
          value: "skuCount",
          width: "90px",
        },
        {
          text: "จำนวนชิ้น",
          align: "center",
          sortable: false,
          value: "productCount",
          width: "90px",
        },
        {
          text: "ช่องทาง",
          align: "center",
          sortable: false,
          value: "channelName",
          width: "90px",
        },
        {
          text: "ขนส่ง",
          align: "center",
          sortable: false,
          value: "expressCompanyName",
          width: "90px",
        },
        {
          text: "เลขอ้างอิง",
          align: "left",
          sortable: false,
          value: "referenceNumber",
          width: "110px",
        },
        {
          text: "TrackingNo",
          align: "left",
          sortable: false,
          value: "trackingNo",
          width: "110px",
        },
        {
          text: "นัดรับ",
          align: "center",
          sortable: false,
          value: "isInit",
          width: "50px",
        },
        {
          text: "ใบแพ็ค",
          align: "center",
          sortable: false,
          value: "isPrintPackingList",
          width: "70px",
        },
        {
          text: "ใบปะหน้า",
          align: "center",
          sortable: false,
          value: "isPrintAWB",
          width: "70px",
        },
        {
          text: "น้ำหนัก",
          align: "center",
          sortable: false,
          value: "weight",
          width: "90px",
        },
        {
          text: "ขนาด",
          align: "center",
          sortable: false,
          value: "boxSize",
          width: "100px",
        },
        {
          text: "กล่อง",
          align: "center",
          sortable: false,
          value: "boxName",
          width: "100px",
        },
        {
          text: "กำลังแพ็ค",
          align: "center",
          sortable: false,
          value: "packedByStaffBranchName",
          width: "90px",
        },
        {
          text: "แพ็คเสร็จโดย",
          align: "center",
          sortable: false,
          value: "packedByStaffBranchName",
          width: "90px",
        },
        {
          text: "เริ่มแพ็ค",
          align: "center",
          sortable: false,
          value: "startPackingDate",
          width: "160px",
        },
        {
          text: "แพ็คเสร็จ",
          align: "center",
          sortable: false,
          value: "endPackingDate",
          width: "160px",
        },
        {
          text: "ลูกค้า",
          align: "left",
          sortable: false,
          value: "receiverName",
          width: "200px",
        },
      ],
      dataTable: [],
      page: 1,
      manageMenuListType: [{ name: "เปลี่ยนสถานะ", btn: "btnPackageChangeStatus" },
      { name: "ย้อนสถานะ", btn: "btnPackageBackStatus" },
      { name: "รายงาน", btn: "btnViewReport" },],
      ManageChangeStatusList: [{ ID: "110", Name: "ตีกลับแล้วรอคืนสต๊อก" },],
      ManageBackStatusList: [],
      offsets: 0,
      limits: 10,
      pageLength: 1,
      total: 0,
      selected: [],
      keyword: {},
      search: {
        select_per_page: 10,
        select_per: [
          { value: 5, name: "5 คำสั่งซื้อ" },
          { value: 10, name: "10 คำสั่งซื้อ" },
          { value: 25, name: "25 คำสั่งซื้อ" },
          { value: 50, name: "50 คำสั่งซื้อ" },
          { value: 100, name: "100 คำสั่งซื้อ" },
          { value: 999999, name: "ทั้งหมด" },
        ],
        search_by: '',
        select_channel: { channelID: 0, channelName: "--ทุกช่องทาง--" },
        list_channel: [{ channelID: 0, channelName: "--ทุกช่องทาง--" }],
        select_express: { expressCompanyID: null, expressCompanyName: " -- ทั้งหมด --" },
        list_express: [{ expressCompanyID: null, expressCompanyName: " -- ทั้งหมด --", },],
        select_print: { id: null, name: " -- ทั้งหมด --" },
        list_print: [{ id: null, name: " -- ทั้งหมด --" }],
        select_topic: { id: 1, name: "เลขออเดอร์", value: "orderId" },
        select_header: [
          { id: 0, name: "เลขออเดอร์", value: "orderId" },
          { id: 1, name: "เลขอ้างอิง", value: "referenceNumber" },
          { id: 2, name: "ชื่อผู้รับ", value: "receiverName" },
        ],
        text_topic: "",

      }
    },
    dataset_Return_To_Return_Stock: {
      headers: [
        {
          text: "No",
          align: "center",
          sortable: false,
          value: "no",
          width: "70px",
        },
        {
          text: "ออเดอร์",
          align: "center",
          sortable: false,
          value: "id",
          width: "110px",
        },
        {
          text: "วันที่สร้าง",
          align: "center",
          sortable: false,
          value: "createdDatetime",
          width: "160px",
        },
        {
          text: "วันที่พร้อมแพ็ค",
          align: "center",
          sortable: false,
          value: "readyToPackDate",
          width: "160px",
        },
        {
          text: "ร้านค้า",
          align: "center",
          sortable: false,
          value: "shopName",
          width: "90px",
        },
        {
          text: "จำนวน SKU",
          align: "center",
          sortable: false,
          value: "skuCount",
          width: "90px",
        },
        {
          text: "จำนวนชิ้น",
          align: "center",
          sortable: false,
          value: "productCount",
          width: "90px",
        },
        {
          text: "ช่องทาง",
          align: "center",
          sortable: false,
          value: "channelName",
          width: "90px",
        },
        {
          text: "ขนส่ง",
          align: "center",
          sortable: false,
          value: "expressCompanyName",
          width: "90px",
        },
        {
          text: "เลขอ้างอิง",
          align: "left",
          sortable: false,
          value: "referenceNumber",
          width: "110px",
        },
        {
          text: "TrackingNo",
          align: "left",
          sortable: false,
          value: "trackingNo",
          width: "110px",
        },
        {
          text: "นัดรับ",
          align: "center",
          sortable: false,
          value: "isInit",
          width: "50px",
        },
        {
          text: "ใบแพ็ค",
          align: "center",
          sortable: false,
          value: "isPrintPackingList",
          width: "70px",
        },
        {
          text: "ใบปะหน้า",
          align: "center",
          sortable: false,
          value: "isPrintAWB",
          width: "70px",
        },
        {
          text: "น้ำหนัก",
          align: "center",
          sortable: false,
          value: "weight",
          width: "90px",
        },
        {
          text: "ขนาด",
          align: "center",
          sortable: false,
          value: "boxSize",
          width: "100px",
        },
        {
          text: "กล่อง",
          align: "center",
          sortable: false,
          value: "boxName",
          width: "100px",
        },
        {
          text: "กำลังแพ็ค",
          align: "center",
          sortable: false,
          value: "packedByStaffBranchName",
          width: "90px",
        },
        {
          text: "แพ็คเสร็จโดย",
          align: "center",
          sortable: false,
          value: "packedByStaffBranchName",
          width: "90px",
        },
        {
          text: "เริ่มแพ็ค",
          align: "center",
          sortable: false,
          value: "startPackingDate",
          width: "160px",
        },
        {
          text: "แพ็คเสร็จ",
          align: "center",
          sortable: false,
          value: "endPackingDate",
          width: "160px",
        },
        {
          text: "ลูกค้า",
          align: "left",
          sortable: false,
          value: "receiverName",
          width: "200px",
        },
      ],
      dataTable: [],
      page: 1,
      manageMenuListType: [{ name: "รายงาน", btn: "btnViewReport" },
      { name: "สต๊อก", btn: "" }],
      ManageChangeStatusList: [],
      ManageBackStatusList: [],
      offsets: 0,
      limits: 10,
      pageLength: 1,
      total: 0,
      selected: [],
      keyword: {},
      search: {
        select_per_page: 10,
        select_per: [
          { value: 5, name: "5 คำสั่งซื้อ" },
          { value: 10, name: "10 คำสั่งซื้อ" },
          { value: 25, name: "25 คำสั่งซื้อ" },
          { value: 50, name: "50 คำสั่งซื้อ" },
          { value: 100, name: "100 คำสั่งซื้อ" },
          { value: 999999, name: "ทั้งหมด" },
        ],
        search_by: '',
        select_channel: { channelID: 0, channelName: "--ทุกช่องทาง--" },
        list_channel: [{ channelID: 0, channelName: "--ทุกช่องทาง--" }],
        select_express: { expressCompanyID: null, expressCompanyName: " -- ทั้งหมด --" },
        list_express: [{ expressCompanyID: null, expressCompanyName: " -- ทั้งหมด --", },],
        select_print: { id: null, name: " -- ทั้งหมด --" },
        list_print: [{ id: null, name: " -- ทั้งหมด --" }],
        select_topic: { id: 1, name: "เลขออเดอร์", value: "orderId" },
        select_header: [
          { id: 0, name: "เลขออเดอร์", value: "orderId" },
          { id: 1, name: "เลขอ้างอิง", value: "referenceNumber" },
          { id: 2, name: "ชื่อผู้รับ", value: "receiverName" },
        ],
        text_topic: "",

      }
    },
    dataset_Return_Stock_Returned: {
      headers: [
        {
          text: "No",
          align: "center",
          sortable: false,
          value: "no",
          width: "70px",
        },
        {
          text: "ออเดอร์",
          align: "center",
          sortable: false,
          value: "id",
          width: "110px",
        },
        {
          text: "วันที่สร้าง",
          align: "center",
          sortable: false,
          value: "createdDatetime",
          width: "160px",
        },
        {
          text: "วันที่พร้อมแพ็ค",
          align: "center",
          sortable: false,
          value: "readyToPackDate",
          width: "160px",
        },
        {
          text: "ร้านค้า",
          align: "center",
          sortable: false,
          value: "shopName",
          width: "90px",
        },
        {
          text: "จำนวน SKU",
          align: "center",
          sortable: false,
          value: "skuCount",
          width: "90px",
        },
        {
          text: "จำนวนชิ้น",
          align: "center",
          sortable: false,
          value: "productCount",
          width: "90px",
        },
        {
          text: "ช่องทาง",
          align: "center",
          sortable: false,
          value: "channelName",
          width: "90px",
        },
        {
          text: "ขนส่ง",
          align: "center",
          sortable: false,
          value: "expressCompanyName",
          width: "90px",
        },
        {
          text: "เลขอ้างอิง",
          align: "left",
          sortable: false,
          value: "referenceNumber",
          width: "110px",
        },
        {
          text: "TrackingNo",
          align: "left",
          sortable: false,
          value: "trackingNo",
          width: "110px",
        },
        {
          text: "นัดรับ",
          align: "center",
          sortable: false,
          value: "isInit",
          width: "50px",
        },
        {
          text: "ใบแพ็ค",
          align: "center",
          sortable: false,
          value: "isPrintPackingList",
          width: "70px",
        },
        {
          text: "ใบปะหน้า",
          align: "center",
          sortable: false,
          value: "isPrintAWB",
          width: "70px",
        },
        {
          text: "น้ำหนัก",
          align: "center",
          sortable: false,
          value: "weight",
          width: "90px",
        },
        {
          text: "ขนาด",
          align: "center",
          sortable: false,
          value: "boxSize",
          width: "100px",
        },
        {
          text: "กล่อง",
          align: "center",
          sortable: false,
          value: "boxName",
          width: "100px",
        },
        {
          text: "กำลังแพ็ค",
          align: "center",
          sortable: false,
          value: "packedByStaffBranchName",
          width: "90px",
        },
        {
          text: "แพ็คเสร็จโดย",
          align: "center",
          sortable: false,
          value: "packedByStaffBranchName",
          width: "90px",
        },
        {
          text: "เริ่มแพ็ค",
          align: "center",
          sortable: false,
          value: "startPackingDate",
          width: "160px",
        },
        {
          text: "แพ็คเสร็จ",
          align: "center",
          sortable: false,
          value: "endPackingDate",
          width: "160px",
        },
        {
          text: "ลูกค้า",
          align: "left",
          sortable: false,
          value: "receiverName",
          width: "200px",
        },
      ],
      dataTable: [],
      page: 1,
      manageMenuListType: [{ name: "รายงาน", btn: "btnViewReport" }],
      ManageChangeStatusList: [],
      ManageBackStatusList: [],
      offsets: 0,
      limits: 10,
      pageLength: 1,
      total: 0,
      selected: [],
      keyword: {},
      search: {
        select_per_page: 10,
        select_per: [
          { value: 5, name: "5 คำสั่งซื้อ" },
          { value: 10, name: "10 คำสั่งซื้อ" },
          { value: 25, name: "25 คำสั่งซื้อ" },
          { value: 50, name: "50 คำสั่งซื้อ" },
          { value: 100, name: "100 คำสั่งซื้อ" },
          { value: 999999, name: "ทั้งหมด" },
        ],
        search_by: '',
        select_channel: { channelID: 0, channelName: "--ทุกช่องทาง--" },
        list_channel: [{ channelID: 0, channelName: "--ทุกช่องทาง--" }],
        select_express: { expressCompanyID: null, expressCompanyName: " -- ทั้งหมด --" },
        list_express: [{ expressCompanyID: null, expressCompanyName: " -- ทั้งหมด --", },],
        select_print: { id: null, name: " -- ทั้งหมด --" },
        list_print: [{ id: null, name: " -- ทั้งหมด --" }],
        select_topic: { id: 1, name: "เลขออเดอร์", value: "orderId" },
        select_header: [
          { id: 0, name: "เลขออเดอร์", value: "orderId" },
          { id: 1, name: "เลขอ้างอิง", value: "referenceNumber" },
          { id: 2, name: "ชื่อผู้รับ", value: "receiverName" },
        ],
        text_topic: "",

      }
    },
    dataset_cancel: {
      headers: [
        {
          text: "No",
          align: "center",
          sortable: false,
          value: "no",
          width: "70px",
        },
        {
          text: "ออเดอร์",
          align: "center",
          sortable: false,
          value: "id",
          width: "110px",
        },
        {
          text: "วันที่สร้าง",
          align: "center",
          sortable: false,
          value: "createdDatetime",
          width: "160px",
        },
        {
          text: "วันที่พร้อมแพ็ค",
          align: "center",
          sortable: false,
          value: "readyToPackDate",
          width: "160px",
        },
        {
          text: "ร้านค้า",
          align: "center",
          sortable: false,
          value: "shopName",
          width: "90px",
        },
        {
          text: "จำนวน SKU",
          align: "center",
          sortable: false,
          value: "skuCount",
          width: "90px",
        },
        {
          text: "จำนวนชิ้น",
          align: "center",
          sortable: false,
          value: "productCount",
          width: "90px",
        },
        {
          text: "ช่องทาง",
          align: "center",
          sortable: false,
          value: "channelName",
          width: "90px",
        },
        {
          text: "ขนส่ง",
          align: "center",
          sortable: false,
          value: "expressCompanyName",
          width: "90px",
        },
        {
          text: "เลขอ้างอิง",
          align: "left",
          sortable: false,
          value: "referenceNumber",
          width: "110px",
        },
        {
          text: "TrackingNo",
          align: "left",
          sortable: false,
          value: "trackingNo",
          width: "110px",
        },
        {
          text: "นัดรับ",
          align: "center",
          sortable: false,
          value: "isInit",
          width: "50px",
        },
        {
          text: "ใบแพ็ค",
          align: "center",
          sortable: false,
          value: "isPrintPackingList",
          width: "70px",
        },
        {
          text: "ใบปะหน้า",
          align: "center",
          sortable: false,
          value: "isPrintAWB",
          width: "70px",
        },
        {
          text: "น้ำหนัก",
          align: "center",
          sortable: false,
          value: "weight",
          width: "90px",
        },
        {
          text: "ขนาด",
          align: "center",
          sortable: false,
          value: "boxSize",
          width: "100px",
        },
        {
          text: "กล่อง",
          align: "center",
          sortable: false,
          value: "boxName",
          width: "100px",
        },
        {
          text: "กำลังแพ็ค",
          align: "center",
          sortable: false,
          value: "packedByStaffBranchName",
          width: "90px",
        },
        {
          text: "แพ็คเสร็จโดย",
          align: "center",
          sortable: false,
          value: "packedByStaffBranchName",
          width: "90px",
        },
        {
          text: "เริ่มแพ็ค",
          align: "center",
          sortable: false,
          value: "startPackingDate",
          width: "160px",
        },
        {
          text: "แพ็คเสร็จ",
          align: "center",
          sortable: false,
          value: "endPackingDate",
          width: "160px",
        },
        {
          text: "ลูกค้า",
          align: "left",
          sortable: false,
          value: "receiverName",
          width: "200px",
        },
      ],
      dataTable: [],
      page: 1,
      manageMenuListType: [{ name: "ย้อนสถานะ", btn: "btnPackageBackStatus" },
      { name: "รายงาน", btn: "btnViewReport" }],
      ManageChangeStatusList: [],
      ManageBackStatusList: [{ ID: 50, Name: "รอแพ็ค" }],
      offsets: 0,
      limits: 10,
      pageLength: 1,
      total: 0,
      selected: [],
      keyword: {},
      search: {
        select_per_page: 10,
        select_per: [
          { value: 5, name: "5 คำสั่งซื้อ" },
          { value: 10, name: "10 คำสั่งซื้อ" },
          { value: 25, name: "25 คำสั่งซื้อ" },
          { value: 50, name: "50 คำสั่งซื้อ" },
          { value: 100, name: "100 คำสั่งซื้อ" },
          { value: 999999, name: "ทั้งหมด" },
        ],
        search_by: '',
        select_channel: { channelID: 0, channelName: "--ทุกช่องทาง--" },
        list_channel: [{ channelID: 0, channelName: "--ทุกช่องทาง--" }],
        select_express: { expressCompanyID: null, expressCompanyName: " -- ทั้งหมด --" },
        list_express: [{ expressCompanyID: null, expressCompanyName: " -- ทั้งหมด --", },],
        select_print: { id: null, name: " -- ทั้งหมด --" },
        list_print: [{ id: null, name: " -- ทั้งหมด --" }],
        select_topic: { id: 1, name: "เลขออเดอร์", value: "orderId" },
        select_header: [
          { id: 0, name: "เลขออเดอร์", value: "orderId" },
          { id: 1, name: "เลขอ้างอิง", value: "referenceNumber" },
          { id: 2, name: "ชื่อผู้รับ", value: "receiverName" },
        ],
        text_topic: "",

      }
    },


    //กรองสินค้า
    filteOrderDataSet: {
      selected: [],
      filteOrderList: [],
      dataset: [],
      productList: [],
      mapProductList: [],
      tab_list: [
        { id: 0, name: "กรองสินค้า" },
        { id: 1, name: "จำนวน SKU ในออเดอร์" },
      ],
      tab: 0,
      header: [
        {
          text: "No",
          align: "center",
          sortable: false,
          value: "no",
          width: "70px",
        },
        {
          text: "ชื่อ",
          align: "left",
          sortable: false,
          value: "productName",
          width: "250px",
        },
        {
          text: "Barcode",
          align: "center",
          sortable: false,
          value: "barcode",
          width: "75px",
        },
        {
          text: "จำนวนออเดอร์ ",
          align: "center",
          sortable: false,
          value: "orderCount",
          width: "85px",
        },
        {
          text: "จำนวนต้องแพ็ค ",
          align: "center",
          sortable: false,
          value: "quantityToPack",
          width: "85px",
        },
        {
          text: "สต๊อกที่มี",
          align: "center",
          sortable: false,
          value: "currentQuantity",
          width: "85px",
        },
        {
          text: "ขาดสต๊อก",
          align: "center",
          sortable: false,
          value: "outStock",
          width: "85px",
        },
        {
          text: "SKU",
          align: "center",
          sortable: false,
          value: "sku",
          width: "90px",
        },
        {
          text: "รหัส CF",
          align: "center",
          sortable: false,
          value: "cfCode",
          width: "90px",
        },
        {
          text: "ชั้นวาง",
          align: "center",
          sortable: false,
          value: "rackNo",
          width: "90px",
        },
      ],
      headerExcel: [
        { label: "No", field: "no", width: "25" },
        { label: "ชื่อ", field: "productName", width: "10" },
        { label: "Barcode", field: "barcode", width: "10" },
        { label: "จำนวนออเดอร์", field: "orderCount", width: "10" },
        { label: "จำนวนต้องแพ็ค", field: "quantityToPack", width: "10" },
        { label: "สต๊อกที่มี", field: "currentQuantity", width: "10" },
        { label: "ขาดสต๊อก", field: "outStockShow", width: "10" },
        { label: "SKU", field: "sku", width: "10" },
        { label: "รหัส CF", field: "cfCode", width: "10" },
        { label: "ชั้นวาง", field: "rackNo", width: "10" },
      ],
      headerMapProductList: [
        {
          text: "จำนวน SKU",
          align: "center",
          sortable: false,
          value: "skuCount",
          width: "70px",
        },
        {
          text: "จำนวน ออเดอร์",
          align: "right",
          sortable: false,
          value: "orderCount",
          width: "100px",
        },
        {
          text: "ปริ้นใบหยิบแล้ว",
          align: "right",
          sortable: false,
          value: "PrintY",
          width: "100px",
        },
        {
          text: "ยังไม่ปริ้น",
          align: "right",
          sortable: false,
          value: "PrintN",
          width: "100px",
        },
      ],
      radio: [
        { id: 0, name: "ทั้งหมด" },
        { id: 1, name: "จาก" },
      ],
      radio_sku: 0,
      radio_item: 0,
      sku_start: 1,
      sku_end: 1,
      item_start: 1,
      item_end: 1,
    },
    filterOrderPopup: {
      dialog: false,
      MaxWidth: "80%",
      MaxHeight: 70,
    },
    //เปลี่ยนสถานะ
    changeStatusDataSet: {
      dataset: [],
      datasetError: [],
      datasetState: [],
      selected: [],
      productList: [],
      mapProductList: [],
      loading: false,
      loadingPercent: false,
      changeStatus: null,
      success: 0,
      error: 0,
      header: [
        {
          text: "เลขออเดอร์ ",
          align: "left",
          sortable: false,
          value: "orderIdText",
          width: "120px",
        },
        {
          text: "เลขอ้างอิง",
          align: "left",
          sortable: false,
          value: "referenceNumber",
          width: "120px",
        },
        {
          text: "ช่องทาง",
          align: "center",
          sortable: false,
          value: "referenceSourceLogo",
          width: "80px",
        },
        {
          text: "ขนส่ง",
          align: "center",
          sortable: false,
          value: "expressCompanyLogo",
          width: "80px",
        },
        {
          text: "TrackingNo ",
          align: "left",
          sortable: false,
          value: "trackingNo",
          width: "120px",
        },
        {
          text: "สถานะ",
          align: "left",
          sortable: false,
          value: "statusName",
          width: "100px",
        },
        {
          text: "การดำเนินงาน",
          align: "left",
          sortable: false,
          value: "processing",
          width: "150px",
        },
      ],
      headerState: [
        {
          text: "ลำดับ",
          align: "center",
          sortable: false,
          value: "no",
          width: "70px",
        },
        {
          text: "Order ID",
          align: "left",
          sortable: false,
          value: "orderMasterID",
          width: "80px",
        },
        {
          text: "สถานะดำเนินการ",
          align: "left",
          sortable: false,
          value: "name",
          width: "120px",
        },
        {
          text: "trackingNo",
          align: "center",
          sortable: false,
          value: "trackingNo",
          width: "75px",
        },
      ],
    },
    changeStatusPopup: {
      dialog: false,
      MaxWidth: "80%",
      MaxHeight: 70,
      tab_list: [
        { id: 0, name: "ดำเนินการ" },
        { id: 1, name: "ข้อมูลที่ไม่สำเร็จ" },
      ],
      tab: 0,
    },
    //ย้อนสถานะ
    backStatusDataSet: {
      dataset: [],
      datasetError: [],
      datasetState: [],
      selected: [],
      productList: [],
      mapProductList: [],
      loading: false,
      loadingPercent: false,
      changeStatus: null,
      success: 0,
      error: 0,
      header: [
        {
          text: "เลขออเดอร์ ",
          align: "left",
          sortable: false,
          value: "orderIdText",
          width: "120px",
        },
        {
          text: "เลขอ้างอิง",
          align: "left",
          sortable: false,
          value: "referenceNumber",
          width: "120px",
        },
        {
          text: "ช่องทาง",
          align: "center",
          sortable: false,
          value: "referenceSourceLogo",
          width: "80px",
        },
        {
          text: "ขนส่ง",
          align: "center",
          sortable: false,
          value: "expressCompanyLogo",
          width: "80px",
        },
        {
          text: "TrackingNo ",
          align: "left",
          sortable: false,
          value: "trackingNo",
          width: "120px",
        },
        {
          text: "สถานะ",
          align: "left",
          sortable: false,
          value: "statusName",
          width: "100px",
        },
        {
          text: "การดำเนินงาน",
          align: "left",
          sortable: false,
          value: "processing",
          width: "150px",
        },
      ],
      headerState: [
        {
          text: "ลำดับ",
          align: "center",
          sortable: false,
          value: "no",
          width: "70px",
        },
        {
          text: "Order ID",
          align: "left",
          sortable: false,
          value: "orderMasterID",
          width: "80px",
        },
        {
          text: "สถานะดำเนินการ",
          align: "left",
          sortable: false,
          value: "name",
          width: "120px",
        },
        {
          text: "trackingNo",
          align: "center",
          sortable: false,
          value: "trackingNo",
          width: "75px",
        },
      ],
    },
    backStatusPopup: {
      dialog: false,
      MaxWidth: "60%",
      MaxHeight: 70,
      tab_list: [
        { id: 0, name: "ดำเนินการ" },
        { id: 1, name: "ข้อมูลที่ไม่สำเร็จ" },
      ],
      tab: 0,
    },
    //อัพเดทไปmarketplace 
    UpdateToMarketplaceDataSet: {
      dataset: [],
      datasetError: [],
      datasetState: [],
      selected: [],
      productList: [],
      mapProductList: [],
      loading: false,
      loadingPercent: false,
      changeStatus: null,
      dropoff: null,
      pickup: null,
      addressID: null,
      selectedAddress: [],
      selectedpickupTime: [],
      pickupTimeID: [],
      shopeeType: 0,
      tiktokType: 0,
      success: 0,
      error: 0,
      handover_method: null,
      header: [
        {
          text: "ออเดอร์ ",
          align: "left",
          sortable: false,
          value: "orderMasterID",
        },
        {
          text: "เลขอ้างอิง",
          align: "left",
          sortable: false,
          value: "referenceNumber",
        },
        {
          text: "ช่องทาง",
          align: "left",
          sortable: false,
          value: "referenceSourceLogo",
        },
        {
          text: "บริการขนส่ง",
          align: "left",
          sortable: false,
          value: "expressCompanyLogo",
        },
        {
          text: "TrackingNo",
          align: "left",
          sortable: false,
          value: "trackingNo",
        },
        {
          text: "สถานะ",
          align: "left",
          sortable: false,
          value: "processing",
        },
      ],
    },
    UpdateToMarketplacePopup: {
      dialog: false,
      MaxWidth: "60%",
      MaxHeight: 70,
      marketplaceName: null,
      marketplaceType: 0,
      tab_list: [
        { id: 0, name: "ดำเนินการ" },
        { id: 1, name: "ข้อมูลที่ไม่สำเร็จ" },
      ],
      tab: 0,
    },
    //รายงาน
    reportOrderDataSet: {
      dataset: [],
      selected: [],
      productList: [],
      mapProductList: [],
      headerIN: [
        {
          text: "No",
          align: "center",
          sortable: false,
          value: "no",
          width: "70px",
          label: "No",
          field: "no",
        },
        {
          text: "รูป",
          align: "left",
          sortable: false,
          value: "photoLink",

          label: "รูป",
          field: "photoLink",
        },
        {
          text: "ชื่อ",
          align: "left",
          sortable: false,
          value: "name",

          label: "ชื่อ",
          field: "name",
        },
        {
          text: "จำนวน",
          align: "center",
          sortable: false,
          value: "quantity",
          width: "75px",
          label: "จำนวน",
          field: "quantity",
        },
        {
          text: "SKU",
          align: "center",
          sortable: false,
          value: "sku",
          width: "85px",
          label: "SKU",
          field: "sku",
        },
        {
          text: "barcode",
          align: "center",
          sortable: false,
          value: "barcode",
          width: "85px",
          label: "barcode",
          field: "barcode",
        },
        {
          text: "รหัส CF",
          align: "center",
          sortable: false,
          value: "cfCode",
          width: "90px",
          label: "รหัส CF",
          field: "cfCode",
        },
        {
          text: "ชั้นวาง",
          align: "center",
          sortable: false,
          value: "rackNo",
          width: "90px",
          label: "ชั้นวาง",
          field: "rackNo",
        },
      ],
      header: [],
    },
    reportOrderPopup: {
      dialog: false,
      MaxWidth: "50%",
      MaxHeight: 70,
    },
  }),
  computed: {
    showSubheader() {
      var data = this.tabListSubHeaders.find(s => s.id == this.set_TabListHeader)
      if (data != undefined) {
        return data.subItems
      } else {
        return []
      }

    },
    countreportOrderDataSet() {
      if (this.reportOrderDataSet.dataset.length > 0) {
        const totalQuantity = this.reportOrderDataSet.dataset.reduce((total, product) => {
          if (product.quantity !== undefined && product.quantity !== null) {
            return total + product.quantity;
          } else {
            return total;
          }
        }, 0);

        return totalQuantity;
      } else {
        return 0;
      }
    },
  },
  watch: {

  },

  async created() {

    this.use_language = await this.get_languages(await localStorage.getItem("set_language"));
    await this.Load_Data();
    await this.map_tab_list_herder();

    this.page_loading = false
    // this.page_loading= false
  },
  methods: {
    OpenPhoto(Photo) {
      this.ShowPhotoLink = Photo;
      this.ShowPhoto = true;
    },
    // เรียกใช้
    ClosePhoto(value) {
      this.ShowPhoto = value;
      this.ShowPhotoLink = "";
    },
    ShowDetailOrder(orderToken) {
      window.open("/order-detail?token=" + orderToken);
    },

    //ส่วนของ API
    async Load_Data() {
      await axios
        .all([
          axios.post(
            shopService_dotnet + "Shop/get-warehouse-list",
            { shopId: parseInt(localStorage.getItem("shop_id")) },
            { headers: this.header_token }
          ),
          axios.post(
            branchService_dotnet + "Branch/get-branch-single",
            { branchId: parseInt(localStorage.getItem("branch_id")) },
            { headers: this.header_token }
          ),
        ])
        .then(
          axios.spread((warehouseID, getBranchID) => {

            if (warehouseID.status == 200 && getBranchID.status == 200) {
              // ดึง warehouseID จาก BranchID
              this.warehouse_list = [{ id: 0, name: "--ทั้งหมด--" }];
              this.warehouse_list.push(...warehouseID.data);
              this.warehouse_select = { id: 0, name: "--ทั้งหมด--" };
              // ดึง branch จาก BranchID

            } else {
              this.AlertError();
            }
          })
        );
    },
    async get_tab_list(warehouse_select) {
      return await axios.post(orderService_dotnet + 'OrderPackage/get-tab-list', {
        warehouseIDList: warehouse_select,
        shopId: parseInt(localStorage.getItem("shop_id")),
      }, { headers: this.header_token })
        .then(res => {
          return res.data
        }).catch(error => {
          return error;
        })
    },
    async SearchOrder(index, limits, packageIDList, keyword, warehouseIDList) {
      // console.log(this.search.keyword);
      return await axios
        .post(
          orderService_dotnet + "OrderPackage/search-package-packing-list",
          {
            shopId: parseInt(localStorage.getItem("shop_id")),
            skip: index,
            take: limits,
            keyword: keyword,
            packageIDList: packageIDList,
            isNeedChannelGroup: index == 0 ? true : false,
            warehouseIDList: warehouseIDList,
          },
          { headers: this.header_token }
        )
        .then((res) => {
          if (res.data.status) {
            return res.data
          } else {
            this.loading = false;
            this.AlertError();
            // this.SearchOrder(index,limits,changeTab)
          }

          return null;
        })
        .catch((error) => {
          this.loading = false;
          this.AlertError();
          return error;
        });


    },
    async search_package_packing_list() {
      return await axios.post(orderService_dotnet + 'OrderPackage/search-package-packing-list', {
        warehouseIDList: this.warehouse_select,
        shopId: parseInt(localStorage.getItem("shop_id")),
      }, { headers: this.header_token })
        .then(res => {
          console.log("res", res);
          return res.data
        }).catch(error => {
          return error;
        })
    },
    async getFilterOrder(packageIDList) {
      return await axios
        .post(
          orderService_dotnet + "Package/get-product-tobe-filter",
          {
            packageIDList: packageIDList,
          },
          { headers: this.header_token }
        )
        .then((res) => {
          return (res.data.data = res.data.productList);
        })
        .catch((error) => {
          return null;
        });
    },
    async getPreparePackageBeforeChangeStatus(packageIDList) {
      return await axios
        .post(
          orderService_dotnet + "Package/prepare-package-before-change-status",
          {
            packageIDList: packageIDList,
          },
          { headers: this.header_token }
        )
        .then(async (res) => {
          if (res.data.status == "success" && res.data.error_description == null) {
            return await res.data;
          } else {
            this.AlertError(res.data.error_description);
            return null;
          }
        })
        .catch((error) => {
          this.loading = false;
          this.AlertError();
          return null;
        });
    },
    async changePackageStatus(packageID, packageStatusID) {
      return await axios
        .post(
          orderService_dotnet + "Package/change-package-status",
          {
            packageID: packageID,
            packageStatusID: packageStatusID,
            staffBranchID: this.staffBranchId,
          },
          { headers: this.header_token }
        )
        .then(async (res) => {
          if (res.data.status == "success" && res.data.error_description == null) {
            return await res.data;
          } else {
            //  this.AlertError(res.data.error_description);
            return res.data;
          }
        })
        .catch((error) => {
          this.loading = false;
          this.AlertError();
          return null;
        });
    },
    async prepareUpdateToMarketplace(packageIDList) {
      return await axios
        .post(
          ecomService_dotnet + "Order/prepare-rts",
          {
            packageIDList: packageIDList,
          },
          { headers: this.header_token }
        )
        .then(async (res) => {
          if (res.data.status == "success" && res.data.error_description == null) {
            return await res.data;
          } else {
            this.AlertError(await res.data.error_description);
            return res.data;
          }
        })
        .catch((error) => {
          this.loading = false;
          this.AlertError();
          return null;
        });
    },
    async getAllReportMapProduct(packageIDList) {
      return await axios
        .post(
          orderService_dotnet + "Package/get-all-to-be-mapped-product",
          {
            packageIDList: packageIDList,
          },
          { headers: this.header_token }
        )
        .then(async (res) => {
          if (res.data.status == "success" && res.data.error_message == null) {
            return await res.data;
          } else {
            this.AlertError(res.data.error_message);
            return null;
          }
        })
        .catch((error) => {
          this.loading = false;
          this.AlertError();
          return null;
        });
    },
    async getAllViewReportPackd(body) {
      return await axios
        .post(
          orderService_dotnet + "Package/view-product-to-be-packed",
          {
            packageIDList: body.packageIDList,
            selectedStockShopIDList: body.selectedStockShopIDList,
            notSelectedStockShopIDList: body.notSelectedStockShopIDList,
            skuFrom: body.skuFrom,
            skuTo: body.skuTo,
            quantityFrom: body.quantityFrom,
            quantityTo: body.quantityTo,
          },
          { headers: this.header_token }
        )
        .then(async (res) => {
          if (res.data.status == "success" && res.data.error_message == null) {
            return await res.data;
          } else {
            this.AlertError(res.data.error_message);
            return null;
          }
        })
        .catch((error) => {
          this.loading = false;
          this.AlertError();
          return null;
        });
    },

    // ส่วนของ function
    async map_tab_list_herder() {
      var warehouse_select
      if (this.warehouse_select.id == 0) {
        var warehouse_listNotZero = this.warehouse_list.filter(w => w.id != 0)
        warehouse_select = warehouse_listNotZero.map(w => w.id)
      } else {
        warehouse_select = [this.warehouse_select.id]
      }
      var tab = await this.get_tab_list(warehouse_select);
      this.tabListHeaders = tab.masterItems
      this.tabListSubHeaders = []
      for (var item of tab.masterItems) {
        var subItem = [];

        if (item.subItems != null) {


          subItem.id = item.id
          subItem.subItems = item.subItems
          this.tabListSubHeaders.push(subItem);

        }

      }
    },
    async map_tab_list_herder_1() {
      var warehouse_select
      if (this.warehouse_select.id == 0) {
        var warehouse_listNotZero = this.warehouse_list.filter(w => w.id != 0)
        return warehouse_listNotZero.map(w => w.id)
      } else {
        return [this.warehouse_select.id]
      }
    },
    async ChangeTab(tab) {

      var tab_select = this.tabListHeaders.find(t => t.id == tab)
      var data, keyword;

      var warehouse_select = await this.map_tab_list_herder_1();
      console.log(warehouse_select);

      if (tab == '0' && !tab_select.isLoaded) {
        tab_select.isLoaded = true;
        console.log('0', tab_select);
      }
      else if (tab == '1-5' && !tab_select.isLoaded) {
        this.resetDefaultTab('dataset_wait_payment_wait_payment');


        tab_select.isLoaded = true;
        keyword = { packageStatusID: "1" };
        this.dataset_wait_payment_wait_payment.keyword = keyword;

        data = await this.SearchOrder(0, 10, null, keyword, warehouse_select);
        this.setDataTable_wait_payment_wait_payment(data, '100');
        this.isTrueIsSubloaded(tab_select, "1");

        console.log('1-5', tab_select);
      }
      else if (tab == '10-20-30-40-60-80' && !tab_select.isLoaded) {
        this.resetDefaultTab('dataset_process_map_product');

        tab_select.isLoaded = true;
        keyword = { packageStatusID: "10" };
        this.dataset_process_map_product.keyword = keyword;

        data = await this.SearchOrder(0, 10, null, keyword, warehouse_select);
        this.setDataTable_process_map_product(data, '10');
        this.isTrueIsSubloaded(tab_select, "10");

      }
      else if (tab == '90' && !tab_select.isLoaded) {
        this.resetDefaultTab('dataset_delivering');

        tab_select.isLoaded = true;
        keyword = { packageStatusID: "90" };
        this.dataset_delivering.keyword = keyword;

        data = await this.SearchOrder(0, 10, null, keyword, warehouse_select);
        this.setDataTable_dataset_delivering(data, '90');
        console.log('90', tab_select);
      }
      else if (tab == '130' && !tab_select.isLoaded) {
        this.resetDefaultTab('dataset_success');

        tab_select.isLoaded = true;
        keyword = { packageStatusID: "130" };
        this.dataset_success.keyword = keyword;

        data = await this.SearchOrder(0, 10, null, keyword, warehouse_select);
        this.setDataTable_dataset_success(data, '130');
        console.log('130', tab_select);
      }
      else if (tab == '100-110-120' && !tab_select.isLoaded) {
        this.resetDefaultTab('dataset_Return_Returnning');

        tab_select.isLoaded = true;
        keyword = { packageStatusID: "100" };
        this.dataset_Return_Returnning.keyword = keyword;

        data = await this.SearchOrder(0, 10, null, keyword, warehouse_select);
        this.setDataTable_Return_Returnning(data, '100');
        this.isTrueIsSubloaded(tab_select, "100");
        console.log('100-110-120', tab_select);
      }
      else if (tab == '140' && !tab_select.isLoaded) {
        this.resetDefaultTab('dataset_cancel');

        tab_select.isLoaded = true;
        keyword = { packageStatusID: "140" };
        this.dataset_cancel.keyword = keyword;

        data = await this.SearchOrder(0, 10, null, keyword, warehouse_select);
        this.setDataTable_dataset_cancel(data, '140');
        console.log('140', tab_select);
      }

    },
    async ChangeTabSub(tab) {
      var tab_select = this.set_TabListHeader;
      var tabSub = await this.tabListSubHeaders.find(s => s.id == tab_select);
      var tabSub_select = await tabSub.subItems.find(ts => ts.id == tab)
      var data, keyword;

      var warehouse_select = await this.map_tab_list_herder_1();

      if (tab == '1' && !tabSub_select.isLoaded) {
        this.resetDefaultTab('dataset_wait_payment_wait_payment');

        keyword = { packageStatusID: "1" };
        data = await this.SearchOrder(0, 10, null, keyword, warehouse_select);
        this.setDataTable_wait_payment_wait_payment(data, '1');
        tabSub_select.isLoaded = true
        this.dataset_wait_payment_wait_payment.keyword = keyword;

        console.log('1', tabSub_select);
      }
      else if (tab == '5' && !tabSub_select.isLoaded) {
        this.resetDefaultTab('dataset_wait_payment_check_payment');

        keyword = { packageStatusID: "5" };
        data = await this.SearchOrder(0, 10, null, keyword, warehouse_select);
        this.setDataTable_wait_payment_check_payment(data, '5');
        tabSub_select.isLoaded = true
        this.dataset_wait_payment_check_payment.keyword = keyword;

        console.log('5', tabSub_select);
      }
      else if (tab == '10' && !tabSub_select.isLoaded) {
        this.resetDefaultTab('dataset_process_map_product');

        keyword = { packageStatusID: "10" };
        data = await this.SearchOrder(0, 10, null, keyword, warehouse_select);
        this.setDataTable_process_map_product(data, '10');
        tabSub_select.isLoaded = true
        this.dataset_process_map_product.keyword = keyword;

        console.log('10', tabSub_select);
      }
      else if (tab == '20' && !tabSub_select.isLoaded) {
        this.resetDefaultTab('dataset_process_allocate_stock');

        keyword = { packageStatusID: "20" };
        data = await this.SearchOrder(0, 10, null, keyword, warehouse_select);
        this.setDataTable_process_allocate_stock(data, '20');
        tabSub_select.isLoaded = true
        this.dataset_process_allocate_stock.keyword = keyword;

        console.log('20', tabSub_select);
      }
      else if (tab == '30' && !tabSub_select.isLoaded) {
        this.resetDefaultTab('dataset_process_ready_to_ship');

        keyword = { packageStatusID: "30" };
        data = await this.SearchOrder(0, 10, null, keyword, warehouse_select);
        this.setDataTable_process_ready_to_ship(data, '30');
        tabSub_select.isLoaded = true
        this.dataset_process_ready_to_ship.keyword = keyword;

        console.log('30', tabSub_select);
      }
      else if (tab == '40' && !tabSub_select.isLoaded) {
        this.resetDefaultTab('dataset_process_to_pack');

        keyword = { packageStatusID: "40" };
        data = await this.SearchOrder(0, 10, null, keyword, warehouse_select);
        this.setDataTable_process_to_pack(data, '40');
        tabSub_select.isLoaded = true
        this.dataset_process_to_pack.keyword = keyword;

        console.log('40', tabSub_select);
      }
      else if (tab == '60' && !tabSub_select.isLoaded) {
        this.resetDefaultTab('dataset_process_packing');

        keyword = { packageStatusID: "60" };
        data = await this.SearchOrder(0, 10, null, keyword, warehouse_select);
        this.setDataTable_process_packing(data, '60');
        tabSub_select.isLoaded = true
        this.dataset_process_packing.keyword = keyword;

        console.log('60', tabSub_select);
      }
      else if (tab == '80' && !tabSub_select.isLoaded) {
        this.resetDefaultTab('dataset_process_wait_pickup');

        keyword = { packageStatusID: "80" };
        data = await this.SearchOrder(0, 10, null, keyword, warehouse_select);
        this.setDataTable_process_wait_pickup(data, '5');
        tabSub_select.isLoaded = true
        this.dataset_process_wait_pickup.keyword = keyword;

        console.log('80', tabSub_select);
      }
      else if (tab == '100' && !tabSub_select.isLoaded) {
        this.resetDefaultTab('dataset_Return_Returnning');

        keyword = { packageStatusID: "100" };
        data = await this.SearchOrder(0, 10, null, keyword, warehouse_select);
        this.setDataTable_Return_Returnning(data, '100');
        tabSub_select.isLoaded = true
        this.dataset_Return_Returnning.keyword = keyword;

        console.log('100', tabSub_select);
      }
      else if (tab == '110' && !tabSub_select.isLoaded) {
        this.resetDefaultTab('dataset_Return_To_Return_Stock');

        keyword = { packageStatusID: "110" };
        data = await this.SearchOrder(0, 10, null, keyword, warehouse_select);
        this.setDataTable_Return_To_Return_Stock(data, '100');
        tabSub_select.isLoaded = true
        this.dataset_Return_To_Return_Stock.keyword = keyword;

        console.log('110', tabSub_select);
      }
      else if (tab == '120' && !tabSub_select.isLoaded) {
        this.resetDefaultTab('dataset_Return_Stock_Returned');

        keyword = { packageStatusID: "120" };
        data = await this.SearchOrder(0, 10, null, keyword, warehouse_select);
        this.setDataTable_Return_Stock_Returned(data, '120');
        tabSub_select.isLoaded = true
        this.dataset_Return_Stock_Returned.keyword = keyword;

        console.log('120', tabSub_select);
      }




    },
    async isTrueIsSubloaded(tab_select, id) {
      var tabSub = await this.tabListSubHeaders.find(s => s.id == tab_select.id);
      var tabSub_select = await tabSub.subItems.find(ts => ts.id == id)
      tabSub_select.isLoaded = true;
    },
    async handleClickOutside(item, tab) {
      console.log("handleClickOutsideitem", item);
      console.log("handleClickOutsidetab", tab);

      if (item.btn && typeof this[item.btn] === "function") {
        this[item.btn](tab);
      }
    },
    async resetDefaultTab(NameTab) {
      // ใช้ชื่อที่รับเป็นตัวชี้
      console.log("resetDefaultTab", NameTab);
      this[NameTab].page = 1;
      this[NameTab].offsets = 0;
      this[NameTab].limits = 10;
      this[NameTab].search.text_topic = "";
      this[NameTab].search.select_topic = { id: 1, name: "เลขออเดอร์", value: "orderId" };
    },
    async getPercentLoading(Round, countLoop) {
      this.percentLoading = 0;
      // let countLoop = 0; //จำนวนที่วนรอบ
      // let Round  =0; //จำนวนปัจจุบัน
      this.percentLoading = parseInt((Round / countLoop) * 100);
      if (this.percentLoading == 100) {
        this.percentLoadingText = "สำเร็จ";
      } else if (this.percentLoading > 0) {
        this.percentLoadingText = "กำลังโหลด";
      } else {
        this.percentLoadingText = "รอโหลด";
      }
    },
    async switchSubCaseChngeDataTable(id) {
      switch (id) {
        case '1':
          return this.dataset_wait_payment_wait_payment

        case '5':
          return this.dataset_wait_payment_check_payment

        case '10':
          return this.dataset_process_map_product

        case '20':
          return this.dataset_process_allocate_stock

        case '30':
          return this.dataset_process_ready_to_ship

        case '40':
          return this.dataset_process_to_pack

        case '60':
          return this.dataset_process_packing

        case '80':
          return this.dataset_process_wait_pickup

        case '100':
          return this.dataset_Return_Returnning

        case '110':
          return this.dataset_Return_To_Return_Stock

        case '120':
          return this.dataset_Return_Stock_Returned


      }
    },
    async getDataTableFilterSetSelected(tab) {

      var data, keyword, item, total, tabDataset;

      var warehouse_select = await this.map_tab_list_herder_1();

      if (tab == '1') { return tabDataset = this.dataset_wait_payment_wait_payment.selected }
      else if (tab == '5') { return tabDataset = this.dataset_wait_payment_check_payment.selected }
      else if (tab == '10') { return tabDataset = this.dataset_process_map_product.selected }
      else if (tab == '20') { return tabDataset = this.dataset_process_allocate_stock.selected }
      else if (tab == '30') { return tabDataset = this.dataset_process_ready_to_ship.selected }
      else if (tab == '40') { return tabDataset = this.dataset_process_to_pack.selected }
      else if (tab == '60') { return tabDataset = this.dataset_process_packing.selected }
      else if (tab == '80') { return tabDataset = this.dataset_process_wait_pickup.selected }
      else if (tab == '90') { return tabDataset = this.dataset_delivering.selected }
      else if (tab == '130') { return tabDataset = this.dataset_success.selected }
      else if (tab == '100') { return tabDataset = this.dataset_Return_Returnning.selected }
      else if (tab == '110') { return tabDataset = this.dataset_Return_To_Return_Stock.selected }
      else if (tab == '120') { return tabDataset = this.dataset_Return_Stock_Returned.selected }
      else if (tab == '140') { return tabDataset = this.dataset_cancel.selected }

      return tabDataset;

    },
    async getNameTab() {
      var checkMainTab = this.tabListHeaders.find(t => t.id == this.set_TabListHeader)
      var checkSubTab, tab, tabName;

      if (checkMainTab.subItems == null) {
        tab = checkMainTab.id;
      } else {
        if (checkMainTab.id == '1-5') {
          checkSubTab = await checkMainTab.subItems.find(sub => sub.id = this.set_TabListSubHeader_wait_payment);
          tab = checkSubTab.id;
        }
        else if (checkMainTab.id == '10-20-30-40-60-80') {
          checkSubTab = await checkMainTab.subItems.find(s => s.id == this.set_TabListSubHeader_Process);
          tab = checkSubTab.id;
        }
        else if (checkMainTab.id == '100-110-120') {
          checkSubTab = await checkMainTab.subItems.find(sub => sub.id = this.set_TabListSubHeader_Return);
          tab = checkSubTab.id;
        }
      }
      if (tab == '1') { tabName = "dataset_wait_payment_wait_payment" }
      else if (tab == '5') { tabName = "dataset_wait_payment_check_payment" }
      else if (tab == '10') { tabName = "dataset_process_map_product" }
      else if (tab == '20') { tabName = "dataset_process_allocate_stock" }
      else if (tab == '30') { tabName = "dataset_process_ready_to_ship" }
      else if (tab == '40') { tabName = "dataset_process_to_pack" }
      else if (tab == '60') { tabName = "dataset_process_packing" }
      else if (tab == '80') { tabName = "dataset_process_wait_pickup" }
      else if (tab == '90') { tabName = "dataset_delivering" }
      else if (tab == '100') { tabName = "dataset_Return_Returnning" }
      else if (tab == '110') { tabName = "dataset_Return_To_Return_Stock" }
      else if (tab == '120') { tabName = "dataset_Return_Stock_Returned" }
      else if (tab == '130') { tabName = "dataset_success" }
      else if (tab == '140') { tabName = "dataset_cancel" }
      return tabName;
    },
    //dataset_wait_payment
    async setDataTable_wait_payment_wait_payment(data, id) {

      // groupExpress
      if (data.groupExpress != null) {
        var exprees = [];
        this.dataset_wait_payment_wait_payment.search.list_express = [];
        this.dataset_wait_payment_wait_payment.search.select_express = ({ expressCompanyID: undefined, expressCompanyName: " -- ทั้งหมด --", });
        exprees.push({ expressCompanyID: undefined, expressCompanyName: " -- ทั้งหมด --", });
        exprees.push(...data.groupExpress);
        console.log("exprees", exprees);
        this.dataset_wait_payment_wait_payment.search.list_express = exprees;
      }
      // channelSourceList
      if (data.groupChannel != null) {
        var channelSource = [];
        this.dataset_wait_payment_wait_payment.search.list_channel = [];
        this.dataset_wait_payment_wait_payment.search.select_channel = { channelID: null, channelName: "--ทุกช่องทาง--", }
        channelSource.push({ channelID: null, channelName: "--ทุกช่องทาง--", });
        channelSource.push(...data.groupChannel)
        this.dataset_wait_payment_wait_payment.search.list_channel = channelSource;

      }
      // // groupPrint
      if (data.groupPrint != null) {
        var groupPrintList = []
        this.dataset_wait_payment_wait_payment.search.list_print = [];
        this.dataset_wait_payment_wait_payment.search.select_print = { id: null, name: " -- ทั้งหมด --" };
        groupPrintList.push({ id: null, name: " -- ทั้งหมด --", });
        groupPrintList.push(...data.groupPrint);
        console.log("data.groupPrint", data.groupPrint);
        this.dataset_wait_payment_wait_payment.search.list_print = groupPrintList;
      }
      if (data.resultData != null) {
        this.dataset_wait_payment_wait_payment.dataTable = data.resultData
        this.dataset_wait_payment_wait_payment.total = data.resultCount;
        this.dataset_wait_payment_wait_payment.pageLength = Math.ceil(this.dataset_wait_payment_wait_payment.total / this.dataset_process_map_product.limits);
      }

    },
    async setDataTable_wait_payment_check_payment(data, id) {

      // groupExpress
      if (data.groupExpress != null) {
        var exprees = [];
        this.dataset_wait_payment_check_payment.search.list_express = [];
        this.dataset_wait_payment_check_payment.search.select_express = ({ expressCompanyID: undefined, expressCompanyName: " -- ทั้งหมด --", });
        exprees.push({ expressCompanyID: undefined, expressCompanyName: " -- ทั้งหมด --", });
        exprees.push(...data.groupExpress);
        console.log("exprees", exprees);
        this.dataset_wait_payment_check_payment.search.list_express = exprees;
      }
      // channelSourceList
      if (data.groupChannel != null) {
        var channelSource = [];
        this.dataset_wait_payment_check_payment.search.list_channel = [];
        this.dataset_wait_payment_check_payment.search.select_channel = { channelID: null, channelName: "--ทุกช่องทาง--", }
        channelSource.push({ channelID: null, channelName: "--ทุกช่องทาง--", });
        channelSource.push(...data.groupChannel)
        this.dataset_wait_payment_check_payment.search.list_channel = channelSource;

      }
      // // groupPrint
      if (data.groupPrint != null) {
        var groupPrintList = []
        this.dataset_wait_payment_check_payment.search.list_print = [];
        this.dataset_wait_payment_check_payment.search.select_print = { id: null, name: " -- ทั้งหมด --" };
        groupPrintList.push({ id: null, name: " -- ทั้งหมด --", });
        groupPrintList.push(...data.groupPrint);
        console.log("data.groupPrint", data.groupPrint);
        this.dataset_wait_payment_check_payment.search.list_print = groupPrintList;
      }
      if (data.resultData != null) {
        this.dataset_wait_payment_check_payment.dataTable = data.resultData
        this.dataset_wait_payment_check_payment.total = data.resultCount;
        this.dataset_wait_payment_check_payment.pageLength = Math.ceil(this.dataset_wait_payment_check_payment.total / this.dataset_wait_payment_check_payment.limits);
      }

    },
    //dataset_process
    async setDataTable_process_map_product(data, id) {

      // groupExpress
      if (data.groupExpress != null) {
        var exprees = [];
        this.dataset_process_map_product.search.list_express = [];
        this.dataset_process_map_product.search.select_express = ({ expressCompanyID: undefined, expressCompanyName: " -- ทั้งหมด --", });
        exprees.push({ expressCompanyID: undefined, expressCompanyName: " -- ทั้งหมด --", });
        exprees.push(...data.groupExpress);
        console.log("exprees", exprees);
        this.dataset_process_map_product.search.list_express = exprees;
      }
      // channelSourceList
      if (data.groupChannel != null) {
        var channelSource = [];
        this.dataset_process_map_product.search.list_channel = [];
        this.dataset_process_map_product.search.select_channel = { channelID: null, channelName: "--ทุกช่องทาง--", }
        channelSource.push({ channelID: null, channelName: "--ทุกช่องทาง--", });
        channelSource.push(...data.groupChannel)
        this.dataset_process_map_product.search.list_channel = channelSource;

      }
      // // groupPrint
      if (data.groupPrint != null) {
        var groupPrintList = []
        this.dataset_process_map_product.search.list_print = [];
        this.dataset_process_map_product.search.select_print = { id: null, name: " -- ทั้งหมด --" };
        groupPrintList.push({ id: null, name: " -- ทั้งหมด --", });
        groupPrintList.push(...data.groupPrint);
        console.log("data.groupPrint", data.groupPrint);
        this.dataset_process_map_product.search.list_print = groupPrintList;
      }
      if (data.resultData != null) {
        this.dataset_process_map_product.dataTable = data.resultData
        this.dataset_process_map_product.total = data.resultCount;
        this.dataset_process_map_product.pageLength = Math.ceil(this.dataset_process_map_product.total / this.dataset_process_map_product.limits);
      }

    },
    async setDataTable_process_allocate_stock(data, id) {

      // groupExpress
      if (data.groupExpress != null) {
        var exprees = [];
        this.dataset_process_allocate_stock.search.list_express = [];
        this.dataset_process_allocate_stock.search.select_express = ({ expressCompanyID: undefined, expressCompanyName: " -- ทั้งหมด --", });
        exprees.push({ expressCompanyID: undefined, expressCompanyName: " -- ทั้งหมด --", });
        exprees.push(...data.groupExpress);
        console.log("exprees", exprees);
        this.dataset_process_allocate_stock.search.list_express = exprees;
      }
      // channelSourceList
      if (data.groupChannel != null) {
        var channelSource = [];
        this.dataset_process_allocate_stock.search.list_channel = [];
        this.dataset_process_allocate_stock.search.select_channel = { channelID: null, channelName: "--ทุกช่องทาง--", }
        channelSource.push({ channelID: null, channelName: "--ทุกช่องทาง--", });
        channelSource.push(...data.groupChannel)
        this.dataset_process_allocate_stock.search.list_channel = channelSource;

      }
      // // groupPrint
      if (data.groupPrint != null) {
        var groupPrintList = []
        this.dataset_process_allocate_stock.search.list_print = [];
        this.dataset_process_allocate_stock.search.select_print = { id: null, name: " -- ทั้งหมด --" };
        groupPrintList.push({ id: null, name: " -- ทั้งหมด --", });
        groupPrintList.push(...data.groupPrint);
        console.log("data.groupPrint", data.groupPrint);
        this.dataset_process_allocate_stock.search.list_print = groupPrintList;
      }
      if (data.resultData != null) {
        this.dataset_process_allocate_stock.dataTable = data.resultData
        this.dataset_process_allocate_stock.total = data.resultCount;
        this.dataset_process_allocate_stock.pageLength = Math.ceil(this.dataset_process_allocate_stock.total / this.dataset_process_allocate_stock.limits);
      }

    },
    async setDataTable_process_ready_to_ship(data, id) {

      // groupExpress
      if (data.groupExpress != null) {
        var exprees = [];
        this.dataset_process_ready_to_ship.search.list_express = [];
        this.dataset_process_ready_to_ship.search.select_express = ({ expressCompanyID: undefined, expressCompanyName: " -- ทั้งหมด --", });
        exprees.push({ expressCompanyID: undefined, expressCompanyName: " -- ทั้งหมด --", });
        exprees.push(...data.groupExpress);
        console.log("exprees", exprees);
        this.dataset_process_ready_to_ship.search.list_express = exprees;
      }
      // channelSourceList
      if (data.groupChannel != null) {
        var channelSource = [];
        this.dataset_process_ready_to_ship.search.list_channel = [];
        this.dataset_process_ready_to_ship.search.select_channel = { channelID: null, channelName: "--ทุกช่องทาง--", }
        channelSource.push({ channelID: null, channelName: "--ทุกช่องทาง--", });
        channelSource.push(...data.groupChannel)
        this.dataset_process_ready_to_ship.search.list_channel = channelSource;

      }
      // // groupPrint
      if (data.groupPrint != null) {
        var groupPrintList = []
        this.dataset_process_ready_to_ship.search.list_print = [];
        this.dataset_process_ready_to_ship.search.select_print = { id: null, name: " -- ทั้งหมด --" };
        groupPrintList.push({ id: null, name: " -- ทั้งหมด --", });
        groupPrintList.push(...data.groupPrint);
        console.log("data.groupPrint", data.groupPrint);
        this.dataset_process_ready_to_ship.search.list_print = groupPrintList;
      }
      if (data.resultData != null) {
        this.dataset_process_ready_to_ship.dataTable = data.resultData
        this.dataset_process_ready_to_ship.total = data.resultCount;
        this.dataset_process_ready_to_ship.pageLength = Math.ceil(this.dataset_process_ready_to_ship.total / this.dataset_process_ready_to_ship.limits);
      }

    },
    async setDataTable_process_to_pack(data, id) {

      // groupExpress
      if (data.groupExpress != null) {
        var exprees = [];
        this.dataset_process_to_pack.search.list_express = [];
        this.dataset_process_to_pack.search.select_express = ({ expressCompanyID: undefined, expressCompanyName: " -- ทั้งหมด --", });
        exprees.push({ expressCompanyID: undefined, expressCompanyName: " -- ทั้งหมด --", });
        exprees.push(...data.groupExpress);
        console.log("exprees", exprees);
        this.dataset_process_to_pack.search.list_express = exprees;
      }
      // channelSourceList
      if (data.groupChannel != null) {
        var channelSource = [];
        this.dataset_process_to_pack.search.list_channel = [];
        this.dataset_process_to_pack.search.select_channel = { channelID: null, channelName: "--ทุกช่องทาง--", }
        channelSource.push({ channelID: null, channelName: "--ทุกช่องทาง--", });
        channelSource.push(...data.groupChannel)
        this.dataset_process_to_pack.search.list_channel = channelSource;

      }
      // // groupPrint
      if (data.groupPrint != null) {
        var groupPrintList = []
        this.dataset_process_to_pack.search.list_print = [];
        this.dataset_process_to_pack.search.select_print = { id: null, name: " -- ทั้งหมด --" };
        groupPrintList.push({ id: null, name: " -- ทั้งหมด --", });
        groupPrintList.push(...data.groupPrint);
        console.log("data.groupPrint", data.groupPrint);
        this.dataset_process_to_pack.search.list_print = groupPrintList;
      }
      if (data.resultData != null) {
        this.dataset_process_to_pack.dataTable = data.resultData
        this.dataset_process_to_pack.total = data.resultCount;
        this.dataset_process_to_pack.pageLength = Math.ceil(this.dataset_process_to_pack.total / this.dataset_process_to_pack.limits);
      }

    },
    async setDataTable_process_packing(data, id) {

      // groupExpress
      if (data.groupExpress != null) {
        var exprees = [];
        this.dataset_process_packing.search.list_express = [];
        this.dataset_process_packing.search.select_express = ({ expressCompanyID: undefined, expressCompanyName: " -- ทั้งหมด --", });
        exprees.push({ expressCompanyID: undefined, expressCompanyName: " -- ทั้งหมด --", });
        exprees.push(...data.groupExpress);
        console.log("exprees", exprees);
        this.dataset_process_packing.search.list_express = exprees;
      }
      // channelSourceList
      if (data.groupChannel != null) {
        var channelSource = [];
        this.dataset_process_packing.search.list_channel = [];
        this.dataset_process_packing.search.select_channel = { channelID: null, channelName: "--ทุกช่องทาง--", }
        channelSource.push({ channelID: null, channelName: "--ทุกช่องทาง--", });
        channelSource.push(...data.groupChannel)
        this.dataset_process_packing.search.list_channel = channelSource;

      }
      // // groupPrint
      if (data.groupPrint != null) {
        var groupPrintList = []
        this.dataset_process_packing.search.list_print = [];
        this.dataset_process_packing.search.select_print = { id: null, name: " -- ทั้งหมด --" };
        groupPrintList.push({ id: null, name: " -- ทั้งหมด --", });
        groupPrintList.push(...data.groupPrint);
        console.log("data.groupPrint", data.groupPrint);
        this.dataset_process_packing.search.list_print = groupPrintList;
      }
      if (data.resultData != null) {
        this.dataset_process_packing.dataTable = data.resultData
        this.dataset_process_packing.total = data.resultCount;
        this.dataset_process_packing.pageLength = Math.ceil(this.dataset_process_packing.total / this.dataset_process_packing.limits);
      }

    },
    async setDataTable_process_wait_pickup(data, id) {

      // groupExpress
      if (data.groupExpress != null) {
        var exprees = [];
        this.dataset_process_wait_pickup.search.list_express = [];
        this.dataset_process_wait_pickup.search.select_express = ({ expressCompanyID: undefined, expressCompanyName: " -- ทั้งหมด --", });
        exprees.push({ expressCompanyID: undefined, expressCompanyName: " -- ทั้งหมด --", });
        exprees.push(...data.groupExpress);
        console.log("exprees", exprees);
        this.dataset_process_wait_pickup.search.list_express = exprees;
      }
      // channelSourceList
      if (data.groupChannel != null) {
        var channelSource = [];
        this.dataset_process_wait_pickup.search.list_channel = [];
        this.dataset_process_wait_pickup.search.select_channel = { channelID: null, channelName: "--ทุกช่องทาง--", }
        channelSource.push({ channelID: null, channelName: "--ทุกช่องทาง--", });
        channelSource.push(...data.groupChannel)
        this.dataset_process_wait_pickup.search.list_channel = channelSource;

      }
      // // groupPrint
      if (data.groupPrint != null) {
        var groupPrintList = []
        this.dataset_process_wait_pickup.search.list_print = [];
        this.dataset_process_wait_pickup.search.select_print = { id: null, name: " -- ทั้งหมด --" };
        groupPrintList.push({ id: null, name: " -- ทั้งหมด --", });
        groupPrintList.push(...data.groupPrint);
        console.log("data.groupPrint", data.groupPrint);
        this.dataset_process_wait_pickup.search.list_print = groupPrintList;
      }
      if (data.resultData != null) {
        this.dataset_process_wait_pickup.dataTable = data.resultData
        this.dataset_process_wait_pickup.total = data.resultCount;
        this.dataset_process_wait_pickup.pageLength = Math.ceil(this.dataset_process_wait_pickup.total / this.dataset_process_wait_pickup.limits);
      }

    },
    //dataset_Return_Returnning:
    async setDataTable_Return_Returnning(data, id) {

      // groupExpress
      if (data.groupExpress != null) {
        var exprees = [];
        this.dataset_Return_Returnning.search.list_express = [];
        this.dataset_Return_Returnning.search.select_express = ({ expressCompanyID: undefined, expressCompanyName: " -- ทั้งหมด --", });
        exprees.push({ expressCompanyID: undefined, expressCompanyName: " -- ทั้งหมด --", });
        exprees.push(...data.groupExpress);
        console.log("exprees", exprees);
        this.dataset_Return_Returnning.search.list_express = exprees;
      }
      // channelSourceList
      if (data.groupChannel != null) {
        var channelSource = [];
        this.dataset_Return_Returnning.search.list_channel = [];
        this.dataset_Return_Returnning.search.select_channel = { channelID: null, channelName: "--ทุกช่องทาง--", }
        channelSource.push({ channelID: null, channelName: "--ทุกช่องทาง--", });
        channelSource.push(...data.groupChannel)
        this.dataset_Return_Returnning.search.list_channel = channelSource;

      }
      // // groupPrint
      if (data.groupPrint != null) {
        var groupPrintList = []
        this.dataset_Return_Returnning.search.list_print = [];
        this.dataset_Return_Returnning.search.select_print = { id: null, name: " -- ทั้งหมด --" };
        groupPrintList.push({ id: null, name: " -- ทั้งหมด --", });
        groupPrintList.push(...data.groupPrint);
        console.log("data.groupPrint", data.groupPrint);
        this.dataset_Return_Returnning.search.list_print = groupPrintList;
      }
      if (data.resultData != null) {
        this.dataset_Return_Returnning.dataTable = data.resultData
        this.dataset_Return_Returnning.total = data.resultCount;
        this.dataset_Return_Returnning.pageLength = Math.ceil(this.dataset_Return_Returnning.total / this.dataset_Return_Returnning.limits);
      }
    },
    async setDataTable_Return_To_Return_Stock(data, id) {

      // groupExpress
      if (data.groupExpress != null) {
        var exprees = [];
        this.dataset_Return_To_Return_Stock.search.list_express = [];
        this.dataset_Return_To_Return_Stock.search.select_express = ({ expressCompanyID: undefined, expressCompanyName: " -- ทั้งหมด --", });
        exprees.push({ expressCompanyID: undefined, expressCompanyName: " -- ทั้งหมด --", });
        exprees.push(...data.groupExpress);
        console.log("exprees", exprees);
        this.dataset_Return_To_Return_Stock.search.list_express = exprees;
      }
      // channelSourceList
      if (data.groupChannel != null) {
        var channelSource = [];
        this.dataset_Return_To_Return_Stock.search.list_channel = [];
        this.dataset_Return_To_Return_Stock.search.select_channel = { channelID: null, channelName: "--ทุกช่องทาง--", }
        channelSource.push({ channelID: null, channelName: "--ทุกช่องทาง--", });
        channelSource.push(...data.groupChannel)
        this.dataset_Return_To_Return_Stock.search.list_channel = channelSource;

      }
      // // groupPrint
      if (data.groupPrint != null) {
        var groupPrintList = []
        this.dataset_Return_To_Return_Stock.search.list_print = [];
        this.dataset_Return_To_Return_Stock.search.select_print = { id: null, name: " -- ทั้งหมด --" };
        groupPrintList.push({ id: null, name: " -- ทั้งหมด --", });
        groupPrintList.push(...data.groupPrint);
        console.log("data.groupPrint", data.groupPrint);
        this.dataset_Return_To_Return_Stock.search.list_print = groupPrintList;
      }
      if (data.resultData != null) {
        this.dataset_Return_To_Return_Stock.dataTable = data.resultData
        this.dataset_Return_To_Return_Stock.total = data.resultCount;
        this.dataset_Return_To_Return_Stock.pageLength = Math.ceil(this.dataset_Return_To_Return_Stock.total / this.dataset_Return_To_Return_Stock.limits);
      }

    },
    async setDataTable_Return_Stock_Returned(data, id) {

      // groupExpress
      if (data.groupExpress != null) {
        var exprees = [];
        this.dataset_Return_Stock_Returned.search.list_express = [];
        this.dataset_Return_Stock_Returned.search.select_express = ({ expressCompanyID: undefined, expressCompanyName: " -- ทั้งหมด --", });
        exprees.push({ expressCompanyID: undefined, expressCompanyName: " -- ทั้งหมด --", });
        exprees.push(...data.groupExpress);
        console.log("exprees", exprees);
        this.dataset_Return_Stock_Returned.search.list_express = exprees;
      }
      // channelSourceList
      if (data.groupChannel != null) {
        var channelSource = [];
        this.dataset_Return_Stock_Returned.search.list_channel = [];
        this.dataset_Return_Stock_Returned.search.select_channel = { channelID: null, channelName: "--ทุกช่องทาง--", }
        channelSource.push({ channelID: null, channelName: "--ทุกช่องทาง--", });
        channelSource.push(...data.groupChannel)
        this.dataset_Return_Stock_Returned.search.list_channel = channelSource;

      }
      // // groupPrint
      if (data.groupPrint != null) {
        var groupPrintList = []
        this.dataset_Return_Stock_Returned.search.list_print = [];
        this.dataset_Return_Stock_Returned.search.select_print = { id: null, name: " -- ทั้งหมด --" };
        groupPrintList.push({ id: null, name: " -- ทั้งหมด --", });
        groupPrintList.push(...data.groupPrint);
        console.log("data.groupPrint", data.groupPrint);
        this.dataset_Return_Stock_Returned.search.list_print = groupPrintList;
      }
      if (data.resultData != null) {
        this.dataset_Return_Stock_Returned.dataTable = data.resultData
        this.dataset_Return_Stock_Returned.total = data.resultCount;
        this.dataset_Return_Stock_Returned.pageLength = Math.ceil(this.dataset_Return_Stock_Returned.total / this.dataset_Return_Stock_Returned.limits);
      }

    },

    //dataset_delivering:
    async setDataTable_dataset_delivering(data, id) {

      // groupExpress
      if (data.groupExpress != null) {
        var exprees = [];
        this.dataset_delivering.search.list_express = [];
        this.dataset_delivering.search.select_express = ({ expressCompanyID: undefined, expressCompanyName: " -- ทั้งหมด --", });
        exprees.push({ expressCompanyID: undefined, expressCompanyName: " -- ทั้งหมด --", });
        exprees.push(...data.groupExpress);
        console.log("exprees", exprees);
        this.dataset_delivering.search.list_express = exprees;
      }
      // channelSourceList
      if (data.groupChannel != null) {
        var channelSource = [];
        this.dataset_delivering.search.list_channel = [];
        this.dataset_delivering.search.select_channel = { channelID: null, channelName: "--ทุกช่องทาง--", }
        channelSource.push({ channelID: null, channelName: "--ทุกช่องทาง--", });
        channelSource.push(...data.groupChannel)
        this.dataset_delivering.search.list_channel = channelSource;

      }
      // // groupPrint
      if (data.groupPrint != null) {
        var groupPrintList = []
        this.dataset_delivering.search.list_print = [];
        this.dataset_delivering.search.select_print = { id: null, name: " -- ทั้งหมด --" };
        groupPrintList.push({ id: null, name: " -- ทั้งหมด --", });
        groupPrintList.push(...data.groupPrint);
        console.log("data.groupPrint", data.groupPrint);
        this.dataset_delivering.search.list_print = groupPrintList;
      }
      if (data.resultData != null) {
        this.dataset_delivering.dataTable = data.resultData
        this.dataset_delivering.total = data.resultCount;
        this.dataset_delivering.pageLength = Math.ceil(this.dataset_delivering.total / this.dataset_delivering.limits);
      }
    },
    //dataset_dataset_success:
    async setDataTable_dataset_success(data, id) {

      // groupExpress
      if (data.groupExpress != null) {
        var exprees = [];
        this.dataset_success.search.list_express = [];
        this.dataset_success.search.select_express = ({ expressCompanyID: undefined, expressCompanyName: " -- ทั้งหมด --", });
        exprees.push({ expressCompanyID: undefined, expressCompanyName: " -- ทั้งหมด --", });
        exprees.push(...data.groupExpress);
        console.log("exprees", exprees);
        this.dataset_success.search.list_express = exprees;
      }
      // channelSourceList
      if (data.groupChannel != null) {
        var channelSource = [];
        this.dataset_success.search.list_channel = [];
        this.dataset_success.search.select_channel = { channelID: null, channelName: "--ทุกช่องทาง--", }
        channelSource.push({ channelID: null, channelName: "--ทุกช่องทาง--", });
        channelSource.push(...data.groupChannel)
        this.dataset_success.search.list_channel = channelSource;

      }
      // // groupPrint
      if (data.groupPrint != null) {
        var groupPrintList = []
        this.dataset_success.search.list_print = [];
        this.dataset_success.search.select_print = { id: null, name: " -- ทั้งหมด --" };
        groupPrintList.push({ id: null, name: " -- ทั้งหมด --", });
        groupPrintList.push(...data.groupPrint);
        console.log("data.groupPrint", data.groupPrint);
        this.dataset_success.search.list_print = groupPrintList;
      }
      if (data.resultData != null) {
        this.dataset_success.dataTable = data.resultData
        this.dataset_success.total = data.resultCount;
        this.dataset_success.pageLength = Math.ceil(this.dataset_success.total / this.dataset_success.limits);
      }
    },
    //dataset_cancel:
    async setDataTable_dataset_cancel(data, id) {

      // groupExpress
      if (data.groupExpress != null) {
        var exprees = [];
        this.dataset_cancel.search.list_express = [];
        this.dataset_cancel.search.select_express = ({ expressCompanyID: undefined, expressCompanyName: " -- ทั้งหมด --", });
        exprees.push({ expressCompanyID: undefined, expressCompanyName: " -- ทั้งหมด --", });
        exprees.push(...data.groupExpress);
        console.log("exprees", exprees);
        this.dataset_cancel.search.list_express = exprees;
      }
      // channelSourceList
      if (data.groupChannel != null) {
        var channelSource = [];
        this.dataset_cancel.search.list_channel = [];
        this.dataset_cancel.search.select_channel = { channelID: null, channelName: "--ทุกช่องทาง--", }
        channelSource.push({ channelID: null, channelName: "--ทุกช่องทาง--", });
        channelSource.push(...data.groupChannel)
        this.dataset_cancel.search.list_channel = channelSource;

      }
      // // groupPrint
      if (data.groupPrint != null) {
        var groupPrintList = []
        this.dataset_cancel.search.list_print = [];
        this.dataset_cancel.search.select_print = { id: null, name: " -- ทั้งหมด --" };
        groupPrintList.push({ id: null, name: " -- ทั้งหมด --", });
        groupPrintList.push(...data.groupPrint);
        console.log("data.groupPrint", data.groupPrint);
        this.dataset_cancel.search.list_print = groupPrintList;
      }
      if (data.resultData != null) {
        this.dataset_cancel.dataTable = data.resultData
        this.dataset_cancel.total = data.resultCount;
        this.dataset_cancel.pageLength = Math.ceil(this.dataset_cancel.total / this.dataset_cancel.limits);
      }
    },



    // ส่วนของ btn
    async btn_warehouse() {
      console.log("set_TabListHeader", this.set_TabListHeader);
      this.set_TabListHeader = '0'
      this.set_TabListSubHeader_wait_payment = '1';
      this.set_TabListSubHeader_Process = '10';
      this.set_TabListSubHeader_Return = '100';
      this.map_tab_list_herder();


    },
    async btnSearchOrder(tab) {

      console.log("btnSearchOrder", tab);


      var data, keyword, item;

      var warehouse_select = await this.map_tab_list_herder_1();


      if (tab == '1') {

        this.dataset_wait_payment_wait_payment.offsets = 0;
        this.dataset_wait_payment_wait_payment.limits = 10;
        this.dataset_wait_payment_wait_payment.page = 1;
        keyword = { packageStatusID: "1" };


        for (item in this.dataset_wait_payment_wait_payment.search.select_header) {
          if (this.dataset_wait_payment_wait_payment.search.select_header[item].value == this.dataset_wait_payment_wait_payment.search.select_topic.value) {
            keyword[this.dataset_wait_payment_wait_payment.search.select_topic.value] = this.dataset_wait_payment_wait_payment.search.text_topic;
          } else {
            keyword[this.dataset_wait_payment_wait_payment.search.select_header[item].value] = "";
          }
        }

        this.dataset_wait_payment_wait_payment.keyword = keyword;

        data = await this.SearchOrder(this.dataset_wait_payment_wait_payment.offsets, this.dataset_wait_payment_wait_payment.limits, null, keyword, warehouse_select);
        this.setDataTable_wait_payment_wait_payment(data, '1');


        console.log('1', tab);
      }
      else if (tab == '5') {
        this.dataset_wait_payment_check_payment.offsets = 0;
        this.dataset_wait_payment_check_payment.limits = 10;
        this.dataset_wait_payment_check_payment.page = 1;
        keyword = { packageStatusID: "5" };


        for (item in this.dataset_wait_payment_check_payment.search.select_header) {
          if (this.dataset_wait_payment_check_payment.search.select_header[item].value == this.dataset_wait_payment_check_payment.search.select_topic.value) {
            keyword[this.dataset_wait_payment_check_payment.search.select_topic.value] = this.dataset_wait_payment_check_payment.search.text_topic;
          } else {
            keyword[this.dataset_wait_payment_check_payment.search.select_header[item].value] = "";
          }
        }

        this.dataset_wait_payment_check_payment.keyword = keyword;

        data = await this.SearchOrder(this.dataset_wait_payment_check_payment.offsets, this.dataset_wait_payment_check_payment.limits, null, keyword, warehouse_select);
        this.setDataTable_wait_payment_check_payment(data, '5');

        console.log('5', tab);
      }
      else if (tab == '10') {
        this.dataset_process_map_product.offsets = 0;
        this.dataset_process_map_product.limits = 10;
        this.dataset_process_map_product.page = 1;
        keyword = { packageStatusID: "10" };


        for (item in this.dataset_process_map_product.search.select_header) {
          if (this.dataset_process_map_product.search.select_header[item].value == this.dataset_process_map_product.search.select_topic.value) {
            keyword[this.dataset_process_map_product.search.select_topic.value] = this.dataset_process_map_product.search.text_topic;
          } else {
            keyword[this.dataset_process_map_product.search.select_header[item].value] = "";
          }
        }

        this.dataset_process_map_product.keyword = keyword;

        data = await this.SearchOrder(this.dataset_process_map_product.offsets, this.dataset_process_map_product.limits, null, keyword, warehouse_select);
        this.setDataTable_process_map_product(data, '10');


        console.log('10', tab);
      }
      else if (tab == '20') {
        this.dataset_process_allocate_stock.offsets = 0;
        this.dataset_process_allocate_stock.limits = 10;
        this.dataset_process_allocate_stock.page = 1;
        keyword = { packageStatusID: "20" };


        for (item in this.dataset_process_allocate_stock.search.select_header) {
          if (this.dataset_process_allocate_stock.search.select_header[item].value == this.dataset_process_allocate_stock.search.select_topic.value) {
            keyword[this.dataset_process_allocate_stock.search.select_topic.value] = this.dataset_process_allocate_stock.search.text_topic;
          } else {
            keyword[this.dataset_process_allocate_stock.search.select_header[item].value] = "";
          }
        }

        this.dataset_process_allocate_stock.keyword = keyword;
        data = await this.SearchOrder(this.dataset_process_allocate_stock.offsets, this.dataset_process_allocate_stock.limits, null, keyword, warehouse_select);
        this.setDataTable_process_allocate_stock(data, '20');


        console.log('20', tab);
      }
      else if (tab == '30') {
        this.dataset_process_ready_to_ship.offsets = 0;
        this.dataset_process_ready_to_ship.limits = 10;
        this.dataset_process_ready_to_ship.page = 1;
        keyword = { packageStatusID: "30" };


        for (item in this.dataset_process_ready_to_ship.search.select_header) {
          if (this.dataset_process_ready_to_ship.search.select_header[item].value == this.dataset_process_ready_to_ship.search.select_topic.value) {
            keyword[this.dataset_process_ready_to_ship.search.select_topic.value] = this.dataset_process_ready_to_ship.search.text_topic;
          } else {
            keyword[this.dataset_process_ready_to_ship.search.select_header[item].value] = "";
          }
        }

        this.dataset_process_ready_to_ship.keyword = keyword;
        data = await this.SearchOrder(this.dataset_process_ready_to_ship.offsets, this.dataset_process_ready_to_ship.limits, null, keyword, warehouse_select);
        this.setDataTable_process_ready_to_ship(data, '30');


        console.log('30', tab);
      }
      else if (tab == '40') {
        this.dataset_process_to_pack.offsets = 0;
        this.dataset_process_to_pack.limits = 10;
        this.dataset_process_to_pack.page = 1;
        keyword = { packageStatusID: "40" };


        for (item in this.dataset_process_to_pack.search.select_header) {
          if (this.dataset_process_to_pack.search.select_header[item].value == this.dataset_process_to_pack.search.select_topic.value) {
            keyword[this.dataset_process_to_pack.search.select_topic.value] = this.dataset_process_to_pack.search.text_topic;
          } else {
            keyword[this.dataset_process_to_pack.search.select_header[item].value] = "";
          }
        }

        this.dataset_process_to_pack.keyword = keyword;
        data = await this.SearchOrder(this.dataset_process_to_pack.offsets, this.dataset_process_to_pack.limits, null, keyword, warehouse_select);
        this.setDataTable_process_to_pack(data, '40');


        console.log('40', tab);
      }
      else if (tab == '60') {
        this.dataset_process_packing.offsets = 0;
        this.dataset_process_packing.limits = 10;
        this.dataset_process_packing.page = 1;
        keyword = { packageStatusID: "60" };


        for (item in this.dataset_process_packing.search.select_header) {
          if (this.dataset_process_packing.search.select_header[item].value == this.dataset_process_packing.search.select_topic.value) {
            keyword[this.dataset_process_packing.search.select_topic.value] = this.dataset_process_packing.search.text_topic;
          } else {
            keyword[this.dataset_process_packing.search.select_header[item].value] = "";
          }
        }

        this.dataset_process_packing.keyword = keyword;
        data = await this.SearchOrder(this.dataset_process_packing.offsets, this.dataset_process_packing.limits, null, keyword, warehouse_select);
        this.setDataTable_process_packing(data, '60');


        console.log('60', tab);
      }
      else if (tab == '80') {
        this.dataset_process_wait_pickup.offsets = 0;
        this.dataset_process_wait_pickup.limits = 10;
        this.dataset_process_wait_pickup.page = 1;
        keyword = { packageStatusID: "80" };


        for (item in this.dataset_process_wait_pickup.search.select_header) {
          if (this.dataset_process_wait_pickup.search.select_header[item].value == this.dataset_process_wait_pickup.search.select_topic.value) {
            keyword[this.dataset_process_wait_pickup.search.select_topic.value] = this.dataset_process_wait_pickup.search.text_topic;
          } else {
            keyword[this.dataset_process_wait_pickup.search.select_header[item].value] = "";
          }
        }

        this.dataset_process_wait_pickup.keyword = keyword;
        data = await this.SearchOrder(this.dataset_process_wait_pickup.offsets, this.dataset_process_wait_pickup.limits, null, keyword, warehouse_select);
        this.setDataTable_process_wait_pickup(data, '5');


        console.log('80', tab);
      }
      else if (tab == '90') {
        this.dataset_delivering.offsets = 0;
        this.dataset_delivering.limits = 10;
        this.dataset_delivering.page = 1;
        keyword = { packageStatusID: "90" };


        for (item in this.dataset_delivering.search.select_header) {
          if (this.dataset_delivering.search.select_header[item].value == this.dataset_delivering.search.select_topic.value) {
            keyword[this.dataset_delivering.search.select_topic.value] = this.dataset_delivering.search.text_topic;
          } else {
            keyword[this.dataset_delivering.search.select_header[item].value] = "";
          }
        }

        this.dataset_delivering.keyword = keyword;
        data = await this.SearchOrder(this.dataset_delivering.offsets, this.dataset_delivering.limits, null, keyword, warehouse_select);
        this.setDataTable_dataset_delivering(data, '90');


        console.log('90', tab);
      }
      else if (tab == '130') {
        this.dataset_success.offsets = 0;
        this.dataset_success.limits = 10;
        this.dataset_success.page = 1;
        keyword = { packageStatusID: "130" };


        for (item in this.dataset_success.search.select_header) {
          if (this.dataset_success.search.select_header[item].value == this.dataset_success.search.select_topic.value) {
            keyword[this.dataset_success.search.select_topic.value] = this.dataset_success.search.text_topic;
          } else {
            keyword[this.dataset_success.search.select_header[item].value] = "";
          }
        }

        this.dataset_success.keyword = keyword;
        data = await this.SearchOrder(this.dataset_success.offsets, this.dataset_success.limits, null, keyword, warehouse_select);
        this.setDataTable_dataset_success(data, '130');


        console.log('130', tab);
      }
      else if (tab == '100') {
        this.dataset_Return_Returnning.offsets = 0;
        this.dataset_Return_Returnning.limits = 10;
        this.dataset_Return_Returnning.page = 1;
        keyword = { packageStatusID: "100" };


        for (item in this.dataset_Return_Returnning.search.select_header) {
          if (this.dataset_Return_Returnning.search.select_header[item].value == this.dataset_Return_Returnning.search.select_topic.value) {
            keyword[this.dataset_Return_Returnning.search.select_topic.value] = this.dataset_Return_Returnning.search.text_topic;
          } else {
            keyword[this.dataset_Return_Returnning.search.select_header[item].value] = "";
          }
        }

        this.dataset_Return_Returnning.keyword = keyword;
        data = await this.SearchOrder(this.dataset_Return_Returnning.offsets, this.dataset_Return_Returnning.limits, null, keyword, warehouse_select);
        this.setDataTable_Return_Returnning(data, '100');


        console.log('100', tab);
      }
      else if (tab == '110') {
        this.dataset_Return_To_Return_Stock.offsets = 0;
        this.dataset_Return_To_Return_Stock.limits = 10;
        this.dataset_Return_To_Return_Stock.page = 1;
        keyword = { packageStatusID: "110" };


        for (item in this.dataset_Return_To_Return_Stock.search.select_header) {
          if (this.dataset_Return_To_Return_Stock.search.select_header[item].value == this.dataset_Return_To_Return_Stock.search.select_topic.value) {
            keyword[this.dataset_Return_To_Return_Stock.search.select_topic.value] = this.dataset_Return_To_Return_Stock.search.text_topic;
          } else {
            keyword[this.dataset_Return_To_Return_Stock.search.select_header[item].value] = "";
          }
        }

        this.dataset_Return_To_Return_Stock.keyword = keyword;
        data = await this.SearchOrder(this.dataset_Return_To_Return_Stock.offsets, this.dataset_Return_To_Return_Stock.limits, null, keyword, warehouse_select);
        this.setDataTable_Return_To_Return_Stock(data, '110');


        console.log('110', tab);
      }
      else if (tab == '120') {
        this.dataset_Return_Stock_Returned.offsets = 0;
        this.dataset_Return_Stock_Returned.limits = 10;
        this.dataset_Return_Stock_Returned.page = 1;
        keyword = { packageStatusID: "120" };


        for (item in this.dataset_Return_Stock_Returned.search.select_header) {
          if (this.dataset_Return_Stock_Returned.search.select_header[item].value == this.dataset_Return_Stock_Returned.search.select_topic.value) {
            keyword[this.dataset_Return_Stock_Returned.search.select_topic.value] = this.dataset_Return_Stock_Returned.search.text_topic;
          } else {
            keyword[this.dataset_Return_Stock_Returned.search.select_header[item].value] = "";
          }
        }

        this.dataset_Return_Stock_Returned.keyword = keyword;
        data = await this.SearchOrder(this.dataset_Return_Stock_Returned.offsets, this.dataset_Return_Stock_Returned.limits, null, keyword, warehouse_select);
        this.setDataTable_Return_Stock_Returned(data, '120');


        console.log('120', tab);
      }
      else if (tab == '140') {
        this.dataset_cancel.offsets = 0;
        this.dataset_cancel.limits = 10;
        this.dataset_cancel.page = 1;
        keyword = { packageStatusID: "140" };


        for (item in this.dataset_cancel.search.select_header) {
          if (this.dataset_cancel.search.select_header[item].value == this.dataset_cancel.search.select_topic.value) {
            keyword[this.dataset_cancel.search.select_topic.value] = this.dataset_cancel.search.text_topic;
          } else {
            keyword[this.dataset_cancel.search.select_header[item].value] = "";
          }
        }

        this.dataset_cancel.keyword = keyword;
        data = await this.SearchOrder(this.dataset_cancel.offsets, this.dataset_cancel.limits, null, keyword, warehouse_select);
        this.setDataTable_dataset_cancel(data, '140');


        console.log('140', tab);
      }




    },
    async btnChangeLimits(tab) {
      console.log("btnChangeLimits", tab);

      var data, keyword;
      var warehouse_select = await this.map_tab_list_herder_1();

      if (tab == '1') {
        keyword = this.dataset_wait_payment_wait_payment.keyword;
        data = await this.SearchOrder(this.dataset_wait_payment_wait_payment.offsets, this.dataset_wait_payment_wait_payment.limits, null, keyword, warehouse_select);
        this.setDataTable_wait_payment_wait_payment(data, '1');


        console.log('1', tab);
      }
      else if (tab == '5') {
        keyword = this.dataset_wait_payment_check_payment.keyword;
        data = await this.SearchOrder(this.dataset_wait_payment_check_payment.offsets, this.dataset_wait_payment_check_payment.limits, null, keyword, warehouse_select);
        this.setDataTable_wait_payment_check_payment(data, '5');

        console.log('5', tab);
      }
      else if (tab == '10') {
        keyword = this.dataset_process_map_product.keyword;
        data = await this.SearchOrder(this.dataset_process_map_product.offsets, this.dataset_process_map_product.limits, null, keyword, warehouse_select);
        this.setDataTable_process_map_product(data, '10');


        console.log('10', tab);
      }
      else if (tab == '20') {
        keyword = this.dataset_process_allocate_stock.keyword;
        data = await this.SearchOrder(this.dataset_process_allocate_stock.offsets, this.dataset_process_allocate_stock.limits, null, keyword, warehouse_select);
        this.setDataTable_process_allocate_stock(data, '20');


        console.log('20', tab);
      }
      else if (tab == '30') {
        keyword = this.dataset_process_ready_to_ship.keyword;
        data = await this.SearchOrder(this.dataset_process_ready_to_ship.offsets, this.dataset_process_ready_to_ship.limits, null, keyword, warehouse_select);
        this.setDataTable_process_ready_to_ship(data, '30');


        console.log('30', tab);
      }
      else if (tab == '40') {
        keyword = this.dataset_process_to_pack.keyword;
        data = await this.SearchOrder(this.dataset_process_to_pack.offsets, this.dataset_process_to_pack.limits, null, keyword, warehouse_select);
        this.setDataTable_process_to_pack(data, '40');


        console.log('40', tab);
      }
      else if (tab == '60') {
        keyword = this.dataset_process_packing.keyword;
        data = await this.SearchOrder(this.dataset_process_packing.offsets, this.dataset_process_packing.limits, null, keyword, warehouse_select);
        this.setDataTable_process_packing(data, '60');


        console.log('60', tab);
      }
      else if (tab == '80') {
        keyword = this.dataset_process_wait_pickup.keyword;
        data = await this.SearchOrder(this.dataset_process_wait_pickup.offsets, this.dataset_process_wait_pickup.limits, null, keyword, warehouse_select);
        this.setDataTable_process_wait_pickup(data, '5');


        console.log('80', tab);
      }
      else if (tab == '90') {
        keyword = this.dataset_delivering.keyword;
        data = await this.SearchOrder(this.dataset_delivering.offsets, this.dataset_delivering.limits, null, keyword, warehouse_select);
        this.setDataTable_dataset_delivering(data, '90');


        console.log('90', tab);
      }
      else if (tab == '130') {
        keyword = this.dataset_success.keyword;
        data = await this.SearchOrder(this.dataset_success.offsets, this.dataset_success.limits, null, keyword, warehouse_select);
        this.setDataTable_dataset_success(data, '130');


        console.log('100', tab);
      }
      else if (tab == '100') {
        keyword = this.dataset_Return_Returnning.keyword;
        data = await this.SearchOrder(this.dataset_Return_Returnning.offsets, this.dataset_Return_Returnning.limits, null, keyword, warehouse_select);
        this.setDataTable_Return_Returnning(data, '100');


        console.log('100', tab);
      }
      else if (tab == '110') {
        keyword = this.dataset_Return_To_Return_Stock.keyword;
        data = await this.SearchOrder(this.dataset_Return_To_Return_Stock.offsets, this.dataset_Return_To_Return_Stock.limits, null, keyword, warehouse_select);
        this.setDataTable_Return_To_Return_Stock(data, '100');


        console.log('110', tab);
      }
      else if (tab == '120') {
        keyword = this.dataset_Return_To_Return_Stock.keyword;
        data = await this.SearchOrder(this.dataset_Return_To_Return_Stock.offsets, this.dataset_Return_To_Return_Stock.limits, null, keyword, warehouse_select);
        this.setDataTable_Return_Stock_Returned(data, '120');


        console.log('120', tab);
      }

      else if (tab == '140') {
        keyword = this.dataset_cancel.keyword;
        data = await this.SearchOrder(this.dataset_cancel.offsets, this.dataset_cancel.limits, null, keyword, warehouse_select);
        this.setDataTable_dataset_cancel(data, '140');


        console.log('140', tab);
      }


    },
    async btnChangePage(tab) {

      console.log("btnChangePage", tab);



      var data, keyword;

      var warehouse_select = await this.map_tab_list_herder_1();


      if (tab == '1') {
        this.dataset_wait_payment_wait_payment.offsets = (this.dataset_wait_payment_wait_payment.page * this.dataset_wait_payment_wait_payment.limits) - this.dataset_wait_payment_wait_payment.limits
        keyword = this.dataset_wait_payment_wait_payment.keyword;
        data = await this.SearchOrder(this.dataset_wait_payment_wait_payment.offsets, this.dataset_wait_payment_wait_payment.limits, null, keyword, warehouse_select);
        this.setDataTable_wait_payment_wait_payment(data, '1');


        console.log('1', tab);
      }
      else if (tab == '5') {
        this.dataset_wait_payment_check_payment.offsets = (this.dataset_wait_payment_check_payment.page * this.dataset_wait_payment_check_payment.limits) - this.dataset_wait_payment_check_payment.limits
        keyword = this.dataset_wait_payment_check_payment.keyword;
        data = await this.SearchOrder(this.dataset_wait_payment_check_payment.offsets, this.dataset_wait_payment_check_payment.limits, null, keyword, warehouse_select);
        this.setDataTable_wait_payment_check_payment(data, '5');

        console.log('5', tab);
      }
      else if (tab == '10') {
        this.dataset_process_map_product.offsets = (this.dataset_process_map_product.page * this.dataset_process_map_product.limits) - this.dataset_process_map_product.limits
        keyword = this.dataset_process_map_product.keyword;
        data = await this.SearchOrder(this.dataset_process_map_product.offsets, this.dataset_process_map_product.limits, null, keyword, warehouse_select);
        this.setDataTable_process_map_product(data, '10');


        console.log('10', tab);
      }
      else if (tab == '20') {
        this.dataset_process_allocate_stock.offsets = (this.dataset_process_allocate_stock.page * this.dataset_process_allocate_stock.limits) - this.dataset_process_allocate_stock.limits
        keyword = this.dataset_process_allocate_stock.keyword;
        data = await this.SearchOrder(this.dataset_process_allocate_stock.offsets, this.dataset_process_allocate_stock.limits, null, keyword, warehouse_select);
        this.setDataTable_process_allocate_stock(data, '20');


        console.log('20', tab);
      }
      else if (tab == '30') {
        this.dataset_process_ready_to_ship.offsets = (this.dataset_process_ready_to_ship.page * this.dataset_process_ready_to_ship.limits) - this.dataset_process_ready_to_ship.limits
        keyword = this.dataset_process_ready_to_ship.keyword;
        data = await this.SearchOrder(this.dataset_process_ready_to_ship.offsets, this.dataset_process_ready_to_ship.limits, null, keyword, warehouse_select);
        this.setDataTable_process_ready_to_ship(data, '30');


        console.log('30', tab);
      }
      else if (tab == '40') {
        this.dataset_process_to_pack.offsets = (this.dataset_process_to_pack.page * this.dataset_process_to_pack.limits) - this.dataset_process_to_pack.limits
        keyword = this.dataset_process_to_pack.keyword;
        data = await this.SearchOrder(this.dataset_process_to_pack.offsets, this.dataset_process_to_pack.limits, null, keyword, warehouse_select);
        this.setDataTable_process_to_pack(data, '40');


        console.log('40', tab);
      }
      else if (tab == '60') {
        this.dataset_process_packing.offsets = (this.dataset_process_packing.page * this.dataset_process_packing.limits) - this.dataset_process_packing.limits
        keyword = this.dataset_process_packing.keyword;
        data = await this.SearchOrder(this.dataset_process_packing.offsets, this.dataset_process_packing.limits, null, keyword, warehouse_select);
        this.setDataTable_process_packing(data, '60');


        console.log('60', tab);
      }
      else if (tab == '80') {
        this.dataset_process_wait_pickup.offsets = (this.dataset_process_wait_pickup.page * this.dataset_process_wait_pickup.limits) - this.dataset_process_wait_pickup.limits
        keyword = this.dataset_process_wait_pickup.keyword;
        data = await this.SearchOrder(this.dataset_process_wait_pickup.offsets, this.dataset_process_wait_pickup.limits, null, keyword, warehouse_select);
        this.setDataTable_process_wait_pickup(data, '5');


        console.log('80', tab);
      }
      else if (tab == '90') {
        this.dataset_delivering.offsets = (this.dataset_delivering.page * this.dataset_delivering.limits) - this.dataset_delivering.limits

        keyword = this.dataset_delivering.keyword;
        data = await this.SearchOrder(this.dataset_delivering.offsets, this.dataset_delivering.limits, null, keyword, warehouse_select);
        this.setDataTable_dataset_delivering(data, '90');


        console.log('90', tab);
      }
      else if (tab == '130') {
        this.dataset_success.offsets = (this.dataset_success.page * this.dataset_success.limits) - this.dataset_success.limits

        keyword = this.dataset_success.keyword;
        data = await this.SearchOrder(this.dataset_success.offsets, this.dataset_success.limits, null, keyword, warehouse_select);
        this.setDataTable_dataset_success(data, '130');


        console.log('130', tab);
      }
      else if (tab == '100') {
        this.dataset_Return_Returnning.offsets = (this.dataset_Return_Returnning.page * this.dataset_Return_Returnning.limits) - this.dataset_Return_Returnning.limits

        keyword = this.dataset_Return_Returnning.keyword;
        data = await this.SearchOrder(this.dataset_Return_Returnning.offsets, this.dataset_Return_Returnning.limits, null, keyword, warehouse_select);
        this.setDataTable_Return_Returnning(data, '100');


        console.log('100', tab);
      }
      else if (tab == '110') {
        this.dataset_Return_To_Return_Stock.offsets = (this.dataset_Return_To_Return_Stock.page * this.dataset_Return_To_Return_Stock.limits) - this.dataset_Return_To_Return_Stock.limits

        keyword = this.dataset_Return_To_Return_Stock.keyword;
        data = await this.SearchOrder(this.dataset_Return_To_Return_Stock.offsets, this.dataset_Return_To_Return_Stock.limits, null, keyword, warehouse_select);
        this.setDataTable_Return_To_Return_Stock(data, '110');


        console.log('110', tab);
      }
      else if (tab == '120') {
        this.dataset_Return_Stock_Returned.offsets = (this.dataset_Return_Stock_Returned.page * this.dataset_Return_Stock_Returned.limits) - this.dataset_Return_Stock_Returned.limits

        keyword = this.dataset_Return_Stock_Returned.keyword;
        data = await this.SearchOrder(this.dataset_Return_Stock_Returned.offsets, this.dataset_Return_Stock_Returned.limits, null, keyword, warehouse_select);
        this.setDataTable_Return_Stock_Returned(data, '120');


        console.log('120', tab);
      }
      else if (tab == '140') {
        this.dataset_cancel.offsets = (this.dataset_cancel.page * this.dataset_cancel.limits) - this.dataset_cancel.limits

        keyword = this.dataset_cancel.keyword;
        data = await this.SearchOrder(this.dataset_cancel.offsets, this.dataset_cancel.limits, null, keyword, warehouse_select);
        this.setDataTable_dataset_cancel(data, '140');


        console.log('140', tab);
      }




    },
    async btnChannelSource(tab, channelID) {
      console.log("btnChannelSourcetab", tab);
      console.log("btnChannelSourcechannelID", channelID);


      var data, keyword;

      var warehouse_select = await this.map_tab_list_herder_1();


      if (tab == '1') {
        this.dataset_wait_payment_wait_payment.page = 1;
        this.dataset_wait_payment_wait_payment.dataTable = [];
        this.dataset_wait_payment_wait_payment.selected = [];
        this.dataset_wait_payment_wait_payment.offsets = 0;
        keyword = this.dataset_wait_payment_wait_payment.keyword;
        keyword.channelID = this.dataset_wait_payment_wait_payment.search.select_channel.channelID;
        keyword.expressCompanyID = null;
        keyword.printType = null;
        keyword.isSelectAll = false;
        keyword.packageStatusID = "1";

        this.dataset_wait_payment_wait_payment.keyword = keyword;
        this.dataset_wait_payment_wait_payment.offsets = 0
        this.dataset_wait_payment_wait_payment.limits = 10

        data = await this.SearchOrder(this.dataset_wait_payment_wait_payment.offsets, this.dataset_wait_payment_wait_payment.limits, null, keyword, warehouse_select);
        this.setDataTable_wait_payment_wait_payment(data, '1');


        console.log('1', tab);
      }
      else if (tab == '5') {
        this.dataset_wait_payment_check_payment.page = 1;
        this.dataset_wait_payment_check_payment.dataTable = [];
        this.dataset_wait_payment_check_payment.selected = [];
        this.dataset_wait_payment_check_payment.offsets = 0;
        keyword = this.dataset_wait_payment_check_payment.keyword;
        keyword.channelID = this.dataset_wait_payment_check_payment.search.select_channel.channelID;
        keyword.expressCompanyID = null;
        keyword.printType = null;
        keyword.isSelectAll = false;
        keyword.packageStatusID = "5";

        this.dataset_wait_payment_check_payment.keyword = keyword;
        this.dataset_wait_payment_check_payment.offsets = 0
        this.dataset_wait_payment_check_payment.limits = 10

        data = await this.SearchOrder(this.dataset_wait_payment_check_payment.offsets, this.dataset_wait_payment_check_payment.limits, null, keyword, warehouse_select);
        this.setDataTable_wait_payment_check_payment(data, '5');

        console.log('5', tab);
      }
      else if (tab == '10') {
        this.dataset_process_map_product.page = 1;
        this.dataset_process_map_product.dataTable = [];
        this.dataset_process_map_product.selected = [];
        this.dataset_process_map_product.offsets = 0;
        keyword = this.dataset_process_map_product.keyword;
        keyword.channelID = this.dataset_process_map_product.search.select_channel.channelID;
        keyword.expressCompanyID = null;
        keyword.printType = null;
        keyword.isSelectAll = false;
        keyword.packageStatusID = "10";

        this.dataset_process_map_product.keyword = keyword;
        this.dataset_process_map_product.offsets = 0
        this.dataset_process_map_product.limits = 10

        data = await this.SearchOrder(this.dataset_process_map_product.offsets, this.dataset_process_map_product.limits, null, keyword, warehouse_select);
        this.setDataTable_process_map_product(data, '10');


        console.log('10', tab);
      }
      else if (tab == '20') {
        this.dataset_process_allocate_stock.page = 1;
        this.dataset_process_allocate_stock.dataTable = [];
        this.dataset_process_allocate_stock.selected = [];
        this.dataset_process_allocate_stock.offsets = 0;
        keyword = this.dataset_process_allocate_stock.keyword;
        keyword.channelID = this.dataset_process_allocate_stock.search.select_channel.channelID;
        keyword.expressCompanyID = null;
        keyword.printType = null;
        keyword.isSelectAll = false;
        keyword.packageStatusID = "20";

        this.dataset_process_allocate_stock.keyword = keyword;
        this.dataset_process_allocate_stock.offsets = 0
        this.dataset_process_allocate_stock.limits = 10

        data = await this.SearchOrder(this.dataset_process_allocate_stock.offsets, this.dataset_process_allocate_stock.limits, null, keyword, warehouse_select);
        this.setDataTable_process_allocate_stock(data, '20');


        console.log('20', tab);
      }
      else if (tab == '30') {
        this.dataset_process_ready_to_ship.page = 1;
        this.dataset_process_ready_to_ship.dataTable = [];
        this.dataset_process_ready_to_ship.selected = [];
        this.dataset_process_ready_to_ship.offsets = 0;
        keyword = this.dataset_process_ready_to_ship.keyword;
        keyword.channelID = this.dataset_process_ready_to_ship.search.select_channel.channelID;
        keyword.expressCompanyID = null;
        keyword.printType = null;
        keyword.isSelectAll = false;
        keyword.packageStatusID = "30";

        this.dataset_process_ready_to_ship.keyword = keyword;
        this.dataset_process_ready_to_ship.offsets = 0
        this.dataset_process_ready_to_ship.limits = 10

        data = await this.SearchOrder(this.dataset_process_ready_to_ship.offsets, this.dataset_process_ready_to_ship.limits, null, keyword, warehouse_select);
        this.setDataTable_process_ready_to_ship(data, '30');


        console.log('30', tab);
      }
      else if (tab == '40') {
        this.dataset_process_to_pack.page = 1;
        this.dataset_process_to_pack.dataTable = [];
        this.dataset_process_to_pack.selected = [];
        this.dataset_process_to_pack.offsets = 0;
        keyword = this.dataset_process_to_pack.keyword;
        keyword.channelID = this.dataset_process_to_pack.search.select_channel.channelID;
        keyword.expressCompanyID = null;
        keyword.printType = null;
        keyword.isSelectAll = false;
        keyword.packageStatusID = "40";

        this.dataset_process_to_pack.keyword = keyword;
        this.dataset_process_to_pack.offsets = 0
        this.dataset_process_to_pack.limits = 10

        data = await this.SearchOrder(this.dataset_process_to_pack.offsets, this.dataset_process_to_pack.limits, null, keyword, warehouse_select);
        this.setDataTable_process_to_pack(data, '40');


        console.log('40', tab);
      }
      else if (tab == '60') {
        this.dataset_process_packing.page = 1;
        this.dataset_process_packing.dataTable = [];
        this.dataset_process_packing.selected = [];
        this.dataset_process_packing.offsets = 0;
        keyword = this.dataset_process_packing.keyword;
        keyword.channelID = this.dataset_process_packing.search.select_channel.channelID;
        keyword.expressCompanyID = null;
        keyword.printType = null;
        keyword.isSelectAll = false;
        keyword.packageStatusID = "60";

        this.dataset_process_packing.keyword = keyword;
        this.dataset_process_packing.offsets = 0
        this.dataset_process_packing.limits = 10

        data = await this.SearchOrder(this.dataset_process_packing.offsets, this.dataset_process_packing.limits, null, keyword, warehouse_select);
        this.setDataTable_process_packing(data, '60');


        console.log('60', tab);
      }
      else if (tab == '80') {
        this.dataset_process_wait_pickup.page = 1;
        this.dataset_process_wait_pickup.dataTable = [];
        this.dataset_process_wait_pickup.selected = [];
        this.dataset_process_wait_pickup.offsets = 0;
        keyword = this.dataset_process_wait_pickup.keyword;
        keyword.channelID = this.dataset_process_wait_pickup.search.select_channel.channelID;
        keyword.expressCompanyID = null;
        keyword.printType = null;
        keyword.isSelectAll = false;
        keyword.packageStatusID = "80";

        this.dataset_process_wait_pickup.keyword = keyword;
        this.dataset_process_wait_pickup.offsets = 0
        this.dataset_process_wait_pickup.limits = 10

        data = await this.SearchOrder(this.dataset_process_wait_pickup.offsets, this.dataset_process_wait_pickup.limits, null, keyword, warehouse_select);
        this.setDataTable_process_wait_pickup(data, '5');


        console.log('80', tab);
      }
      else if (tab == '90') {
        this.dataset_delivering.page = 1;
        this.dataset_delivering.dataTable = [];
        this.dataset_delivering.selected = [];
        this.dataset_delivering.offsets = 0;
        keyword = this.dataset_delivering.keyword;
        keyword.channelID = this.dataset_delivering.search.select_channel.channelID;
        keyword.expressCompanyID = null;
        keyword.printType = null;
        keyword.isSelectAll = false;
        keyword.packageStatusID = "90";

        this.dataset_delivering.keyword = keyword;
        this.dataset_delivering.offsets = 0
        this.dataset_delivering.limits = 10

        data = await this.SearchOrder(this.dataset_delivering.offsets, this.dataset_delivering.limits, null, keyword, warehouse_select);
        this.setDataTable_dataset_delivering(data, '90');


        console.log('90', tab);
      }
      else if (tab == '130') {
        this.dataset_success.page = 1;
        this.dataset_success.dataTable = [];
        this.dataset_success.selected = [];
        this.dataset_success.offsets = 0;
        keyword = this.dataset_success.keyword;
        keyword.channelID = this.dataset_success.search.select_channel.channelID;
        keyword.expressCompanyID = null;
        keyword.printType = null;
        keyword.isSelectAll = false;
        keyword.packageStatusID = "130";

        this.dataset_success.keyword = keyword;
        this.dataset_success.offsets = 0
        this.dataset_success.limits = 10

        data = await this.SearchOrder(this.dataset_success.offsets, this.dataset_success.limits, null, keyword, warehouse_select);
        this.setDataTable_dataset_success(data, '130');


        console.log('130', tab);
      }
      else if (tab == '100') {
        this.dataset_Return_Returnning.page = 1;
        this.dataset_Return_Returnning.dataTable = [];
        this.dataset_Return_Returnning.selected = [];
        this.dataset_Return_Returnning.offsets = 0;
        keyword = this.dataset_Return_Returnning.keyword;
        keyword.channelID = this.dataset_Return_Returnning.search.select_channel.channelID;
        keyword.expressCompanyID = null;
        keyword.printType = null;
        keyword.isSelectAll = false;
        keyword.packageStatusID = "100";

        this.dataset_Return_Returnning.keyword = keyword;
        this.dataset_Return_Returnning.offsets = 0
        this.dataset_Return_Returnning.limits = 10

        data = await this.SearchOrder(this.dataset_Return_Returnning.offsets, this.dataset_Return_Returnning.limits, null, keyword, warehouse_select);
        this.setDataTable_Return_Returnning(data, '100');


        console.log('100', tab);
      }
      else if (tab == '110') {
        this.dataset_Return_To_Return_Stock.page = 1;
        this.dataset_Return_To_Return_Stock.dataTable = [];
        this.dataset_Return_To_Return_Stock.selected = [];
        this.dataset_Return_To_Return_Stock.offsets = 0;
        keyword = this.dataset_Return_To_Return_Stock.keyword;
        keyword.channelID = this.dataset_Return_To_Return_Stock.search.select_channel.channelID;
        keyword.expressCompanyID = null;
        keyword.printType = null;
        keyword.isSelectAll = false;
        keyword.packageStatusID = "110";

        this.dataset_Return_To_Return_Stock.keyword = keyword;
        this.dataset_Return_To_Return_Stock.offsets = 0
        this.dataset_Return_To_Return_Stock.limits = 10

        data = await this.SearchOrder(this.dataset_Return_To_Return_Stock.offsets, this.dataset_Return_To_Return_Stock.limits, null, keyword, warehouse_select);
        this.setDataTable_Return_To_Return_Stock(data, '110');


        console.log('110', tab);
      }
      else if (tab == '120') {
        this.dataset_Return_Stock_Returned.page = 1;
        this.dataset_Return_Stock_Returned.dataTable = [];
        this.dataset_Return_Stock_Returned.selected = [];
        this.dataset_Return_Stock_Returned.offsets = 0;
        keyword = this.dataset_Return_Stock_Returned.keyword;
        keyword.channelID = this.dataset_Return_Stock_Returned.search.select_channel.channelID;
        keyword.expressCompanyID = null;
        keyword.printType = null;
        keyword.isSelectAll = false;
        keyword.packageStatusID = "120";

        this.dataset_Return_Stock_Returned.keyword = keyword;
        this.dataset_Return_Stock_Returned.offsets = 0
        this.dataset_Return_Stock_Returned.limits = 10

        data = await this.SearchOrder(this.dataset_Return_Stock_Returned.offsets, this.dataset_Return_Stock_Returned.limits, null, keyword, warehouse_select);
        this.setDataTable_Return_Stock_Returned(data, '120');


        console.log('120', tab);
      }
      else if (tab == '140') {
        this.dataset_cancel.page = 1;
        this.dataset_cancel.dataTable = [];
        this.dataset_cancel.selected = [];
        this.dataset_cancel.offsets = 0;
        keyword = this.dataset_cancel.keyword;
        keyword.channelID = this.dataset_cancel.search.select_channel.channelID;
        keyword.expressCompanyID = null;
        keyword.printType = null;
        keyword.isSelectAll = false;
        keyword.packageStatusID = "110";

        this.dataset_cancel.keyword = keyword;
        this.dataset_cancel.offsets = 0
        this.dataset_cancel.limits = 10

        data = await this.SearchOrder(this.dataset_cancel.offsets, this.dataset_cancel.limits, null, keyword, warehouse_select);
        this.setDataTable_dataset_cancel(data, '140');


        console.log('140', tab);
      }

    },
    async btnExprees(tab, expressCompanyID) {
      console.log("btnExprees", tab);
      console.log("btnExprees", expressCompanyID);


      var data, keyword;

      var warehouse_select = await this.map_tab_list_herder_1();


      if (tab == '1') {
        this.dataset_wait_payment_wait_payment.page = 1;
        this.dataset_wait_payment_wait_payment.dataTable = [];
        this.dataset_wait_payment_wait_payment.selected = [];
        this.dataset_wait_payment_wait_payment.offsets = 0;
        keyword = this.dataset_wait_payment_wait_payment.keyword;
        keyword.expressCompanyID = this.dataset_wait_payment_wait_payment.search.select_express.expressCompanyID;
        keyword.printType = null;
        keyword.isSelectAll = false;


        this.dataset_wait_payment_wait_payment.keyword = keyword;
        this.dataset_wait_payment_wait_payment.offsets = 0
        this.dataset_wait_payment_wait_payment.limits = 10

        data = await this.SearchOrder(this.dataset_wait_payment_wait_payment.offsets, this.dataset_wait_payment_wait_payment.limits, null, keyword, warehouse_select);
        this.setDataTable_wait_payment_wait_payment(data, '1');


        console.log('1', tab);
      }
      else if (tab == '5') {
        this.dataset_wait_payment_check_payment.page = 1;
        this.dataset_wait_payment_check_payment.dataTable = [];
        this.dataset_wait_payment_check_payment.selected = [];
        this.dataset_wait_payment_check_payment.offsets = 0;
        keyword = this.dataset_wait_payment_check_payment.keyword;
        keyword.expressCompanyID = this.dataset_wait_payment_check_payment.search.select_express.expressCompanyID;
        keyword.printType = null;
        keyword.isSelectAll = false;

        this.dataset_wait_payment_check_payment.keyword = keyword;
        this.dataset_wait_payment_check_payment.offsets = 0
        this.dataset_wait_payment_check_payment.limits = 10

        data = await this.SearchOrder(this.dataset_wait_payment_check_payment.offsets, this.dataset_wait_payment_check_payment.limits, null, keyword, warehouse_select);
        this.setDataTable_wait_payment_check_payment(data, '5');

        console.log('5', tab);
      }
      else if (tab == '10') {
        this.dataset_process_map_product.page = 1;
        this.dataset_process_map_product.dataTable = [];
        this.dataset_process_map_product.selected = [];
        this.dataset_process_map_product.offsets = 0;
        keyword = this.dataset_process_map_product.keyword;
        keyword.expressCompanyID = this.dataset_process_map_product.search.select_express.expressCompanyID;
        keyword.printType = null;
        keyword.isSelectAll = false;

        this.dataset_process_map_product.keyword = keyword;
        this.dataset_process_map_product.offsets = 0
        this.dataset_process_map_product.limits = 10

        data = await this.SearchOrder(this.dataset_process_map_product.offsets, this.dataset_process_map_product.limits, null, keyword, warehouse_select);
        this.setDataTable_process_map_product(data, '10');


        console.log('10', tab);
      }
      else if (tab == '20') {
        this.dataset_process_allocate_stock.page = 1;
        this.dataset_process_allocate_stock.dataTable = [];
        this.dataset_process_allocate_stock.selected = [];
        this.dataset_process_allocate_stock.offsets = 0;
        keyword = this.dataset_process_allocate_stock.keyword;
        keyword.expressCompanyID = this.dataset_process_allocate_stock.search.select_express.expressCompanyID;
        keyword.printType = null;
        keyword.isSelectAll = false;

        this.dataset_process_allocate_stock.keyword = keyword;
        this.dataset_process_allocate_stock.offsets = 0
        this.dataset_process_allocate_stock.limits = 10

        data = await this.SearchOrder(this.dataset_process_allocate_stock.offsets, this.dataset_process_allocate_stock.limits, null, keyword, warehouse_select);
        this.setDataTable_process_allocate_stock(data, '20');


        console.log('20', tab);
      }
      else if (tab == '30') {
        this.dataset_process_ready_to_ship.page = 1;
        this.dataset_process_ready_to_ship.dataTable = [];
        this.dataset_process_ready_to_ship.selected = [];
        this.dataset_process_ready_to_ship.offsets = 0;
        keyword = this.dataset_process_ready_to_ship.keyword;
        keyword.expressCompanyID = this.dataset_process_ready_to_ship.search.select_express.expressCompanyID;
        keyword.printType = null;
        keyword.isSelectAll = false;

        this.dataset_process_ready_to_ship.keyword = keyword;
        this.dataset_process_ready_to_ship.offsets = 0
        this.dataset_process_ready_to_ship.limits = 10

        data = await this.SearchOrder(this.dataset_process_ready_to_ship.offsets, this.dataset_process_ready_to_ship.limits, null, keyword, warehouse_select);
        this.setDataTable_process_ready_to_ship(data, '30');


        console.log('30', tab);
      }
      else if (tab == '40') {
        this.dataset_process_to_pack.page = 1;
        this.dataset_process_to_pack.dataTable = [];
        this.dataset_process_to_pack.selected = [];
        this.dataset_process_to_pack.offsets = 0;
        keyword = this.dataset_process_to_pack.keyword;
        keyword.expressCompanyID = this.dataset_process_to_pack.search.select_express.expressCompanyID;
        keyword.printType = null;
        keyword.isSelectAll = false;

        this.dataset_process_to_pack.keyword = keyword;
        this.dataset_process_to_pack.offsets = 0
        this.dataset_process_to_pack.limits = 10

        data = await this.SearchOrder(this.dataset_process_to_pack.offsets, this.dataset_process_to_pack.limits, null, keyword, warehouse_select);
        this.setDataTable_process_to_pack(data, '40');


        console.log('40', tab);
      }
      else if (tab == '60') {
        this.dataset_process_packing.page = 1;
        this.dataset_process_packing.dataTable = [];
        this.dataset_process_packing.selected = [];
        this.dataset_process_packing.offsets = 0;
        keyword = this.dataset_process_packing.keyword;
        keyword.expressCompanyID = this.dataset_process_packing.search.select_express.expressCompanyID;
        keyword.printType = null;
        keyword.isSelectAll = false;

        this.dataset_process_packing.keyword = keyword;
        this.dataset_process_packing.offsets = 0
        this.dataset_process_packing.limits = 10

        data = await this.SearchOrder(this.dataset_process_packing.offsets, this.dataset_process_packing.limits, null, keyword, warehouse_select);
        this.setDataTable_process_packing(data, '60');


        console.log('60', tab);
      }
      else if (tab == '80') {
        this.dataset_process_wait_pickup.page = 1;
        this.dataset_process_wait_pickup.dataTable = [];
        this.dataset_process_wait_pickup.selected = [];
        this.dataset_process_wait_pickup.offsets = 0;
        keyword = this.dataset_process_wait_pickup.keyword;
        keyword.expressCompanyID = this.dataset_process_wait_pickup.search.select_express.expressCompanyID;
        keyword.printType = null;
        keyword.isSelectAll = false;

        this.dataset_process_wait_pickup.keyword = keyword;
        this.dataset_process_wait_pickup.offsets = 0
        this.dataset_process_wait_pickup.limits = 10

        data = await this.SearchOrder(this.dataset_process_wait_pickup.offsets, this.dataset_process_wait_pickup.limits, null, keyword, warehouse_select);
        this.setDataTable_process_wait_pickup(data, '5');


        console.log('80', tab);
      }
      else if (tab == '90') {
        this.dataset_delivering.page = 1;
        this.dataset_delivering.dataTable = [];
        this.dataset_delivering.selected = [];
        this.dataset_delivering.offsets = 0;
        keyword = this.dataset_delivering.keyword;
        keyword.expressCompanyID = this.dataset_delivering.search.select_express.expressCompanyID;
        keyword.printType = null;
        keyword.isSelectAll = false;

        this.dataset_delivering.keyword = keyword;
        this.dataset_delivering.offsets = 0
        this.dataset_delivering.limits = 10

        data = await this.SearchOrder(this.dataset_delivering.offsets, this.dataset_delivering.limits, null, keyword, warehouse_select);
        this.setDataTable_dataset_delivering(data, '90');


        console.log('90', tab);
      }
      else if (tab == '130') {
        this.dataset_success.page = 1;
        this.dataset_success.dataTable = [];
        this.dataset_success.selected = [];
        this.dataset_success.offsets = 0;
        keyword = this.dataset_success.keyword;
        keyword.expressCompanyID = this.dataset_success.search.select_express.expressCompanyID;
        keyword.printType = null;
        keyword.isSelectAll = false;

        this.dataset_success.keyword = keyword;
        this.dataset_success.offsets = 0
        this.dataset_success.limits = 10

        data = await this.SearchOrder(this.dataset_success.offsets, this.dataset_success.limits, null, keyword, warehouse_select);
        this.setDataTable_dataset_success(data, '130');


        console.log('130', tab);
      }
      else if (tab == '100') {
        this.dataset_Return_Returnning.page = 1;
        this.dataset_Return_Returnning.dataTable = [];
        this.dataset_Return_Returnning.selected = [];
        this.dataset_Return_Returnning.offsets = 0;
        keyword = this.dataset_Return_Returnning.keyword;
        keyword.expressCompanyID = this.dataset_Return_Returnning.search.select_express.expressCompanyID;
        keyword.printType = null;
        keyword.isSelectAll = false;

        this.dataset_Return_Returnning.keyword = keyword;
        this.dataset_Return_Returnning.offsets = 0
        this.dataset_Return_Returnning.limits = 10

        data = await this.SearchOrder(this.dataset_Return_Returnning.offsets, this.dataset_Return_Returnning.limits, null, keyword, warehouse_select);
        this.setDataTable_Return_Returnning(data, '100');


        console.log('100', tab);
      }
      else if (tab == '110') {
        this.dataset_Return_To_Return_Stock.page = 1;
        this.dataset_Return_To_Return_Stock.dataTable = [];
        this.dataset_Return_To_Return_Stock.selected = [];
        this.dataset_Return_To_Return_Stock.offsets = 0;
        keyword = this.dataset_Return_To_Return_Stock.keyword;
        keyword.expressCompanyID = this.dataset_Return_To_Return_Stock.search.select_express.expressCompanyID;
        keyword.printType = null;
        keyword.isSelectAll = false;


        this.dataset_Return_To_Return_Stock.keyword = keyword;
        this.dataset_Return_To_Return_Stock.offsets = 0
        this.dataset_Return_To_Return_Stock.limits = 10

        data = await this.SearchOrder(this.dataset_Return_To_Return_Stock.offsets, this.dataset_Return_To_Return_Stock.limits, null, keyword, warehouse_select);
        this.setDataTable_Return_To_Return_Stock(data, '110');


        console.log('110', tab);
      }
      else if (tab == '120') {
        this.dataset_Return_Stock_Returned.page = 1;
        this.dataset_Return_Stock_Returned.dataTable = [];
        this.dataset_Return_Stock_Returned.selected = [];
        this.dataset_Return_Stock_Returned.offsets = 0;
        keyword = this.dataset_Return_Stock_Returned.keyword;
        keyword.expressCompanyID = this.dataset_Return_Stock_Returned.search.select_express.expressCompanyID;
        keyword.printType = null;
        keyword.isSelectAll = false;

        this.dataset_Return_Stock_Returned.keyword = keyword;
        this.dataset_Return_Stock_Returned.offsets = 0
        this.dataset_Return_Stock_Returned.limits = 10

        data = await this.SearchOrder(this.dataset_Return_Stock_Returned.offsets, this.dataset_Return_Stock_Returned.limits, null, keyword, warehouse_select);
        this.setDataTable_Return_Stock_Returned(data, '120');


        console.log('120', tab);
      }
      else if (tab == '140') {
        this.dataset_cancel.page = 1;
        this.dataset_cancel.dataTable = [];
        this.dataset_cancel.selected = [];
        this.dataset_cancel.offsets = 0;
        keyword = this.dataset_cancel.keyword;
        keyword.expressCompanyID = this.dataset_cancel.search.select_express.expressCompanyID;
        keyword.printType = null;
        keyword.isSelectAll = false;

        this.dataset_cancel.keyword = keyword;
        this.dataset_cancel.offsets = 0
        this.dataset_cancel.limits = 10

        data = await this.SearchOrder(this.dataset_cancel.offsets, this.dataset_cancel.limits, null, keyword, warehouse_select);
        this.setDataTable_dataset_cancel(data, '140');


        console.log('140', tab);
      }

    },
    async btnSearchPrintType(tab, expressCompanyID) {
      console.log("btnSearchPrintType", tab);
      console.log("btnSearchPrintType", expressCompanyID);


      var data, keyword;

      var warehouse_select = await this.map_tab_list_herder_1();


      if (tab == '1') {
        this.dataset_wait_payment_wait_payment.page = 1;
        this.dataset_wait_payment_wait_payment.dataTable = [];
        this.dataset_wait_payment_wait_payment.selected = [];
        this.dataset_wait_payment_wait_payment.offsets = 0;
        keyword = this.dataset_wait_payment_wait_payment.keyword;
        keyword.printType = this.dataset_wait_payment_wait_payment.search.select_print.id;
        keyword.isSelectAll = false;


        this.dataset_wait_payment_wait_payment.keyword = keyword;
        this.dataset_wait_payment_wait_payment.offsets = 0
        this.dataset_wait_payment_wait_payment.limits = 10

        data = await this.SearchOrder(this.dataset_wait_payment_wait_payment.offsets, this.dataset_wait_payment_wait_payment.limits, null, keyword, warehouse_select);
        this.setDataTable_wait_payment_wait_payment(data, '1');


        console.log('1', tab);
      }
      else if (tab == '5') {
        this.dataset_wait_payment_check_payment.page = 1;
        this.dataset_wait_payment_check_payment.dataTable = [];
        this.dataset_wait_payment_check_payment.selected = [];
        this.dataset_wait_payment_check_payment.offsets = 0;
        keyword = this.dataset_wait_payment_check_payment.keyword;
        keyword.printType = this.dataset_wait_payment_check_payment.search.select_print.id;
        keyword.isSelectAll = false;

        this.dataset_wait_payment_check_payment.keyword = keyword;
        this.dataset_wait_payment_check_payment.offsets = 0
        this.dataset_wait_payment_check_payment.limits = 10

        data = await this.SearchOrder(this.dataset_wait_payment_check_payment.offsets, this.dataset_wait_payment_check_payment.limits, null, keyword, warehouse_select);
        this.setDataTable_wait_payment_check_payment(data, '5');

        console.log('5', tab);
      }
      else if (tab == '10') {
        this.dataset_process_map_product.page = 1;
        this.dataset_process_map_product.dataTable = [];
        this.dataset_process_map_product.selected = [];
        this.dataset_process_map_product.offsets = 0;
        keyword = this.dataset_process_map_product.keyword;
        keyword.printType = this.dataset_process_map_product.search.select_print.id;
        keyword.isSelectAll = false;

        this.dataset_process_map_product.keyword = keyword;
        this.dataset_process_map_product.offsets = 0
        this.dataset_process_map_product.limits = 10

        data = await this.SearchOrder(this.dataset_process_map_product.offsets, this.dataset_process_map_product.limits, null, keyword, warehouse_select);
        this.setDataTable_process_map_product(data, '10');


        console.log('10', tab);
      }
      else if (tab == '20') {
        this.dataset_process_allocate_stock.page = 1;
        this.dataset_process_allocate_stock.dataTable = [];
        this.dataset_process_allocate_stock.selected = [];
        this.dataset_process_allocate_stock.offsets = 0;
        keyword = this.dataset_process_allocate_stock.keyword;
        keyword.printType = this.dataset_process_allocate_stock.search.select_print.id;
        keyword.isSelectAll = false;

        this.dataset_process_allocate_stock.keyword = keyword;
        this.dataset_process_allocate_stock.offsets = 0
        this.dataset_process_allocate_stock.limits = 10

        data = await this.SearchOrder(this.dataset_process_allocate_stock.offsets, this.dataset_process_allocate_stock.limits, null, keyword, warehouse_select);
        this.setDataTable_process_allocate_stock(data, '20');


        console.log('20', tab);
      }
      else if (tab == '30') {
        this.dataset_process_ready_to_ship.page = 1;
        this.dataset_process_ready_to_ship.dataTable = [];
        this.dataset_process_ready_to_ship.selected = [];
        this.dataset_process_ready_to_ship.offsets = 0;
        keyword = this.dataset_process_ready_to_ship.keyword;
        keyword.printType = this.dataset_process_ready_to_ship.search.select_print.id;
        keyword.isSelectAll = false;

        this.dataset_process_ready_to_ship.keyword = keyword;
        this.dataset_process_ready_to_ship.offsets = 0
        this.dataset_process_ready_to_ship.limits = 10

        data = await this.SearchOrder(this.dataset_process_ready_to_ship.offsets, this.dataset_process_ready_to_ship.limits, null, keyword, warehouse_select);
        this.setDataTable_process_ready_to_ship(data, '30');


        console.log('30', tab);
      }
      else if (tab == '40') {
        this.dataset_process_to_pack.page = 1;
        this.dataset_process_to_pack.dataTable = [];
        this.dataset_process_to_pack.selected = [];
        this.dataset_process_to_pack.offsets = 0;
        keyword = this.dataset_process_to_pack.keyword;
        keyword.printType = this.dataset_process_to_pack.search.select_print.id;
        keyword.isSelectAll = false;

        this.dataset_process_to_pack.keyword = keyword;
        this.dataset_process_to_pack.offsets = 0
        this.dataset_process_to_pack.limits = 10

        data = await this.SearchOrder(this.dataset_process_to_pack.offsets, this.dataset_process_to_pack.limits, null, keyword, warehouse_select);
        this.setDataTable_process_to_pack(data, '40');


        console.log('40', tab);
      }
      else if (tab == '60') {
        this.dataset_process_packing.page = 1;
        this.dataset_process_packing.dataTable = [];
        this.dataset_process_packing.selected = [];
        this.dataset_process_packing.offsets = 0;
        keyword = this.dataset_process_packing.keyword;
        keyword.printType = this.dataset_process_packing.search.select_print.id;
        keyword.isSelectAll = false;

        this.dataset_process_packing.keyword = keyword;
        this.dataset_process_packing.offsets = 0
        this.dataset_process_packing.limits = 10

        data = await this.SearchOrder(this.dataset_process_packing.offsets, this.dataset_process_packing.limits, null, keyword, warehouse_select);
        this.setDataTable_process_packing(data, '60');


        console.log('60', tab);
      }
      else if (tab == '80') {
        this.dataset_process_wait_pickup.page = 1;
        this.dataset_process_wait_pickup.dataTable = [];
        this.dataset_process_wait_pickup.selected = [];
        this.dataset_process_wait_pickup.offsets = 0;
        keyword = this.dataset_process_wait_pickup.keyword;
        keyword.printType = this.dataset_process_wait_pickup.search.select_print.id;
        keyword.isSelectAll = false;

        this.dataset_process_wait_pickup.keyword = keyword;
        this.dataset_process_wait_pickup.offsets = 0
        this.dataset_process_wait_pickup.limits = 10

        data = await this.SearchOrder(this.dataset_process_wait_pickup.offsets, this.dataset_process_wait_pickup.limits, null, keyword, warehouse_select);
        this.setDataTable_process_wait_pickup(data, '5');


        console.log('80', tab);
      }
      else if (tab == '90') {
        this.dataset_delivering.page = 1;
        this.dataset_delivering.dataTable = [];
        this.dataset_delivering.selected = [];
        this.dataset_delivering.offsets = 0;
        keyword = this.dataset_delivering.keyword;
        keyword.printType = this.dataset_delivering.search.select_print.id;
        keyword.isSelectAll = false;

        this.dataset_delivering.keyword = keyword;
        this.dataset_delivering.offsets = 0
        this.dataset_delivering.limits = 10

        data = await this.SearchOrder(this.dataset_delivering.offsets, this.dataset_delivering.limits, null, keyword, warehouse_select);
        this.setDataTable_dataset_delivering(data, '90');


        console.log('90', tab);
      }
      else if (tab == '130') {
        this.dataset_success.page = 1;
        this.dataset_success.dataTable = [];
        this.dataset_success.selected = [];
        this.dataset_success.offsets = 0;
        keyword = this.dataset_success.keyword;
        keyword.printType = this.dataset_success.search.select_print.id;
        keyword.isSelectAll = false;

        this.dataset_success.keyword = keyword;
        this.dataset_success.offsets = 0
        this.dataset_success.limits = 10

        data = await this.SearchOrder(this.dataset_success.offsets, this.dataset_success.limits, null, keyword, warehouse_select);
        this.setDataTable_dataset_success(data, '130');


        console.log('130', tab);
      }
      else if (tab == '100') {
        this.dataset_Return_Returnning.page = 1;
        this.dataset_Return_Returnning.dataTable = [];
        this.dataset_Return_Returnning.selected = [];
        this.dataset_Return_Returnning.offsets = 0;
        keyword = this.dataset_Return_Returnning.keyword;
        keyword.printType = this.dataset_Return_Returnning.search.select_print.id;
        keyword.isSelectAll = false;

        this.dataset_Return_Returnning.keyword = keyword;
        this.dataset_Return_Returnning.offsets = 0
        this.dataset_Return_Returnning.limits = 10

        data = await this.SearchOrder(this.dataset_Return_Returnning.offsets, this.dataset_Return_Returnning.limits, null, keyword, warehouse_select);
        this.setDataTable_Return_Returnning(data, '100');


        console.log('100', tab);
      }
      else if (tab == '110') {
        this.dataset_Return_To_Return_Stock.page = 1;
        this.dataset_Return_To_Return_Stock.dataTable = [];
        this.dataset_Return_To_Return_Stock.selected = [];
        this.dataset_Return_To_Return_Stock.offsets = 0;
        keyword = this.dataset_Return_To_Return_Stock.keyword;
        keyword.printType = this.dataset_Return_To_Return_Stock.search.select_print.id;
        keyword.isSelectAll = false;


        this.dataset_Return_To_Return_Stock.keyword = keyword;
        this.dataset_Return_To_Return_Stock.offsets = 0
        this.dataset_Return_To_Return_Stock.limits = 10

        data = await this.SearchOrder(this.dataset_Return_To_Return_Stock.offsets, this.dataset_Return_To_Return_Stock.limits, null, keyword, warehouse_select);
        this.setDataTable_Return_To_Return_Stock(data, '110');


        console.log('110', tab);
      }
      else if (tab == '120') {
        this.dataset_Return_Stock_Returned.page = 1;
        this.dataset_Return_Stock_Returned.dataTable = [];
        this.dataset_Return_Stock_Returned.selected = [];
        this.dataset_Return_Stock_Returned.offsets = 0;
        keyword = this.dataset_Return_Stock_Returned.keyword;
        keyword.printType = this.dataset_Return_Stock_Returned.search.select_print.id;
        keyword.isSelectAll = false;

        this.dataset_Return_Stock_Returned.keyword = keyword;
        this.dataset_Return_Stock_Returned.offsets = 0
        this.dataset_Return_Stock_Returned.limits = 10

        data = await this.SearchOrder(this.dataset_Return_Stock_Returned.offsets, this.dataset_Return_Stock_Returned.limits, null, keyword, warehouse_select);
        this.setDataTable_Return_Stock_Returned(data, '120');


        console.log('120', tab);
      }
      else if (tab == '140') {
        this.dataset_cancel.page = 1;
        this.dataset_cancel.dataTable = [];
        this.dataset_cancel.selected = [];
        this.dataset_cancel.offsets = 0;
        keyword = this.dataset_cancel.keyword;
        keyword.printType = this.dataset_cancel.search.select_print.id;
        keyword.isSelectAll = false;


        this.dataset_cancel.keyword = keyword;
        this.dataset_cancel.offsets = 0
        this.dataset_cancel.limits = 10

        data = await this.SearchOrder(this.dataset_cancel.offsets, this.dataset_cancel.limits, null, keyword, warehouse_select);
        this.setDataTable_dataset_cancel(data, '140');


        console.log('140', tab);
      }

    },
    async btnSelectedTarget(tab) {

      var data, keyword, item, total;

      var warehouse_select = await this.map_tab_list_herder_1();


      if (tab == '1') {


        this.dataset_wait_payment_wait_payment.selected = [];

        total = this.dataset_wait_payment_wait_payment.total
        keyword = { ...this.dataset_wait_payment_wait_payment.keyword };
        keyword.isSelectAll = true;

        data = await this.SearchOrder(0, total, null, keyword, warehouse_select);

        for (item of data.orderMasterPackageInfoList) {
          this.dataset_wait_payment_wait_payment.selected.push({
            packageID: item,
          });
        }



        console.log('1', tab);
      }
      else if (tab == '5') {

        this.dataset_wait_payment_check_payment.selected = [];

        total = this.dataset_wait_payment_check_payment.total
        keyword = { ...this.dataset_wait_payment_check_payment.keyword };
        keyword.isSelectAll = true;

        data = await this.SearchOrder(0, total, null, keyword, warehouse_select);

        for (item of data.orderMasterPackageInfoList) {
          this.dataset_wait_payment_check_payment.selected.push({
            packageID: item,
          });
        }


        console.log('5', tab);
      }
      else if (tab == '10') {

        this.dataset_process_map_product.selected = [];

        total = this.dataset_process_map_product.total
        keyword = { ...this.dataset_process_map_product.keyword };
        keyword.isSelectAll = true;

        data = await this.SearchOrder(0, total, null, keyword, warehouse_select);

        for (item of data.orderMasterPackageInfoList) {
          this.dataset_process_map_product.selected.push({
            packageID: item,
          });
        }



        console.log('10', tab);
      }
      else if (tab == '20') {

        this.dataset_process_allocate_stock.selected = [];

        total = this.dataset_process_allocate_stock.total
        keyword = { ...this.dataset_process_allocate_stock.keyword };
        keyword.isSelectAll = true;

        data = await this.SearchOrder(0, total, null, keyword, warehouse_select);

        for (item of data.orderMasterPackageInfoList) {
          this.dataset_process_allocate_stock.selected.push({
            packageID: item,
          });
        }


        console.log('20', tab);
      }
      else if (tab == '30') {

        this.dataset_process_ready_to_ship.selected = [];

        total = this.dataset_process_ready_to_ship.total
        keyword = { ...this.dataset_process_ready_to_ship.keyword };
        keyword.isSelectAll = true;

        data = await this.SearchOrder(0, total, null, keyword, warehouse_select);

        for (item of data.orderMasterPackageInfoList) {
          this.dataset_process_ready_to_ship.selected.push({
            packageID: item,
          });
        }


        console.log('30', tab);
      }
      else if (tab == '40') {

        this.dataset_process_to_pack.selected = [];

        total = this.dataset_process_to_pack.total
        keyword = { ...this.dataset_process_to_pack.keyword };
        keyword.isSelectAll = true;

        data = await this.SearchOrder(0, total, null, keyword, warehouse_select);

        for (item of data.orderMasterPackageInfoList) {
          this.dataset_process_to_pack.selected.push({
            packageID: item,
          });
        }



        console.log('40', tab);
      }
      else if (tab == '60') {

        this.dataset_process_packing.selected = [];

        total = this.dataset_process_packing.total
        keyword = { ...this.dataset_process_packing.keyword };
        keyword.isSelectAll = true;

        data = await this.SearchOrder(0, total, null, keyword, warehouse_select);

        for (item of data.orderMasterPackageInfoList) {
          this.dataset_process_packing.selected.push({
            packageID: item,
          });
        }



        console.log('60', tab);
      }
      else if (tab == '80') {

        this.dataset_process_wait_pickup.selected = [];

        total = this.dataset_process_wait_pickup.total
        keyword = { ...this.dataset_process_wait_pickup.keyword };
        keyword.isSelectAll = true;

        data = await this.SearchOrder(0, total, null, keyword, warehouse_select);

        for (item of data.orderMasterPackageInfoList) {
          this.dataset_process_wait_pickup.selected.push({
            packageID: item,
          });
        }


        console.log('80', tab);
      }
      else if (tab == '90') {

        this.dataset_delivering.selected = [];

        total = this.dataset_delivering.total
        keyword = { ...this.dataset_delivering.keyword };
        keyword.isSelectAll = true;

        data = await this.SearchOrder(0, total, null, keyword, warehouse_select);

        for (item of data.orderMasterPackageInfoList) {
          this.dataset_delivering.selected.push({
            packageID: item,
          });
        }



        console.log('90', tab);
      }
      else if (tab == '130') {

        this.dataset_success.selected = [];

        total = this.dataset_success.total
        keyword = { ...this.dataset_success.keyword };
        keyword.isSelectAll = true;

        data = await this.SearchOrder(0, total, null, keyword, warehouse_select);

        for (item of data.orderMasterPackageInfoList) {
          this.dataset_success.selected.push({
            packageID: item,
          });
        }


        console.log('130', tab);
      }
      else if (tab == '100') {

        this.dataset_Return_Returnning.selected = [];

        total = this.dataset_Return_Returnning.total
        keyword = { ...this.dataset_Return_Returnning.keyword };
        keyword.isSelectAll = true;

        data = await this.SearchOrder(0, total, null, keyword, warehouse_select);

        for (item of data.orderMasterPackageInfoList) {
          this.dataset_Return_Returnning.selected.push({
            packageID: item,
          });
        }


        console.log('100', tab);
      }
      else if (tab == '110') {

        this.dataset_Return_To_Return_Stock.selected = [];

        total = this.dataset_Return_To_Return_Stock.total
        keyword = { ...this.dataset_Return_To_Return_Stock.keyword };
        keyword.isSelectAll = true;

        data = await this.SearchOrder(0, total, null, keyword, warehouse_select);

        for (item of data.orderMasterPackageInfoList) {
          this.dataset_Return_To_Return_Stock.selected.push({
            packageID: item,
          });
        }



        console.log('110', tab);
      }
      else if (tab == '120') {

        this.dataset_Return_Stock_Returned.selected = [];

        total = this.dataset_Return_Stock_Returned.total
        keyword = { ...this.dataset_Return_Stock_Returned.keyword };
        keyword.isSelectAll = true;

        data = await this.SearchOrder(0, total, null, keyword, warehouse_select);

        for (item of data.orderMasterPackageInfoList) {
          this.dataset_Return_Stock_Returned.selected.push({
            packageID: item,
          });
        }


        console.log('120', tab);
      }
      else if (tab == '140') {

        this.dataset_cancel.selected = [];

        total = this.dataset_cancel.total
        keyword = { ...this.dataset_cancel.keyword };
        keyword.isSelectAll = true;

        data = await this.SearchOrder(0, total, null, keyword, warehouse_select);

        for (item of data.orderMasterPackageInfoList) {
          this.dataset_cancel.selected.push({
            packageID: item,
          });
        }



        console.log('140', tab);
      }



    },

    // ส่วนของ btn ที่เป็น dialog
    async btnMappedProduct(tab) {
      console.log("btnMappedProduct", tab);

    },
    async btnFilterOrder(tab) {

      this.filteOrderDataSet.tab = 0;
      this.filteOrderDataSet.sku_start = 1;
      this.filteOrderDataSet.sku_end = 1;
      this.filteOrderDataSet.item_start = 1;
      this.filteOrderDataSet.item_end = 1;
      this.filteOrderDataSet.item_end = 1;
      this.filteOrderDataSet.radio_item = 0;
      this.filteOrderDataSet.radio_sku = 0;
      this.filteOrderDataSet.productList = [];
      this.filteOrderDataSet.dataset = [];
      this.filteOrderDataSet.mapProductList = [];
      let dataTableFilterSetSelected = await this.getDataTableFilterSetSelected(tab);

      if (dataTableFilterSetSelected.length > 0) {
        // this.loading = true;
        let selectedBody = dataTableFilterSetSelected;
        const packageIDList = selectedBody
          .map((item) => item.packageID)
          .map((packageID) => Number(packageID));

        let amount_of_data_each = 50; // จำนวนข้อมูลที่มีในแต่ละก้อน
        let countObject = packageIDList.length;
        let loopCountFetch = Math.ceil(countObject / amount_of_data_each); // จำนวนที่ต้องวนข้อมูล
        let bodyOut = [];
        let objList = [];
        // if (this.filteOrderDataSet.filteOrderList.length > 0) {
        //   const packageIDArray = Object.values(packageIDList);
        //   this.filteOrderDataSet.filteOrderList.forEach((packageIDList) => {
        //     const filterSelected = packageIDArray.find(
        //       (item) => item == packageIDList.packageID
        //     );
        //     if (filterSelected) {
        //       objList.push(packageIDList);
        //     }
        //   });
        // } else {
        for (let i = 0; i < loopCountFetch; i++) {
          bodyOut[i] = packageIDList.splice(0, amount_of_data_each);
          var data_obj = await this.getFilterOrder(bodyOut[i]);
          await this.getPercentLoading(i + 1, loopCountFetch);
          if (data_obj != null) {
            objList.push(...data_obj);
          }
        }
        // }
        // เช็คว่ามี SKU ซ้ำไหม
        var indexCount = 1;
        const newData = objList.reduce((acc, curr) => {
          const found = acc.find((item) => item.stockShopID === curr.stockShopID);
          if (found) {
            found.orderCount += 1;
            found.quantityToPack += curr.quantityToPack;
            found.outStock -= curr.quantityToPack;
            found.outStockShow = found.outStock < 0 ? found.outStock : "";
          } else {
            curr.orderCount = 1;
            curr.outStock = curr.currentQuantity - curr.quantityToPack;
            curr.outStockShow = curr.outStock < 0 ? curr.outStock : "";
            curr.no = indexCount;
            acc.push({ ...curr });
            indexCount = indexCount + 1;
          }
          return acc;
        }, []);
        // map ข้อมูล ที่ orderMasterID เหมือนกันอยู่ใน arry เดียวกัน
        const mapData = [];

        objList.forEach((order) => {
          const existingOrder = mapData.find(
            (item) => item.orderMasterID === order.orderMasterID
          );
          if (existingOrder) {
            existingOrder.item.push(order);
            existingOrder.itemCount = existingOrder.itemCount + order.quantityToPack;
            existingOrder.skuCount += 1;
          } else {
            mapData.push({
              orderMasterID: order.orderMasterID,
              item: [order],
              itemCount: order.quantityToPack,
              printpackinglistTime: order.printpackinglistTime,
              skuCount: 1,
              packageID: order.packageID,
            });
          }
        });
        //console.log(mapData);
        const mapSkuCount = [];
        //จัดกลุ่มตามจำนวน SKU เท่ากัน
        const skuCountsGroup = [...new Set(mapData.map((item) => item.skuCount))].sort(
          (a, b) => a - b
        );

        //วนข้อมูลเพื่อดึงมา
        skuCountsGroup.forEach((skuCount) => {
          //กรองข้อมูลที่มาค่าเท่ากัน
          const filteredData = mapData.filter((item) => item.skuCount === skuCount);
          const orderCount = filteredData.length;
          const printY = filteredData.filter((item) => item.printpackinglistTime !== null)
            .length;
          const printN = filteredData.filter((item) => item.printpackinglistTime === null)
            .length;

          mapSkuCount.push({
            orderCount,
            PrintY: printY,
            PrintN: printN,
            skuCount,
          });
        });

        this.filteOrderDataSet.filteOrderList = objList; // ไว้สำหรับแสดง จำนวน SKU ในออเดอร์
        this.filteOrderDataSet.mapProductList = mapSkuCount; // ไว้สำหรับแสดง จำนวน SKU ในออเดอร์
        this.filteOrderDataSet.productList = mapData; // ไว้สำหรับค้นหาต้อนใส่ข้อมูล
        this.filteOrderDataSet.dataset = newData; //  ไว้แสดงข้อมูล
        this.filteOrderDataSet.selected = newData; // ไว้เลิอกในการแสดง
        // this.loading = false;
        this.filterOrderPopup.dialog = true;
      }



    },
    async btnSumitFilteOrder() {

      var NameTab = await this.getNameTab();

      // this.loading = true;
      var warehouse_select = await this.map_tab_list_herder_1();

      var clone = [...this.filteOrderDataSet.selected];

      var data = [...this.filteOrderDataSet.dataset];

      var dataList = [...this.filteOrderDataSet.productList];

      // กรองข้อมูลที่มี stockShopID ไม่ซ้ำกัน  ระหว่าง clone กับ data คือข้อมูลที่ไม่ถูกเลือกออกมา
      const filteredData = data.filter((obj1) => {
        return !clone.some((obj2) => {
          return obj1.stockShopID == obj2.stockShopID;
        });
      });

      //แปลงเป็นเลขโดยนำจากข้อมูลที่เลือก
      const itemStockShopIdSelected = clone.map((item) => item.stockShopID);
      //แปลงเป็นเลขโดยนำจากข้อมูลที่ไม่เลือก
      const itemStockShopIdNotSelected = filteredData.map((item) => item.stockShopID);
      //แปลงเป็นเลขโดยนำจากข้อมูลทั้งหมด โดยเอา packageID แต่ละ Iiem
      const itemStockPackageIDList = [
        ...new Set(dataList.flatMap((data) => data.item.map((item) => item.packageID))),
      ];

      const PackageIDList = [];
      for (let index = 0; index < dataList.length; index++) {
        //เช็คว่าต้องเช็คจากจำนวน sku มั้ย
        if (this.filteOrderDataSet.radio_sku) {
          if (
            dataList[index].skuCount >= this.filteOrderDataSet.sku_start &&
            dataList[index].skuCount <= this.filteOrderDataSet.sku_end
          ) {
            //ไม่ต้องทำอะไร
          } else {
            //ถ้าจำนวน sku ไม่อยู๋ใน range ที่เลือก ให้ข้ามไปเลย
            continue;
          }
        }

        //เช็คว่าต้องเช็คจากจำนวน สินค้า มั้ย
        if (this.filteOrderDataSet.radio_item) {
          if (
            dataList[index].itemCount >= this.filteOrderDataSet.item_start &&
            dataList[index].itemCount <= this.filteOrderDataSet.item_end
          ) {
            //ไม่ต้องทำอะไร
          } else {
            //ถ้าจำนวนสินค้าไม่อยู๋ใน range ที่เลือก ให้ข้ามไปเลย
            continue;
          }
        }
        if (itemStockShopIdNotSelected.length > 0) {
          const existingOrder = dataList[index].item.find((item) =>
            itemStockShopIdNotSelected.includes(item.stockShopID)
          );
          if (existingOrder) {
            continue;
          }
        }
        PackageIDList.push(dataList[index].packageID);
      }

      var keyword = this[NameTab].keyword; //ใช้ชื่อ [NameTab] จากตอนส่ง
      keyword.isSelectAll = false;
      this[NameTab].keyword = keyword; //เก็บค่าไว้

      let amount_of_data_each = 50; // จำนวนข้อมูลที่มีในแต่ละก้อน
      let TotalRoundToFetchData = Math.ceil(PackageIDList.length / amount_of_data_each); // จำนวนที่ต้องวนข้อมูล
      let objList = [];



      var indexNo = 1;
      try {
        this.loading = true;
        for (let i = 0; i < TotalRoundToFetchData; i++) {
          var TempPackageIDList = PackageIDList.splice(0, amount_of_data_each);
          let data_obj = await this.SearchOrder(
            0,
            amount_of_data_each,
            TempPackageIDList,
            keyword,
            warehouse_select
          );
          for (let index = 0; index < data_obj.resultData.length; index++) {
            data_obj.resultData[index].no = indexNo;
            indexNo = indexNo + 1;
          }
          await this.getPercentLoading(i + 1, TotalRoundToFetchData);
          objList.push(...data_obj.resultData);
        }
      } catch (error) {
        this.loading = false;
        Swal.fire({
          icon: "warning",
          title: "ไม่สามารถทำรายการได้ กรุณาทำรายการอีกครั้ง",
          showConfirmButton: true,
          confirmButtonText: "OK",
        });
        return;
      }



      this[NameTab].limits = 999999; //กำหนด [NameTab] คือชื่อของค่า 
      this[NameTab].dataTable = objList;
      this[NameTab].total = objList.resultCount;
      this[NameTab].pageLength = 1;
      this[NameTab].selected = [];
      this.filteOrderDataSet.dataset = [];
      this.loading = false;
      this.filterOrderPopup.dialog = false;
    },
    async btnExportExcelFilteOrder() {
      return this.filteOrderDataSet.headerExcel;
    },
    async btnAllocateStock(tab) {
      // ไม่มีในส่วนนี้
      console.log("btnAllocateStock", tab);
    },
    async btnUpdateToMarketplace() {
      console.log("btnUpdateToMarketplace");
      var NameTab = await this.getNameTab();

      const packageIDList = this[NameTab].selected.map((item) => item.packageID);

      let data = await this.prepareUpdateToMarketplace(packageIDList);

      if (data.error_description == null) {
        this.UpdateToMarketplacePopup.marketplaceName =
          (await data.marketplaceName) != null ? data.marketplaceName : null;
        this.UpdateToMarketplaceDataSet.dataset = data.orderList;
        this.UpdateToMarketplaceDataSet.datasetError = [];
        this.UpdateToMarketplaceDataSet.success = 0;
        this.UpdateToMarketplaceDataSet.error = 0;
        this.UpdateToMarketplacePopup.tab = 0;

        if (data.marketplaceName == "shopee") {
          this.UpdateToMarketplaceDataSet.shopeeType = 0;
          this.UpdateToMarketplaceDataSet.pickupTimeID = null;

          if (data.shopeeInitData.response.pickup.address_list) {
            this.UpdateToMarketplaceDataSet.pickup = await data.shopeeInitData.response
              .pickup.address_list;

            //หา ตำแหน่งที่ให้เลือกเริ่มต้น
            const index = await this.UpdateToMarketplaceDataSet.pickup.findIndex((item) =>
              item.address_flag.includes("pickup_address")
            );

            //นำค่ามาใส่ เริ่มต้น และ ใส่เวลา
            this.UpdateToMarketplaceDataSet.selectedAddress = index;
            this.UpdateToMarketplaceDataSet.selectedpickupTime = this.UpdateToMarketplaceDataSet.pickup[
              index
            ];
          }
        }
        if (data.marketplaceName == "tiktok") {
          this.UpdateToMarketplaceDataSet.tiktokType = 0;
        }
        this.UpdateToMarketplaceDataSet.loading = false;
        this.UpdateToMarketplaceDataSet.loadingPercent = false;
        this.UpdateToMarketplacePopup.dialog = true;
      }

    },
    async btnSubmitToMarketPlace(marketplaceName) {
      let Round = 0;

      let countLoop = this.UpdateToMarketplaceDataSet.dataset.map(
        (item) => item.orderMasterID
      ).length;
      this.UpdateToMarketplaceDataSet.loading = true;
      this.UpdateToMarketplaceDataSet.loadingPercent = true;

      if (marketplaceName == "shopee") {
        for (const item of this.UpdateToMarketplaceDataSet.dataset) {
          Round++;
          let body = {
            packageID: item.packageID,
            orderMasterID: item.orderMasterID,
            addressID:
              this.UpdateToMarketplaceDataSet.pickupTimeID == null
                ? null
                : this.UpdateToMarketplaceDataSet.selectedpickupTime.address_id,
            pickupTimeID: this.UpdateToMarketplaceDataSet.pickupTimeID,
          };
          this.getPercentLoading(Round, countLoop);
          if (body.addressID != null) {
            body.addressID = body.addressID.toString();
          }
          let data = await this.RTS_shopee_package(body);
          if (data.status == "success") {
            this.$set(item, "processing", data.status);
            this.$set(item, "message", data.message["th"]);
            this.UpdateToMarketplaceDataSet.success++;
          } else {
            this.$set(item, "processing", "error");
            this.$set(
              item,
              "error_description",
              data.message["th"] + data.error_description
            );
            this.UpdateToMarketplaceDataSet.datasetError.push(item);
            this.UpdateToMarketplaceDataSet.error++;
          }
        }
      }
      if (marketplaceName == "lazada") {
        for (const item of this.UpdateToMarketplaceDataSet.dataset) {
          Round++;
          let body = { packageID: item.packageID };
          this.getPercentLoading(Round, countLoop);
          let data = await this.RTS_lazada_package(body);
          if (data.status == "success") {
            this.$set(item, "processing", data.status);
            this.$set(item, "message", data.message["th"]);
            this.UpdateToMarketplaceDataSet.success++;
          } else {
            this.$set(item, "processing", "error");
            this.$set(
              item,
              "error_description",
              data.message["th"] + data.error_description
            );
            this.UpdateToMarketplaceDataSet.datasetError.push(item);
            this.UpdateToMarketplaceDataSet.error++;
          }
        }
      }
      if (marketplaceName == "tiktok") {
        for (const item of this.UpdateToMarketplaceDataSet.dataset) {
          Round++;
          let body = {
            packageID: item.packageID,
            handover_method: this.UpdateToMarketplaceDataSet.handover_method,
          };
          this.getPercentLoading(Round, countLoop);
          let data = await this.RTS_tiktok_order(body);
          if (data.status == "success") {
            this.$set(item, "processing", data.status);
            this.$set(item, "message", data.message["th"]);
            this.UpdateToMarketplaceDataSet.success++;
          } else {
            this.$set(item, "processing", "error");
            this.$set(
              item,
              "error_description",
              data.message["th"] + data.error_description
            );
            this.UpdateToMarketplaceDataSet.datasetError.push(item);
            this.UpdateToMarketplaceDataSet.error++;
          }
        }
      }
    },
    async btnPrintBarcode(tab) {
      console.log("btnPrintBarcode", tab);

    },
    async btnPrintAwb(tab) {
      console.log("btnPrintAwb", tab);

    },
    async btnPackageChangeStatus() {
      console.log("btnPackageChangeStatus");

      var NameTab = await this.getNameTab();


      const packageIDList = this[NameTab].selected.map((item) => item.packageID);

      this.changeStatusDataSet.showManageChangeStatusList = this[NameTab].ManageChangeStatusList;

      let data = await this.getPreparePackageBeforeChangeStatus(packageIDList);
      this.changeStatusDataSet.dataset = data.packageList;
      this.changeStatusDataSet.changeStatus = null;
      this.changeStatusDataSet.success = 0;
      this.changeStatusDataSet.error = 0;
      this.changeStatusDataSet.datasetError = [];
      this.changeStatusPopup.tab = 0;
      this.changeStatusPopup.dialog = true;

    },
    async btnSubmitChamgeStatus() {
      console.log("btnSubmitChamgeStatus");

      this.changeStatusDataSet.loadingPercent = true;
      this.changeStatusDataSet.loading = true;

      let Round = 0;
      let countLoop = this.changeStatusDataSet.dataset.length;

      for (const item of this.changeStatusDataSet.dataset) {
        Round++;
        this.getPercentLoading(Round, countLoop);
        let data = await this.changePackageStatus(
          item.packageID,
          this.changeStatusDataSet.changeStatus.ID
        );

        if (data.status == "success") {
          this.$set(item, "processing", data.status);
          this.changeStatusDataSet.success++;
        } else {
          this.$set(item, "processing", "error");
          this.$set(item, "error_description", data.error_description);
          this.changeStatusDataSet.datasetError.push(item);
          this.changeStatusDataSet.error++;
        }
      }
    },
    async btnPackageBackStatus(tab) {
      console.log("btnPackageBackStatus", tab);


      var NameTab = await this.getNameTab();


      const packageIDList = this[NameTab].selected.map((item) => item.packageID);

      this.backStatusDataSet.showManageBackStatusList = this[NameTab].ManageChangeStatusList;


      let data = await this.getPreparePackageBeforeChangeStatus(packageIDList);
      this.backStatusDataSet.dataset = data.packageList;
      this.backStatusDataSet.changeStatus = null;
      this.backStatusDataSet.success = 0;
      this.backStatusDataSet.error = 0;
      this.backStatusDataSet.datasetError = [];
      this.backStatusPopup.tab = 0;
      this.backStatusPopup.dialog = true;

    },
    async btnSubmitBackStatus() {
      this.backStatusDataSet.loadingPercent = true;
      this.backStatusDataSet.loading = true;
      let Round = 0;
      let countLoop = this.backStatusDataSet.dataset.length;

      for (const item of this.backStatusDataSet.dataset) {
        Round++;
        this.getPercentLoading(Round, countLoop);
        let data = await this.changePackageStatus(
          item.packageID,
          this.backStatusDataSet.changeStatus.ID
        );

        if (data.status == "success") {
          this.$set(item, "processing", data.status);
          this.backStatusDataSet.success++;
        } else {
          this.$set(item, "processing", "error");
          this.$set(item, "error_description", data.error_description);
          this.backStatusDataSet.datasetError.push(item);
          this.backStatusDataSet.error++;
        }
      }
    },
    async btnViewReport() {
      console.log("btnViewReport");

      var NameTab = await this.getNameTab();
      const packageIDList = this[NameTab].selected.map((item) => item.packageID);

      let body = [];
      this.reportOrderDataSet.header = [...this.reportOrderDataSet.headerIN];
      var headerItem = this.reportOrderDataSet.header.find(
        (item) => item.text === "ชื่อ"
      );

      if (this.set_TabListSubHeader_Process == '10' && this.set_TabListHeader == '10-20-30-40-60-80') {
        headerItem.value = "name";
        headerItem.field = "name";
        body = await this.getAllReportMapProduct(packageIDList);
        if (body.length > 0) {
          this.reportOrderDataSet.header = this.reportOrderDataSet.header.filter((item) =>
            Object.keys(body.productListToMap[0]).includes(item.value)
          );
          this.reportOrderDataSet.dataset = await body.productListToMap;
        }
      } else {
        let bodyin = [];
        headerItem.value = "productName";
        headerItem.field = "productName";
        bodyin.packageIDList = packageIDList;
        body = await this.getAllViewReportPackd(bodyin);
        this.reportOrderDataSet.header = this.reportOrderDataSet.header.filter((item) =>
          Object.keys(body.productList[0]).includes(item.value)
        );
        this.reportOrderDataSet.dataset = await body.productList;
      }

      this.reportOrderPopup.dialog = true;

    },
    async btnExportExcel(tab) {
      console.log("btnExportExcel", tab);

    },
    async btnApproveBalanceCheck(tab) {
      console.log("btnExportExcel", tab);

    },
    FormatOrder,
    length60,
    AlertError,
    AlertSuccess,
    formatMoney,
    formatDatetime,
    GetDate,
    get_languages,
  },
};
</script>
<style scoped>
.box-shadow-picker {
  border: 1px solid;
  border-radius: 5px;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%),
    0px 1px 5px 0px rgb(0 0 0 / 12%);
  background: white;
}
</style>
